<template>
  <div
    class="shadow-lg"
    :id="widgetId"
    @contextmenu="this.setContextIdWidget"
    :style="{
      height: this.widgetHeight + 'px',
      width: this.widgetWidth + 'px',
      paxyAxisngTop: '2px',
      background: 'var(--widgetBg)',
      transition: 'var(--transition)',
      'border-radius': '10px',
    }"
  >
    <div
      class="block tool_group"
      :style="{
        top: this.mouse_y - 150 + 'px',
        left: this.mouse_x + 50 + 'px',
      }"
      :id="'msetooltip' + this.widgetId"
    >
      <div v-if="isTvdTooltip" class="actc_tool text-white">
        <p :key="i" v-for="(msg, i) of tvdTooltipValues.split('</br>')">
          {{ msg }}
        </p>
      </div>
    </div>
    <div class="text-white h-Full p-2" style="color: var(--textColor)">
      <div class="flex justify-between flex-wrap">
        <!-- <div></div> -->
        <div class="selections px-2 flex items-center">
          <div
            v-if="
              logType == 'time' &&
              (primaryYaxisFullName?.value || secondaryYaxisFullName?.value)
            "
          >
            <ul class="flex" style="gap: 5px">
              <li
                class="timeList activeRecord"
                v-if="!hours.includes(timeDifference)"
                disabled
              >
                <span>{{ timeDifference }} </span>
                <span class="pl-1">HRS</span>
              </li>
              <li
                class="timeList cursor-pointer"
                @click="selectedDifference(hour)"
                :class="timeDifference == hour && 'activeRecord'"
                v-for="(hour, index) of hours"
                :key="index"
              >
                <span>{{ hour }} </span>
                <span class="pl-1">{{ hour == 1 ? "HR" : "HRS" }}</span>
              </li>
            </ul>
          </div>
          <div
            style="font-size: 9px"
            class="flex items-center pr-3"
            v-if="
              logType == 'depth' &&
              (primaryYaxisFullName?.value || secondaryYaxisFullName?.value)
            "
          >
            <div class="start flex items-center" style="width: 75%">
              <label class="uppercase">START</label>
              <input
                type="number"
                :value="minX"
                step="0.01"
                class="newStWDeptInput ml-1"
                @change="startManualDepthValidation($event)"
              />
            </div>
            <div class="end flex items-center mx-3" style="width: 75%">
              <label class="uppercase">End</label>
              <input
                type="number"
                :value="maxX"
                step="0.01"
                class="newStWDeptInput ml-1"
                @change="endManualDepthValidation($event)"
              />
            </div>
            <ul class="flex" style="gap: 5px">
              <li
                class="timeList activeRecord"
                v-if="!depths.includes(depthDifference)"
                disabled
              >
                <span>{{ depthDifference }}FT </span>
              </li>
              <li
                class="timeList cursor-pointer"
                @click="selectedDifference(depth)"
                :class="depthDifference == depth && 'activeRecord'"
                v-for="(depth, index) of depths"
                :key="index"
              >
                <span>{{ depth }}FT </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="flex items-center justify-between __toggle__boxes">
          <div class="flex px-2">
            <div
              style="font-size: 9px"
              class="mt-1 settingPopColor mb-1 cursor-pointer text_xs newStylesLiveBtn"
              @click="resetZoomHandler(), liveClick()"
              :style="
                isWellLive && !draggedRangeElement
                  ? 'color: #fff; background:var(--liveBtnGreen)'
                  : 'background:#b6adb4; color:#fff;'
              "
            >
              <div class="liveBtnDot" style="background: #fff"></div>
              LIVE
            </div>
          </div>
          <div class="flex px-2">
            <div
              :style="{ fontSize: 9 + 'px' }"
              class="mt-1 resetbutton settingPopColor mb-1 cursor-pointer activeRecord px-3 rounded-lg"
              @click="resetZoomHandler(), liveClick()"
            >
              Reset
            </div>
          </div>
          <div class="flex px-2">
            <div
              :style="{ fontSize: 9 + 'px' }"
              class="mt-1 settingPopColor mb-1"
            >
              Zoom
            </div>
            <div class="ml-0.5 tool_section">
              <input
                type="checkbox"
                :id="'zoom' + this.widgetId"
                class="tool_toggle_input"
                v-model="isZoomEnable"
              />
              <label
                class="tool_toggle_label _zoom ml-1 mb-1"
                :for="'zoom' + this.widgetId"
                >Zoom :</label
              >
            </div>
          </div>
          <div class="normalBg flex px-2">
            <div
              :style="{ fontSize: 9 + 'px' }"
              class="mt-1 settingPopColor mb-1"
            >
              Tooltip
            </div>
            <div class="ml-0.5 tool_section">
              <input
                v-model="isTooltipEnable"
                type="checkbox"
                :id="'tooltip' + this.widgetId"
                class="tool_toggle_input"
              />
              <label
                class="tool_toggle_label _zoom ml-1 mb-1"
                :for="'tooltip' + this.widgetId"
                >Tooltip :</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <CanvasMSEKPItChatVue
      :id="'canvasXy_axis'"
      :totl_height="cHeight - 90"
      :totl_width="cWidth"
      :log-type="logType"
      :enableTooltip="isTooltipEnable"
      :enableZoom="isZoomEnable"
      :primaryAxixX="primaryXaxisFullName"
      :primaryAxixY="primaryYaxisFullName"
      :secondaryAxixY="secondaryYaxisFullName"
      :enableSecondaryYaxis="isSecondaryAxis"
      :min_X="minX"
      :min_Y="minY"
      :min_Y_Secondary="minY1"
      :max_X="maxX"
      :max_Y="maxY"
      :max_Y_Secondary="maxY1"
      :data_set="primarDataSet"
      :data_set_secondary="secondaryDataSet"
      :primaryAxisLabelColor="axisLabelColor"
      :secondaryAxisLabelColor="secondaryAxisLabelColor"
      :gridColor="gridColor"
      :primaryGrapgColor="graphScaleColor"
      :secondaryGrapgColor="graphSecondaryScaleColor"
      :xAxisLabelSize="xAxisLabelSize"
      :yAxisLabelSize="yAxisLabelSize"
      :yAxisLabelSizeSecondary="yAxisSecondaryLabelSize"
      :primaryYaxisUnit="primaryYaxisUnit"
      :secondryYaxisUnit="secondryYaxisUnit"
      :primaryYaxisUnitFactor="primaryYaxisUnitFactor"
      :secondaryYaxisUnitFactor="secondaryYaxisUnitFactor"
      @xCoordsAfterZoom="xCoordsAfterZoom"
      @onDoubleClickOfDexponent="onDoubleClickOfDexponent"
      @dbclick="onDoubleClickOfDexponent"
      ref="canvasXyAxisGraph"
      :time_zone="time_zone"
      :key="refreshXyCanvasKey"
    />
    <div
      class="w-full flex justify-center items-center relative"
      style="top: -3px"
    >
      <div v-if="primaryYaxisFullName?.value" class="flex items-center">
        <span
          style="width: 30px; height: 5px"
          :style="{ background: graphScaleColor ? graphScaleColor : '#ff0000' }"
        ></span>
        <span class="pl-1" style="color: var(--textColor); font-size: 9px">
          {{ primaryYaxisFullName?.name }}</span
        >
      </div>
      <div v-if="secondaryYaxisFullName?.value" class="flex items-center pl-5">
        <span
          style="width: 30px; height: 5px"
          :style="{
            background: graphSecondaryScaleColor
              ? graphSecondaryScaleColor
              : '#0000ff',
          }"
        ></span>
        <span class="pl-1" style="color: var(--textColor); font-size: 9px">
          {{ secondaryYaxisFullName?.name }}</span
        >
      </div>
    </div>
    <div
      class="w-full flex"
      v-if="
        (primaryYaxisFullName?.value || secondaryYaxisFullName?.value) &&
        isSlider
      "
    >
      <input
        class="mx-auto"
        style="width: 95%"
        type="range"
        :value="rangeValue"
        @change="maxXSliderRange($event, 'fromRange')"
        :step="
          logType == 'time' && logInterval
            ? logInterval.split('_')[0] * 1000
            : 1
        "
        :min="
          logType == 'depth'
            ? minMsxTime.min + depthDifference
            : parseFloat(minMsxTime.min) +
              parseFloat(this.timeDifference * 1000 * 60 * 60)
        "
        :max="minMsxTime.max"
      />
    </div>
    <!-- <div :id="'xyAxis' + widgetId"></div> -->
    <div id="d3Tooltip" class="tooltipd3"></div>
  </div>
</template>
<script>
import { max, min } from "d3";
var svg = "";
let id = null;
import apiService from "../../../../api/services";
import moment from "moment/src/moment";
import CanvasMSEKPItChatVue from "./CanvasMseChat copy.vue";
import helperServices from "../../../../helper-services";

export default {
  name: "MSE-KPI",
  components: {
    CanvasMSEKPItChatVue,
  },
  props: {
    idx: [String, Number],
    cardBackground: {
      type: String,
      default: "var(navBar2Bg)",
    },
    widgetHeight: {
      type: Number,
      default: 200,
    },
    widgetWidth: {
      type: Number,
      default: 350,
    },
    widgetpopHeight: {
      type: Number,
      default: 480,
    },
    widgetpopWidth: {
      type: Number,
      default: 1080,
    },
    displayId: String,
    id: String,
    gridColor: {
      type: String,
      default: "",
    },
    gridBackground: {
      type: String,
      default: "",
    },
    axisLabelColor: {
      type: String,
      require: false,
      default: "",
    },
    xAxisLabelSize: {
      Number,
    },
    yAxisLabelSize: {
      type: Number,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    widgetId: String,
    isSwitched: {
      type: Boolean,
      default: false,
    },
    widgetUnitL: {
      type: String,
      default: "",
    },
    unitDisplay: {
      type: Boolean,
      default: true,
    },
    openConfig: Function,
    isSecondaryAxis: {
      type: Boolean,
      require: false,
      default: false,
    },
    graphScaleColor: {
      type: String,
      require: false,
      default: "",
    },
    secondaryAxisLabelColor: {
      type: String,
      require: false,
      default: "",
    },
    graphSecondaryScaleColor: {
      type: String,
      require: false,
      default: "",
    },
    yAxisSecondaryLabelSize: {
      type: Number,
      require: false,
      default: 10,
    },
    primaryXaxisFullName: {
      type: Object,
      require: false,
      default: { name: "time", value: "time" },
    },
    primaryXaxisUnit: {
      type: String,
      require: false,
      default: "",
    },
    primaryYaxisFullName: {
      type: String,
      require: false,
      default: { name: "MSE", value: "msetotal" },
    },
    primaryYaxisUnit: {
      type: String,
      require: false,
      default: "",
    },
    secondaryYaxisFullName: {
      type: String,
      require: false,
      default: { name: "mnemonic", value: "" },
    },
    secondryYaxisUnit: {
      type: String,
      require: false,
      default: "",
    },
    isSlider: {
      type: Boolean,
      default: false,
    },
    themeColor: {
      type: String,
      default: "",
    },
    primaryYaxisUnitFactor: {
      type: String,
      default: "",
    },
    secondaryYaxisUnitFactor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isTvdTooltip: false,
      tvdTooltipValues: "",
      mouse_x: 0,
      mouse_y: 0,
      minX: 0,
      maxX: 100,
      minY: 0,
      maxY: 3500,
      minY1: 0,
      maxY1: 1000,
      width: this.widgetWidth,
      height: this.widgetHeight,
      widgetUnit: this.widgetUnitL,
      widgetUnits: this.widgetUnitL,
      displayName: "ss",
      displayNames: "yy",
      wellId: null,
      wellboreId: null,
      logId: null,
      logType: null,
      mnemonic_groups: {},
      minMsxTime: {},
      completeData: {},
      isTooltipEnable: false,
      cWidth: this.widgetWidth,
      cHeight: this.widgetHeight,
      primarDataSet: [],
      secondaryDataSet: [],
      isZoomEnable: false,
      refreshXyCanvasKey: 5665,
      topic: null,
      rangeValue: null,
      draggedRangeElement: false,
      timeDifference: 1,
      depthDifference: 50,
      hours: [1, 2, 8, 12, 24],
      depths: [50, 100, 500, 1000],
      isWellLive: false,
      idx: null,
      object: {},
      time_zone: null,
      logInterval: null,
    };
  },
  beforeMount() {
    let id = "id" + Math.random().toString(16).slice(2);
    this.tempWidgetId = id;
    try {
      let rects = this.$store.state.rect.rects;
      if (typeof rects != "undefined" && rects.length > 0) {
        for (let i = 0; i < rects.length; i++) {
          if (
            rects[i].widgetId == this.widgetId &&
            rects[i].displayId == this.displayId
          ) {
            this.idx = i;
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  },
  async mounted() {
    this.rangeValue = null;
    this.tempWidgetId = this.backgroundId || this.tempWidgetId;
    let dtls = this.$store.state.disp.displays[this.displayId];
    if (
      dtls != null &&
      dtls.wellId != null &&
      dtls.wellboreId != null &&
      dtls.wellboreState != null
    ) {
      this.takeOffSocket();
      this.wellId = this.wellId = dtls.wellId;
      this.wellboreId = this.wellboreId = dtls.wellboreId;
      this.logId = this.logId = dtls.logId;
      this.logType = dtls.logType;
      this.topic = dtls.topicName;
      this.minMsxTime = await this.getMinMaxRange();
      this.completeData = null;
      this.draggedRangeElement = false;
      this.timeDifference = 1;
      this.depthDifference = 50;
      this.time_zone = dtls.time_zone || "CST6CDT";
      this.logInterval = dtls?.well_interval || null;
      // moment.tz.setDefault(this.time_zone);
      this.logType = this.logType == "trajectory" ? "time" : this.logType;
      if (this.logType) {
        this.primaryXaxisFullName.name = this.logType;
        this.primaryXaxisFullName.value =
          this.logType == "depth" ? "dmea" : this.logType;
        this.object = this.$store.state.rect.rects[this.idx].MSE;

        await this.commonDispatcher("MSE", {
          ...this.object,
          primaryXaxisFullName: this.primaryXaxisFullName,
        });
      }
      if (this.primaryXaxisFullName && this.primaryXaxisFullName.value) {
        await this.prepareGraphDetail(this.primaryXaxisFullName.value);
      }
      if (this.primaryYaxisFullName && this.primaryYaxisFullName.value) {
        await this.prepareGraphDetail(this.primaryYaxisFullName.value);
        this.getRealtimeData();
      }
      if (this.secondaryYaxisFullName && this.secondaryYaxisFullName.value) {
        await this.prepareGraphDetail(this.secondaryYaxisFullName.value);
        this.getRealtimeData();
      }
    }
    this.$store.subscribe(async (mutation, state) => {
      if (
        mutation.type == "disp/setDisplay" &&
        this.displayId == mutation.payload.display &&
        this.id
      ) {
        console.log("mutation.payload", mutation.payload);
        this.takeOffSocket();
        this.wellId = mutation.payload.wellId;
        this.wellboreId = mutation.payload.wellboreId;
        this.logId = mutation.payload.logId;
        this.topic = mutation.payload.topicName;
        this.time_zone = mutation.payload.time_zone || "CST6CDT";
        this.logInterval = mutation.payload?.well_interval || null;
        // moment.tz.setDefault(this.time_zone);

        this.logType =
          mutation.payload.logType == "trajectory"
            ? "time"
            : mutation.payload.logType;
        if (this.logType) {
          this.primaryXaxisFullName.name = this.logType;

          this.primaryXaxisFullName.value =
            this.logType == "depth" ? "dmea" : this.logType;
          this.object = this.$store.state.rect.rects[this.idx].MSE;
          await this.commonDispatcher("MSE", {
            ...this.object,
            primaryXaxisFullName: this.primaryXaxisFullName,
          });
        }
        this.logTypeId = mutation.payload.logTypeId;
        this.minMsxTime = await this.getMinMaxRange();
        this.completeData = null;
        this.draggedRangeElement = false;
        this.timeDifference = 1;
        this.depthDifference = 50;
        if (this.primaryXaxisFullName && this.primaryXaxisFullName.value) {
          await this.prepareGraphDetail(this.primaryXaxisFullName.value);
        }
        if (this.primaryYaxisFullName && this.primaryYaxisFullName.value) {
          await this.prepareGraphDetail(this.primaryYaxisFullName.value);
          this.getRealtimeData();
        }
        if (this.secondaryYaxisFullName && this.secondaryYaxisFullName.value) {
          await this.prepareGraphDetail(this.secondaryYaxisFullName.value);
          this.getRealtimeData();
        }
      } else if (mutation.type == "rect/DARK_MODE") {
        this.refreshXyCanvasKey += 13;
      }
    });
    this.buildSVG();
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
    axiosParams() {
      const params = new URLSearchParams();
      params.append("well_id", this.wellId);
      params.append("well_bore_id", this.wellboreId);
      params.append("customer", this.customer);
      return params;
    },
  },
  sockets: {
    connect: function () {
      console.log("socket connected in trend");
    },
    disconnect() {
      try {
        let topic = this.topic;
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error("disconnect unsubscribe error ", error);
      }
    },

    reconnect() {
      this.$socket.emit("subscribe", {
        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
        topic: this.topic,
        fields: this.fields,
        id: this.widgetId,
        clientId: this.$clientId,
      });
      console.log("----------------------------------------");
    },
  },
  methods: {
    async commonDispatcher(keyToDispatch, val) {
      console.log(keyToDispatch, val, "dispacther in mse");
      setTimeout(() => {
        this.$store.dispatch("rect/swabSurgeRealTimedata", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: val,
          field: keyToDispatch,
        });
      }, 300);
    },
    onDoubleClickOfDexponent() {
      this.$emit("dexponentEmits");
      console.log("function calling");
    },
    async xCoordsAfterZoom(minX, maxX) {
      this.draggedRangeElement = true;
      if (this.logType == "time") {
        let Min_forTimediffTime = moment(minX).unix() * 1000;
        let Max_forTimediffTime = moment(maxX).unix() * 1000;
        this.timeDifference = Number(
          (Max_forTimediffTime - Min_forTimediffTime) / (60 * 60 * 1000)
        ).toFixed(2);
      } else {
        this.minX = Number(minX).toFixed(2);
        this.maxX = Number(maxX).toFixed(2);
        this.depthDifference = Number(maxX - minX).toFixed(2);
      }
    },
    async startManualDepthValidation(event) {
      this.$store.dispatch("data/startLoading");
      console.log(this.maxY, "maxy");
      setTimeout(async () => {
        const inputValue = +event.target.value;
        if (inputValue >= this.maxX) {
          event.target.value = this.minX;
          this.$toast.clear();
          this.$toast.error(
            `Start depth should not be greater than end depth`,
            {
              duration: "2000",
              position: "top",
              pauseOnHover: true,
            }
          );
        } else if (inputValue < this.minMsxTime.min) {
          event.target.value = this.minX;
          this.$toast.clear();
          this.$toast.error(
            `Start depth should not be less than ${this.minMsxTime.min}`,
            {
              duration: "2000",
              position: "top",
              pauseOnHover: true,
            }
          );
        } else {
          this.minX = +event.target.value;
          // this.filterData();
          await this.graphData();
          // this.refreshXyCanvasKey += 38;
        }

        this.depthDifference = this.maxX - this.minX;
        this.rangeValue = parseFloat(this.maxX);
        this.$store.dispatch("data/stopLoading");
      }, 0);
      // this.draggedRangeElement = true;
      // await this.selectedDifference(this.maxY - this.minY);
    },
    // async startManualDepthValidation(event) {
    //   event.target.value = parseFloat(event.target.value)
    //   if (event.target.value >= this.maxX) {
    //     event.target.value = this.minX;
    //     this.$toast.clear();
    //     this.$toast.error(`Start depth should not be greater than end depth`, {
    //       duration: "2000",
    //       position: "top",
    //       pauseOnHover: true,
    //     });
    //   } else {
    //     this.minX = event.target.value;
    //   }
    //   // this.depthDifference = this.maxX - this.minX
    //   this.rangeValue = this.maxX;
    //   this.draggedRangeElement = true;
    //   await this.selectedDifference(this.maxX - this.minX);
    // },
    // async endManualDepthValidation(event) {
    //   event.target.value = parseFloat(event.target.value)
    //   if (event.target.value <= this.minX) {
    //     event.target.value = this.maxX;
    //     this.$toast.clear();
    //     this.$toast.error(`End depth should be greater than start depth`, {
    //       duration: "2000",
    //       position: "top",
    //       pauseOnHover: true,
    //     });
    //   } else if (this.minMsxTime.max < event.target.value) {
    //     event.target.value = this.maxX;
    //     this.$toast.clear();
    //     this.$toast.error(
    //       `End depth should not be greater than ${this.minMsxTime.max}`,
    //       { duration: "2000", position: "top", pauseOnHover: true }
    //     );
    //   } else {
    //     this.maxX = event.target.value;
    //   }
    //   // this.depthDifference = this.maxX - this.minX
    //   this.rangeValue = this.maxX;
    //   this.draggedRangeElement = true;
    //   await this.selectedDifference(this.maxX - this.minX);
    // },
    async endManualDepthValidation(event) {
      this.$store.dispatch("data/startLoading");

      setTimeout(async () => {
        const inputValue = +event.target.value;
        if (inputValue <= this.minX) {
          event.target.value = this.maxX;
          this.$toast.clear();
          this.$toast.error(`End depth should be greater than start depth`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        } else if (this.minMsxTime.max < inputValue) {
          event.target.value = this.maxX;
          this.$toast.clear();
          this.$toast.error(
            `End depth should not be greater than ${this.minMsxTime.max}`,
            { duration: "2000", position: "top", pauseOnHover: true }
          );
        } else {
          this.maxX = inputValue;
          // this.filterData();
          await this.graphData();
          // this.refreshXyCanvasKey += 50;
        }
        this.depthDifference = this.maxX - this.minX;
        this.rangeValue = parseFloat(this.maxX);
        this.$store.dispatch("data/stopLoading");
        // this.draggedRangeElement = true;
        // await this.selectedDifference(this.maxY - this.min);
      }, 0);
    },
    async wellStatusCheck() {
      try {
        let wellDetails = await apiService.WellServices.getWellDetails({
          well_id: this.wellId,
          well_borename: this.wellboreId,
          customer: this.$store.state.data.customer,
          log: this.logType,
        });
        if (
          (wellDetails?.data?.well_status == 4 ||
            wellDetails?.data?.well_status == 104) &&
          !this.draggedRangeElement
        ) {
          this.isWellLive = true;
        } else {
          this.isWellLive = false;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async graphData() {
      console.log("coming to graph data on resize");

      let xy = [];
      let xy1 = [];
      this.primarDataSet = [];
      this.secondaryDataSet = [];

      if (
        this.primaryYaxisFullName &&
        this.primaryYaxisFullName.value &&
        this.completeData[this.primaryYaxisFullName.value]
      ) {
        this.minY = min(
          this.completeData[this.primaryYaxisFullName.value],
          (d) => d
        );
        this.maxY = max(
          this.completeData[this.primaryYaxisFullName.value],
          (d) => d
        );
        if (this.primaryYaxisUnitFactor && this.primaryYaxisUnitFactor != "") {
          this.minY *= this.primaryYaxisUnitFactor;
          this.maxY *= this.primaryYaxisUnitFactor;
        }
        for (
          let i = 0;
          i < this.completeData[this.primaryYaxisFullName.value].length;
          i++
        ) {
          // xy[i].y = this.completeData[this.primaryYaxisFullName.value][i];
          // xy[i].x = this.completeData['dmea'][i];

          if (this.logType === "time") {
            xy.push({
              x: this.completeData["time"][i],
              y:
                this.primaryYaxisUnitFactor && this.primaryYaxisUnitFactor != ""
                  ? this.completeData[this.primaryYaxisFullName.value][i] *
                    this.primaryYaxisUnitFactor
                  : this.completeData[this.primaryYaxisFullName.value][i],
              name: this.primaryYaxisFullName?.value,
            });
            xy1.push({ x: this.completeData["time"][i], y: null, name: "" });
          } else {
            if (
              this.minX <= this.completeData["dmea"][i] &&
              this.maxX >= this.completeData["dmea"][i]
            ) {
              xy.push({
                x: this.completeData["dmea"][i],
                y:
                  this.primaryYaxisUnitFactor &&
                  this.primaryYaxisUnitFactor != ""
                    ? this.completeData[this.primaryYaxisFullName.value][i] *
                      this.primaryYaxisUnitFactor
                    : this.completeData[this.primaryYaxisFullName.value][i],
                name: this.primaryYaxisFullName?.value,
              });
              xy1.push({ x: this.completeData["dmea"][i], y: null, name: "" });
            }
          }
        }
        let det = {
          width: 2,
          color: "red",
          data: xy,
        };
        this.primarDataSet.push(det);
      }
      console.log(this.primarDataSet, "primary dataset");
      this.cWellFormation = this.wellFormation;
      if (this.isSecondaryAxis) {
        if (
          this.secondaryYaxisFullName &&
          this.secondaryYaxisFullName.value &&
          this.completeData[this.secondaryYaxisFullName.value]
        ) {
          this.minY1 = min(
            this.completeData[this.secondaryYaxisFullName.value],
            (d) => d
          );
          this.maxY1 = max(
            this.completeData[this.secondaryYaxisFullName.value],
            (d) => d
          );
          if (
            this.secondaryYaxisUnitFactor &&
            this.secondaryYaxisUnitFactor != ""
          ) {
            this.minY1 *= this.secondaryYaxisUnitFactor;
            this.maxY1 *= this.secondaryYaxisUnitFactor;
          }
          if (this.logType == "time") {
            for (
              let i = 0;
              i < this.completeData[this.secondaryYaxisFullName.value].length;
              i++
            ) {
              xy1[i].y =
                this.secondaryYaxisUnitFactor &&
                this.secondaryYaxisUnitFactor != ""
                  ? this.completeData[this.secondaryYaxisFullName.value][i] *
                    this.secondaryYaxisUnitFactor
                  : this.completeData[this.secondaryYaxisFullName.value][i];
              xy1[i].name = this.secondaryYaxisFullName?.value;
            }
          } else {
            for (let i = 0; i < xy1.length; i++) {
              let index = this.completeData["dmea"].findIndex(
                (each) => each == xy1[i].x
              );
              if (index > 0) {
                xy1[i].y =
                  this.secondaryYaxisUnitFactor &&
                  this.secondaryYaxisUnitFactor != ""
                    ? this.completeData[this.secondaryYaxisFullName.value][
                        index
                      ] * this.secondaryYaxisUnitFactor
                    : this.completeData[this.secondaryYaxisFullName.value][
                        index
                      ];
                xy1[i].name = this.secondaryYaxisFullName?.value;
              }
            }
          }
          let det = {
            width: 2,
            color: "blue",
            data: xy1,
          };
          this.secondaryDataSet.push(det);
        }
      }
    },
    async selectedDifference(val) {
      if (this.isZoomEnable) {
        this.isZoomEnable = false;
      }
      this.$store.dispatch("data/startLoading");
      if (this.logType == "time") {
        this.timeDifference = val;
      } else {
        this.depthDifference = val;
      }
      if (
        this.logType == "time" &&
        !this.draggedRangeElement &&
        this.isWellLive
      ) {
        this.draggedRangeElement = true;
        this.timeDifference = val;
        if (this.timeDifference >= 8) {
          this.isWellLive = false;
          this.draggedRangeElement = false;
          this.takeOffSocket();
          this.selectedDifference(1);
          this.$store.dispatch("data/stopLoading");
        } else {
          if (this.primaryYaxisFullName && this.primaryYaxisFullName.value) {
            await this.settingDataAfterTimeDifference(
              parseFloat(this.rangeValue),
              this.primaryYaxisFullName.value
            );
          }
          if (
            this.secondaryYaxisFullName &&
            this.secondaryYaxisFullName.value
          ) {
            await this.settingDataAfterTimeDifference(
              parseFloat(this.rangeValue),
              this.secondaryYaxisFullName.value
            );
          }
          this.draggedRangeElement = false;
          await this.buildSVG();
          this.$store.dispatch("data/stopLoading");
        }
        console.log(
          "coming to live time data",
          this.timeDifference,
          this.rangeValue,
          "range value"
        );
      } else if (this.logType == "depth") {
        this.$store.dispatch("data/startLoading");

        setTimeout(async () => {
          if (this.maxX > val) {
            this.minX = this.maxX - val;
            console.log(this.minY, "miny from selected difference");
            this.depthDifference = val;
            await this.graphData();
            // await this.buildSVG()
            // this.refreshXyCanvasKey += 13;
          } else {
            this.maxX = val + this.minX;
            console.log(this.maxY, "mAXy from selected difference");
            this.depthDifference = val;
            if (this.isZoomEnable) {
              this.isZoomEnable = false;
            }

            // await this.buildSVG()
            await this.graphData();
            // this.refreshXyCanvasKey += 14;
          }
          this.$store.dispatch("data/stopLoading");
        }, 0);
      } else {
        if (this.timeDifference >= 8 && this.isWellLive) {
          this.isWellLive = false;
          this.draggedRangeElement = false;
          this.takeOffSocket();
          this.selectedDifference(1);
          this.$store.dispatch("data/stopLoading");
        } else {
          if (this.primaryYaxisFullName && this.primaryYaxisFullName.value) {
            await this.settingDataAfterTimeDifference(
              parseFloat(this.rangeValue),
              this.primaryYaxisFullName.value
            );
          }
          if (
            this.secondaryYaxisFullName &&
            this.secondaryYaxisFullName.value
          ) {
            await this.settingDataAfterTimeDifference(
              parseFloat(this.rangeValue),
              this.secondaryYaxisFullName.value
            );
          }
          await this.buildSVG();
          this.$store.dispatch("data/stopLoading");
        }
      }
    },
    async settingDataAfterTimeDifference(value, axisMnemonic) {
      try {
        if (this.isZoomEnable) {
          this.isZoomEnable = false;
        }
        let nemonicsType = await this.getMnemonicType(axisMnemonic);
        let min = 0;
        let max = value;
        // console.log(max - this.timeDifference * 1000 * 60 * 60 <= parseFloat(this.minMsxTime.min),max - this.timeDifference * 1000 * 60 * 60,'minxof slide drag',parseFloat(this.minMsxTime.min >= max),'maxslide drag')
        if (this.logType == "time") {
          nemonicsType = "SOLUTION";
          min = max - this.timeDifference * 1000 * 60 * 60;
          console.log(
            min >= parseFloat(this.minMsxTime.min),
            "checking lessthan"
          );
          console.log(
            max <= parseFloat(this.minMsxTime.max),
            "checking greaterthan"
          );
        } else {
          min = this.minMsxTime.min;
          return;
        }
        let range = [max, min];

        await this.getdateFromDb(range, nemonicsType, axisMnemonic);
      } catch (err) {
        console.error(err);
      }
    },
    async liveClick() {
      this.draggedRangeElement = false;
      let max = this.minMsxTime.max;
      this.rangeValue = parseFloat(max);
      let obj = { target: { value: "" } };
      obj.target.value = max;
      this.timeDifference = 1;
      this.depthDifference = 50;
      if (
        this.logType == "time" &&
        !this.draggedRangeElement &&
        this.isWellLive
      ) {
        this.draggedRangeElement = true;
        await this.maxXSliderRange(obj);
        this.draggedRangeElement = false;
      } else {
        await this.maxXSliderRange(obj, "fromLive");
      }
      await this.getRealtimeData();
    },
    async maxXSliderRange(event, from) {
      if (this.isZoomEnable) {
        this.isZoomEnable = false;
      }
      this.$store.dispatch("data/startLoading");
      if (this.primaryYaxisFullName && this.primaryYaxisFullName.value) {
        await this.settingDataAfterSliderDrag(
          event.target.value,
          this.primaryYaxisFullName.value,
          from
        );
      }
      if (this.secondaryYaxisFullName && this.secondaryYaxisFullName.value) {
        await this.settingDataAfterSliderDrag(
          event.target.value,
          this.secondaryYaxisFullName.value,
          from
        );
      }
      await this.buildSVG();
      this.$store.dispatch("data/stopLoading");
    },
    async settingDataAfterSliderDrag(value, axisMnemonic, from) {
      try {
        let nemonicsType = await this.getMnemonicType(axisMnemonic);
        let min = 0;
        let max = value;
        this.rangeValue = parseFloat(value);

        if (from && from == "fromLive") {
          this.draggedRangeElement = false;
        } else {
          this.draggedRangeElement = true;
        }
        if (this.logType == "time") {
          nemonicsType = "SOLUTION";
          min = max - this.timeDifference * 1000 * 60 * 60;
        } else {
          min = this.minMsxTime.min;
          return;
        }
        let range = [max, min];
        await this.getdateFromDb(range, nemonicsType, axisMnemonic);
      } catch (err) {
        console.error(err);
      }
    },
    memomenucsChangesHandler(menomenics) {
      //unsuscribe & suscribe websocket
      this.takeOffSocket();
    },
    resetZoomHandler() {
      console.log("resetZoom");
      this.$refs.canvasXyAxisGraph.resetZoom();
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
    },
    processResult(sResult) {
      console.log("processResult ", sResult);
      console.log("processResult widgetId", this.widgetId);
    },

    windowToggle(name) {
      this.classes = name;
    },
    dark() {
      document.querySelector("nav").classList.add("dark-mode");
      document.querySelector("div").classList.add("dark-mode");
      document.querySelector("button").classList.add("dark-mode");
      document.querySelector("a").classList.add("text-red-600");
      this.darkMode = true;
      this.$emit("dark");
      console.log("Dark Mode");
    },
    light() {
      document.querySelector("nav").classList.remove("dark-mode");
      document.querySelector("div").classList.remove("dark-mode");
      document.querySelector("button").classList.remove("dark-mode");
      document.querySelector("a").classList.remove("text-red-600");
      this.darkMode = false;
      this.$emit("light");
    },
    async buildSVG() {
      if (
        this.primaryYaxisFullName &&
        this.primaryYaxisFullName.value &&
        this.completeData[this.primaryYaxisFullName.value]
      ) {
        this.minY = min(
          this.completeData[this.primaryYaxisFullName.value],
          (d) => d
        );
        this.maxY = max(
          this.completeData[this.primaryYaxisFullName.value],
          (d) => d
        );
        if (this.primaryYaxisUnitFactor && this.primaryYaxisUnitFactor != "") {
          this.minY *= this.primaryYaxisUnitFactor;
          this.maxY *= this.primaryYaxisUnitFactor;
        }
        if (this.logType === "time") {
          this.minX = min(this.completeData["time"], (d) => d);
          this.maxX = max(this.completeData["time"], (d) => d);
          if (!this.draggedRangeElement) {
            this.rangeValue = parseFloat(this.maxX);
          }
        } else {
          if (!this.draggedRangeElement) {
            if (this.minMsxTime.max <= 0) {
              this.minx = 0;
              this.maxX = this.depthDifference;
            } else {
              this.maxX = this.minMsxTime.max;
              this.minX = this.maxX - this.depthDifference;
              this.rangeValue = parseFloat(this.maxX);
            }
          } else {
            this.maxX = this.rangeValue;
            this.minX = this.maxX - this.depthDifference;
          }
        }
      }
      if (
        this.secondaryYaxisFullName &&
        this.secondaryYaxisFullName.value &&
        this.completeData[this.secondaryYaxisFullName.value]
      ) {
        this.minY1 = min(
          this.completeData[this.secondaryYaxisFullName.value],
          (d) => d
        );
        this.maxY1 = max(
          this.completeData[this.secondaryYaxisFullName.value],
          (d) => d
        );
      }
      if (
        this.secondaryYaxisUnitFactor &&
        this.secondaryYaxisUnitFactor != ""
      ) {
        this.minY1 *= this.secondaryYaxisUnitFactor;
        this.maxY1 *= this.secondaryYaxisUnitFactor;
      }

      let xy = [];
      let xy1 = [];
      this.primarDataSet = [];
      this.secondaryDataSet = [];

      if (
        this.primaryYaxisFullName &&
        this.primaryYaxisFullName.value &&
        this.completeData[this.primaryYaxisFullName.value]
      ) {
        for (
          let i = 0;
          i < this.completeData[this.primaryYaxisFullName.value].length;
          i++
        ) {
          // xy[i].y = this.completeData[this.primaryYaxisFullName.value][i];
          // xy[i].x = this.completeData['dmea'][i];

          if (this.logType === "time") {
            xy.push({
              x: this.completeData["time"][i],
              y:
                this.primaryYaxisUnitFactor && this.primaryYaxisUnitFactor != ""
                  ? this.completeData[this.primaryYaxisFullName.value][i] *
                    this.primaryYaxisUnitFactor
                  : this.completeData[this.primaryYaxisFullName.value][i],
              name: this.primaryYaxisFullName?.value,
            });
            xy1.push({ x: this.completeData["time"][i], y: null, name: "" });
          } else {
            if (
              this.minX <= this.completeData["dmea"][i] &&
              this.maxX >= this.completeData["dmea"][i]
            ) {
              xy.push({
                x: this.completeData["dmea"][i],
                y:
                  this.primaryYaxisUnitFactor &&
                  this.primaryYaxisUnitFactor != ""
                    ? this.completeData[this.primaryYaxisFullName.value][i] *
                      this.primaryYaxisUnitFactor
                    : this.completeData[this.primaryYaxisFullName.value][i],
                name: this.primaryYaxisFullName?.value,
              });
              xy1.push({ x: this.completeData["dmea"][i], y: null, name: "" });
            }
          }
        }
        let det = {
          width: 2,
          color: "red",
          data: xy,
        };
        this.primarDataSet.push(det);
      }

      if (this.isSecondaryAxis) {
        if (
          this.secondaryYaxisFullName &&
          this.secondaryYaxisFullName.value &&
          this.completeData[this.secondaryYaxisFullName.value]
        ) {
          if (this.logType == "time") {
            for (
              let i = 0;
              i < this.completeData[this.secondaryYaxisFullName.value].length;
              i++
            ) {
              xy1[i].y =
                this.secondaryYaxisUnitFactor &&
                this.secondaryYaxisUnitFactor != ""
                  ? this.completeData[this.secondaryYaxisFullName.value][i] *
                    this.secondaryYaxisUnitFactor
                  : this.completeData[this.secondaryYaxisFullName.value][i];
              xy1[i].name = this.secondaryYaxisFullName?.value;
            }
          } else {
            for (let i = 0; i < xy1.length; i++) {
              let index = this.completeData["dmea"].findIndex(
                (each) => each == xy1[i].x
              );
              if (index > 0) {
                xy1[i].y =
                  this.secondaryYaxisUnitFactor &&
                  this.secondaryYaxisUnitFactor != ""
                    ? this.completeData[this.secondaryYaxisFullName.value][
                        index
                      ] * this.secondaryYaxisUnitFactor
                    : this.completeData[this.secondaryYaxisFullName.value][
                        index
                      ];
                xy1[i].name = this.secondaryYaxisFullName?.value;
              }
            }
          }
          let det = {
            width: 2,
            color: "blue",
            data: xy1,
          };
          this.secondaryDataSet.push(det);
        }
      }
    },
    async getRealtimeData() {
      let topic = this.topic;
      let wells = this.$store.state.data.wells;
      let well = wells[this.wellId];
      await this.wellStatusCheck();
      let fields = [];
      if (this.primaryXaxisFullName && this.primaryXaxisFullName.value) {
        fields.push(this.primaryXaxisFullName.value);
      }
      if (this.primaryYaxisFullName && this.primaryYaxisFullName.value) {
        fields.push(this.primaryYaxisFullName.value);
      }
      if (this.secondaryYaxisFullName && this.secondaryYaxisFullName.value) {
        fields.push(this.secondaryYaxisFullName.value);
      }
      let topicExists = this.$store.state.live.isSubscribed[topic];
      if (well) {
        if (this.wellId && this.wellboreId && this.logType) {
          this.$socket.emit("subscribe", {
            logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
            topic: this.topic,
            fields: fields,
            id: this.id,
            clientId: this.$clientId,
            logType: this.logType,
          });
          if (!topicExists) {
            this.$store.dispatch("live/setSubscriptionFlag", {
              topic,
              flag: true,
            });
          }

          this.sockets.subscribe(this.$clientId, (sResult) => {
            let result = sResult[this.id];
            if (result) {
              if (!this.isWellLive) {
                this.isWellLive = true;
              }
              //get minmax
              if (
                this.primaryYaxisFullName &&
                this.primaryYaxisFullName.value
              ) {
                // console.log('live result', result)
                if (this.primarDataSet.length > 0) {
                  let countX = result[this.logType].length;
                  let newDataSet = this.primarDataSet[0].data.slice(
                    countX,
                    this.primarDataSet[0].data.length
                  );
                  result[this.logType].forEach((e, i) => {
                    newDataSet.push({
                      x: e,
                      y: result[this.primaryYaxisFullName.value][i],
                      name: this.primaryYaxisFullName.value,
                    });
                  });
                  if (!this.draggedRangeElement) {
                    if (this.logType == "time") {
                      this.maxX = max(newDataSet, (d) => d.x);
                      this.minX = min(newDataSet, (d) => d.x);
                      // this.minX = this.maxX - this.timeDifference * 1000 * 60 * 60;
                      // newDataSet = newDataSet.filter((each)=>{
                      //   return this.minX <= each.x && this.maxX > each.x
                      // })
                      this.minMsxTime.max = this.maxX;
                      this.rangeValue = parseFloat(this.maxX);
                      let Min_forTimediffTime = moment(this.minX).unix() * 1000;
                      let Max_forTimediffTime = moment(this.maxX).unix() * 1000;
                      this.timeDifference = Number(
                        (Max_forTimediffTime - Min_forTimediffTime) /
                          (60 * 60 * 1000)
                      ).toFixed(2);
                      this.minY = min(newDataSet, (d) => d.y);
                      this.maxY = max(newDataSet, (d) => d.y);
                      newDataSet.sort((a, b) => a.x - b.x);
                      this.primarDataSet[0].data = newDataSet;
                    } else {
                      let newDepthData = [];
                      this.maxX = max(result[this.logType], (d) => d);
                      this.minX = this.maxX - this.depthDifference;
                      if (this.completeData["dmea"].length) {
                        let minValue = min(result[this.logType], (d) => d);
                        for (
                          let depthIndex = 0;
                          depthIndex < this.completeData["dmea"].length;
                          depthIndex++
                        ) {
                          if (
                            this.completeData["dmea"][depthIndex] >= minValue
                          ) {
                            break;
                          }
                          if (
                            this.minX <= this.completeData["dmea"][depthIndex]
                          ) {
                            newDepthData.push({
                              x: this.completeData["dmea"][depthIndex],
                              y: this.completeData[
                                this.primaryYaxisFullName.value
                              ][depthIndex],
                              name: this.primaryYaxisFullName.value,
                            });
                          }
                        }
                      }
                      result[this.logType].forEach((e, i) => {
                        newDepthData.push({
                          x: e,
                          y: result[this.primaryYaxisFullName.value][i],
                          name: this.primaryYaxisFullName.value,
                        });
                      });
                      this.minMsxTime.max = this.maxX;
                      this.rangeValue = parseFloat(this.maxX);
                      this.minY = min(newDepthData, (d) => d.y);
                      this.maxY = max(newDepthData, (d) => d.y);
                      this.primarDataSet[0].data = newDepthData;
                    }

                    // this.minMsxTime.max = this.maxX;
                    // this.rangeValue = this.maxX
                    // this.minY = min(newDataSet, (d) => d.y);
                    // this.maxY = max(newDataSet, (d) => d.y);
                    // this.primarDataSet[0].data = newDataSet;
                  }
                }
              }
              if (
                this.secondaryYaxisFullName &&
                this.secondaryYaxisFullName.value &&
                this.isSecondaryAxis
              ) {
                if (this.secondaryDataSet.length > 0) {
                  let countX = result[this.logType].length;
                  let newDataSet = this.secondaryDataSet[0].data.slice(
                    countX,
                    this.secondaryDataSet[0].data.length
                  );
                  result[this.logType].forEach((e, i) => {
                    newDataSet.push({
                      x: e,
                      y: result[this.secondaryYaxisFullName.value][i],
                      name: this.secondaryYaxisFullName.value,
                    });
                  });
                  if (!this.draggedRangeElement) {
                    if (this.logType == "time") {
                      this.maxX = max(newDataSet, (d) => d.x);
                      this.minX = min(newDataSet, (d) => d.x);
                      this.minY1 = min(newDataSet, (d) => d.y);
                      this.maxY1 = max(newDataSet, (d) => d.y);
                      newDataSet.sort((a, b) => a.x - b.x);
                      this.secondaryDataSet[0].data = newDataSet;
                    } else {
                      let newDepthData = [];
                      this.maxX = max(result[this.logType], (d) => d);
                      this.minX = this.maxX - this.depthDifference;
                      if (this.completeData["dmea"].length) {
                        let minValue = min(result[this.logType], (d) => d);
                        for (
                          let depthIndex = 0;
                          depthIndex < this.completeData["dmea"].length;
                          depthIndex++
                        ) {
                          if (
                            this.completeData["dmea"][depthIndex] >= minValue
                          ) {
                            break;
                          }
                          if (
                            this.minX <= this.completeData["dmea"][depthIndex]
                          ) {
                            newDepthData.push({
                              x: this.completeData["dmea"][depthIndex],
                              y: this.completeData[
                                this.secondaryYaxisFullName.value
                              ][depthIndex],
                              name: this.secondaryYaxisFullName.value,
                            });
                          }
                        }
                      }
                      result[this.logType].forEach((e, i) => {
                        newDepthData.push({
                          x: e,
                          y: result[this.secondaryYaxisFullName.value][i],
                          name: this.secondaryYaxisFullName.value,
                        });
                      });

                      this.minY1 = min(newDepthData, (d) => d.y);
                      this.maxY1 = max(newDepthData, (d) => d.y);
                      this.secondaryDataSet[0].data = newDepthData;
                    }
                    // this.minY1 = min(newDataSet,(d) => d.y);
                    // this.maxY1 = max(newDataSet,(d) => d.y);
                    // this.secondaryDataSet[0].data = newDataSet;
                  }
                }
              }
            }
          });
        }
        topicExists = this.$store.state.live.isSubscribed[topic];
      }
    },
    takeOffSocket() {
      if (this.$socket) {
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.id,
          clientId: this.$clientId,
        });
        this.sockets.unsubscribe(this.$clientId);
      }
      this.stopUpdation = false;
    },
    async getTagValue(displayDtls, title) {
      if (this.table) {
        let valueAt = "end";
        let dconfig = {};
        let wells = this.$store.state.data.wells;
        let well = wells[this.wellId];
        if (well) {
          let wellbore = well.wellbores[this.wellboreId];
          let log = wellbore["logs"][this.logType];
          this.logId = log.id;
          dconfig.field = title || this.title;
          dconfig.logId = this.logId;
          dconfig.table = this.table;
          if (this.logType === "depth") {
            dconfig.logName = "depthlog";
          }
          if (this.logType === "time") {
            dconfig.logName = "timelog";
          }
          let url = await getLatestValueUrl(dconfig);
          let result = await executeQuery(url);
          if (result != null) {
            this.numericValue = result.data[0]
              ? result.data[0][dconfig.field]
              : "";
            if (this.logType === "depth") {
              this.tooltipValue = result.data[0]["dept"];
            }
            if (this.logType === "time") {
              this.tooltipValue = new Date(
                result.data[0]["time"]
              ).toLocaleString("en-US", {
                timeZone: this.time_zone,
              });
            }
          }
        } else {
          this.numericValue = "";
        }
        return this.numericValue;
      }
    },
    getNumericValue(displayDtls) {
      let value;
      let valueAt = "end";
      let titleValues = this.$store.state.data.tagValues[this.title];
      if (titleValues != null) {
        for (let i = 0; i < titleValues.length; i++) {
          if (
            titleValues[i].wellId == displayDtls.wellId &&
            titleValues[i].wellboreId == displayDtls.wellboreId
          ) {
            let logs = titleValues[i].logs;
            for (let j = 0; j < logs.length; j++) {
              if ((logs[j].logId = displayDtls.logId)) {
                let logValues = logs[j].logValues;
                value = logValues[valueAt];
                break;
              }
            }
          }
        }
      }
      return value;
    },
    async getMinMaxRange() {
      try {
        let result = null;
        if (this.logType == "time") {
          result = await apiService.WellServices.getMinMaxTime({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
          result.data.min = moment(result.data.min).unix() * 1000;
          result.data.max = moment(result.data.max).unix() * 1000;
        } else {
          result = await apiService.WellServices.getMinMaxDepth({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
          result.data.min = result.data.min;
          result.data.max = result.data.max;
        }

        return result.data;
      } catch (error) {
        console.error(error);
        return { min: 0, max: 1000 };
      }
    },
    async getdateFromDb(range, mnemonicType, mnemonicValue) {
      console.log(range);
      // this.$store.dispatch("data/startLoading");
      let payload = {
        fields: [mnemonicValue],
        well_id: this.wellId,
        wellbore_name: this.wellboreId,
        type: mnemonicType,
        topic:this.$store.state.disp?.displays[this.displayId]?.topicName
      };
      let result = null;
      if (this.logType === "time") {
        payload["from"] = parseInt(range[0]);
        payload["to"] = parseInt(range[1]);
        result = await apiService.WellServices.getTimeLogs(payload);
      } else {
        payload["end"] = parseInt(range[0]);
        payload["start"] = parseInt(range[1]);
        result = await apiService.WellServices.getDepthLogs(payload);
      }
      this.completeData = { ...this.completeData, ...result.data.data };
    },
    async getMnemonicType(mnemonic) {
      // console.time("get mnemonic type", mnemonic)
      let mnemonic_groups = {};
      try {
        // let all_mnemonics =
        //   this.$store.state.disp.displays[this.displayId].mnemonics;
        let logDetails = await helperServices.getIndexDetails(this.wellId);
        let all_mnemonics = logDetails.data.logs[this.logType].mnemonics;
        const source_mnemonice = logDetails.data.logs[this.logType].source;
        const tags = logDetails.data.logs[this.logType].tags;
        const mnemonicKey = tags.findIndex((t) => t == mnemonic);
        const actualKey = source_mnemonice[mnemonicKey];
        let mnemonic_type = "UNPREFERRED";
        for (let i in all_mnemonics) {
          if (all_mnemonics[i][actualKey]) {
            mnemonic_type = i;
            mnemonic_groups[i] = [];
            break;
          }
        }
        if (!this.mnemonic_groups[mnemonic_type])
          this.mnemonic_groups[mnemonic_type] = [];
        if (this.mnemonic_groups[mnemonic_type].indexOf(mnemonic) == -1)
          this.mnemonic_groups[mnemonic_type] = [
            ...this.mnemonic_groups[mnemonic_type],
            mnemonic,
          ];
        return mnemonic_type;
        // console.timeEnd("get mnemonic type", mnemonic)
      } catch (error) {}
    },
    async prepareGraphDetail(mnemonic) {
      this.$store.dispatch("data/startLoading");
      let nemonicsType = await this.getMnemonicType(mnemonic);
      let min = 0;
      let max = parseFloat(this.minMsxTime.max);
      if (this.logType == "time") {
        min = this.minMsxTime.max - this.timeDifference * 1000 * 60 * 60;
        nemonicsType = "SOLUTION";
      } else {
        min = this.minMsxTime.min;
      }
      let range = [max, min];
      try {
        await this.getdateFromDb(range, nemonicsType, mnemonic);
      } catch (err) {
        console.error(err);
      }

      //suscribe websocket

      this.buildSVG();
      this.$store.dispatch("data/stopLoading");
    },
  },
  watch: {
    isZoomEnable(val) {
      if (!val) {
        this.refreshXyCanvasKey += 1;
        this.draggedRangeElement = false;
        this.$refs.canvasXyAxisGraph.resetZoom();
      }
    },
    themeColor() {
      if (
        this.gridColor &&
        this.axisLabelColor &&
        this.secondaryAxisLabelColor
      ) {
        this.refreshXyCanvasKey += 22;
      }
    },
    isSecondaryAxis() {
      // this.buildSVG();
      // this.refreshXyCanvasKey += 12;
    },
    gridColor(val) {
      // this.buildSVG();
      // selectAll('.yaxis-grid').style("color", val);
      // this.refreshXyCanvasKey += 13;
    },
    gridBackground() {
      this.buildSVG();
    },
    axisLabelColor() {
      // this.buildSVG();
      // selectAll(".primary_xlabel").style("fill",val);
      // this.refreshXyCanvasKey += 9;
    },
    graphScaleColor() {
      // this.buildSVG();
      // select(".primary_line").attr("stroke",val);
      // this.refreshXyCanvasKey += 14;
    },
    xAxisLabelSize() {
      // this.buildSVG();
      // this.refreshXyCanvasKey += 18;
    },
    yAxisLabelSize() {
      // this.refreshXyCanvasKey += 19;
    },
    secondaryAxisLabelColor(val) {
      // this.buildSVG();
      // select(".secondary_ylabel").style("fill",val);
      // this.refreshXyCanvasKey += 11;
    },
    graphSecondaryScaleColor(val) {
      // this.buildSVG();
      // select(".secondary_line").attr("stroke",val);
      // this.refreshXyCanvasKey += 15;
    },
    yAxisSecondaryLabelSize() {
      // this.buildSVG();
      // this.refreshXyCanvasKey += 21;
    },
    async primaryYaxisFullName(val, old) {
      // this.buildSVG();
      if (val?.value) {
        if (
          this.logType == "time" &&
          !this.draggedRangeElement &&
          this.isWellLive
        ) {
          this.draggedRangeElement = true;
          if (this.primaryYaxisFullName && this.primaryYaxisFullName.value) {
            await this.prepareGraphDetail(this.primaryYaxisFullName.value);
          }
          if (
            this.secondaryYaxisFullName &&
            this.secondaryYaxisFullName.value
          ) {
            await this.prepareGraphDetail(this.secondaryYaxisFullName.value);
          }
          this.draggedRangeElement = false;
        } else {
          await this.prepareGraphDetail(val.value);
        }
        // this.prepareGraphDetail(val.value);
        await this.getRealtimeData();
      }
    },
    primaryYaxisUnit() {
      this.buildSVG();
    },
    primaryXaxisFullName(val, old) {
      // this.refreshXyCanvasKey += 1;
      // this.buildSVG();
      // console.log(val, old, 'primaryxaxis watcher')
      if (val) {
        if (val.value != old.value && old.value != "") {
          this.prepareGraphDetail(val.value);
        }
      }
    },
    primaryXaxisUnit() {
      this.buildSVG();
    },
    async secondaryYaxisFullName(val, old) {
      // this.buildSVG();
      if (val?.value) {
        if (
          this.logType == "time" &&
          !this.draggedRangeElement &&
          this.isWellLive
        ) {
          this.draggedRangeElement = true;
          if (this.primaryYaxisFullName && this.primaryYaxisFullName.value) {
            await this.prepareGraphDetail(this.primaryYaxisFullName.value);
          }
          if (
            this.secondaryYaxisFullName &&
            this.secondaryYaxisFullName.value
          ) {
            await this.prepareGraphDetail(val.value);
          }
          this.draggedRangeElement = false;
        } else {
          await this.prepareGraphDetail(val.value);
        }
        await this.getRealtimeData();
      }
    },
    secondryYaxisUnit() {
      this.buildSVG();
    },
    widgetHeight(val) {
      // setTimeout(() => {
      this.height = val;
      this.cHeight = val;
      // }, 0)
      this.refreshXyCanvasKey += 6;

      // this.buildSVG();
    },
    widgetWidth(val) {
      // setTimeout(() => {
      this.width = val;
      // this.buildSVG();
      this.cWidth = val;
      // }, 0)
      this.refreshXyCanvasKey += 7;
    },
    selectedMenomenics(newVal, oldVal) {
      console.log("selectedMenomenics---", newVal, oldVal);
    },
  },
  beforeUnmount() {
    this.completeData = null;
    this.takeOffSocket();
    this.isUnmounted = true;
  },
};
</script>
<style scoped>
h1 {
  font-size: 20px;
}

.axis-grids .tick {
  display: none !important;
}

.text-xxs {
  font-size: 9px;
}

.tool_section {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 4px;
}

.tool_toggle_label {
  cursor: pointer;
  text-indent: -9999px;
  width: 23px;
  height: 12px;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tool_toggle_input:checked + ._tooltip {
  /* background: #5aba81; */
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._alert {
  /* background: #ff2200; */
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._zoom {
  /* background: #00bcd4; */
  background: var(--activeTextColor);
}

.tool_toggle_input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.tool_toggle_label {
  cursor: pointer;
  text-indent: -9999px;
  width: 23px;
  height: 12px;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tool_toggle_label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 10px;
  height: 10px;
  background: var(--sidebarbg);
  border-radius: 90px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.tool_toggle_label {
  /* background: #5aba81; */
  background: var(--toggleSliderBg);
}

.tool_toggle_input:checked + .tool_toggle_label:after {
  /* left: calc(100% - 2px); */
  -webkit-transform: translateX(9px);
  transform: translateX(9px);
}

.tooltipd3 {
  position: absolute;
  padding: 10px;
  background-color: steelblue;
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  display: none;
  opacity: 0.75;
}

.tool_group {
  position: fixed;
  display: inline-grid;
  /* left: 221px; */
  right: 162px;
  z-index: 99999;
}

.actc_tool {
  font-size: 10px;
  background: #000000;
  width: auto;
  position: absolute;
  padding: 2px 5px;
  /* border-radius: 20px; */
}

.timeList {
  background: var(--colorBoxBg);
  color: var(--selectingTimeFeetColor);
  /* box-shadow: 0 4px 10px 4px rgba(0,0,0,.15); */
  transition: var(--transition);
  padding: 0 7px;
  border-radius: 8px;
  font-size: 10px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeList:hover {
  background: var(--activeTextColor);
  color: #fff;
}

.activeRecord {
  background: var(--activeTextColor);
  color: #fff;
}

.newStylesLiveBtn {
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  padding: 2px 8px;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 9px;
  color: #fff;
}

.liveBtnDot {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.newStWDeptInput {
  background: var(--colorBoxPopUpInputBg) !important;
  border: none;
  color: var(--textColor) !important;
  font-weight: 600;
  text-align: center;
  height: 20px;
  width: 60px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.activeRecord[disabled] {
  /* opacity: 0.9; */
  pointer-events: none;
}
.resetbutton {
  padding: 3px 14px !important;
  background-color: var(--colorBoxBg) !important;
  color: var(--selectingTimeFeetColor) !important ;
}
.resetbutton:hover {
  background-color: var(--activeTextColor) !important;
  color: white !important;
  transition: 2s all;
  font-weight: 400;
}
</style>
