const HOST_URL = {
  //BUCKET_URL: "https://apollologos.s3.ap-south-1.amazonaws.com/",

  //DEV STAGING IPS
  SERVER_URL: "https://analytics.apollodart.com/",
  SOCKET_URL: "wss://analytics-stream.apollodart.com/",
  //ADMINISTRATOR_URL: "https://usrm.apollodart.com/",
  DATASERVICES_URL: "https://dataservices.apollodart.com/apollosolutions/",
  APOLLO_UTIL_URL: "https://dataservices.apollodart.com/apolloutils/",
  TND_SERVICE: "https://killsheets.apollodart.com/",
  LWD_UPLOAD: "https://lwd.apollodart.com/",
  REPORTS: "https://reports-analytics.apollodart.com/", 

  //Zulip chat realated config
  ZULIP_REALM: "https://zulip.apollodart.com",
  AWS_S3_URL: "https://apollodart-logos.s3.amazonaws.com/",
};
export default {
  HOST_URL,
};
