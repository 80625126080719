<template>
  <div :id="div_id" class="__trend_bg_seperation">
    <div
      :id="'main' + compId"
      :style="{
        height: wh.height + 'px',
        width: 100 + '%',
      }"
      class="arrangementClass relative"
    >
      <div class="flex justify-end zoomTooltipContainer">
        <div>
          <div class="flex justify-around mt-1" v-if="!hide_tooltip_zoom">
            <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex">
                <div
                  :style="{ fontSize: 9 + 'px' }"
                  class="mt-1 settingPopColor mb-1"
                  :class="isZoom ? 'text-gray-600' : 'white-white'"
                >
                  Tooltip
                </div>
                <div class="ml-0.5 tool_section">
                  <input
                    type="checkbox"
                    :id="'switch' + this.id"
                    class="tool_toggle_input"
                    :checked="isShowTooltip"
                    v-bind:value="isShowTooltip"
                    @click="changeTooltip()"
                    :disabled="isZoom"
                  />
                  <label
                    class="tool_toggle_label ml-1 mb-1"
                    :class="isZoom ? 'muted_bg' : '_tooltip'"
                    :for="'switch' + this.id"
                    >Tool Tip :</label
                  >
                </div>
              </div>
            </div>
            <div
              class="flex items-center justify-between __toggle__boxes ml-2"
              style="padding-right: 15px"
            >
              <div class="flex">
                <div class="ml-0.5 tool_section" style="margin: 0">
                  <div
                    class="flex justify-center items-center settingPopColor"
                    style="gap: 5px"
                    :style="{ fontSize: 9 + 'px' }"
                  >
                    <button
                      @click="zoomOut"
                      type="button"
                      class="text-sm settingPopColor"
                    >
                      <i class="fas fa-search-minus"></i>
                    </button>
                    <p>Zoom</p>
                    <button
                      @click="zoomIn"
                      type="button"
                      class="text-sm settingPopColor"
                    >
                      <i class="fas fa-search-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex justify-start"
        :style="{
          width: wh.templateWidth + 'px',
          height: bheight + 'px',
        }"
      >
        <div
          class="flex"
          :style="{
            width: wh.templateWidth + 'px',
            maxWidth: templateWidth + 'px',
            height: bheight + 'px',
            paddingLeft: this.rangeSliderWidth + 'px',
          }"
        >
          <div
            class="__label_boxes"
            style="height: inherit"
            v-if="trackConfig.tracks && !hide_mnemonics_div"
          >
            <div class="flex full h-full">
              <div
                class="outer_mnemonics_container"
                v-for="(track, j) in trackConfig.tracks"
                :key="j + trendIndex"
                :style="{
                  marginLeft:
                    j == 1
                      ? this.scaleWidth + this.lithologyWidth - 1 + 'px'
                      : '0px',
                  width:
                    singleTrackWidth -
                    (trackConfig.tracks.length == 5 ? 1 : 0) + 
                    (j > 0 && trackConfig.tracks.length > 2 &&
                      j + 1 < trackConfig.tracks.length &&
                      Object.keys(trackConfig.tracks[j + 1][0]).includes('hide') ? 2 : 0) +
                    'px',
                }"
              >
                <!-- <p class="trackHeading"> Track {{ j + 1}} </p> -->
                <div
                  class="__label_boxes mnemonics_container"
                  v-show="!Object.keys(track[0]).includes('hide')"
                  :class="
                    trackConfig.tracks.length > 2 &&
                    j + 1 < trackConfig.tracks.length &&
                    Object.keys(trackConfig.tracks[j + 1][0]).includes('hide')
                      ? 'borderRightWidth'
                      : ''
                  "
                >
                  <div
                    id="tempdiv"
                    v-for="(curve, i) in track"
                    :key="i"
                    class="relative flex track_config_border bg-opacity-99"
                    :style="{
                      // width: singleTrackWidth - 4 + 'px',
                      marginRight: 0 + 'px',
                      fontSize: 19 + '%',
                      height: mnemonics_height + 'px',
                      marginLeft: j == 0 ? '0px' : '0',
                      userSelect: 'none',
                    }"
                  >
                    <div
                      v-if="
                        curveProps[j + '-' + i] &&
                        curveProps[j + '-' + i]['show']
                      "
                      :class="'curve_pop_opened'"
                      :style="{ right: singleTrackWidth - 22 + 'px' }"
                    >
                      <Props
                        :width="120"
                        :height="100 + '%'"
                        :curve="curveProps[j + '-' + i]['curve']"
                        @closeIt="closeIt(j, i)"
                        @apply="setCurveProps(j, i, $event)"
                        @delete="delete_mnemonic(j, i)"
                      />
                    </div>
                    <div
                      :title="curve.fullName.toUpperCase()"
                      v-on:dblclick="showProps1(j, i)"
                      class="name_numaric_con tooltip__tip top truncate items-center"
                      :class="
                        this.darkDark != 'white'
                          ? 'tooltip__tip_dark'
                          : ['tooltip__tip_light', 'whiteBg']
                      "
                      :data-tip="getLatestValuesToDisplay(curve.name)"
                    >
                      <div class="px-2">
                        <div
                          style="font-size: 0.65rem"
                          class="td_trnk"
                          v-if="curve.fullName"
                          :class="
                            this.darkDark == true
                              ? 'text_gray colorBox_text_gray'
                              : 'text_dark colorBox_text_gray'
                          "
                          :data-tip="curve.fullName.toUpperCase()"
                        >
                          {{
                            Object.keys(curve).includes("editedName")
                              ? curve.editedName
                              : curve.description
                          }}
                        </div>
                        <div
                          class="td_trnk mnemonic_borderLine"
                          :style="{ borderColor: curve.color }"
                          v-if="curve.fullName"
                          :class="
                            this.darkDark == true
                              ? 'text_gray colorBox_text_gray'
                              : 'text_dark colorBox_text_gray'
                          "
                          :data-tip="curve.fullName.toUpperCase()"
                        >
                          <!-- {{ curve.name }} -->
                        </div>
                      </div>
                      <div class="flex justify-around" style="gap: 10px">
                        <p
                          class="mnemonic_min_max"
                          style="font-size: 0.55rem"
                          :style="{
                            color: darkDark != 'white' ? 'white' : 'black',
                          }"
                        >
                          {{ mnemonicMinRange(curve, j) }}
                        </p>
                        <p class="flex" style="font-size: 0.55rem;max-width: 50%">
                          <span class="truncate">{{ curve.name }}</span><span class="pl-1">({{ curve.unit }})</span>
                        </p>
                        <p
                          class="mnemonic_min_max"
                          style="font-size: 0.55rem"
                          :style="{
                            color: darkDark != 'white' ? 'white' : 'black',
                          }"
                        >
                          {{ mnemonicMaxRange(curve, j) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-start" :style="{ width: width + 'px' }">
        <div class="absolute top-0">
          <input
            type="range"
            v-model="rangeValue"
            :min="rangeMinValue"
            :max="rangeMaxValue"
            orient="vertical"
            :style="{
              height: singleTrackHeight + bheight + 'px',
              width: this.rangeSliderWidth + 'px',
            }"
            style="appearance: slider-vertical; transform: rotateZ(180deg)"
            @input="handleRangeChange"
          />
        </div>

        <div
          class="flex"
          :class="darkDark == true ? 'bg-black' : 'light_trend-mode'"
          style="margin-left:20px;"
          :style="{
            height: singleTrackHeight + 'px',
            width: wh.templateWidth - sliderWidth - rangeSliderWidth + 'px',
          }"
          v-if="trackConfig.tracks.length"
        >
          <div
            class="scrolldiv bg-opcity-95 flex"
            :style="{
              height: singleTrackHeight + 'px',
              width: wh.templateWidth - sliderWidth - rangeSliderWidth + 'px',
              maxWidth: width - sliderWidth + 'px',
            }"
            :id="div_id + this.trendIndex + 'div_id'"
          ></div>
        </div>
      </div>
    </div>
    <div v-if="selected_files && selected_files.length">
      <Teleport :to="'#newFileSaveBtn'+widgetId">
        <button class="mx-2 fontSize buttonStyles"
          @click="open_print_options_popup">
          Print
        </button>
      </Teleport>
    </div>
    <div class="fixed LwdPrintMainCLass" style="top: 100%">
      <!-- well details starts -->
      <div class="wellDetails" :id="'wellDetails' + this.widgetId"
        style="color: #000;"
        :style="{
            width: total_print_page_width + 'px',
            height: print_track_height + print_bheight + 'px',
        }">

        <!-- heading -->
        <p class="mt-1 text-center pb-2 font-semibold" style="font-size: 12px; border-bottom: 1px solid #000">
          LWD TREND
        </p>

        <!-- body starts -->
        <section class="w-full uppercase mt-2" style="font-size: 10px;">
          <div class="w-full flex">
            <span class="w-1/2"> well name: <span class="font-semibold">{{ wellDetails?.wellName }}</span></span>
            <span class="w-1/2"> well bore name: <span class="font-semibold">{{ wellDetails?.wellboreId }}</span></span>
          </div>
          <div class="w-full mt-3">
            <p> start range: <span class="font-semibold">{{ print_start_depth }}</span></p>
            <p> end range: <span class="font-semibold">{{ print_end_depth }}</span></p>
            <p> depth per page: <span class="font-semibold">{{ depth_to_print_per_page }}</span></p>
            <p> Notes: <span class="font-semibold">{{ enable_comments ? 'YES' : 'NO' }}</span></p>
            <p> Lithology: <span class="font-semibold">{{ enable_lithology ? 'YES' : 'NO' }}</span></p>
          </div>
        </section>
        <!-- body ends -->
      </div>
      <!-- well details ends -->
      <div :id="'forPrint'+ this.widgetId" v-if="printing_page" class="LWD_forPrint relative"
        :style="{
          width: total_print_page_width + 'px',
          height: total_print_page_height + 'px',
        }"
      >
        <div
          class="flex mnemonicsContainer"
          :style="{
            width: total_print_page_width + 'px',
            height: print_bheight + 'px',
          }"
        >
          <div
            class="__label_boxes"
            style="height: inherit"
            v-if="print_trackConfig.tracks && !hide_mnemonics_div"
          >
            <div class="flex full h-full">
              <div
                class="outer_mnemonics_container"
                v-for="(track, j) in print_trackConfig.tracks"
                :key="j + trendIndex"
                :style="{
                  marginLeft: '0px',
                  display: j == 0 ? 'flex' : '',
                  width:
                    print_track_width + 
                    (j > 0 && trackConfig.tracks.length > 2 &&
                      j + 1 < trackConfig.tracks.length &&
                      Object.keys(trackConfig.tracks[j + 1][0]).includes('hide') ? 1 : 0) + 
                      (j == 0 ? print_scale_width + print_lithology_width - 1 : 0) +
                    'px',
                }"
              >
                <div :style="j == 0 ? `width: ${print_track_width + 
                      (j > 0 && trackConfig.tracks.length > 2 &&
                      j + 1 < trackConfig.tracks.length &&
                      Object.keys(trackConfig.tracks[j + 1][0]).includes('hide') ? 1 : 0) + 'px'
                    }` : ''"
                  class="__label_boxes mnemonics_container"
                  v-show="!Object.keys(track[0]).includes('hide')"
                  :class="
                    trackConfig.tracks.length > 2 &&
                    j + 1 < trackConfig.tracks.length &&
                    Object.keys(trackConfig.tracks[j + 1][0]).includes('hide')
                      ? 'borderRightWidth'
                      : ''
                  "
                >
                  <div
                    id="print_tempdiv"
                    v-for="(curve, i) in track"
                    :key="i"
                    class="relative flex track_config_border bg-opacity-99"
                    :style="{
                      marginRight: 0 + 'px',
                      fontSize: 19 + '%',
                      height: print_mnemonics_height + 'px',
                      marginLeft: j == 0 ? '0px' : '0',
                      userSelect: 'none',
                    }"
                  >
                    <div
                      :title="curve.fullName.toUpperCase()"
                      class="name_numaric_con tooltip__tip top truncate items-center"
                      style="background: #fff; font-weight: 800;"
                    >
                      <div class="px-2">
                        <div
                          style="
                            font-size: 8px;
                            /* padding-bottom: 6px; 
                            margin-bottom: -3px;
                            margin-top: -4px; 
                            line-height: 1.1;*/
                            color: #000;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-wrap: wrap;
                          "
                          class="td_trnk"
                          v-if="curve.fullName"
                        >
                          {{
                            Object.keys(curve).includes("editedName")
                              ? curve.editedName
                              : curve.description
                          }}
                        </div>
                        <div
                          class="td_trnk mnemonic_borderLine"
                          style="color: #000;font-size: 8px;padding-bottom: 3px;"
                          :style="{ borderColor: curve.color }"
                          v-if="curve.fullName"
                        >
                          {{ curve.name }}
                        </div>
                      </div>
                      <div class="flex justify-around" style="gap: 5px; padding: 0px 3px; margin-top: 1px;">
                        <p
                          class="mnemonic_min_max"
                          style="font-size: 7px; color: #000;"
                        >
                          {{ mnemonicMinRange(curve, j) }}
                        </p>
                        <p style="font-size: 7px; color: #000;">
                          {{ curve.unit }}
                        </p>
                        <p
                          class="mnemonic_min_max"
                          style="font-size: 7px; color: #000;"
                        >
                          {{ mnemonicMaxRange(curve, j) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <h1 v-if="j == 0" 
                    style="border-top: 2px solid #000;text-align: center;font-size: 12px;color: #000; font-weight: 600;"
                    :style="{width: print_scale_width + print_lithology_width - 1 + 'px', height: print_bheight + 'px', 
                            display: hide_mnemonics_div ? 'none' : 'flex'}"
                >
                  <p v-if="depthType == 'MD' && total_leftbar_data?.selected_unit == 'METRIC'" 
                    class="flex justify-center items-end pb-3" 
                    :style="{width: print_scale_width + 'px', 
                          borderRight: (trackConfig.tracks.length ==1 || (trackConfig.tracks.length > 1 && trackConfig.tracks[1][0]?.hide)) || enable_lithology ? '2px solid #000' : '0px'
                          }"
                  >
                    MD (m)
                  </p>
                  <p v-if="depthType == 'MD' && total_leftbar_data?.selected_unit == 'IMPERIAL'" 
                    class="flex justify-center items-end pb-3" 
                    :style="{width: print_scale_width + 'px', 
                      borderRight: (trackConfig.tracks.length ==1 || (trackConfig.tracks.length > 1 && trackConfig.tracks[1][0]?.hide)) || enable_lithology ? '2px solid #000' : '0px'
                      }"
                  >
                    MD (ft)
                  </p>
                  <p v-if="depthType == 'TVD' && total_leftbar_data?.selected_unit == 'METRIC'" 
                    class="flex justify-center items-end pb-3" 
                    :style="{width: print_scale_width + 'px',
                      borderRight: (trackConfig.tracks.length ==1 || (trackConfig.tracks.length > 1 && trackConfig.tracks[1][0]?.hide)) || enable_lithology ? '2px solid #000' : '0px'
                      }"
                  >
                    TVD (m)
                  </p>
                  <p v-if="depthType == 'TVD' && total_leftbar_data?.selected_unit == 'IMPERIAL'" 
                    class="flex justify-center items-end pb-3" 
                    :style="{width: print_scale_width + 'px', 
                      borderRight: (trackConfig.tracks.length ==1 || (trackConfig.tracks.length > 1 && trackConfig.tracks[1][0]?.hide)) || enable_lithology ? '2px solid #000' : '0px'
                      }"
                  >
                    TVD (ft)
                  </p>
                  <p class="flex justify-center items-end pb-3" v-if="enable_lithology"
                      :style="{width: print_lithology_width + 'px',
                              borderRight: (trackConfig.tracks.length ==1 || (trackConfig.tracks.length > 1 && trackConfig.tracks[1][0]?.hide)) ? '2px solid #000' : '0px',
                              }"
                  >
                    LITHOLOGY
                  </p>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div
          :style="{
            height: print_track_height + 'px',
            width: total_print_page_width + 'px',
          }"
          v-if="trackConfig.tracks.length"
          :id="'print_div_id' + this.widgetId"
        >
          <div class="LwdPrintScrollDiv scrolldiv bg-opcity-95 flex"
            :style="{
              width: total_print_page_width + 'px',
            }"
            v-for="val of NumberOfPrintDivs" 
            :key="val" 
            :id="'print_div_id' + this.widgetId + val"
          >
          </div>
      </div>
      </div>
    </div>

    <!-- ADD LITHOLOGY ICONS WITH RANGES POPUP STARTS -->
    <div class="runInfo" v-if="show_add_ranges_icon_popup">
      <div class="popup">
        <div class="p-5 text-xs flex flex-col items-center">
          <div class="flex w-full justify-around mb-4">
            <button
              class="uppercase font-semibold"
              :class="section_type_to_show == 'symbol' ? 'activeClass' : ''"
              @click="changeSection('symbol')"
              v-if="section_type_to_show == 'symbol'"
            >
              Lithology
            </button>
            <button
              class="uppercase font-semibold"
              :class="section_type_to_show == 'icon' ? 'activeClass' : ''"
              @click="changeSection('icon')"
              v-if="section_type_to_show == 'icon'"
            >
              Icon
            </button>
          </div>
          <!-- SYMBOL STARTS -->
          <div class="" v-if="section_type_to_show == 'symbol'">
            <div class="">
              <div class="flex items-center">
                <label class="pl-2 font-semibold uppercase w-1/2"
                  >Start Range</label
                >
                <input
                  class="fieldHeight w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  type="number"
                  v-model="newStartRange"
                  style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                />
              </div>
              <div class="flex items-center mt-2">
                <label class="pl-2 font-semibold uppercase w-1/2"
                  >End range</label
                >
                <input
                  class="fieldHeight w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  type="number"
                  v-model="newEndRange" @change="symbolEndRangeChanged"
                  style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                />
              </div>
              <div class="flex items-center mt-2">
                <label class="pl-2 font-semibold uppercase w-1/2">Icon</label>
                <select
                  class="fieldHeight w-1/2 rounded text-xxxs text-white focus:outline-none focus-visible:outline-none px-2"
                  v-model="newSelectedIcon"
                  style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                >
                  <option value="select" disabled>Select</option>
                  <option
                    :value="iconObj"
                    v-for="(iconObj, index) in lithology_icons_list"
                    :key="index"
                    v-show="iconObj?.type == 'symbol'"
                  >
                    <div class="flex justify-between">
                      {{ iconObj?.iconName }}
                      <img
                        :src="'data:image/png;base64,' + iconObj.icon"
                        style="width: 40px; height: 40px"
                      />
                    </div>
                  </option>
                </select>
              </div>
              <div class="flex items-center mt-2" v-if="newSelectedIcon">
                <label class="pl-2 font-semibold uppercase w-1/2"
                  >preview</label
                >
                <div class="fieldHeight w-1/2 flex justify-end">
                  <img
                    :src="'data:image/png;base64,' + newSelectedIcon.icon"
                    style="width: 25px; height: 25px"
                  />
                </div>
              </div>
            </div>
            <div class="flex justify-center mt-4">
              <button
                v-if="
                  newStartRange !== null &&
                  newStartRange !== '' &&
                  newEndRange !== null &&
                  newEndRange !== '' &&
                  newSelectedIcon
                "
                type="button"
                @click="add_ranges_icon"
                class="text-xxs font-semibold border"
                style="
                  padding: 3px 8px;
                  outline: none;
                  color: var(--activeTextColor);
                  border-color: currentColor;
                "
              >
                Submit
              </button>
              <button v-if="show_lithology_delete_button && Object.keys(clickedLithologyObj).length && 
                  newStartRange !== null &&
                  newStartRange !== '' &&
                  newEndRange !== null &&
                  newEndRange !== '' "
                @click="delete_lithology"
                class="text-xxs font-semibold"
                style="
                  padding: 3px 8px;
                  border: 1px solid #fda900;
                  color: #fda900;
                  outline: none;
                  margin-left: 10px;
                "
              >
                Delete
              </button>
              <button
                @click="close_lithology_add_icon_popup"
                class="text-xxs font-semibold"
                style="
                  padding: 3px 8px;
                  border: 1px solid #f40303;
                  outline: none;
                  color: #f40303;
                  margin-left: 10px;
                "
              >
                Cancel
              </button>
            </div>
          </div>
          <!-- SYMBOL ENDS -->

          <!-- ICON STARTS -->
          <div class="" v-if="section_type_to_show == 'icon'">
            <div class="">
              <div class="flex items-center">
                <label class="pl-2 font-semibold uppercase w-1/2">depth</label>
                <input
                  class="fieldHeight w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  type="number"
                  v-model="newStartRange"
                  style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                />
              </div>
              <div class="flex items-center mt-2">
                <label class="pl-2 font-semibold uppercase w-1/2">Icon</label>
                <select
                  class="fieldHeight w-1/2 rounded text-xxxs text-white focus:outline-none focus-visible:outline-none px-2"
                  v-model="newSelectedIcon"
                  style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                >
                  <option value="select" disabled>Select</option>
                  <option
                    :value="iconObj"
                    v-for="(iconObj, index) in lithology_icons_list"
                    :key="index"
                    v-show="iconObj?.type == 'icon'"
                  >
                    <div class="flex justify-between">
                      {{ iconObj?.iconName }}
                      <img
                        :src="'data:image/png;base64,' + iconObj.icon"
                        style="width: 40px; height: 40px"
                      />
                    </div>
                  </option>
                </select>
              </div>
              <div class="flex items-center mt-2" v-if="newSelectedIcon">
                <label class="pl-2 font-semibold uppercase w-1/2"
                  >preview</label
                >
                <div class="fieldHeight w-1/2 flex justify-end">
                  <img
                    :src="'data:image/png;base64,' + newSelectedIcon.icon"
                    style="width: 25px; height: 25px"
                  />
                </div>
              </div>
            </div>
            <div class="flex justify-center mt-4">
              <button
                v-if="
                  newStartRange !== null &&
                  newStartRange !== '' &&
                  newSelectedIcon
                "
                type="button"
                @click="add_ranges_icon"
                class="text-xxs font-semibold border"
                style="
                  padding: 3px 8px;
                  outline: none;
                  color: var(--activeTextColor);
                  border-color: currentColor;
                "
              >
                Submit
              </button>
              <button
                @click="close_lithology_add_icon_popup"
                class="text-xxs font-semibold"
                style="
                  padding: 3px 8px;
                  border: 1px solid #f40303;
                  outline: none;
                  color: #f40303;
                  margin-left: 10px;
                "
              >
                Cancel
              </button>
            </div>
          </div>
          <!-- ICON ENDS -->
        </div>
      </div>
    </div>
    <!-- ADD LITHOLOGY ICONS WITH RANGES POPUP ENDS -->

    <!-- ADD TO CONSOLIDATED DATA WITH RANGES POPUP STARTS -->
    <div class="runInfo" v-if="show_consolidated_ranges_popup">
      <div class="popup">
        <div class="p-5 text-xs flex flex-col items-center">
          <div class="">
            <div class="">
              <div class="flex items-center">
                <label class="pl-2 font-semibold uppercase w-1/2"
                  >Start Range</label
                >
                <input
                  class="fieldHeight w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  type="number"
                  v-model="fileStartRange"
                  style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                />
              </div>
              <div class="flex items-center mt-2">
                <label class="pl-2 font-semibold uppercase w-1/2"
                  >End range</label
                >
                <input
                  class="fieldHeight w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  type="number"
                  v-model="fileEndRange"
                  style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                />
              </div>
              <div
                class="flex items-center mt-2"
                v-if="
                  fileStartRange !== null &&
                  fileStartRange !== '' &&
                  fileEndRange !== null &&
                  fileEndRange !== ''
                "
              >
                <label class="pl-2 font-semibold uppercase w-1/2">Run</label>
                <select
                  class="fieldHeight w-1/2 rounded text-xxxs text-white focus:outline-none focus-visible:outline-none px-2"
                  v-model="selectedFile"
                  style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                >
                  <option value="select" disabled>Select</option>
                  <option
                    :value="file"
                    v-for="(file, index) in files_data"
                    :key="index"
                  >
                    {{ file.alias_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="flex justify-center mt-4">
              <button
                v-if="
                  fileStartRange !== null &&
                  fileStartRange !== '' &&
                  fileEndRange !== null &&
                  fileEndRange !== '' &&
                  selectedFile !== ''
                "
                type="button"
                @click="add_new_filerange"
                class="text-xxs font-semibold border"
                style="
                  padding: 3px 8px;
                  outline: none;
                  color: var(--activeTextColor);
                  border-color: currentColor;
                "
              >
                Submit
              </button>
              <button
                @click="close_consolidated_range_popup"
                class="text-xxs font-semibold"
                style="
                  padding: 3px 8px;
                  border: 1px solid #f40303;
                  outline: none;
                  color: #f40303;
                  margin-left: 10px;
                "
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ADD TO CONSOLIDATED DATA WITH RANGES POPUP ENDS -->

    <!-- ADD MESSAGE POPUP STARTS -->
    <div class="" v-if="show_message_adding_popup">
      <div
        class="fixed rounded"
        style="background: var(--sidebarbg); color: var(--textColor)"
        :style="message_pop_styles"
      >
        <div class="text-xs flex flex-col items-center p-2 pt-0">
          <div class="">
            <div class="">
              <div class="flex items-center">
                <!-- <label class="pl-2 font-semibold uppercase w-1/2">DEPTH {{ messageDepth }}</label> -->
                <!-- <input
                  class="fieldHeight w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  type="number"
                  v-model="messageDepth"
                  style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                /> -->
              </div>
              <div
                class="flex items-center mt-2 p-1"
                v-if="messageDepth !== null && messageDepth !== ''"
              >
                <!-- <label class="pl-2 font-semibold uppercase w-1/2">Text</label> -->
                <textarea
                  class="text-xxxs text-gray-700 focus:outline-none focus:shadow-outline bg-gray-100 px-2 py-1"
                  type="text"
                  v-model="messageText"
                  style="
                    width: 25em;
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                ></textarea>
              </div>
            </div>
            <div class="flex justify-center mt-1">
              <button
                v-if="
                  messageDepth !== null &&
                  messageDepth !== '' &&
                  messageText !== null
                "
                type="button"
                @click="add_or_update_message"
                class="text-xxs font-semibold border"
                style="
                  padding: 3px 8px;
                  outline: none;
                  color: var(--activeTextColor);
                  border-color: currentColor;
                "
              >
                Submit
              </button>
              <button
                @click="close_message_adding_popup"
                class="text-xxs font-semibold"
                style="
                  padding: 2px 2px;
                  border: 1px solid #f40303;
                  outline: none;
                  color: #f40303;
                  margin-left: 10px;
                "
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ADD MESSAGE POPUP ENDS -->

  <!-- DELETE ICON POPUP STARTS -->
   <div class="runInfo" v-if="show_delete_icon_popup">
      <div class="popup">
        <div class="p-5 text-xs flex flex-col items-center">
          <div class="">
            <div class="">
              <div class="flex items-center">
                <p class="pl-2 font-semibold uppercase w-full">
                  Do you want to delete the icon
                </p>
              </div>
            </div>
            <div class="flex justify-center mt-4">
              <button
                type="button"
                @click="delete_icon"
                class="text-xxs font-semibold border"
                style="
                  padding: 3px 8px;
                  outline: none;
                  color: var(--activeTextColor);
                  border-color: currentColor;
                "
              >
                Delete
              </button>
              <button
                @click="close_delete_icon_popup"
                class="text-xxs font-semibold"
                style="
                  padding: 3px 8px;
                  border: 1px solid #f40303;
                  outline: none;
                  color: #f40303;
                  margin-left: 10px;
                "
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- DELETE ICON POPUP ENDS -->


    <!-- CONFLICT POPUP STARTS -->
    <div class="runInfo" v-if="show_conflict_popup">
      <div class="popup">
        <div class="p-5 text-xs flex flex-col items-center">
          <h4 class="" v-if="conflict_range['sameValues']">
            In the below files, start depth
            <span style="color: var(--activeTextColor)">{{
              conflict_range["start"]
            }}</span>
            , end depth
            <span style="color: var(--activeTextColor)">{{
              conflict_range["end"]
            }}</span>
            are same,
          </h4>
          <h4 class="" v-else>
            There is a conflict in below files from
            <span style="color: var(--activeTextColor)">{{
              conflict_range["start"]
            }}</span>
            to
            <span style="color: var(--activeTextColor)">{{
              conflict_range["end"]
            }}</span>
            depths,
          </h4>
          <h4 class="">Please choose the file below to get the data.</h4>
          <div class="pt-2" style="width: 90%">
            <div class="flex justify-around">
              <div class="flex items-center">
                <input
                  type="radio"
                  :value="conflict_range['actualObject'].index"
                  v-model="choosedFileIndex"
                />
                <label class="pl-2 font-semibold"
                  >{{ conflict_range["actualObject"].obj.startDepth }} -
                  {{ conflict_range["actualObject"].obj.endDepth }}</label
                >
              </div>
              <div class="flex items-center">
                <input
                  type="radio"
                  :value="conflict_range['conflictObject'].index"
                  v-model="choosedFileIndex"
                />
                <label class="pl-2 font-semibold"
                  >{{ conflict_range["conflictObject"].obj.startDepth }} -
                  {{ conflict_range["conflictObject"].obj.endDepth }}</label
                >
              </div>
            </div>
            <div class="flex justify-center mt-2">
              <button
                v-if="choosedFileIndex !== null"
                type="button"
                @click="resolveConflict"
                class="text-xxs font-semibold border"
                style="
                  padding: 3px 8px;
                  outline: none;
                  color: var(--activeTextColor);
                  border-color: currentColor;
                "
              >
                Submit
              </button>
              <button
                v-on:click="
                  () => {
                    this.show_conflict_popup = false;
                  }
                "
                class="text-xxs font-semibold"
                style="
                  padding: 3px 8px;
                  border: 1px solid #f40303;
                  outline: none;
                  color: #f40303;
                  margin-left: 10px;
                "
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CONFLICT POPUP ENDS -->


    <!-- PRINT OPTIONS POPUP STARTS -->
    <div class="runInfo" v-if="open_printing_options_page">
      <div class="popup">
        <div class="p-5 text-xs flex flex-col items-center">
          <div class="">
            <div class="">
              <div class="flex items-center">
                <label class="pl-2 font-semibold uppercase w-1/2"
                  >Start Range</label>
                <input
                  class="fieldHeight w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  type="number"
                  v-model="print_start_depth"
                  style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                />
              </div>
              <div class="flex items-center mt-2">
                <label class="pl-2 font-semibold uppercase w-1/2"
                  >End range</label>
                <input
                  class="fieldHeight w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  type="number"
                  v-model="print_end_depth"
                  @change="changed_print_end_depth"
                  style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                />
              </div>
              <div class="flex items-center mt-2" 
                v-if="print_start_depth !== null && 
                      print_start_depth !== ''  && 
                      print_end_depth !== null && 
                      print_end_depth !== ''"
              >
                <label class="pl-2 font-semibold uppercase w-1/2"
                  >Depth per page</label>
                <input
                  class="fieldHeight w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  type="number"
                  v-model="depth_to_print_per_page"
                  @change="changed_depth_to_print_per_page"
                  style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                />
              </div>
              <div class="flex items-center mt-2 pl-2">
                <input
                  type="checkbox"
                  v-model="enable_comments"
                  class="m-2"
                  style="margin-left: 0px;"
                />
                <label class="pl-2 font-semibold uppercase"> Notes </label>
              </div>
              <div class="flex items-center mt-2 pl-2">
                <input
                  type="checkbox"
                  v-model="enable_lithology"
                  class="m-2"
                  style="margin-left: 0px;"
                />
                <label class="pl-2 font-semibold uppercase"> Lithology </label>
              </div>
            </div>
            <div class="flex justify-center mt-4">
              <button
                v-if="
                  print_start_depth !== null &&
                  print_start_depth !== '' &&
                  print_end_depth !== null &&
                  print_end_depth !== '' &&
                  depth_to_print_per_page !== null &&
                  depth_to_print_per_page !== ''
                "
                type="button"
                @click="print_page"
                class="printBtn text-xxs font-semibold border"
                style="
                  padding: 3px 8px;
                  outline: none;
                  color: var(--activeTextColor);
                  border-color: currentColor;
                "
                :disabled="printing_page"
              >
                {{ printing_page ? 'Please wait..' : 'Print' }}
              </button>
              <button
                @click="close_print_options_popup"
                class="text-xxs font-semibold"
                style="
                  padding: 3px 8px;
                  border: 1px solid #f40303;
                  outline: none;
                  color: #f40303;
                  margin-left: 10px;
                "
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PRINT OPTIONS POPUP ENDS -->

    <!-- PRINT PREVIEW POPUP CODE STARTS -->
    <div class="print_preview_popup" v-if="open_print_preview_popup">
      <div class="print_preview_data">
        <div
          class="p-4 py-2 flex justify-between items-center shadow"
          style="height: 42px"
        >
          <h4 class="text-xs uppercase font-semibold">
            Download Screenshot
          </h4>
          <button
            v-on:click="close_print_preview_popup"
            class="text-sm font-normal uppercase text-red_"
            style="color: #cf634b"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="w-full flex px-5 py-3 overflow-auto" style="height: calc(100% - 50px)">
          <div class="w-full h-full flex mx-auto">
            <iframe
              v-if="pdfDataUrl"
              :src="pdfDataUrl"
              frameborder="0"
              class="w-3/4 mx-auto"
              style="height: calc(100% - 50px); max-height: 100%"
            ></iframe>
            <p v-else> Print is not generated. </p>
            <div
              class="w-1/4 mb-2 px-4 pt-3 flex justify-end items-start"
            >
              <a
                :id="'download_print' + this.widgetId"
                href=""
                download
              ></a>
              <button
                v-on:click="download_print"
                class="text-xs uppercase mr-5 font-semibold px-4 py-1 border"
                style="
                  color: var(--activeTextColor);
                  border-color: currentColor;
                  font-size: 11px;
                "
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PRINT PREVIEW POPUP CODE ENDS -->


  </div>
</template>
  
<script>
import nouislider from "../../../js/noUiSlider-15.2.0/src/nouislider.js";
import "../../../js/noUiSlider-15.2.0/src/slider.css";
import { template } from "../../../js/vertical/template.js";
import plotConfig from "../../../js/vertical/wellconfig.js";
import * as d3 from "d3";
import AdminSubNavbar from "../../Navbars/AdminSubNavbar.vue";
import Props from "../Trend/Trendprops.vue";
import CustomSelect from "../../Select/SelectComponent.vue";
import apiService from "../../../api/services";
import defaultRanges from "../../../js/ranges";
import moment from "moment-timezone";
// import html2canvas from "html2canvas";
// import { PDFDocument } from "pdf-lib";

export default {
  emits: ["lithology_data", "selected_runs", "getConsolidateLithologyIcons", "background_live", "getting_curveProps"],
  name: "LWDTrend",
  components: {
    AdminSubNavbar,
    Props,
    CustomSelect,
  },
  data() {
    return {
      minmax: {},
      isZoom: false,
      isRerendering: false,
      zero: [
        "wob",
        "mse",
        "diff",
        "spm1",
        "spm2",
        "spm3",
        "spp",
        "tgpm",
        "mfop",
        "rpm",
        "mg1c",
        "simplifiedmse",
        "stickslipindex",
        "washoutindex",
        "chkp",
      ],
      double: [
        "hkl",
        "bpos",
        "dbtm",
        "DEPTH",
        "rop",
        "tor",
        "drtm",
        "inc",
        "az",
        "tvd",
        "dens_in",
        "dens_out",
        "temp_in",
        "temp_out",
      ],
      // Socket Variables:
      stopUpdation: false,
      isShowTooltip: false,
      depthMaxValue: 0,
      depthMinValue: 0,
      topic: null,

      liveDisabled: false,
      focusAxisList: [],
      bgColor: "#202124",
      configurePlots: {},
      templateWidth: "",
      div_id: "divId",
      tickColor: "#ededed",
      sliderWidth: 170,
      readyToPlot: false,
      emptyPlot: true,
      currentCurve: null,
      curve: "",
      trackConfig: {
        height: "500",
        width: "260",
        UWI: "00/01-01-095-19W4/0",
        curveName: "CHALKTALK",
        tracks: [],
        curveType: "DEPTH",
        depthName: "DEPTH",
      },
      bheight: 2, // 35*5 = boxes width, 5 = sum of boxes margins, 30 = height of track heading
      mnemonics_height: 35,
      print_bheight: 45 * 5 + 2,
      print_mnemonics_height: 45,
      wellId: null,
      wellboreId: null,
      logId: null,
      wellboreState: null,
      logType: "depth",
      data: [],
      completeData: [],
      zoomMin: null,
      zoomMax: null,
      plots: [],
      plotTemplate: template(""),
      curvesMinsMaxes: {},
      sliderWidth: 5,
      leftbarWidth: 100,
      trackWidth: 500,
      scaleMin: 0,
      scaleMax: 100,
      rangeMinValue: 0,
      rangeMaxValue: 100,
      rangeSliderWidth: 20,
      rangeValue: 0,
      clear_range_timeout: null,
      scaleMinFixed: 0,
      scaleMaxFixed: 100,
      singleTrackHeight: null,
      slider: null,
      noUislider: null,
      singleTrackWidth: 120,
      totTracks: [],
      compId: "",
      curveProps: {},
      templateHeight: 0,
      tracksNbr: 6,
      tickThickness: 0.1,
      tracks: [],
      feet: "",
      status: -1,
      defaultColor: [
        // ["#c530ab", "#ff0000", "#c530ab", "#ff0000","#c530ab"],
        // ["#2abbbc", "#F08E05"],
        // ["#37b937", "#fe019a"],
        // ["#37b937", "#fe019a"],
        // ["#37b937", "#fe019a"],
        // ["#41E100", "#ec6409", "#c800d2", "#e1415f", "#50b4c8"],
        // ["#aa85db", "#740b23", "#4c37d7", "#fa0ac8", "#8c8746"],
        // ["#50b4c8", "#e1415f", "#c800d2", "#ec6409", "#41E100"],
        // ["#8c8746", "#fa0ac8", "#4c37d7", "#740b23", "#aa85db"],
        // ["#c800d2", "#50b4c8", "#41E100", "#e1415f", "#ec6409"],
        ["#dee100", "#ec6409", "#c800d2", "#7cb196", "#50b4c8"],
        ["#aa85db", "#740b23", "#527698", "#fa0ac8", "#8c8746"],
        ["#50b4c8", "#7cb196", "#c800d2", "#ec6409", "#dee100"],
        ["#8c8746", "#fa0ac8", "#527698", "#740b23", "#aa85db"],
        ["#c800d2", "#50b4c8", "#dee100", "#7cb196", "#ec6409"],
      ],
      myWatcher: null,
      scaleWidth: 90 - 2, // 2=RIGHT BORDER
      lithologyWidth: 60 * 2,
      interval: null,
      scroll_clear_timer: null,
      mnemonics_arr: {},
      filtered_mnemics_data: {},
      hide_mnemonics_div: true,
      tooltip_zoom_height: 25,
      show_add_ranges_icon_popup: false,
      newEndRange: null,
      newStartRange: null,
      newSelectedIcon: "",
      copy_of_lithology_icons_data: [],
      show_conflict_popup: false,
      conflict_range: {},
      choosedFileIndex: null,
      show_consolidated_ranges_popup: false,
      fileStartRange: null,
      fileEndRange: null,
      selectedFile: "",
      section_type_to_show: "symbol",
      show_message_adding_popup: false,
      messageText: null,
      messageDepth: null,
      messageX_pos: null,
      messageY_pos: null,
      show_delete_icon_popup: false,
      clickedGraphIndex: null,
      track_wise_mnemonic_colors: [],
      clickedLithologyObj: null,
      show_lithology_delete_button: false,

      // FOR PRINT
      print_track_width: null,
      print_track_height: null,
      print_lithology_width: 90,
      print_scale_width: 60 - 2,
      open_printing_options_page: false,
      printing_page: false,
      open_print_preview_popup: false,
      print_start_depth: null,
      print_end_depth: null,
      depth_to_print_per_page: null,
      enable_comments: false,
      enable_lithology: false,
      total_print_page_width: null,
      total_print_page_height: null,
      pdfDataUrl: null,
      // FOR PRINT END

      print_trackConfig: {},
      print_plots: [],
      initially_assinging_range: false,
      NumberOfPrintDivs: [],
    };
  },
  props: {
    wellDetails: {
      type: Object,
    },
    well_id: {
      type: String,
    },
    requiredData: {
      type: Object,
    },
    displayIndex: [Number, String],
    widgetIndex: Number,
    height: {
      type: [Number, String],
      default: "50",
    },
    width: {
      type: [Number, String],
      default: "50",
    },
    divid: String,
    id: String,
    displayId: String,
    widgetId: String,
    plotConfigProps: {
      type: Object,
      default: {},
    },
    trendIndex: Number,
    selectedWell: {
      type: Object,
    },
    idx: [Number, String],
    scaleMaxFixed_Prop: [Number, String],
    scaleMax_Prop: [Number, String],
    scaleMaxProp: [Number, String, Object],
    total_leftbar_data: [Object],
    left_bar_tracks: [Array],
    default_tracks_prop: [Array],
    number_of_tracks: {
      type: [Number, String],
      default: 4,
    },
    data_points: [Object, Array],
    hide_tooltip_zoom: Boolean,
    depthType: String,
    lithology_icons_data: Array,
    lithology_icons_list: Array,
    selected_files: Array,
    payload_to_get_file_litho_ranges: Object,
    files_data: Array,
    selectedType: String,
    consolidatedListData: Array,
    selectedSavedRun: Object,
    isBackgroundLive: Boolean,
  },
  beforeUnmount() {
    console.log("before unmount console : ", this.left_bar_tracks);
  },
  async mounted() {
    try {
      this.compId = Math.random().toString(36).slice(8);
      this.div_id = "id" + this.compId;
      this.myWatcher = this.$store.subscribe(async (mutation, state) => {
        if (
          mutation.type == "rect/setCurveProps" &&
          this.displayId == mutation.payload.displayId
        ) {
          if (
            mutation.payload.value.i == -1 &&
            mutation.payload.value.j == -1
          ) {
          } else {
            if (mutation.payload.value.curve)
              this.setCurveProps(
                mutation.payload.value.j,
                mutation.payload.value.i,
                mutation.payload.value.curve
              );
            else
              this.deleteCurve(
                mutation.payload.value.j,
                mutation.payload.value.i
              );
          }
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.trackConfig.tracks = this.default_tracks_prop.slice(
        0,
        this.number_of_tracks
      );
      this.hide_mnemonics_div = true;
    }
  },
  destroyed() {},
  computed: {
    message_pop_styles() {
      let obj = {
        left: this.messageX_pos + 10 + "px",
      };
      this.messageY_pos > window.innerHeight * 0.8
        ? (obj["bottom"] = window.innerHeight - this.messageY_pos + 5 + "px")
        : (obj["top"] = this.messageY_pos + "px");
      return obj;
    },
    wh() {
      let wh = {};
      wh.width = this.width;
      wh.height = this.height;
      wh.templateWidth = this.width - 10;
      wh.templateHeight = this.height - 10;
      this.templateHeight = this.height - 10;
      this.templateWidth = this.width - 10;
      if (this.trackConfig.tracks)
        this.setTrackWidth(this.trackConfig.tracks.length);
      else this.setTrackWidth(0);
      this.setTrackHeight();
      // this.rerender('WH');

      return wh;
    },
    darkDark() {
      d3.selectAll(".y2").style(
        "fill",
        // this.$store.state.rect.darkmode ? "white" : "black"
        this.$store.state.rect.darkmode ? "black" : "black"
      );
      setTimeout(() => {
        this.rerender("darkDark");
      }, 0);
      return this.$store.state.rect.darkmode;
    },
  },

  methods: {
    changed_depth_to_print_per_page(){
      if(this.depth_to_print_per_page > (this.print_end_depth - this.print_start_depth)){
        this.depth_to_print_per_page = null;
        this.$toast.clear();
        this.$toast.error(`Depth per page should not be greater than ${this.print_end_depth - this.print_start_depth}`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    changed_print_end_depth(){
      if(this.print_end_depth <= this.print_start_depth){
        this.print_end_depth = null;
        this.$toast.clear();
        this.$toast.error(`End range should be greater than the start range`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    async print_configure(){
      this.print_trackConfig = JSON.parse(JSON.stringify(this.trackConfig))
      let UWI = this.print_trackConfig.UWI;
      let curveName = this.print_trackConfigcurveName;
      let _plots = [];
      let tracks_temp = this.print_trackConfig.tracks;
      if (tracks_temp.length) {
        this.setTrackWidth(tracks_temp.length);
      }
      for (let i = 0; i < tracks_temp.length; i++) {
        let track = tracks_temp[i];
        let curveNames = track.map((curve) => curve.name);
        let curveColors = track.map((curve, k) =>
          curve.color ? curve.color : this.defaultColor[i][k]
        );
        let fillDir = track.map((curve) => curve.fillD);
        let fill = track.map((curve) => curve.fill);
        let strokes = track.map((curve) => curve.stroke);
        let units = track.map((curve) => {
          return "unit";
        });
        let scale = track.map((curve) => curve.scale);
        let scaleType = track.map((curve) => curve.scaleType);
        let trackId;
        if (track.length > 0) {
          trackId = track[0].trackId;
        }

        let trackObjs = [];
        for (let j = 0; j < track.length; j++) {
          let configObj = {
            curve_name: "",
            fill: "",
            fill_direction: "",
            cutoffs: [],
            fill_colors: [],
          };
          configObj.curve_name = track[j].name;
          configObj.fill_direction = track[j].fillD;
          configObj.fill = track[j].fill;
          configObj.cutoffs = [0, 4.25];
          configObj.color = track[j].color
            ? track[j].color
            : this.defaultColor[i][j];
          track[j].color = track[j].color
            ? track[j].color
            : this.defaultColor[i][j];
          trackObjs.push(configObj);
          trackObjs = trackObjs;
        }

        let graphType = "DEPTH";
        let plot = plotConfig(
          this.plotTemplate,
          UWI,
          curveNames,
          curveColors,
          units,
          strokes,
          scale,
          scaleType,
          trackObjs,
          this.div_id,
          this.singleTrackWidth,
          this.singleTrackHeight,
          graphType,
          this.tickColor,
          this.tickThickness,
          leftbarbg,
          tooltipbg,
          trackId
        );

        _plots.push(plot);
      }

      this.print_plots = _plots;
      return this.print_plots;
    },
    async print_verticalplots(page_starting_depth, endDepthPerPage, notes, lithology, endDepth, startDepth, divId){
      // let noDIV = d3
      //   .select("#print_div_id"+ this.widgetId)
      //   .selectAll("div")
      //   .remove();
      // let noSVG = d3
      //   .select("#print_div_id"+ this.widgetId)
      //   .selectAll("svg")
      //   .remove();
      let new_templates = [];
      let templates = this.print_plots;
      for (let i = 0; i < templates.length; i++) {
        if (i == 1) {
          if (templates.length > 0) {
            await this.print_createLeftBar(divId, templates, page_starting_depth, endDepthPerPage);
            if(lithology){
              await this.print_createLithologyBar(divId, templates, page_starting_depth, endDepthPerPage, endDepth, startDepth);
            }
          }
        }
        templates[i][0]["curve_box"]["div_id"] = templates[i][0]["curve_box"]["trackId"];
        new_templates.push(templates[i]);
        let template = templates[i];
        await this.print_curveBox(template, i, divId, page_starting_depth, endDepthPerPage, notes);
      }
      if (templates.length == 1) {
        await this.print_createLeftBar(divId, templates, page_starting_depth, endDepthPerPage);
        if(lithology){
          await this.print_createLithologyBar(divId, templates, page_starting_depth, endDepthPerPage, endDepth, startDepth);
        }
      }
    },
    async print_curveBox(template_for_plotting, index, divId, page_starting_depth, endDepthPerPage, notes){
      try {
        let template_overall = template_for_plotting[0]["curve_box"];
        let template_components = template_for_plotting[0]["components"];
        let template_curves = template_components[0]["curves"][0];
        let template_lines = template_components[0]["lines"];
        let title = "";
        /// Parameters that define shape & size of overall curve box
        let width = this.print_track_width;
        let height_multiplier_components = 0.95;
        if (template_overall["height_multiplier_components"]) {
          height_multiplier_components =
            template_overall["height_multiplier_components"];
        }

        let height_components = this.print_track_height;
        // let height = template_overall['height'] * height_multiplier_components;
        let height = height_components * height_multiplier_components;
        let margin = template_overall["margin"];
        let gridlines = template_overall["gridlines"];
        let gridlines_color = template_overall["gridlines_color"];

        //// Data is in d3.js form. An array of objects consisting of single level key:value pairs
        let data = this.data;

        if (data == null || (data["DEPTH"] && data["DEPTH"].length == 0)) {
          console.info("no data");
          this.isRerendering = false;
        
        }

        //// Variables related to curves, these should all be arrays with one or more values!
        let curve_names = template_curves["curve_names"];
        let curve_colors = template_curves["curve_colors"];
        let curve_units = template_curves["curve_units"];
        let scale_linear_log_or_yours =
          template_curves["scale_linear_log_or_yours"];
        if (template_curves["curve_units"]) {
          curve_units = template_curves["curve_units"];
        } else {
          curve_units = "";
        }
        let curveScales = template_curves["scale"];
        let curveScaleType = template_curves["scaleType"];

        
        //// The depth_curve_name needs to be the same for all curves plotted!
        let depth_curve_name = "";
        if (
          template_curves["depth_curve_name"].length > 1 &&
          typeof template_curves["depth_curve_name"] == "object" &&
          template_curves["depth_curve_name"][0] !==
            template_curves["depth_curve_name"][1]
        ) {
          depth_curve_name =
            "depth_curve_name is not the same in two or more curves";
        } else {
          depth_curve_name = template_curves["depth_curve_name"];
        }
        let depth_type_string = "";
        if (
          template_curves["depth_type_string"].length > 1 &&
          typeof template_curves["depth_type_string"] == "object" &&
          template_curves["depth_type_string"][0] !=
            template_curves["depth_type_string"][1]
        ) {
          depth_type_string =
            "depth type string is not the same in two or more curves";
        } else if (template_curves["depth_type_string"][0] == "") {
          depth_type_string = "";
        } else if (template_curves["depth_type_string"]) {
          depth_type_string = "- " + template_curves["depth_type_string"];
        }
        let depth_units_string = "";
        if (
          template_curves["depth_units_string"] &&
          template_curves["depth_units_string"][0] !== ""
        ) {
          depth_units_string = "- " + template_curves["depth_units_string"];
        }
        
        
        let trendIndex = this.trendIndex;
        // if(index == 0){
          // d3.select("#" + divId)
          //   .selectAll("*")
          //   .remove();
        // }
        ///////// NEED TO FIX DEPTHS AS THERE ARE MULTIPLE DEPTH LIMITS AND THEY NEED TO BE CALCULATED PROPERLY !!!!! //////////////////////////
        //       //// Calculate depth min and max if depth min and/or max is not given explicitly in the template
        let depth_min;
        let depth_max;
        if (
          !template_curves["depth_limits"] ||
          template_curves["depth_limits"][0]["min"] == "autocalculate"
        ) {
          depth_min = page_starting_depth;
        } else {
          depth_min = template_curves["depth_limits"][0]["min"];
        }
        //// max depth
        if (
          !template_curves["depth_limits"] ||
          template_curves["depth_limits"][0]["max"] == "autocalculate"
        ) {
          depth_max = endDepthPerPage;
        } else {
          depth_max = template_curves["depth_limits"][0]["max"];
        }
        // [depth_max,depth_min]
        //// Apply depth min and max to incoming well log data
        //// To save time, we'll first check if the first object in the array had as depth that is smaller than min
        //// and check if the last object in the array has a depth that is larger than the max, if not. we do nothing.
        if (data.length > 0 && data != null) {
          if (
            data[0][depth_curve_name] > depth_min &&
            data[data.length - 1][depth_curve_name] < depth_max
          ) {
          } else {
            data = data.filter(function (objects) {
              return (
                objects[depth_curve_name] > depth_min &&
                objects[depth_curve_name] < depth_max
              );
            });
          }
          if (data.length > 0) {
            if (
              template_curves["min_depth"][0] == "autocalculate" ||
              template_curves["min_depth"] == "autocalculate"
            ) {
              depth_min = data[0][depth_curve_name];
            } else {
              depth_min = template_curves["min_depth"];
            }
            if (
              template_curves["max_depth"][0] == "autocalculate" ||
              template_curves["max_depth"] == "autocalculate"
            ) {
              depth_max = data[data.length - 1][depth_curve_name];
            } else {
              depth_max = template_curves["max_depth"];
            }
          }
        }
        let svg = "";

        svg = d3
          .select("#" + divId)
          .append("svg")
          .attr(
            "style",
            `border: 2px solid #000; ${
              index == 0
                ? "border-top-width: 1px;border-left-width: 1px"
                : this.trackConfig.tracks.length - 1 == index ? "border-top-width: 1px;border-right-width: 2px;border-left-width: 0px" : "border-top-width: 1px;border-right-width: 1px;border-left-width: 0px"
            }`
          )
          .style("max-height", height_components + "px")
          .style("height", height_components + "px")
          .style("background", "var(--lwdGraphBg)")

        //////////////  Calculate Axis & Scales =>//////////////
        //// Need to handle: zero curves, arbitrary number of curves, and min/max of all curves in single axis.
        //// For zero curves, need to look into rectange and lines for x scales maybe?
        //// Need to handle scales in linear, log, or arbitary user-provided scale.
        //// Also, need x function for gridlines! so....?
        //////////////  Calculate x domain extent for one or more than one curve, used in scaling =>//////////////
        let mins = [];
        let maxes = [];
        mins = mins;
        maxes = maxes;
        for (let i = 0; i < curve_names.length; i++) {
          let curveObj = {
            name: "",
            min: "",
            max: "",
          };
          let curveName = curve_names[i];
          curveObj.name = curve_names[i];
          let curveScale = curveScales[i];
          let scaleType = curveScaleType[i];
          let min_this = "";
          let max_this = "";

          if (
            scaleType == "manual" &&
            (curveScale != null || curveScale != undefined)
          ) {
            if (curveScale[0] != null || curveScale[0] != "") {
              min_this = curveScale[0];
            } else {
              min_this = this.minmax[curve_names[i]].min;
              curveScale[0] = min_this;
            }
            if (curveScale[1] != null || curveScale[1] != "") {
              max_this = curveScale[1];
            } else {
              max_this = this.minmax[curve_names[i]].max;
              curveScale[1] = max_this;
            }
          } else {
            let currentRange = defaultRanges.ranges.find(
              (data) => data.name == curve_names[i]
            );

            if (currentRange) {
              min_this = currentRange.low;
              max_this = currentRange.high;
            } else {
              // min_this = this.minmax[curve_names[i]].min;
              // max_this = this.minmax[curve_names[i]].max;
              min_this = 0;
              max_this = 500;
            }
            curveScale[0] = min_this;
            curveScale[1] = max_this;
          }
          curveObj.min = min_this;
          curveObj.max = max_this;
          curvesMinsMaxes.push(curveObj);

          mins.push(min_this);
          maxes.push(max_this);
          mins = mins;
          maxes = maxes;
        }

        let min_all_curves = d3.min(mins);
        let max_all_curves = d3.max(maxes);
        if (curve_names.length == 0) {
          //// THIS NEEDS TO CHANGE TO LOOK AT RECTANGLE AT SOME POINT!!!!!!
          min_all_curves = 0;
          max_all_curves = 100;
        }
        //////////////  Calculate x domain extent for one or more than one curve, used in scaling =>//////////////
        let x_func;
        let x;
        let y = d3
          .scaleLinear()
          .domain([endDepthPerPage, page_starting_depth])
          .range([height_components, 0]);

        let yAxis2 = (g) =>
          g
            .attr("transform", `translate(${margin.left - 35},0)`)
            .call(d3.axisLeft(y))
            .call((g) => g.select(".domain"));

        //////////////  Building curvebox parts that aren't header. First define size & title =>//////////////

        x = d3
          .scaleLinear()
          .domain([min_all_curves, max_all_curves])
          .range([margin.left, width - margin.right]);
        // .nice();
        svg.attr("class", `components components-${index}`);
        svg.attr("width", width).attr("height", height - 100);

        svg.style("margin", "0");

        let distance_from_top = -15;
        if (title !== "") {
          svg
            .append("text") //
            .attr("x", margin.left / 3 + width / 2)
            .attr("y", 0 + -distance_from_top)
            .attr("text-anchor", "middle")
            .style("font-size", template_overall["title"]["title_font_size"])
            .text(title);
        }
        if (gridlines == "yes") {
          let xGrid;
          let gridlines_obj;
          if (
            this.left_bar_tracks[index]?.trackScale == "logarithmic" &&
            this.left_bar_tracks[index]?.logarithmicCycleScale
          ) {
            let logarithmic_cycle_scale = null;
            if (this.left_bar_tracks[index].logarithmicCycleScale == 2) {
              logarithmic_cycle_scale = 20;
            } else if (this.left_bar_tracks[index].logarithmicCycleScale == 3) {
              logarithmic_cycle_scale = 200;
            } else if (this.left_bar_tracks[index].logarithmicCycleScale == 4) {
              logarithmic_cycle_scale = 2000;
            }
            xGrid = d3
              .scaleLog()
              .domain([0.2, logarithmic_cycle_scale])
              .range([margin.left, width - margin.right]);

            gridlines_obj = d3
              .axisTop(xGrid)
              // .ticks((width - margin.left - margin.right) / 25)
              .ticks(10)
              .tickFormat("")
              .tickSize(-height - 50);
          } else {
            if (
              index == 1 &&
              this.left_bar_tracks[index]?.trackScale !== "linear"
            ) {
              // BY DEFAULT
              xGrid = d3
                .scaleLog()
                .domain([0.2, 2000])
                .range([margin.left, width - margin.right]);

              gridlines_obj = d3
                .axisTop(xGrid)
                // .ticks((width - margin.left - margin.right) / 25)
                .ticks(10)
                .tickFormat("")
                .tickSize(-height - 50);
            } else {
              xGrid = d3
                .scaleLinear()
                .domain([0, 500])
                .range([margin.left, width - margin.right]);

              gridlines_obj = d3
                .axisTop()
                // .ticks((width - margin.left - margin.right) / 25)
                .ticks(8)
                .tickFormat("")
                .tickSize(-height - 50)
                .scale(xGrid);
            }
          }
          svg
            .append("g")
            .attr("class", "grid")
            .call(gridlines_obj)
            // .style("stroke", this.darkDark == true ? gridlines_color : "black")
            .style("stroke", "#000")
            .style("color", "#d5d5d5")
            // .style("stroke-dasharray", "5 5")
            .style("z-index", -1)
            .style("stroke-width", 0.5);
          let yGrid = d3
            .scaleLinear()
            .domain([endDepthPerPage, page_starting_depth])
            .range([height_components, -1]);

          this.setting_interval();
          // setting ticks code starts
          let tickValues = [];
          for (
            let tick = endDepthPerPage;
            tick >= page_starting_depth;
            tick -= (this.interval/5)
          ) {
            let intervalValue = this.interval/5
            tickValues.push(tick + (intervalValue - (endDepthPerPage % intervalValue)));
            
          }
          tickValues.reverse();
          // setting ticks code ends
          let horizontalLines = d3
            .axisLeft()
            .tickFormat("")
            .tickSize((-width + margin.left + margin.right) * 1)
            // .ticks(tickValues.length * 5)
            .tickValues(tickValues)
            .scale(yGrid);

          let tempInterval = this.interval;
          svg
            .append("g")
            .attr("class", "grid horizontal")
            .call(horizontalLines)
            .style("stroke", gridlines_color)
            .style("color", "black")
            .style("z-index", 0)
            .attr("transform", "translate(0, 0 )");

          // Modify the style of every 5th grid line to make it bold
          svg
            .selectAll(".grid.horizontal line")
            .attr("color", function (d, i) {
              return d % tempInterval === 0 ? '#d5d5d5' : '#d5d5d5'; // Make every 5th line bold
            })
            .attr("stroke-width", function (d, i) {
              return d % tempInterval === 0 ? 0.8 : 0.5; // Make every 5th line bold
            });
        }

        let x_functions_for_each_curvename = {};
        for (let k = 0; k < curve_names.length; k++) {
          if (!this.data[curve_names[k]]) {
            this.isRerendering = false;
            continue;
          }
          if (curve_names[k] == "alert") {
          }  else {
            //// code that creates a line for each Curve in order provided and applies
            //// the color in the color array in order provided
            let curveUnit = "";
            if (curve_units[k]) {
              curveUnit = curve_units[k];
            }
            let min = mins[k];
            let max = maxes[k];
            let header_text_line = "";
            if (min != null && max != null) {
              header_text_line =
                parseFloat(min).toFixed(1) +
                " - " +
                curve_names[k] +
                "  " +
                curveUnit +
                " - " +
                parseFloat(max).toFixed(1);
            }
            let curveScale = curveScales[k];
            let min_this = "";
            let max_this = "";
            let scaleType = curveScaleType[k];
            if (
              scaleType == "manual" &&
              (curveScale != null || curveScale != undefined)
            ) {
              if (curveScale[0] != null || curveScale[0] != "") {
                min_this = curveScale[0];
              } else {
                min_this = this.minmax[curve_names[k]].min;
                curveScale[0] = min_this;
              }
              if (curveScale[1] != null || curveScale[1] != "") {
                max_this = curveScale[1];
              } else {
                max_this = this.minmax[curve_names[k]].max;
                curveScale[1] = max_this;
              }
            } else {
              let currentRange = defaultRanges.ranges.find(
                (data) => data.name == curve_names[k]
              );
              if (currentRange) {
                max_this = currentRange.high;
                min_this = currentRange.low;
              } else {
                min_this = d3.min(this.data[curve_names[k]]);
                max_this = d3.max(this.data[curve_names[k]]);
              }
              curveScale[0] = min_this;
              curveScale[1] = max_this;
              let foundObj = this.left_bar_tracks[index]?.subtracks?.find(
                (eachCurve) => eachCurve.sourceMnemonic == curve_names[k]
              );
              if (foundObj) {
                let descrptn = foundObj.pnrgDescription;
                if (
                  descrptn.replace(/\s/g, "").toLowerCase().includes("bitsize")
                ) {
                  curveScale[0] = 0;
                  curveScale[1] = 30;
                } else if (
                  descrptn.replace(/\s/g, "").toLowerCase().includes("gamma")
                ) {
                  curveScale[0] = 0;
                  curveScale[1] = 200;
                } else if (
                  descrptn.replace(/\s/g, "").toLowerCase().includes("caliper")
                ) {
                  curveScale[0] = 0;
                  curveScale[1] = 30;
                } else if (
                  descrptn
                    .replace(/\s/g, "")
                    .toLowerCase()
                    .includes("spontaneous")
                ) {
                  curveScale[0] = -160;
                  curveScale[1] = 40;
                } else if (
                  descrptn
                    .replace(/\s/g, "")
                    .toLowerCase()
                    .includes("resistivity")
                ) {
                  curveScale[0] = 0.2;
                  curveScale[1] = 2000;
                } else if (
                  descrptn.replace(/\s/g, "").toLowerCase().includes("neutron")
                ) {
                  curveScale[0] = 45;
                  curveScale[1] = -15;
                }
                else if (
                  descrptn
                    .replace(/\s/g, "")
                    .toLowerCase()
                    .includes("bulkdensity")
                ) {
                  curveScale[0] = 1.65;
                  curveScale[1] = 2.9;
                } else if (
                  descrptn.replace(/\s/g, "").toLowerCase().includes("sonic")
                ) {
                  curveScale[0] = 2000;
                  curveScale[1] = 40;
                } else if (
                  descrptn
                    .replace(/\s/g, "")
                    .toLowerCase()
                    .includes("mudweight")
                ) {
                  curveScale[0] = 8;
                  curveScale[1] = 18;
                }
                else{
                  curveScale[0] = 0;
                }
                min_this = curveScale[0];
                max_this = curveScale[1];
              }
               for (let key in this.curveProps){
                  let trackIndex = key.split('-')[0]
                  if(trackIndex == index && this.curveProps[key]?.curve?.name == curve_names[k] && this.curveProps[key]?.curve?.scaleType == 'manual'){
                    curveScale[0] = this.curveProps[key]?.curve?.scale[0];
                    curveScale[1] = this.curveProps[key]?.curve?.scale[1];
                    curve_colors[k] = this.curveProps[key]?.curve?.color
                    min_this = curveScale[0];
                    max_this = curveScale[1];
                  }
               }
            }
            let x;
            if (
              this.left_bar_tracks[index].trackScale == "logarithmic" &&
              this.left_bar_tracks[index].logarithmicCycleScale
            ) {
              let logarithmic_cycle_scale = null;
              if (this.left_bar_tracks[index].logarithmicCycleScale == 2) {
                logarithmic_cycle_scale = 20;
                curveScale[0] = 0.2;
                curveScale[1] = 20;
              } else if (
                this.left_bar_tracks[index].logarithmicCycleScale == 3
              ) {
                logarithmic_cycle_scale = 200;
                curveScale[0] = 0.2;
                curveScale[1] = 200;
              } else if (
                this.left_bar_tracks[index].logarithmicCycleScale == 4
              ) {
                logarithmic_cycle_scale = 2000;
                curveScale[0] = 0.2;
                curveScale[1] = 2000;
              }
              x = d3
                .scaleLog()
                .domain([0.2, logarithmic_cycle_scale])
                // .domain([0.1, 1000])
                .range([margin.left, width - margin.right]);
            } else {
              x = d3
                .scaleLinear()
                .domain([min_this, max_this])
                .range([margin.left, width - margin.right]);
            }
            // let x = d3
            //   .scaleLinear()
            //   .domain([min_this, max_this])
            //   .range([margin.left, width - margin.right]);
            // .nice();
            if (scale_linear_log_or_yours == "log") {
              x = d3
                .scaleLog()
                .domain([min_all_curves, max_all_curves])
                // .domain([min_this, max_this])

                .range([margin.left, width - margin.right]);
              // .nice();
            } else if (scale_linear_log_or_yours == "linear") {
            } else if (typeof scale_linear_log_or_yours !== "string") {
              x = scale_linear_log_or_yours["yours"];
            }
            if (k == 0) {
              x_func == x;
            }
            //// This creates an object to hold multiple x axis scale functions
            //// that will be used if 'between' style fill is selected.
            x_functions_for_each_curvename[curve_names[k]] = x;
            //////////////  Header text, two way depending on  =>//////////////

            let recent_point_x = 1;
            let another_line = function (x_arr, y_arr, trackScaleType) {
              return d3
                .line()
                .x(function (d, i) {
                  // if (x_arr[i]) recent_point_x = x_arr[i];
                  if (trackScaleType == "logarithmic") {
                    if (x_arr[i] <= 0) {
                      return x(0.2);
                    }
                  }
                  return x_arr[i] || x_arr[i] == 0 ? x(x_arr[i]) : x(recent_point_x);
                })
                .y(function (d, i) {
                  return y_arr[i] ? y(y_arr[i]) : y(0);
                })(Array(x_arr ? x_arr.length : 0));
            };

            let defs = svg.append("defs");

            let gradient = defs.append("linearGradient").attr("id", "gradient");

            gradient
              .append("stop")
              .attr("offset", "0%")
              .attr("stop-color", "red");
            gradient
              .append("stop")
              .attr("offset", "100%")
              .attr("stop-color", "green");
            svg
              .append("path")
              // .datum(data['DEPTH'])
              .attr("fill", "none")
              .attr("stroke", curve_colors[k])
              .attr("stroke-width", template_curves["stroke_width"][k])
              .attr("class", "area")
              .attr(
                "d",
                another_line(
                  data[curve_names[k]],
                  data["DEPTH"],
                  this.left_bar_tracks[index].trackScale
                )
              );

            // if (index == 0 && curve_names.length - 1 == k) {
            if (curve_names.length - 1 == k) {
              function wrap(text, width) {
                text.each(function (d) {
                  let text = d3.select(this),
                    words = text.text().split(/\s+/).reverse(),
                    word,
                    line = [],
                    lineNumber = 0,
                    lineHeight = 1, // ems
                    x = text.attr("x"),
                    y = text.attr("y"),
                    dy = 0, //parseFloat(text.attr("dy")),
                    tspan = text
                      .text(null)
                      .append("tspan")
                      .attr("class", "comment__style")
                      .attr("x", x)
                      .attr("y", y)
                      .attr("dy", dy + "em");

                  while ((word = words.pop())) {
                    line.push(word);
                    tspan.text(line.join(" "));

                    if (tspan.node().getComputedTextLength() > width) {
                      line.pop();
                      tspan.text(line.join(" "));
                      line = [word];
                      tspan = text
                        .append("tspan")

                        .attr("x", x)
                        .attr("y", y)
                        .attr("dy", ++lineNumber * lineHeight + dy + "em")
                        .attr("class", "comment__style")
                        .text(word);
                    }
                  }
                });
              }
              if (notes && Object.keys(data).includes(`comment-${index}`) && data[`comment-${index}`].length) {
                let depth_data_arr = [];
                let alerts = data[`comment-${index}`].filter((each, currentIndx) => {
                  if (each !== null && each !== '') {
                    depth_data_arr.push(data["DEPTH"][currentIndx]);
                    return each;
                  }
                });
                // console.log('depth_data_arr', depth_data_arr, alerts)
                let y = d3
                  .scaleLinear()
                  .domain([endDepthPerPage, page_starting_depth])
                  .range([height_components, 0]);
                alerts.map((text, alertindx) => {
                  // svg
                  //   .append('rect')
                  //   .attr('x', width/2 - margin.right) // Adjust x-coordinate spacing as needed
                  //   .attr('y', y(depth_data_arr[alertindx]) - 14) // Set the y-coordinate for the background
                  //   .attr('width', width/2 - margin.right) // Adjust the width of the background
                  //   .attr('height', 20) // Adjust the height of the background
                  //   .attr('fill', '#fff')
                  //   .attr('stroke', 'red')
                  //   .attr('rx', 5) // Set horizontal border-radius
                  //   .attr('ry', 5); // Set vertical border-radius
                  svg
                    .append("text")
                    .attr("x", 5) // Adjust x-coordinate spacing as needed
                    .attr("y", y(depth_data_arr[alertindx])) // Set the y-coordinate based on the 'y' array
                    .style("font-size", "0.9rem")
                    .style("font-weight", "500")
                    // .style("background", "red")
                    // .style("color", "white")
                    .style("stroke", "red")
                    .style("fill", "black")
                    .attr("text-anchor", "start")
                    .text(text)
                    .call(wrap, width - 10);
                  // textWarp.call(wrap, width - 15);
                });
              }
            }
          }
        }

        //////////////  Horizontal Lines AKA tops =>//////////////

        try {
          for (let i = 0; i < template_lines.length; i++) {
            let this_line = template_lines[i];
            svg
              .append("line")
              .attr("x1", 0 + margin.left)
              .attr("y1", y(this_line["depth"]))
              .attr("x2", width * 0.75)
              .attr("y2", y(this_line["depth"]))
              .style("stroke-width", this_line["stroke_width"])
              .style("stroke", this_line["color"])
              // .style("stroke", function (d, i) {
              //   if (d[curve_names[k]] < 0) return "white";
              //   if (d[curve_names[k]] < 50) return "red";
              //   if (d[curve_names[k]] < 150) return "green";
              // })
              .style("stroke-dasharray", this_line["stroke-dasharray"])
              .style("stroke-linecap", this_line["stroke_linecap"])
              .style("fill", "none");

            svg
              .append("text")
              .attr("x", width * 0.75)
              .attr("y", y(this_line["depth"]))
              .attr("text-anchor", "start")
              .style("font-size", "12px")
              .text(this_line["label"]);
          }
        } catch (err) {
          console.error(
            "could not do lines for tops in curveBox function, error: ",
            err
          );
        }
        if (this.plots.length - 1 == index) {
          this.isRerendering = false;
        }
      } catch (error) {
        this.isRerendering = false;
        console.error("error in create curve box : ", error);
        return true;
      }
    },
    async print_createLeftBar(div_id, templates, page_starting_depth, endDepthPerPage) {
      try {
        let yAxisHolder = d3
          .select("#" + div_id)
          .append("div");
        yAxisHolder
          .attr("class", "leftbarcontainer")
          .style("display", "inline-block")
          .style("vertical-align", "top")
          .style("margin-right", "0px")
          .style("fill", "black")
          .style("background", "var(--lwdGraphBg)");

        yAxisHolder.style("width", this.print_scale_width + "px");

        let height_components = this.print_track_height;

        this.yLeftScale = d3
          .scaleLinear()
          .domain([endDepthPerPage, page_starting_depth])
          .range([height_components, 0]);
        // .nice();

        let leftbarOuter = yAxisHolder
          .append("div")
          .attr("class", "leftbar-outer")
          .style("width", this.print_scale_width + "px");

        let leftbarInner = leftbarOuter
          .append("div")
          .attr("class", `leftbar-inner-${div_id}`)
          .style("max-height", height_components + "px")
          .style("scrollbar-width", "thin")
          .style("width", this.print_scale_width + "px")
          .style("height", height_components + "px")
          .style("direction", "rtl")
          .style("scrollbar-track", "red");

        let svg = leftbarInner
          .append("svg")
          .style("width", this.print_scale_width + "px")
          .style("border-block", "2px solid #000")
          .style("border-top", "1px solid #000")
          .style("border-right", (this.trackConfig.tracks.length ==1 || (this.trackConfig.tracks.length > 1 && this.trackConfig.tracks[1][0]?.hide)) || this.enable_lithology ? "2px solid #000" : "1px solid #000")
          .attr("height", height_components)
          
        let print_startDepth = parseInt(page_starting_depth);
        let print_endDepth = parseInt(endDepthPerPage);
        
        let yTicksCompare = null;
        // this.setting_interval();
        // setting ticks code starts
        let tickValues = [];
        for (
          let tick = print_startDepth;
          tick <= print_endDepth;
          tick += this.interval
        ) {
          tickValues.push(
            tick + (this.interval - (print_startDepth % this.interval))
          );
        }
        if(tickValues.length < 6){
          tickValues = []
          for (
            let tick = print_endDepth;
            tick >= print_startDepth;
            tick -= (this.interval/5)
          ) {
            let intervalValue = this.interval/5
            tickValues.push(tick + (intervalValue - (print_endDepth % intervalValue)));
          }
          tickValues.reverse();
        }
        // setting ticks code ends
        yTicksCompare = d3
          .axisLeft()
          .scale(this.yLeftScale)
          // .tickValues(depthTicks)
          .tickValues(tickValues)
          .tickSizeInner(-8);

        if (Object.keys(this.data).includes("enggicon")) {
          // Append images on the right side at specific y-values
          let y = d3
            .scaleLinear()
            .domain([endDepthPerPage, print_startDepth])
            .range([height_components, 0]);
          let iconsData = [];
          this.data["enggicon"].map((iconNumber, inx) => {
            if (iconNumber > 0) {
              let obj = this.lithology_icons_list.find(
                (each) =>
                  each.displayIconId == iconNumber && each.type == "icon"
              );
              if (obj) {
                iconsData.push({
                  y: this.data["DEPTH"][inx],
                  icon: "data:image/png;base64," + obj.icon,
                });
              }
            }
          });
          svg
            .selectAll("image")
            .data(iconsData)
            .enter()
            .append("image")
            .attr("xlink:href", (d) => d.icon)
            .attr("x", this.print_scale_width - 20) // Adjust the x-position for the images on the right side
            .attr("y", (d) => y(d.y))
            .attr("width", 20) // Set the image width
            // .attr("height", 20)
        }

        let depthAxis = svg
          .append("g")
          .attr("transform", "translate(-10,0)")
          .call(yTicksCompare);

        // depthAxis.selectAll("line").attr("transform", "translate(10,0)").style("opacity", "0.2").style("stroke", "black");
        depthAxis
          .selectAll("line")
          .attr("transform", "translate(10,-0.5)")
          .style("opacity", "1")
          .style("stroke", "black")
          .style("stroke-width", 0.3);

        depthAxis
          .selectAll("path")
          .style("opacity", "0.2")
          .attr("transform", "translate(10,0)");

        depthAxis
          .selectAll("text")
          .attr("x", "2em")
          .attr("dy", "0.2em")
          .style("font-size", "0.7rem")
          .style("font-weight", "600")
          .style("color", "black");
      } catch (error) {
        console.error("errro in left bar ", error);
      }
    },
    async print_createLithologyBar(div_id, templates, page_starting_depth, endDepthPerPage, endDepth, startDepth) {
      try {
        let yAxisHolder = d3
          .select("#" + div_id )
          .append("div");
        yAxisHolder
          .attr("class", "leftbarcontainer")
          .attr("id", "print_myCanvasContainer" + this.widgetId + page_starting_depth)
          .style("display", "inline-block")
          .style("vertical-align", "top")
          .style("margin-right", "0px")
          .style("border-block", "2px solid #000")
          .style("border-right", "1px solid #000")
          .style("fill", "black")
          .style("background", "var(--lwdGraphBg)");

        yAxisHolder.style("width", this.print_lithology_width + "px");
        
        let height_components = this.print_track_height;

        // Create a canvas element
        let ele = document.getElementById("print_myCanvasElement" + this.widgetId + page_starting_depth);
        if (ele) {
          ele.remove();
        }
        let ImgEle = document.getElementById("print_myImgElement" + this.widgetId + page_starting_depth);
        if (ImgEle) {
          ImgEle.remove();
        }
        const canvas_ele = document.createElement("canvas");
        canvas_ele.setAttribute("id", "print_myCanvasElement" + this.widgetId + page_starting_depth);
        // // Set the canvas dimensions

        // Append the canvas to the DOM
        const container = document.getElementById("print_myCanvasContainer" + this.widgetId + page_starting_depth);

        container.appendChild(canvas_ele);

        // Get a reference to the canvas element
        var canvas = document.getElementById("print_myCanvasElement" + this.widgetId + page_starting_depth);
        canvas.width = this.print_lithology_width - 1;
        canvas.height = height_components - 4;

        // Get the 2D drawing context
        var ctx = canvas.getContext("2d");
        let yScale = d3
          .scaleLinear()
          .domain([endDepthPerPage, page_starting_depth])
          .range([height_components, 0]);
        // Define the coordinates (x1 and x2) where you want to fill the area
        var x1 = 0; // Starting x-coordinate
        var x2 = this.print_lithology_width - 1; // Ending x-coordinate
        
        let icons_data = this.lithology_icons_data;
        for (let icon = 0; icon < icons_data.length; icon++) {
          let eachIcon = icons_data[icon];
          // console.log("Lithology list : ", eachIcon.startDepth , this.scaleMin , eachIcon.endDepth , this.scaleMax )
          if (
            (eachIcon.startDepth >= page_starting_depth ||
              eachIcon.endDepth >= page_starting_depth) &&
            (eachIcon.startDepth <= endDepthPerPage ||
              eachIcon.endDepth <= endDepthPerPage)
          ) {
            let lithoStartDepth = eachIcon.startDepth;
            let lithoEndDepth = eachIcon.endDepth;
            
            // TO SHOW LITHOLOGY WITHIN THE SAVED FILE RANGE CODE STARTS
            // if(this.selectedSavedRun !== null && 
            //   Object.keys(this.selectedSavedRun).length && 
            //   !this.selectedSavedRun?.is_default
            // ){ // IF SAVED FILE IS SELECTED
              let hadStartDepth = Math.min(...this.data['DEPTH']) <= eachIcon.startDepth && Math.max(...this.data['DEPTH']) >= eachIcon.startDepth;
              let hadEndDepth = Math.min(...this.data['DEPTH']) <= eachIcon.endDepth && Math.max(...this.data['DEPTH']) >= eachIcon.endDepth;

              if(!hadStartDepth && !hadEndDepth){ // IF START AND END DEPTHS ARE NOT AVAILABLE THEN NOT SHOWING LITHOLOGY
                continue;
              }
              else{
                if(hadStartDepth && !hadEndDepth){
                  lithoEndDepth = endDepth;
                }
                if(!hadStartDepth && hadEndDepth){
                  lithoStartDepth = startDepth
                }
              }
            // }
            // TO SHOW LITHOLOGY WITHIN THE SAVED FILE RANGE CODE ENDS

             let file = "data:image/png;base64," + eachIcon.icon;
            let image = new Image();
            image.src = file;
            let render_img = await this.loadImage(image);
            let pattern1 = ctx.createPattern(render_img, "repeat");
            let startPoint =
              yScale(lithoStartDepth) > 0 ? yScale(lithoStartDepth) : 0;
            let height = yScale(lithoEndDepth) - startPoint;
            ctx.fillStyle = pattern1;
            ctx.fillRect(x1, startPoint, x2, height);

          }
        }
        const img_ele = document.createElement("img");
        img_ele.setAttribute("id", "print_myImgElement" + this.widgetId + page_starting_depth);
        img_ele.width = canvas.width;
        img_ele.style.height = '100%';
        img_ele.src = canvas.toDataURL('image/png');
        container.appendChild(img_ele);
        canvas.style.display = 'none'
      } catch (error) {
        console.error("errro in left bar ", error);
      }
    },
    async renderprint_graph(page_starting_depth, endDepthPerPage, notes, lithology, endDepth, startDepth, divId){
      try{
        if(page_starting_depth == startDepth){
          // SETTING_DATA_POINTS CODE STARTS
          let startIndex = this.data_points['DEPTH'].findIndex((each)=> each >= startDepth);
          let endIndex = null;
          let points = JSON.parse(JSON.stringify(this.data_points));
          if(points['DEPTH']){
            // let scaleMax = endDepthPerPage; // if needed page wise mnemonics with different ranges
            let scaleMax = endDepth; // for single mnemonic header on 1st page 
            let filteredArray = points['DEPTH'].filter((each)=> each <= scaleMax);
            endIndex = points['DEPTH'].findIndex((each)=> each == Math.max(...filteredArray));
          }
          if(startIndex >= 0 && endIndex !== null && endIndex > 0){
            let slicedData = {};
            for(let key in points){
              slicedData[key] = points[key].slice(startIndex, endIndex+1)
            }
            this.data = slicedData
          }
          else{
            this.data = this.data_points;
          }
          // SETTING_DATA_POINTS CODE ENDS
          await this.print_configure();
        }
        await this.print_verticalplots(page_starting_depth, endDepthPerPage, notes, lithology, endDepth, startDepth, divId);


      }
      catch(err){
        console.error(err)
      }
    },
    download_print() {
      const a = document.getElementById("download_print" + this.widgetId);
      a.setAttribute("download", "LWD_Trend.pdf");
      a.setAttribute("href", this.pdfDataUrl);
      a.click();
    },
    async set_print_track_width_height(printPageWidth, printPageHeight, tracksLength) {
      this.total_print_page_width = printPageWidth;
      this.total_print_page_height = printPageHeight;
      this.print_track_width = (this.total_print_page_width / tracksLength) - 
                              (this.print_scale_width / tracksLength) - 
                              (this.print_lithology_width / tracksLength); // this.print_lithology_width/5 = lithology, this.print_scale_width/5 = scale
      this.print_track_height = this.total_print_page_height - this.print_bheight;
    },
    open_print_options_popup(){
      this.open_printing_options_page = true;
    },
    close_print_options_popup(){
      if(!this.printing_page){
        this.print_start_depth = null;
        this.print_end_depth = null;
        this.depth_to_print_per_page = null;
        this.enable_comments = false;
        this.enable_lithology = false;
      }
      this.open_printing_options_page = false; 
    },
    async print_page(){
      this.printing_page = true;
      await this.$store.dispatch("data/startLoading");
      setTimeout(async ()=>{
        let startDepth = this.print_start_depth;
        let endDepth = this.print_end_depth;
        let perPage = this.depth_to_print_per_page;
        let notes = this.enable_comments;
        let lithology = this.enable_lithology;
        // const pdfDoc = await PDFDocument.create();

        //  FOR WELL DETAILS STARTS
        // let wellDetails_html = document.getElementById('wellDetails'+ this.widgetId);
        // let page = pdfDoc.addPage();
        // let { width, height } = page.getSize();
        // await this.set_print_track_width_height(width, height, this.trackConfig.tracks.length);
        // // PRINTING CODE STARTS
        // let canvas = await html2canvas(wellDetails_html, {scale: 2});
        // let imgData = canvas.toDataURL('image/png');
        // let img = await pdfDoc.embedPng(imgData)
        // let img_width = img.width / 2;
        // let img_height = img.height / 2;
        // page.drawImage(img,{
        //     x: 20,
        //     y: 20,
        //     width: img_width - 40,
        //     height: img_height - 40,
        //   }
        // );
          // PRINTING CODE ENDS
        //  FOR WELL DETAILS ENDS
        d3
        .select("#print_div_id"+ this.widgetId)
        .selectAll("*")
        .remove();
        this.NumberOfPrintDivs = [];
        for(let page_starting_depth = startDepth; page_starting_depth < endDepth; page_starting_depth += perPage){
          this.NumberOfPrintDivs.push(page_starting_depth)
        }
        for(let page_starting_depth = startDepth; page_starting_depth < endDepth; page_starting_depth += perPage){
          // this.NumberOfPrintDivs.push(page_starting_depth)
          let endDepthPerPage = page_starting_depth + perPage;
          let htmlContent = null;
          // const page = pdfDoc.addPage();
          if(page_starting_depth == startDepth){
            // const { width, height } = page.getSize();
            if(lithology){
              this.print_lithology_width = 90;
            }
            else{
              this.print_lithology_width = 0;
            }
            // console.log(width + 120, height + 285)
            // width-715.28 height-1126.89
            // await this.set_print_track_width_height(width + 120, height + 285, this.trackConfig.tracks.length);
            let width = 715.28;
            let height = 1126.89;
            await this.set_print_track_width_height(width, height, this.trackConfig.tracks.length);
            htmlContent = document.getElementById('forPrint' + this.widgetId);
          }
          else{
            this.print_track_height = this.total_print_page_height;
            htmlContent = document.getElementById('print_div_id' + this.widgetId);
          }
          
          

          // TO RENDER GRAPH FUNCITON STARTS
          await this.renderprint_graph(page_starting_depth, endDepthPerPage, notes, lithology, endDepth, startDepth, 'print_div_id' + this.widgetId + page_starting_depth);
          // TO RENDER GRAPH FUNCITON ENDS

          // PRINTING CODE STARTS
          // let canvas = await html2canvas(htmlContent, {scale: 2});
          // const imgData = canvas.toDataURL('image/png');
          // let img = await pdfDoc.embedPng(imgData)
          // let img_width = img.width / 2;
          // let img_height = img.height / 2;
          // page.drawImage(img,{
          //     x: 10,
          //     y: 0,
          //     width: img_width - 20,
          //     height: img_height,
          //   }
          // );
          // PRINTING CODE ENDS
        }
        // const pdfBytes = await pdfDoc.save();
        // this.pdfDataUrl = URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }));

        
        // await this.close_print_options_popup();
        // this.printing_page = false;
        // this.NumberOfPrintDivs = [];
        // this.open_print_preview_popup = true;
        this.open_printing_options_page = false;
        setTimeout(async ()=>{
          try{
            let wdWdth = window.innerWidth;
            let applyWidth = (window.innerWidth * 3) / 4;
            let remainingWidth = wdWdth - applyWidth;

            let wdht = window.innerHeight;
            let applyHeight = (window.innerWidth * 3) / 4;
            let remainingHeight = wdht - applyHeight;

            let leftPos = remainingWidth / 2;
            let topPos = remainingHeight / 2;
            let printWindow = window.open(
              "",
              "",
              `height=${applyHeight}, width=${applyWidth}, left=${leftPos},top=${topPos}, title=""`
            );
            let styles = `
            @import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400&display=swap");
            body{
              font-family: "Work Sans", sans-serif;
              margin-top: 0px;
              margin-bottom: 0px;
            }
            .flex{
              display: flex;
            }
            .__label_boxes{
              margin-top: auto;
            }
            .h-full {
                height: 100%;
            }
            .outer_mnemonics_container:first-child .mnemonics_container,
            .outer_mnemonics_container:last-child .mnemonics_container {
              border-width: 2px;
            }
            .mnemonics_container{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: end;
                border: solid #000;
                border-width: 2px 0px 2px 2px;
                border-bottom: 0px;
            }
            .track_config_border{
                border-bottom: 2px solid #000;
            }
            .relative {
                position: relative;
            }
            .name_numaric_con{
                width: 100%;
                background: #fff;
            }
            .tooltip__tip {
                position: relative;
                text-align: center;
            }
            .truncate {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .items-center {
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }
            .px-2 {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
            .colorBox_text_gray {
                color: #000;
                font-size: 0.55rem;
                margin-top: 0.07rem;
            }
            .text_dark {
                font-weight: 600;
            }
            .td_trnk {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .mnemonic_borderLine{
                border-bottom: 2px solid #00d100;
                width: 90%;
                margin: auto;
            }
            .justify-around {
                -ms-flex-pack: distribute;
                justify-content: space-around;
            }
            .mnemonic_min_max{
                color: #00d100;
            }
            .track_config_border:last-child {
                // border-bottom: 0px;
            }
            :root{
              --lwdGraphBg: #fff;
            }
            .justify-center {
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
            }
            .items-end {
                -webkit-box-align: end;
                -ms-flex-align: end;
                align-items: flex-end;
            }
            h1, p {
                margin: 0;
            }
            .pb-3 {
                padding-bottom: 0.75rem;
            }
            @media print {
              @page {
                margin-top: 0;
                margin-bottom: 0;
                size: A4;
              }
              .LwdPrintScrollDiv{
                page-break-after: always;
              }
              .mnemonicsContainer{
                page-break-after: avoid;
              }
            }
            `
            printWindow.document.write("<html>");
            printWindow.document.write(
              `<head><style>
                ${styles}
                </style></head><body >`
            );
            printWindow.document.write(document.querySelector('.LWD_forPrint').innerHTML)
            printWindow.document.write(
              "</body></html>"
            );
            printWindow.addEventListener("afterprint", async (event) => {
              printWindow.close();
            });
            printWindow.document.close();
            await printWindow.print()
            // await window.print();
            this.printing_page = false;
            this.NumberOfPrintDivs = [];
            await this.close_print_options_popup();
          }
          catch(err){
            console.error(err)
          }
        },500)
        setTimeout(async () => {
          await this.$store.dispatch("data/stopLoading");
        }, 700);
      },0)
    },
    close_print_preview_popup(){
      this.printing_page = false;
      this.open_print_preview_popup = false;
      this.print_start_depth = null;
      this.print_end_depth = null;
      this.depth_to_print_per_page = null;
      this.enable_comments = false;
      this.enable_lithology = false;
      this.print_trackConfig = {};
      this.print_plots = [];
    },
    async setting_ranges_according_to_saved_file(val){
      if(val !== null && Object.keys(val).length && !val?.is_default){
        this.setting_interval();
        let tickValues = [];
        for (
          let tick = val.start_depth;
          tick <= val.end_depth;
          tick += this.interval
        ) {
          tickValues.push(
            tick + (this.interval - (val.start_depth % this.interval))
          );
        }
        if(tickValues.length < 6){ // FOR SAVED FILE TO SHOW RANGES BETWEEN FILE START AND END DEPTHS
          let eachLine = this.interval/5;
          let start = val.start_depth;
          let end = val.end_depth + eachLine;
          this.scaleMin = start;
          this.scaleMax = end;
          this.rangeMinValue = 0;
          this.rangeMaxValue = 0;
        }
        else{ // FOR SAVED FILE, IF TICKS ARE MORE THAN 5 THEN START RANGE SHOULD BE FILE START DEPTH, AND END RANGE NOT DEPENDS ON THE FILE END DEPTH 
          let eachLine = this.interval/5;
          this.scaleMin = val.start_depth - eachLine;
          if (this.total_leftbar_data?.selected_unit == 'IMPERIAL') {
            if (this.scaleMax_Prop == 1200) {
              this.scaleMax = this.scaleMin + 500; // 5 Boxes * 100 each box = 500
            }
            else if (this.scaleMax_Prop == 600) {
              this.scaleMax = this.scaleMin + 250; // 5 Boxes * 50 each box = 250
            }
            else if (this.scaleMax_Prop == 240) {
              this.scaleMax = this.scaleMin + 100; // 5 Boxes * 20 each box = 100
            }
          }
          else if (this.total_leftbar_data?.selected_unit == 'METRIC') {
            if (this.scaleMax_Prop == 200) {
              this.scaleMax = this.scaleMin + 50; // 5 Boxes * 10 each box = 50
            }
            else if (this.scaleMax_Prop == 500) {
              this.scaleMax = this.scaleMin + 125; // 5 Boxes * 25 each box = 125
            }
          }
          this.rangeMinValue = this.scaleMin
          // this.rangeMaxValue = this.scaleMax
          this.rangeMaxValue = val.end_depth
        }
        this.initially_assinging_range = true;
        this.rangeValue = this.scaleMin
        setTimeout(()=>{
          this.initially_assinging_range = false;
        },0)
        try{
          let startIndex = this.data_points['DEPTH'].findIndex((each)=> each >= this.selectedSavedRun?.start_depth);
          let endIndex = null;
          let points = JSON.parse(JSON.stringify(this.data_points));
          if(points['DEPTH']){
            let scaleMax = this.selectedSavedRun?.end_depth;
            let filteredArray = points['DEPTH'].filter((each)=> each <= scaleMax);
            endIndex = points['DEPTH'].findIndex((each)=> each == Math.max(...filteredArray));
          }
          if(startIndex >= 0 && endIndex !== null && endIndex > 0){
            let slicedData = {};
            for(let key in points){
              slicedData[key] = points[key].slice(startIndex, endIndex+1)
            }
            this.data = slicedData

          }
          else{
            this.data = this.data_points;
          }
        }
        catch(err){
          console.error(err)
        }
      }
    },
    async delete_lithology(){
      try{
        let payload = {
            "lithologyRangeId": this.clickedLithologyObj.lithologyRangeId,
            "log": "individual",
            "startDepth":this.clickedLithologyObj.startDepth
        }
        let response = await apiService.lwdServices.delete_lithology(payload);
        if(response.status == 200){
          if (this.selected_files.length == 1) {
            this.$emit(
              "lithology_data",
              this.payload_to_get_file_litho_ranges, 'enable_render'
            );
          }
          else{
            this.$emit("getConsolidateLithologyIcons", this.payload_to_get_file_litho_ranges, 'enable_render')
          }
          this.$toast.clear();
          this.$toast.success("Lithology deleted successfully", {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        }
        await this.close_lithology_add_icon_popup();
      }
      catch(err){
        console.error(err)
      }
    },
    async delete_icon(){
      this.section_type_to_show = 'icon'
      await this.add_ranges_icon('deleteIcon')
      await this.close_delete_icon_popup();
    },
    async close_delete_icon_popup(){
      this.show_delete_icon_popup = false;
      this.newStartRange = null;
    },
    symbolEndRangeChanged(){
      if(this.newStartRange && this.newEndRange && this.newStartRange >= this.newEndRange){
        this.newEndRange = null;
        this.$toast.clear();
        this.$toast.error("End range should be greater than start range", {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
      else if(this.newStartRange && this.newEndRange && this.newStartRange < this.newEndRange && this.newEndRange-this.newStartRange < 1){
        this.newEndRange = null;
        this.$toast.clear();
        this.$toast.error(`End range should be equal or more than ${this.newStartRange + 1}`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    async add_or_update_message() {
      // MESSAGE
      let recordFound = null;
      if (this.selected_files.length == 1) { // FOR SINGLE FILE
        recordFound = this.files_data.find((each) => {
          return (
            each.lwd_data_id == this.selected_files[0].id &&
            each.end_depth == this.selected_files[0].end_depth &&
            each.start_depth == this.selected_files[0].start_depth &&
            each.alias_name == this.selected_files[0].name
          );
        });
      }
      else{ // CONSOLIDATED
        let matched_files = [];
        matched_files = this.consolidatedListData.filter((eachFile)=>{
          if(eachFile.start_depth <= this.messageDepth && this.messageDepth <= eachFile.end_depth){
            return eachFile;
          }
        })
        if(matched_files.length){
          recordFound = this.files_data.find((each) => {
            return (
              each.lwd_data_id == matched_files[0].id
            );
          });
        }
        else{
          this.$toast.clear();
          this.$toast.error("File not found for the entered range", {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
          return;
        }
      }

      if (recordFound) {
        let payload_to_update = [
          {
            entityId: recordFound.entity_id,
            wellId: recordFound.well_id,
            wellBoreName: recordFound.well_borename,
            section: recordFound.section,
            aliasName: recordFound.alias_name,
            feedType: recordFound.feed_type,
            solutionType: recordFound.solution_type,
            startDepth: recordFound.start_depth,
            logId: recordFound.log_id,
            endDepth: recordFound.end_depth,
            lwdDepthData: [
              {
                sourceData: {},
                depth: this.messageDepth,
              },
            ],
          },
        ];
        payload_to_update[0].lwdDepthData[0].sourceData[`comment-${this.clickedGraphIndex}`] = this.messageText
        let response = await apiService.lwdServices.add_icon_to_file({
          list: payload_to_update,
        });
        if (response.status == 200) {
          this.$toast.clear();
          this.$toast.success("Message added successfully", {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        }
        this.close_message_adding_popup();
        let payload = JSON.parse(JSON.stringify(this.selected_files));
        // this.$emit("selected_runs", payload, this.scaleMax, 'updating');
        this.$emit("selected_runs", payload, this.scaleMin, 'updating');
      }
    },
    close_message_adding_popup() {
      this.show_message_adding_popup = false;
      this.messageDepth = null;
      this.messageText = null;
    },
    handleRangeChange(event) {
      this.rangeValue = event.target.value;
    },
    changeSection(type) {
      this.newSelectedIcon = "";
      this.newStartRange = null;
      this.newEndRange = null;
      this.section_type_to_show = type;
    },
    async resolveConflict() {
      let newly_entered_obj = null;
      if (this.selected_files.length == 1) { // FOR SINGLE FILE
        newly_entered_obj = this.copy_of_lithology_icons_data.findIndex(
          (each) => {
            return (
              each.lithologyRangeId == this.selected_files[0]?.id &&
              each.startDepth == this.newStartRange &&
              each.endDepth == this.newEndRange &&
              each.lithologyData == this.newSelectedIcon?.displayIconId &&
              each.icon == this.newSelectedIcon?.icon
            );
          }
        );
      }
      else{ // FOR CONSOLIDATED
        let matched_files = [];
        matched_files = this.consolidatedListData.filter((eachFile)=>{
          // if(eachFile.start_depth <= this.newStartRange && eachFile.end_depth >= this.newEndRange){
          if(eachFile.start_depth <= this.newStartRange && this.newStartRange <= eachFile.end_depth){
            return eachFile;
          }
        })
        if(matched_files.length){
          newly_entered_obj = this.copy_of_lithology_icons_data.findIndex(
            (each) => {
              return (
                each.lithologyRangeId == matched_files[0]?.id &&
                each.startDepth == this.newStartRange &&
                each.endDepth == this.newEndRange &&
                each.lithologyData == this.newSelectedIcon?.displayIconId &&
                each.icon == this.newSelectedIcon?.icon
              );
            }
          );
        }
        else{
          this.$toast.clear();
          this.$toast.error("File not found for the entered range", {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
          return;
        }
      }
      if (newly_entered_obj >= 0) {
        this.choosedFileIndex = newly_entered_obj;
      } else {
        this.choosedFileIndex = null;
      }

      if (this.conflict_range["actualObject"].index == this.choosedFileIndex) {
        if (
          this.conflict_range["actualObject"].obj.endDepth <
          this.conflict_range["conflictObject"].obj.endDepth
        ) {
          this.copy_of_lithology_icons_data[
            this.conflict_range["conflictObject"].index
          ].startDepth = this.conflict_range["actualObject"].obj.endDepth;
        } else if (
          this.conflict_range["actualObject"].obj.endDepth >
          this.conflict_range["conflictObject"].obj.endDepth
        ) {
          this.copy_of_lithology_icons_data[
            this.conflict_range["conflictObject"].index
          ].startDepth = 0;
          this.copy_of_lithology_icons_data[
            this.conflict_range["conflictObject"].index
          ].endDepth = 0;
        } else if (
          this.conflict_range["actualObject"].obj.startDepth !=
            this.conflict_range["conflictObject"].obj.startDepth &&
          this.conflict_range["actualObject"].obj.endDepth ==
            this.conflict_range["conflictObject"].obj.endDepth
        ) {
          this.copy_of_lithology_icons_data[
            this.conflict_range["conflictObject"].index
          ].startDepth = 0;
          this.copy_of_lithology_icons_data[
            this.conflict_range["conflictObject"].index
          ].endDepth = 0;
        } else {
          this.copy_of_lithology_icons_data[
            this.conflict_range["conflictObject"].index
          ].startDepth = 0;
          this.copy_of_lithology_icons_data[
            this.conflict_range["conflictObject"].index
          ].endDepth = 0;
        }
      } else if (
        this.conflict_range["conflictObject"].index == this.choosedFileIndex
      ) {
        if (
          this.conflict_range["actualObject"].obj.endDepth >
            this.conflict_range["conflictObject"].obj.endDepth &&
          this.conflict_range["actualObject"].obj.startDepth ==
            this.conflict_range["conflictObject"].obj.startDepth
        ) {
          this.copy_of_lithology_icons_data[
            this.conflict_range["actualObject"].index
          ].startDepth = this.conflict_range["conflictObject"].obj.endDepth;
        } else if (
          this.conflict_range["actualObject"].obj.endDepth <
            this.conflict_range["conflictObject"].obj.endDepth &&
          this.conflict_range["actualObject"].obj.startDepth ==
            this.conflict_range["conflictObject"].obj.startDepth
        ) {
          this.copy_of_lithology_icons_data[
            this.conflict_range["actualObject"].index
          ].startDepth = 0;
          this.copy_of_lithology_icons_data[
            this.conflict_range["actualObject"].index
          ].endDepth = 0;
        } else if (
          this.conflict_range["actualObject"].obj.endDepth >
          this.conflict_range["conflictObject"].obj.endDepth
        ) {
          this.copy_of_lithology_icons_data.push({
            lithologyRangeId: this.conflict_range["conflictObject"].obj.lithologyRangeId,
            log: "individual",
            startDepth: this.conflict_range["conflictObject"].obj.endDepth,
            endDepth: this.conflict_range["actualObject"].obj.endDepth,
            sourceType: "file",
            lithologyData:
              this.conflict_range["actualObject"].obj?.lithologyData,
            icon: this.conflict_range["actualObject"].obj?.icon,
          });
          this.copy_of_lithology_icons_data[
            this.conflict_range["actualObject"].index
          ].endDepth = this.conflict_range["conflictObject"].obj.startDepth;
        } else if (
          this.conflict_range["actualObject"].obj.endDepth <
          this.conflict_range["conflictObject"].obj.endDepth
        ) {
          this.copy_of_lithology_icons_data[
            this.conflict_range["actualObject"].index
          ].endDepth = this.conflict_range["conflictObject"].obj.startDepth;
        } else if (
          this.conflict_range["actualObject"].obj.startDepth !=
            this.conflict_range["conflictObject"].obj.startDepth &&
          this.conflict_range["actualObject"].obj.endDepth ==
            this.conflict_range["conflictObject"].obj.endDepth
        ) {
          this.copy_of_lithology_icons_data[
            this.conflict_range["actualObject"].index
          ].endDepth = this.conflict_range["conflictObject"].obj.startDepth;
        } else {
          this.copy_of_lithology_icons_data[
            this.conflict_range["actualObject"].index
          ].startDepth = 0;
          this.copy_of_lithology_icons_data[
            this.conflict_range["actualObject"].index
          ].endDepth = 0;
        }
      }
      // this.choosedFileIndex = null;
      await this.conflict_check();
    },
    async add_ranges_icon(from) {
        if (this.section_type_to_show == "symbol") {
          // SYMBOL
          let newly_entered_obj = null;
          if (this.selected_files.length == 1) { // FOR SINGLE FILE
            this.copy_of_lithology_icons_data.push({
              lithologyRangeId: this.selected_files[0]?.id,
              log: "individual",
              startDepth: this.newStartRange,
              endDepth: this.newEndRange,
              sourceType: "file",
              lithologyData: this.newSelectedIcon?.displayIconId,
              icon: this.newSelectedIcon?.icon,
            });
            this.copy_of_lithology_icons_data.sort(
              (a, b) => a.startDepth - b.startDepth
            );
            newly_entered_obj = this.copy_of_lithology_icons_data.findIndex(
              (each) => {
                return (
                  each.lithologyRangeId == this.selected_files[0]?.id &&
                  each.startDepth == this.newStartRange &&
                  each.endDepth == this.newEndRange &&
                  each.lithologyData == this.newSelectedIcon?.displayIconId &&
                  each.icon == this.newSelectedIcon?.icon
                );
              }
            );
          }
          else{ // FOR CONSOLIDATED
            let matched_files = [];
            matched_files = this.consolidatedListData.filter((eachFile)=>{
              // if(eachFile.start_depth <= this.newStartRange && eachFile.end_depth >= this.newEndRange){
              if(eachFile.start_depth <= this.newStartRange && this.newStartRange <= eachFile.end_depth){
                return eachFile;
              }
            })
            if(matched_files.length){
              // MULTIPLE FILES SELECTED
              this.copy_of_lithology_icons_data.push({
                lithologyRangeId: matched_files[0]?.id,
                startDepth: this.newStartRange,
                endDepth: this.newEndRange,
                sourceType: "file",
                lithologyData: this.newSelectedIcon?.displayIconId,
                icon: this.newSelectedIcon?.icon,
              });
              this.copy_of_lithology_icons_data.forEach((everyObj)=>{
                everyObj.log = 'individual'
              })
              this.copy_of_lithology_icons_data.sort(
                (a, b) => a.startDepth - b.startDepth
              );
              newly_entered_obj = this.copy_of_lithology_icons_data.findIndex(
                (each) => {
                  return (
                    each.lithologyRangeId == matched_files[0]?.id &&
                    each.startDepth == this.newStartRange &&
                    each.endDepth == this.newEndRange &&
                    each.lithologyData == this.newSelectedIcon?.displayIconId &&
                    each.icon == this.newSelectedIcon?.icon
                  );
                }
              );
            }
            else{
              this.$toast.clear();
              this.$toast.error("File not found for the entered range", {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
              return;
            }
          }
          if (newly_entered_obj >= 0) {
            this.choosedFileIndex = newly_entered_obj;
          } else {
            this.choosedFileIndex = null;
          }
          // this.close_lithology_add_icon_popup();
          await this.conflict_check();
        } else if (this.section_type_to_show == "icon") {
          // ICON
          let recordFound = null;
          if (this.selected_files.length == 1) { // FOR SINGLE FILE
            recordFound = this.files_data.find((each) => {
              return (
                each.lwd_data_id == this.selected_files[0].id &&
                each.end_depth == this.selected_files[0].end_depth &&
                each.start_depth == this.selected_files[0].start_depth &&
                each.alias_name == this.selected_files[0].name
              );
            });
          }
          else{ // FOR CONSOLIDATED
            let matched_files = [];
            matched_files = this.consolidatedListData.filter((eachFile)=>{
              if(eachFile.start_depth <= this.newStartRange && this.newStartRange <= eachFile.end_depth){
                return eachFile;
              }
            })
            if(matched_files.length){
              recordFound = this.files_data.find((each) => {
                return (
                  each.lwd_data_id == matched_files[0].id
                  // each.end_depth == matched_files[0].end_depth &&
                  // each.start_depth == matched_files[0].start_depth &&
                  // each.alias_name == matched_files[0].name
                );
              });
            }
            else{
              this.$toast.clear();
              this.$toast.error("File not found for the entered range", {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
              return;
            }
          }

           // COMMON FOR SAVE
          if (recordFound) {
            let payload_to_update = [
              {
                entityId: recordFound.entity_id,
                wellId: recordFound.well_id,
                wellBoreName: recordFound.well_borename,
                section: recordFound.section,
                aliasName: recordFound.alias_name,
                feedType: recordFound.feed_type,
                solutionType: recordFound.solution_type,
                startDepth: recordFound.start_depth,
                logId: recordFound.log_id,
                endDepth: recordFound.end_depth,
                lwdDepthData: [
                  {
                    sourceData: {
                      enggicon: from && from == 'deleteIcon' ? '' : this.newSelectedIcon?.displayIconId,
                    },
                    depth: this.newStartRange,
                  },
                ],
              },
            ];
            let response = await apiService.lwdServices.add_icon_to_file({
              list: payload_to_update,
            });
            if (response.status == 200) {
              this.$toast.clear();
              if(from && from == 'deleteIcon'){
                this.$toast.success("Icon deleted successfully", {
                  duration: "2000",
                  position: "top",
                  pauseOnHover: true,
                });
              }
              else{
                this.$toast.success("Icon added successfully", {
                  duration: "2000",
                  position: "top",
                  pauseOnHover: true,
                });
              }
            }
            this.close_lithology_add_icon_popup();
            let payload = JSON.parse(JSON.stringify(this.selected_files));
            // this.$emit("selected_runs", payload, this.scaleMax, 'updating');
            this.$emit("selected_runs", payload, this.scaleMin, 'updating');
          }
        }
    },
    async conflict_check() {
      if (this.lithology_icons_data != "") {
          this.conflict_range = {};
          this.show_conflict_popup = false;
          this.copy_of_lithology_icons_data.sort(
            (a, b) => a.startDepth - b.startDepth
          );
          let stop_looping = false;
          for (let i = 0; i < this.copy_of_lithology_icons_data.length; i++) {
            let lwd_id = this.copy_of_lithology_icons_data[i];
            for (
              let subIndex = 0;
              subIndex < this.copy_of_lithology_icons_data.length;
              subIndex++
            ) {
              let each = this.copy_of_lithology_icons_data[subIndex];
              if (i != subIndex) {
                if (
                  lwd_id.startDepth <= each.startDepth &&
                  lwd_id.endDepth > each.startDepth &&
                  lwd_id.endDepth <= each.endDepth
                ) {
                  this.conflict_range["start"] = each.startDepth;
                  this.conflict_range["end"] = lwd_id.endDepth;
                  this.conflict_range["actualObject"] = {
                    index: i,
                    obj: lwd_id,
                  };
                  this.conflict_range["conflictObject"] = {
                    index: subIndex,
                    obj: each,
                  };
                  this.conflict_range["sameValues"] = false;
                  // this.show_conflict_popup = true;
                  stop_looping = true;
                  setTimeout(async ()=>{
                    await this.resolveConflict();
                  },0)
                  break;
                } else if (
                  lwd_id.startDepth <= each.startDepth &&
                  lwd_id.endDepth > each.startDepth &&
                  lwd_id.endDepth > each.endDepth
                ) {
                  this.conflict_range["start"] = each.startDepth;
                  this.conflict_range["end"] = each.endDepth;
                  this.conflict_range["actualObject"] = {
                    index: i,
                    obj: lwd_id,
                  };
                  this.conflict_range["conflictObject"] = {
                    index: subIndex,
                    obj: each,
                  };
                  this.conflict_range["sameValues"] = false;
                  // this.show_conflict_popup = true;
                  stop_looping = true;
                  setTimeout(async ()=>{
                    await this.resolveConflict();
                  },0)
                  break;
                } else if (
                  lwd_id.startDepth == each.startDepth &&
                  lwd_id.endDepth == each.endDepth &&
                  lwd_id.startDepth != 0 &&
                  lwd_id.endDepth != 0
                ) {
                  this.conflict_range["start"] = each.startDepth;
                  this.conflict_range["end"] = lwd_id.endDepth;
                  this.conflict_range["actualObject"] = {
                    index: i,
                    obj: lwd_id,
                  };
                  this.conflict_range["conflictObject"] = {
                    index: subIndex,
                    obj: each,
                  };
                  this.conflict_range["sameValues"] = true;
                  // this.show_conflict_popup = true;
                  stop_looping = true;
                  setTimeout(async ()=>{
                    await this.resolveConflict();
                  },0)
                  break;
                }
              }
            }
            if (stop_looping) {
              break;
            }
          }

          console.log("had lithology data");

          if (!Object.keys(this.conflict_range).length) {
            this.show_conflict_popup = false;
            this.close_lithology_add_icon_popup();
            let payload_to_save_lithology = null;
            payload_to_save_lithology =
              this.copy_of_lithology_icons_data.filter((each) => {
                delete each?.icon;
                if (each.startDepth != 0 && each.endDepth != 0) {
                  return each;
                }
              });
            try {
              // COMMON FOR SAVE
              let response =
                await apiService.lwdServices.lithology_well_save_update(
                  payload_to_save_lithology
                );
              if (response.status == 200 && response.data != "") {
                if (this.selected_files.length == 1) {
                  this.$emit(
                    "lithology_data",
                    this.payload_to_get_file_litho_ranges, 'enable_render'
                  );
                }
                else{
                  this.$emit("getConsolidateLithologyIcons", this.payload_to_get_file_litho_ranges, 'enable_render')
                }
                this.$toast.clear();
                this.$toast.success("Lithology ranges updated successfully", {
                  duration: "2000",
                  position: "top",
                  pauseOnHover: true,
                });
              }
            } catch (err) {
              console.error(err);
            }
          }
      } else {
        console.log(
          "dont had lithology data",
          this.newStartRange,
          this.newEndRange
        );

        this.show_conflict_popup = false;
        this.close_lithology_add_icon_popup();
        let payload_to_save_lithology = null;
        payload_to_save_lithology = this.copy_of_lithology_icons_data.filter(
          (each) => {
            delete each?.icon;
            if (each.startDepth != 0 && each.endDepth != 0) {
              return each;
            }
          }
        );
        try {
          // COMMON FOR SAVE
          let response =
            await apiService.lwdServices.lithology_well_save_update(
              payload_to_save_lithology
            );
          if (response.status == 200 && response.data != "") {
            if (this.selected_files.length == 1) {
              this.$emit("lithology_data", this.payload_to_get_file_litho_ranges, 'enable_render');
            }
            else{
              this.$emit("getConsolidateLithologyIcons", this.payload_to_get_file_litho_ranges, 'enable_render')
            }
            this.$toast.clear();
            this.$toast.success("Lithology ranges updated successfully", {
              duration: "2000",
              position: "top",
              pauseOnHover: true,
            });
          }
        } catch (err) {
          console.error(err);
        }
      }
    },
    close_lithology_add_icon_popup() {
      this.show_add_ranges_icon_popup = false;
      this.newSelectedIcon = "";
      this.newStartRange = null;
      this.newEndRange = null;
    },
    async add_new_filerange() {
      // let payload = JSON.parse(JSON.stringify(this.selected_files))
      let checkConsolidatedList = await apiService.lwdServices.consolidatedList(
        { wellId: this.wellId, wellBoreName: this.wellboreId }
      );
      let data = [];
      if (checkConsolidatedList.status == 200) {
        checkConsolidatedList.data.map((obj) => {
          data.push({
            id: obj.lwdConsolidatedRangeId,
            start_depth: obj.startDepth,
            end_depth: obj.endDepth,
            name: obj.name,
          });
        });
      }
      data.push({
        id: this.selectedFile.lwd_data_id,
        start_depth: this.fileStartRange,
        end_depth: this.fileEndRange,
        name: this.selectedFile.alias_name,
      });

      // BECAUSE OF ABOVE PUSH SAME ID IS APPENDING AND BECAUSE OF THAT IN CONFLICT CHECK SHOWING SAME FILE NAMES
      // IN API REPEATED DATA WILL SAVE

      this.close_consolidated_range_popup();
      this.$emit("selected_runs", data, "trend");
    },
    close_consolidated_range_popup() {
      this.show_consolidated_ranges_popup = false;
      this.fileStartRange = null;
      this.fileEndRange = null;
      this.selectedFile = "";
    },
    // Function to load an image and return a promise
    loadImage(image) {
      return new Promise(function (resolve, reject) {
        image.onload = function () {
          resolve(image);
        };
        image.onerror = function () {
          reject(new Error("Failed to load image: " + image.src));
        };
      });
    },
    mnemonicMinRange(curve, trackIndex) {
      if (
        this.left_bar_tracks[trackIndex]?.trackScale == "logarithmic" &&
        this.left_bar_tracks[trackIndex]?.logarithmicCycleScale
      ) {
        return 0.2;
      } else {
        return Number(curve.scale[0]) ? Number(curve.scale[0]).toFixed(2) : "0.00";
      }
    },
    mnemonicMaxRange(curve, trackIndex) {
      if (
        this.left_bar_tracks[trackIndex]?.trackScale == "logarithmic" &&
        this.left_bar_tracks[trackIndex]?.logarithmicCycleScale
      ) {
        if (this.left_bar_tracks[trackIndex].logarithmicCycleScale == 2) {
          return 20;
        } else if (
          this.left_bar_tracks[trackIndex].logarithmicCycleScale == 3
        ) {
          return 200;
        } else if (
          this.left_bar_tracks[trackIndex].logarithmicCycleScale == 4
        ) {
          return 2000;
        }
      } else {
        return Number(curve.scale[1]) ? Number(curve.scale[1]).toFixed(2) : 0.0;
      }
    },
    async setting_max_scale(val, existScaleMax) {
      if(this.selectedSavedRun !== null && Object.keys(this.selectedSavedRun).length && !this.selectedSavedRun?.is_default){ // IF SAVED FILE IS SELECTED
        await this.setting_ranges_according_to_saved_file(this.selectedSavedRun);
      }
      else{
        if((this.selectedType == 'witsml' || this.selectedType == 'wits0') && !this.isBackgroundLive){
          this.scaleMin = 0;
          // if (existScaleMax) this.scaleMax = existScaleMax;
          // else
            this.scaleMax = this.data_points["DEPTH"]?.length
              ? Math.ceil(Math.max(...this.data_points["DEPTH"]) / 10) * 10
              : 1000;
          if (this.total_leftbar_data?.selected_unit == "IMPERIAL") {
            if (val == 1200) {
              this.scaleMin = this.scaleMax - 500; // 5 Boxes * 100 each box = 500
            } else if (val == 600) {
              this.scaleMin = this.scaleMax - 250; // 5 Boxes * 50 each box = 250
            } else if (val == 240) {
              this.scaleMin = this.scaleMax - 100; // 5 Boxes * 20 each box = 100
            }
          } else if (this.total_leftbar_data?.selected_unit == "METRIC") {
            if (val == 200) {
              this.scaleMin = this.scaleMax - 50; // 5 Boxes * 10 each box = 50
            } else if (val == 500) {
              this.scaleMin = this.scaleMax - 125; // 5 Boxes * 25 each box = 125
            }
          }
        }
        else{
          this.scaleMin = Object.keys(this.data_points).includes("DEPTH") && this.data_points["DEPTH"].length
              ? Math.floor(Math.min(...this.data_points["DEPTH"]) / 10) * 10
              : 0;
          // if (existScaleMax) this.scaleMax = existScaleMax;
          if (existScaleMax) this.scaleMin = existScaleMax;
          // else
          //   this.scaleMax = this.data_points["DEPTH"]?.length
          //     ? Math.ceil(Math.max(...this.data_points["DEPTH"]) / 10) * 10
          //     : 1000;
          if (this.total_leftbar_data?.selected_unit == "IMPERIAL") {
            if (val == 1200) {
              this.scaleMax = this.scaleMin + 500; // 5 Boxes * 100 each box = 500
            } else if (val == 600) {
              this.scaleMax = this.scaleMin + 250; // 5 Boxes * 50 each box = 250
            } else if (val == 240) {
              this.scaleMax = this.scaleMin + 100; // 5 Boxes * 20 each box = 100
            }
          } else if (this.total_leftbar_data?.selected_unit == "METRIC") {
            if (val == 200) {
              this.scaleMax = this.scaleMin + 50; // 5 Boxes * 10 each box = 50
            } else if (val == 500) {
              this.scaleMax = this.scaleMin + 125; // 5 Boxes * 25 each box = 125
            }
          }
        }
        this.rangeMinValue =
          Object.keys(this.data_points).includes("DEPTH") && this.data_points["DEPTH"].length
            ? Math.floor(Math.min(...this.data_points["DEPTH"]) / 10) * 10
            : 0;
        this.rangeMaxValue =
          Object.keys(this.data_points).includes("DEPTH") && this.data_points["DEPTH"].length
            ? Math.max(...this.data_points["DEPTH"])
            : 1000;
        // TO AVOID NEGATIVE SCALE CODE STARTS
        if(this.scaleMin < 0){ 
          this.scaleMin = 0;
          if (this.total_leftbar_data?.selected_unit == 'IMPERIAL') {
            if (val == 1200) {
              this.scaleMax = this.scaleMin + 500; // 5 Boxes * 100 each box = 500
            }
            else if (val == 600) {
              this.scaleMax = this.scaleMin + 250; // 5 Boxes * 50 each box = 250
            }
            else if (val == 240) {
              this.scaleMax = this.scaleMin + 100; // 5 Boxes * 20 each box = 100
            }
          }
          else if (this.total_leftbar_data?.selected_unit == 'METRIC') {
            if (val == 200) {
              this.scaleMax = this.scaleMin + 50; // 5 Boxes * 10 each box = 50
            }
            else if (val == 500) {
              this.scaleMax = this.scaleMin + 125; // 5 Boxes * 25 each box = 125
            }
          }
          this.rangeMinValue = this.scaleMin
          this.rangeMaxValue = this.scaleMax
        }
        // TO AVOID NEGATIVE SCALE CODE ENDS
        this.initially_assinging_range = true;
        this.rangeValue = this.scaleMin;
        setTimeout(()=>{
          this.initially_assinging_range = false;
        },0)
      }
    },
    async delete_mnemonic(j, i) {
      this.trackConfig.tracks[j].splice(i, 1);
      this.closeIt(j, i);
      let copied_data = JSON.parse(
        JSON.stringify(this.total_leftbar_data.tracksData)
      );
      copied_data[j].subtracks.splice(i, 1);
      this.$store.dispatch("rect/LwdData", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: {
          ...this.$store.state.rect.rects[this.idx]?.lwdLeftBarData,
          tracksData: copied_data,
        },
        field: "lwdLeftBarData",
      });
      await this.rerender();
    },
    zoomOut() {
      if (
        this.data["DEPTH"]?.length &&
        this.data["DEPTH"][0] >= this.scaleMin
      ) {
        this.$toast.clear();
        this.$toast.error("Can't decrease more", {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      } else {
        this.$emit("background_live");
        this.scaleMin = this.scaleMin - this.interval;
        this.scaleMax = this.scaleMax + this.interval;
        this.rerender();
      }
    },
    zoomIn() {
      if (this.scaleMax - this.scaleMin == this.interval) {
        this.$toast.clear();
        this.$toast.error("Can't increase more", {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      } else {
        this.$emit("background_live");
        this.scaleMin = this.scaleMin + this.interval;
        this.scaleMax = this.scaleMax - this.interval;
        this.rerender();
      }
    },
    setting_interval() {
      // setting Interval code starts
      this.interval = 20;
      if (this.total_leftbar_data?.selected_unit == "IMPERIAL") {
        if (this.scaleMaxFixed_Prop == 1200) {
          this.interval = 100;
        } else if (this.scaleMaxFixed_Prop == 600) {
          this.interval = 50;
        } else if (this.scaleMaxFixed_Prop == 240) {
          this.interval = 20;
        }
      } else if (this.total_leftbar_data?.selected_unit == "METRIC") {
        if (this.scaleMaxFixed_Prop == 200) {
          this.interval = 10;
        } else if (this.scaleMaxFixed_Prop == 500) {
          this.interval = 25;
        }
      }
      // setting Interval code ends
    },
    scrollFunction(e) {
      // if (this.scroll_clear_timer) clearTimeout(this.scroll_clear_timer)
      // this.scroll_clear_timer = setTimeout(() => {
      // down scroll

      if (e.deltaY > 0) {
        if(this.rangeMaxValue != 0 && this.scaleMax + Math.round(this.interval / 5) > this.rangeMaxValue + Math.round(this.interval / 5)){
          this.$toast.clear();
          this.$toast.info("End reached", {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
          return;
        }
        this.scaleMax += Math.round(this.interval / 5);
        this.scaleMin += Math.round(this.interval / 5);
        this.scaleMaxFixed += Math.round(this.interval / 5);
        this.scaleMinFixed += Math.round(this.interval / 5);
      }
      // up scroll
      if (e.deltaY < 0 && this.scaleMin > 0) {
        if(this.rangeMaxValue != 0 && this.scaleMin - Math.round(this.interval / 5) < this.rangeMinValue - Math.round(this.interval / 5)){
          this.$toast.clear();
          this.$toast.info("Start reached", {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
          return;
        }
        this.$emit("background_live");
        this.scaleMax -= Math.round(this.interval / 5);
        this.scaleMin -= Math.round(this.interval / 5);
        this.scaleMaxFixed -= Math.round(this.interval / 5);
        this.scaleMinFixed -= Math.round(this.interval / 5);
      }
      this.rerender();
      //   clearTimeout(this.scroll_clear_timer)
      // }, 0)
    },
    getFullNameDisplay(name) {
      try {
        if (name == "diff") return "DIFF PRESS";
        else if (name == "simplifiedmse") return "MSE";
        else if (name == "bpos") return "BLOCK POS";
        else if (name == "stickslipindex") return "STICK SLIP";
        else if (name == "washoutindex") return "WASH OUT";
        else if (name == "sl_res") return "SL RES";
        else
          return this.$store.state.disp.displays[this.displayId].displayTags[
            this.$store.state.disp.displays[this.displayId].tags.indexOf(name)
          ].toUpperCase();
      } catch (error) {
        return "";
      }
    },
    getDescription(name) {
      try {
        if (name == "diff") return "DIFF PRESS";
        else if (name == "simplifiedmse") return "MSE";
        else if (name == "bpos") return "BLOCK POS";
        else if (name == "stickslipindex") return "STICK SLIP";
        else if (name == "washoutindex") return "WASH OUT";
        else if (name == "sl_res") return "SL RES";
        else
          return this.$store.state.disp.displays[this.displayId].descriptions[
            this.$store.state.disp.displays[this.displayId].tags.indexOf(name)
          ].toUpperCase();
      } catch (error) {
        return "";
      }
    },
    getLatestValuesToDisplay(name) {
      try{
        return this.data[name]
          ? this.data[name][this.data[name].length - 1] && !isNaN(this.data[name][this.data[name].length - 1])
            ? Number(this.data[name][this.data[name].length - 1]).toFixed(2)
            : "0.00"
          : "0.00";
      }
      catch(err){
        conosle.error(err);
        return "0.00"
      }
    },
    showProps1(j, i) {
      let cProps = {};
      cProps["show"] = true;
      cProps["curve"] = this.trackConfig.tracks[j][i];

      this.curveProps[j + "-" + i] = cProps;
    },

    closeIt(j, i) {
      this.curveProps[j + "-" + i]["show"] = false;
    },

    setCurveProps(j, i, curve) {
      if (this.trackConfig.tracks[j][i].name == "alert") {
        this.alertArr = curve.alertsArr;
      }
      this.curveProps[j + "-" + i]["show"] = false;
      let stroke = curve.thickness;
      let scaleMin = curve.scaleMin;
      let scaleMax = curve.scaleMax;
      let alertMin = curve.alertMin;
      let alertMax = curve.alertMax;
      let decimals = curve.decimals;
      let editedName = curve.editedDescription;
      if (stroke != null) {
        stroke = Number(stroke);
        this.trackConfig.tracks[j][i].stroke = stroke;
      }
      if (
        scaleMin != null &&
        scaleMin !== "" &&
        scaleMax != null &&
        scaleMax !== ""
      ) {
        scaleMin = Number(scaleMin);
        scaleMax = Number(scaleMax);
        this.trackConfig.tracks[j][i].scale = [scaleMin, scaleMax];
        this.trackConfig.tracks[j][i].scaleType = "manual";
      }
      if (alertMin != null && alertMax != null) {
        alertMin = Number(alertMin);
        alertMax = Number(alertMax);

        this.trackConfig.tracks[j][i].alert = [alertMin, alertMax];
      }
      if (!isNaN(decimals)) {
        this.trackConfig.tracks[j][i].decimals = decimals;
      }
      if (editedName !== this.trackConfig.tracks[j][i].description) {
        this.trackConfig.tracks[j][i].editedName = editedName;
      }
      else if(editedName == this.trackConfig.tracks[j][i].description && this.trackConfig.tracks[j][i]['editedName']){
        delete this.trackConfig.tracks[j][i].editedName
      }
      this.trackConfig.tracks[j][i].color = curve.color;
      this.trackConfig.tracks[j][i].isAlert = curve.isAlert;
      this.rerender("setCurveProps");
    },

    deleteCurve(j, i) {
      if (this.curveProps[j + "-" + i])
        this.curveProps[j + "-" + i]["show"] = false;
      if (this.trackConfig.tracks[j]) {
        this.trackConfig.tracks[j].splice(i, 1);

        if (this.trackConfig.tracks[j].length == 0) {
          this.trackConfig.tracks.splice(j, 1);
        }
      }
      // this.configure();

      // this.rerender()
      this.configure();
      this.verticalplots(this.div_id, this.plots, true);

      this.zoomIt(
        this.scaleMax,
        this.scaleMin,
        this.feet,
        true,
        "after delete and adding"
      );
    },
    setTrackWidth(nbr) {
      // let usableWidth = this.templateWidth - this.sliderWidth - this.leftbarWidth;
      let usableWidth = this.templateWidth;
      // this.singleTrackWidth = (usableWidth / nbr);
      this.singleTrackWidth =
        usableWidth / 5 -
        this.scaleWidth / 5 -
        this.lithologyWidth / 5 -
        this.rangeSliderWidth / 5; // this.lithologyWidth/5 = lithology, this.scaleWidth/5 = scale
    },
    setTrackHeight() {
      this.singleTrackHeight =
        this.templateHeight - this.bheight - 15 + this.tooltip_zoom_height;
    },
    createLeftBar(div_id, templates) {
      try {
        let template_for_plotting = templates[0];
        let template_overall = template_for_plotting[0]["curve_box"];
        let leftbarbg = template_overall.leftbarbg;
        let left_bar_bg_ =
          this.darkDark == true ? "light_trend-mode" : "bg-dark";
        let yAxisHolder = d3
          .select("#" + div_id + this.trendIndex + "div_id")
          .append("div");
        const trendtimeStampCol = this.darkDark ? "dv" : "ddv";
        yAxisHolder
          .attr("class", "leftbarcontainer")
          .attr("class", left_bar_bg_)
          .style("display", "inline-block")
          .style("vertical-align", "top")
          // .style("background", leftbarbg)
          .style("margin-right", "0px")
          // .style("fill", this.darkDark == true ? "white" : "black");
          .style("fill", "black")
          .style("background", "var(--lwdGraphBg)");

        yAxisHolder.style("width", this.scaleWidth + "px");
        let depth_min_sec = "";
        let depth_max_sec = "";
        // if (!this.data["time"]) {
        //   depth_min_sec = d3.min(this.data["time"]);
        //   depth_max_sec = d3.max(this.data["time"]);
        // }

        this.depthMaxValue = parseInt(depth_max_sec);
        this.depthMinValue = parseInt(depth_min_sec);
        if (
          this.trendIndex == 0 &&
          isNaN(this.depthMaxValue) &&
          isNaN(this.depthMinValue)
        ) {
          yAxisHolder
            .append("h1")
            .style("position", "absolute")
            .style("transform-origin", "center")
            // .style("transform", "translate(-4.5vw, 25vh) rotate(270deg)")
            // .style("transform", "translate(-7.5vw, -16vh) rotate(90deg)")
            .style("transform", "translateY(-22px)")
            // .style("width", this.bheight + 'px')
            .style("width", this.scaleWidth + this.lithologyWidth + "px")
            .style("height", this.bheight + "px")
            .style("top", "22px")
            .style("border-top", "2px solid #000")
            .style("text-align", "center")
            // .text("TIME AXIS NOT AVAILABLE")
            .html(
              (this.depthType == "MD"
                ? this.total_leftbar_data?.selected_unit == "METRIC"
                  ? `<p class="flex justify-center items-end" style="width:${this.scaleWidth}px; border-right:2px solid #000;">MD (m)</p>`
                  : `<p class="flex justify-center items-end" style="width:${this.scaleWidth}px; border-right:2px solid #000;">MD (ft)</p>`
                : this.total_leftbar_data?.selected_unit == "METRIC"
                ? `<p class="flex justify-center items-end" style="width:${this.scaleWidth}px; border-right:2px solid #000;">TVD (m)</p>`
                : `<p class="flex justify-center items-end" style="width:${this.scaleWidth}px; border-right:2px solid #000;">TVD (ft)</p>`)
                + `<p class="flex justify-center items-end" style="width:${this.lithologyWidth}px; border-right:${(this.trackConfig.tracks.length ==1 || (this.trackConfig.tracks.length > 1 && this.trackConfig.tracks[1][0]?.hide)) ? '2px solid #000' : '0px'}">LITHOLOGY</p>`
            )
            .style("font-size", "14px")
            .style("display", this.hide_mnemonics_div ? "none" : "flex");
        }

        this.leftRangeSliderSet = true;
        let height_components = template_overall["height"];

        this.yLeftScale = d3
          .scaleLinear()
          .domain([this.scaleMax, this.scaleMin])
          .range([height_components, 0]);
        // .nice();
        if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue))
          this.yLeftScaleCompare = d3
            .scaleTime()
            .domain([this.depthMaxValue, this.depthMinValue])
            .range([height_components, 0]);
        // .nice();

        let leftbarOuter = yAxisHolder
          .append("div")
          .attr("class", "leftbar-outer")
          // .style("width", "100px");
          .style("width", this.scaleWidth + "px");

        let leftbarInner = leftbarOuter
          .append("div")
          .attr("class", `leftbar-inner-${div_id}`)
          .style("max-height", height_components + "px")
          .style("scrollbar-width", "thin")
          // .style("width", "100px")
          .style("width", this.scaleWidth + "px")
          .style("height", height_components + "px")
          .style("direction", "rtl")
          .style("scrollbar-track", "red");

        let svg = leftbarInner
          .append("svg")
          .style("width", this.scaleWidth + "px")
          .style("border-block", "2px solid #000")
          .style("border-right", "2px solid #000")
          .attr("height", height_components)
          .on('dblclick',() => {
            if (this.selected_files.length) {
              // if (this.selected_files.length == 1) {
                this.changeSection('icon')
                this.show_add_ranges_icon_popup = true;
                this.copy_of_lithology_icons_data = [];
                if (this.lithology_icons_data != "") {
                  this.copy_of_lithology_icons_data = JSON.parse(
                    JSON.stringify(this.lithology_icons_data)
                  );
                }
              // }
            } else {
              this.$toast.clear();
              this.$toast.error("Please select the run", {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }
          })
        this.scaleMax = parseInt(this.scaleMax);
        this.scaleMin = parseInt(this.scaleMin);
        let tickInterval = (this.scaleMax - this.scaleMin) / 10;
        let depthTicks = [];
        for (
          let tick = this.scaleMin;
          tick <= this.scaleMax;
          tick = tick + tickInterval
        ) {
          depthTicks.push(tick);
        }

        depthTicks.push(this.scaleMax);
        let tickIntervalCompare = parseInt(
          (this.depthMaxValue - this.depthMinValue) / 10
        );

        let timeTicks = [];
        for (
          let tick = this.depthMinValue;
          tick < this.depthMaxValue;
          tick = tick + tickIntervalCompare
        ) {
          timeTicks.push(tick);
        }
        timeTicks.push(this.depthMaxValue);
        let yTicks = null;
        let yTicksCompare = null;
        if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue))
          if (this.data["DEPTH"] && this.data["DEPTH"].length > 0) {
            yTicks = d3
              .axisLeft()
              .scale(this.yLeftScaleCompare)
              .tickValues(timeTicks)
              .tickSize(-65);
            // yTicks.tickFormat(d3.timeFormat("%Y-%m-%d %H:%M:%S"));
          }
        this.setting_interval();
        // setting ticks code starts
        let tickValues = [];
        for (
          let tick = this.scaleMin;
          tick <= this.scaleMax;
          tick += this.interval
        ) {
          tickValues.push(
            tick + (this.interval - (this.scaleMin % this.interval))
          );
        }
        if(tickValues.length < 6){
          tickValues = []
          for (
            let tick = this.scaleMax;
            tick >= this.scaleMin;
            tick -= (this.interval/5)
          ) {
            let intervalValue = this.interval/5
            tickValues.push(tick + (intervalValue - (this.scaleMax % intervalValue)));
          }
          tickValues.reverse();
        }
        // setting ticks code ends
        yTicksCompare = d3
          .axisLeft()
          .scale(this.yLeftScale)
          // .tickValues(depthTicks)
          .tickValues(tickValues)
          .tickSizeInner(-8);

        if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue))
          if (
            this.data["DEPTH"] &&
            this.data["DEPTH"].length > 0 &&
            this.trendIndex == 0
          ) {
            let timeAxis = svg
              .append("g")
              .attr("transform", "translate(5,0)")
              .call(yTicks);

            timeAxis.selectAll("line").attr("transform", "translate(10,0)");
            timeAxis.selectAll("path").attr("transform", "translate(10,0)");
            timeAxis.selectAll("text").call(function (t) {
              t.each(function (d) {
                // for each one
                let self = d3.select(this);
                let s = [];
                s[1] = moment(new Date(d)).format("MM-DD-YYYY");
                s[0] = moment(new Date(d)).format("HH:mm:ss");
                // self.path("")
                self.text(""); // clear it out

                self
                  .append("tspan")
                  .attr("x", 20)
                  .attr("dy", "-0.5em")
                  .attr("class", trendtimeStampCol)
                  .text(s[1]);
                self
                  .append("tspan") // insert two tspans
                  .attr("x", 25)
                  .attr("dy", "1.5em")
                  .text(s[0]);
              });
            });
          }
        if (Object.keys(this.data).includes("enggicon")) {
          // Append images on the right side at specific y-values
          let y = d3
            .scaleLinear()
            .domain([this.scaleMax, this.scaleMin])
            .range([height_components, 0]);
          let iconsData = [];
          this.data["enggicon"].map((iconNumber, inx) => {
            if (iconNumber > 0) {
              let obj = this.lithology_icons_list.find(
                (each) =>
                  each.displayIconId == iconNumber && each.type == "icon"
              );
              if (obj) {
                iconsData.push({
                  y: this.data["DEPTH"][inx],
                  icon: "data:image/png;base64," + obj.icon,
                });
              }
            }
          });
          svg
            .selectAll("image")
            .data(iconsData)
            .enter()
            .append("image")
            .attr("xlink:href", (d) => d.icon)
            .attr("x", this.scaleWidth - 20) // Adjust the x-position for the images on the right side
            .attr("y", (d) => y(d.y))
            .attr("width", 20) // Set the image width
            // .attr("height", 20)
            .on('click',(e,d)=>{
              this.newStartRange = d.y
              this.show_delete_icon_popup = true;
            }); // Set the image height
        }

        let depthAxis = svg
          .append("g")
          .attr("transform", "translate(-10,0)")
          .call(yTicksCompare);

        // depthAxis.selectAll("line").attr("transform", "translate(10,0)").style("opacity", "0.2").style("stroke", "black");
        depthAxis
          .selectAll("line")
          .attr("transform", "translate(10,-0.5)")
          .style("opacity", "1")
          .style("stroke", "black")
          .style("stroke-width", 0.3);

        depthAxis
          .selectAll("path")
          .style("opacity", "0.2")
          .attr("transform", "translate(10,0)");

        depthAxis
          .selectAll("text")
          .attr("x", "2em")
          .attr("dy", "0.2em")
          .style("font-size", "0.7rem")
          .style("font-weight", "600")
          .style("color", "black");
      } catch (error) {
        console.error("errro in left bar ", error);
      }
    },
    async createLithologyBar(div_id, templates) {
      try {
        let template_for_plotting = templates[0];
        let template_overall = template_for_plotting[0]["curve_box"];
        let leftbarbg = template_overall.leftbarbg;
        let left_bar_bg_ =
          this.darkDark == true ? "light_trend-mode" : "bg-dark";
        let yAxisHolder = d3
          .select("#" + div_id + this.trendIndex + "div_id")
          .append("div");
        const trendtimeStampCol = this.darkDark ? "dv" : "ddv";
        yAxisHolder
          .attr("class", "leftbarcontainer")
          .attr("class", left_bar_bg_)
          .attr("id", "myCanvasContainer" + this.widgetId)
          .style("display", "inline-block")
          .style("vertical-align", "top")
          // .style("background", leftbarbg)
          .style("margin-right", "0px")
          .style("border-block", "2px solid #000")
          .style("border-right", "1px solid #000")
          // .style("fill", this.darkDark == true ? "white" : "black");
          .style("fill", "black")
          .style("background", "var(--lwdGraphBg)");

        yAxisHolder.style("width", this.lithologyWidth + "px");
        let height_components = template_overall["height"];

        // Create a canvas element
        let ele = document.getElementById("myCanvasElement" + this.widgetId);
        if (ele) {
          ele.remove();
        }
        const canvas_ele = document.createElement("canvas");
        canvas_ele.setAttribute("id", "myCanvasElement" + this.widgetId);
        // Append the canvas to the DOM
        const container = document.getElementById("myCanvasContainer" + this.widgetId);
        container.appendChild(canvas_ele);

        // Get a reference to the canvas element
        var canvas = document.getElementById("myCanvasElement" + this.widgetId);
        canvas.width = this.lithologyWidth - 1;
        canvas.height = height_components - 4;

        // BELOW DOUBLE CLICK TO SHOW THE POPUP TO ADD THE LITHOLOGY
        canvas.addEventListener("dblclick", (event) => {
          if (this.selected_files.length) {
            // if (this.selected_files.length == 1) {
              this.changeSection('symbol')
              this.show_add_ranges_icon_popup = true;
              this.show_lithology_delete_button = false;
              this.clickedLithologyObj = null;
              this.copy_of_lithology_icons_data = [];
              if (this.lithology_icons_data != "") {
                this.copy_of_lithology_icons_data = JSON.parse(
                  JSON.stringify(this.lithology_icons_data)
                );
                let startDepthOfLitholigies = [];
                this.copy_of_lithology_icons_data.map((eachLitho)=>{
                  startDepthOfLitholigies.push(eachLitho.startDepth)
                })
                startDepthOfLitholigies.sort((a,b)=> a - b);
                let data = this.data
                for (let focusAxis of this.focusAxisList) {
                  if (!focusAxis) {
                    return;
                  }
                  let y = focusAxis["y"];
                  let depth_curve_name = focusAxis["depth_curve_name"];
                  let bisectDate = d3.bisector(function (d) {
                    return d;
                  }).left; // **

                  let y0 = 0;
                  let index = 0;
                  if (this.logType == "depth") {
                    y0 = y.invert(d3.pointer(event)[1]);
                  } else {
                    y0 = moment(y.invert(d3.pointer(event)[1])).unix() * 1000;
                  }
                  try {
                    index = bisectDate(startDepthOfLitholigies, y0, 0);
                  } catch (error) {}
                  if (!y0 || (!index && index != 0) || !startDepthOfLitholigies[index - 1]) {
                    continue;
                  }
                  let foundLitho = this.copy_of_lithology_icons_data.find((eachLithoSymbol)=> {
                                    return eachLithoSymbol.startDepth == startDepthOfLitholigies[index - 1] && y0 < eachLithoSymbol.endDepth
                                  })
                  if(foundLitho){
                    this.clickedLithologyObj = foundLitho;
                    this.newStartRange = foundLitho?.startDepth
                    this.newEndRange = foundLitho?.endDepth
                    this.show_lithology_delete_button = true;
                  }                                 
                  else{
                    this.clickedLithologyObj = null;
                    this.show_lithology_delete_button = false;
                  }
                  break;;
                }
              }
            // }
          } else {
            this.$toast.clear();
            this.$toast.error("Please select the run", {
              duration: "2000",
              position: "top",
              pauseOnHover: true,
            });
          }
        });

        // Get the 2D drawing context
        var ctx = canvas.getContext("2d");
        //  let icons_res = await apiService.lwdServices.lwd_icons_list();
        let plot_data = [];
        let yScale = d3
          .scaleLinear()
          .domain([this.scaleMax, this.scaleMin])
          .range([height_components, 0]);
        // Define the coordinates (x1 and x2) where you want to fill the area
        var x1 = 0; // Starting x-coordinate
        var x2 = this.lithologyWidth - 1; // Ending x-coordinate
        // if(icons_res.status == 200){
        // let icons_data = icons_res.data
        let icons_data = this.lithology_icons_data;
        for (let icon = 0; icon < icons_data.length; icon++) {
          let eachIcon = icons_data[icon];
          // console.log("Lithology list : ", eachIcon.startDepth , this.scaleMin , eachIcon.endDepth , this.scaleMax )
          if (
            (eachIcon.startDepth >= this.scaleMin ||
              eachIcon.endDepth >= this.scaleMin) &&
            (eachIcon.startDepth <= this.scaleMax ||
              eachIcon.endDepth <= this.scaleMax)
          ) {
            let lithoStartDepth = eachIcon.startDepth;
            let lithoEndDepth = eachIcon.endDepth;
            
            // TO SHOW LITHOLOGY WITHIN THE SAVED FILE RANGE CODE STARTS
            if(this.selectedSavedRun !== null && 
              Object.keys(this.selectedSavedRun).length && 
              !this.selectedSavedRun?.is_default
            ){ // IF SAVED FILE IS SELECTED
              let hadStartDepth = Math.min(...this.data['DEPTH']) <= eachIcon.startDepth && Math.max(...this.data['DEPTH']) >= eachIcon.startDepth;
              let hadEndDepth = Math.min(...this.data['DEPTH']) <= eachIcon.endDepth && Math.max(...this.data['DEPTH']) >= eachIcon.endDepth;

              if(!hadStartDepth && !hadEndDepth){ // IF START AND END DEPTHS ARE NOT AVAILABLE THEN NOT SHOWING LITHOLOGY
                continue;
              }
              else{
                if(hadStartDepth && !hadEndDepth){
                  lithoEndDepth = this.selectedSavedRun?.end_depth;
                }
                if(!hadStartDepth && hadEndDepth){
                  lithoStartDepth = this.selectedSavedRun?.start_depth
                }
              }
            }
            // TO SHOW LITHOLOGY WITHIN THE SAVED FILE RANGE CODE ENDS

            // console.log(x1, yScale(eachIcon.startDepth) > 0 ? yScale(eachIcon.startDepth) : 0, x2, yScale(eachIcon.endDepth-yScale(eachIcon.startDepth))
            let file = "data:image/png;base64," + eachIcon.icon;
            let image = new Image();
            image.src = file;
            let render_img = await this.loadImage(image);
            let pattern1 = ctx.createPattern(render_img, "repeat");
            // ctx.strokeStyle = 'red';
            let startPoint =
              yScale(lithoStartDepth) > 0 ? yScale(lithoStartDepth) : 0;
            let height = yScale(lithoEndDepth) - startPoint;
            ctx.fillStyle = pattern1;
            ctx.fillRect(x1, startPoint, x2, height);

            // ctx.fillRect(x1, yScale(eachIcon.startDepth) > 0 ? yScale(eachIcon.startDepth) : 0, x2, yScale(eachIcon.endDepth) > 0 ? yScale(eachIcon.endDepth) : 0);
            // ctx.fill();
          }
        }
      } catch (error) {
        console.error("errro in left bar ", error);
      }
    },
    verticalplots(
      div_id,
      templates,
      show_all = true,
      remove_preexisting = true
    ) {
      let noDIV = d3
        .select("#" + div_id + this.trendIndex + "div_id")
        .selectAll("div")
        .remove();
      let noSVG = d3
        .select("#" + div_id + this.trendIndex + "div_id")
        .selectAll("svg")
        .remove();
      // if (templates.length > 0) {
      //   this.createLeftBar(div_id, templates);

      // }
      let new_templates = [];
      curvesMinsMaxes = [];
      for (let i = 0; i < templates.length; i++) {
        if (i == 1) {
          if (templates.length > 0) {
            this.createLeftBar(div_id, templates);
            this.createLithologyBar(div_id, templates);
          }
        }
        // let track_bg_s = this.darkDark == true ? "" : this.bgColor;
        let track_bg_s = this.bgColor;
        let curvebox_holder = d3
          .select("#" + div_id + this.trendIndex + "div_id")
          .append("div");
        curvebox_holder
          .style("vertical-align", "middle")
          .style("background", track_bg_s)
          .attr("id", templates[i][0]["curve_box"]["trackId"]);

        //// to control view of plots on site, user can show-hide by triggering action here. However, if show_all = false then none will show, so developer will need to change CSS with another function one by one!
        if (show_all) {
          curvebox_holder.style("display", "inline-block");
          curvebox_holder.style("margin-right", "0px");
        } else {
          curvebox_holder.style("display", "none");
        }
        // templates[i][0]["curve_box"]["div_id"] = div_id + "curvebox_holder" + i;

        templates[i][0]["curve_box"]["div_id"] =
          templates[i][0]["curve_box"]["trackId"];

        new_templates.push(templates[i]);
        let template = templates[i];
        this.curveBox(template, i, div_id);
      }
      if (templates.length == 1) {
        this.createLeftBar(div_id, templates);
        this.createLithologyBar(div_id, templates);
      }
    },
    mousemove(e, trendIndex) {
      let offsetYPercent =
        (e.offsetY / e.target.getBoundingClientRect().height) * 100;
      let timeRangeInSeconds = this.scaleMax - this.scaleMin;
      let tooltipShift = timeRangeInSeconds / 4;
      let data = this.data;
      for (let focusAxis of this.focusAxisList) {
        if (!focusAxis) {
          return;
        }
        let focus = focusAxis["focus"];
        let y = focusAxis["y"];
        let x = focusAxis["x"];
        // let data = focusAxis['data'];
        let depth_curve_name = focusAxis["depth_curve_name"];
        let width = focusAxis["width"];
        let curve_names = focusAxis["curve_names"];
        let mouseover_curvename = focusAxis["mouseover_curvename"];
        let height = focusAxis["height"];
        let bisectDate = d3.bisector(function (d) {
          return d;
        }).left; // **

        let y0 = 0;
        let index = 0;

        if (this.logType == "depth") {
          y0 = y.invert(d3.pointer(e)[1]);
        } else {
          y0 = moment(y.invert(d3.pointer(e)[1])).unix() * 1000;
        }
        this.$store.dispatch("rect/yAxisValue", {
          yAxisValue: y0,
          widgetId: this.id,
          displayId: this.displayId,
          widgetIndex: this.widgetIndex,
          trendIndex: this.trendIndex,
        });
        try {
          // if("45be09c1-937c-4fdf-9640-406cad0824d5" == this.logId)

          index = bisectDate(data[depth_curve_name], y0, 0);
        } catch (error) {}

        if (!y0 || (!index && index != 0) || !data[depth_curve_name][index]) {
          continue;
        }
        focus.style("display", null);
        focus.transition().duration(200).style("opacity", 1);

        let d = {};
        for (let i in data) {
          d[i] = data[i][index];
        }

        // let d = y0 - d0[depth_curve_name] > d1[depth_curve_name] - y0 ? d1 : d0;

        //// fixed value along y axis
        // let fixed_x_value = width * 0.8;
        let fixed_x_value = width/2;
        //// depth value
        let num = d[depth_curve_name] / this.scaleMax;

        focus
          .select("text.y2")
          .attr(
            "transform",
            // "translate(" + x(d[mouseover_curvename]) + "," +
            "translate(" +
              (fixed_x_value - 60) +
              "," +
              `${
                offsetYPercent > 85
                  ? y(d[depth_curve_name] - tooltipShift)
                  : offsetYPercent < 15
                  ? y(d[depth_curve_name] + tooltipShift)
                  : y(d[depth_curve_name])
              })`
            // `${y(d[depth_curve_name])})`
          )
          .text(`Depth ${d[depth_curve_name]} (ft)`);

        //// curve value
        for (let i = 0; i < curve_names.length; i++) {
          let text = d[curve_names[i]];
          
          if (this.zero.indexOf(curve_names[i]) > -1 && text) {
            text = parseInt(text);
          }
          if (this.double.indexOf(curve_names[i]) > -1 && text) {
            text = parseFloat(text).toFixed(2);
          }

          focus
            .select(`.data${i + 1}`)
            .attr(
              "transform",
              "translate(" +
                (fixed_x_value - 45) +
                "," +
                `${
                  offsetYPercent > 85
                    ? y(d[depth_curve_name] - tooltipShift)
                    : offsetYPercent < 15
                    ? y(d[depth_curve_name] + tooltipShift)
                    : y(d[depth_curve_name])
                })`
              // `${y(d[depth_curve_name])})`
            )
            .text(text);
        }
        focus
          .select(".x")
          .attr(
            "transform",
            "translate(" + x(d[mouseover_curvename]) + "," + 0 + ")"
          )
          .attr("y2", height);
        //// circle y class part 2
        focus
          .select(".y")
          .attr(
            "transform",
            "translate(" +
              x(d[mouseover_curvename]) +
              "," +
              y(d[depth_curve_name]) +
              ")"
          )
          .text(d[mouseover_curvename]);
        focus
          .select(".yl")
          .attr(
            "transform",
            "translate(" + 0 + "," + y(d[depth_curve_name]) + ")"
          );

        // if (this.trendIndex == 0)
        //   d3.select('#horizontal_line_tooltip_line').attr('y1', y(d[depth_curve_name]))

        // if (this.trendIndex == 1)
        //   d3.select('#horizontal_line_tooltip_line').attr('y2', y(d[depth_curve_name]))

        // if (this.trendIndex == 1)
        //   d3.select('#horizontal_line_tooltip_line1').attr('y1', y(d[depth_curve_name]))

        // if (this.trendIndex == 2)
        //   d3.select('#horizontal_line_tooltip_line1').attr('y2', y(d[depth_curve_name]))
        // .text(d[mouseover_curvename]);
        let back_height = Math.max(curve_names.length * 22, 66);

        focus
          .select(".background")
          .attr(
            "transform",
            "translate(" +
              (fixed_x_value - 50) +
              "," +
              `${
                offsetYPercent > 85
                  ? y(d[depth_curve_name] - tooltipShift)
                  : offsetYPercent < 15
                  ? y(d[depth_curve_name] + tooltipShift)
                  : y(d[depth_curve_name])
              })`
            // `${y(d[depth_curve_name])})`
          )
          .attr("height", back_height)
          .transition()
          .duration(5000)
          .style("opacity", 0.9);
      }
    },
    mouseout(e) {
      for (let focusAxis of this.focusAxisList) {
        let focus = focusAxis["focus"];
        focus
          .style("display", "none")
          .transition()
          .duration(250)
          .style("opacity", 0);
      }

      // d3.select('#horizontal_line_tooltip_line').attr('y1', -10).attr('y2', -10)
      // d3.select('#horizontal_line_tooltip_line1').attr('y1', -10).attr('y2', -10)
    },
    mouseover(focus) {
      focus
        .style("display", "none")
        .transition()
        .duration(250)
        .style("opacity", 0);
    },
    mousescroll(e, trendIndex) {
      if (this.slider && this.slider.noUiSlider) {
        this.slider.noUiSlider.updateOptions({
          behaviour: "drag",
          start: [this.scaleMin, this.scaleMax],
          range: {
            min: this.scaleMinFixed,
            max: this.scaleMaxFixed,
          },
        });

        if (this.mousewheeltimeout) clearTimeout(this.mousewheeltimeout);
        this.mousewheeltimeout = setTimeout(() => {
          let scaleMin = 0;
          if (this.liveDisabled) {
            this.liveDisabled = false;
          }

          let scroll_amount =
            (this.scaleMax - this.scaleMin) / 10 > 0
              ? (this.scaleMax - this.scaleMin) / 10
              : 10;

          // downscroll code
          if (e.deltaY > 0) {
            if (this.scaleMaxFixed - scroll_amount > this.scaleMax) {
              this.scaleMax = this.scaleMax + scroll_amount;
              this.scaleMin = this.scaleMin + scroll_amount;
            }
          }

          // upscroll code
          if (e.deltaY < 0) {
            if (this.scaleMinFixed < this.scaleMin - scroll_amount) {
              this.scaleMax = this.scaleMax - scroll_amount;
              this.scaleMin = this.scaleMin - scroll_amount;
            }
          }

          // console.log("🚀 ➽ file: CorrelationTrend.vue:2343  ➽ mousescroll  ➽ scroll_amount ⏩", scroll_amount)
          this.zoomIt(
            this.scaleMax,
            this.scaleMin,
            this.feet,
            false,
            "mousewheel"
          );
          clearTimeout(this.mousewheeltimeout);
        }, 100);
      }
    },
    curveBox(template_for_plotting, index, divId) {
      try {
        if(index == 0){
          if(this.selectedSavedRun !== null && Object.keys(this.selectedSavedRun).length && !this.selectedSavedRun?.is_default){ // IF SAVED FILE IS SELECTED
          }
          else{
            this.data = this.data_points;
          }
        }
        let template_overall = template_for_plotting[0]["curve_box"];

        let template_components = template_for_plotting[0]["components"];

        let template_curves = template_components[0]["curves"][0];

        let template_lines = template_components[0]["lines"];
        let template_rectangles = template_components[0]["rectangles"];
        let title = "";

        /// Parameters that define shape & size of overall curve box
        // let width = template_overall["width"] - this.scaleWidth / (this.trackConfig?.tracks?.length || 3);
        let width = template_overall["width"];
        let height_multiplier_components = 0.95;
        if (template_overall["height_multiplier_components"]) {
          height_multiplier_components =
            template_overall["height_multiplier_components"];
        }

        let height_components = template_overall["height"];
        // let height = template_overall['height'] * height_multiplier_components;
        let height = height_components * height_multiplier_components;
        let margin = template_overall["margin"];
        let gridlines = template_overall["gridlines"];
        let gridlines_color = template_overall["gridlines_color"];
        let gridlines_stroke_width = template_overall["gridlines_stroke_width"];

        let mouseover_depth_or_depth_and_curve =
          template_overall["mouseover_depth_or_depth_and_curve"];
        let mouseover_curvename = template_overall["mouseover_curvename"]; //// default is first curve
        let mouseover_color_or_default_which_is_curve_color =
          template_overall["mouseover_color_or_default_which_is_curve_color"];
        let secondary_depth_exist = "no"; // THIS IS NOT YET EXISTING IN PLOTTING INPUT JSON SO HARDCODING FOR NOW
        //// Data is in d3.js form. An array of objects consisting of single level key:value pairs
        let data = this.data;

        if (data == null || (data["DEPTH"] && data["DEPTH"].length == 0)) {
          console.info("no data");
          this.isRerendering = false;
        }

        //// Variables related to curves, these should all be arrays with one or more values!
        let curve_names = template_curves["curve_names"];
        let curve_colors = template_curves["curve_colors"];

        let curve_stroke_dasharray = template_curves["curve_stroke_dasharray"];
        let curve_name = curve_names[0];
        let curve_color = curve_colors[0];
        let curve_units = template_curves["curve_units"];
        let scale_linear_log_or_yours =
          template_curves["scale_linear_log_or_yours"];
        if (template_curves["curve_units"]) {
          curve_units = template_curves["curve_units"];
        } else {
          curve_units = "";
        }
        let curveScales = template_curves["scale"];
        let curveScaleType = template_curves["scaleType"];

        //////// NEED TO MAKE THIS FLAG IN INPUT PLOTTING JSON
        let flag_for_single_scale_or_independent_scales =
          template_overall["grouped_or_independent_x_scales"];
        let grouped_or_independent_x_scale =
          template_overall["grouped_or_independent_x_scales"];
        //// The depth_curve_name needs to be the same for all curves plotted!
        let depth_curve_name = "";
        if (
          template_curves["depth_curve_name"].length > 1 &&
          typeof template_curves["depth_curve_name"] == "object" &&
          template_curves["depth_curve_name"][0] !==
            template_curves["depth_curve_name"][1]
        ) {
          depth_curve_name =
            "depth_curve_name is not the same in two or more curves";
        } else {
          depth_curve_name = template_curves["depth_curve_name"];
        }
        let depth_type_string = "";
        if (
          template_curves["depth_type_string"].length > 1 &&
          typeof template_curves["depth_type_string"] == "object" &&
          template_curves["depth_type_string"][0] !=
            template_curves["depth_type_string"][1]
        ) {
          depth_type_string =
            "depth type string is not the same in two or more curves";
        } else if (template_curves["depth_type_string"][0] == "") {
          depth_type_string = "";
        } else if (template_curves["depth_type_string"]) {
          depth_type_string = "- " + template_curves["depth_type_string"];
        }
        let depth_units_string = "";
        if (
          template_curves["depth_units_string"] &&
          template_curves["depth_units_string"][0] !== ""
        ) {
          depth_units_string = "- " + template_curves["depth_units_string"];
        }
        ///// THIS LINE BELOW DOESN"T MAKE ANY SENSE, CHANGE ////
        let div_id = template_overall["div_id"];
        if (template_overall["div_id"]) {
          div_id = template_overall["div_id"];
        } else {
          return "there_was_no_div_id_in_the_template";
        }
        let trendIndex = this.trendIndex;
        d3.select("#" + div_id + trendIndex + "div_id")
          .selectAll("*")
          .remove();
        ///////// NEED TO FIX DEPTHS AS THERE ARE MULTIPLE DEPTH LIMITS AND THEY NEED TO BE CALCULATED PROPERLY !!!!! //////////////////////////
        //       //// Calculate depth min and max if depth min and/or max is not given explicitly in the template
        let depth_min;
        let depth_max;
        if (
          !template_curves["depth_limits"] ||
          template_curves["depth_limits"][0]["min"] == "autocalculate"
        ) {
          depth_min = this.scaleMin;
        } else {
          depth_min = template_curves["depth_limits"][0]["min"];
        }
        //// max depth
        if (
          !template_curves["depth_limits"] ||
          template_curves["depth_limits"][0]["max"] == "autocalculate"
        ) {
          depth_max = this.scaleMax;
        } else {
          depth_max = template_curves["depth_limits"][0]["max"];
        }
        // [depth_max,depth_min]
        //// Apply depth min and max to incoming well log data
        //// To save time, we'll first check if the first object in the array had as depth that is smaller than min
        //// and check if the last object in the array has a depth that is larger than the max, if not. we do nothing.
        if (data.length > 0 && data != null) {
          if (
            data[0][depth_curve_name] > depth_min &&
            data[data.length - 1][depth_curve_name] < depth_max
          ) {
          } else {
            data = data.filter(function (objects) {
              return (
                objects[depth_curve_name] > depth_min &&
                objects[depth_curve_name] < depth_max
              );
            });
          }
          // let depth_min = -1000000
          // let depth_max = 1000000
          if (data.length > 0) {
            if (
              template_curves["min_depth"][0] == "autocalculate" ||
              template_curves["min_depth"] == "autocalculate"
            ) {
              depth_min = data[0][depth_curve_name];
            } else {
              depth_min = template_curves["min_depth"];
            }
            if (
              template_curves["max_depth"][0] == "autocalculate" ||
              template_curves["max_depth"] == "autocalculate"
            ) {
              depth_max = data[data.length - 1][depth_curve_name];
            } else {
              depth_max = template_curves["max_depth"];
            }
          }
        }
        let svg = "";
        let svg_holder = "";
        let svg_header = "";

        svg = d3
          .select("#" + this.div_id + this.trendIndex + "div_id")
          .append("svg")
          // .attr("style", `border: 1.5px solid ${this.darkDark == true ? 'var(--nav1BgClr)' : '#c1c0c0'}`)
          .attr(
            "style",
            `border: 2px solid #000; ${
              index == 0
                ? ""
                : this.trackConfig.tracks.length - 1 == index ? "border-right-width: 2px;border-left-width: 0px" : "border-right-width: 1px;border-left-width: 0px"
            }`
          )
          .style("max-height", height_components + "px")
          .style("height", height_components + "px")
          .style("background", "var(--lwdGraphBg)")
          .on("dblclick", (event) => {
            // if (this.selected_files.length > 1) {
            //   this.show_consolidated_ranges_popup = true;
            // }
            this.clickedGraphIndex = index
            if (this.selected_files.length) {
              // CODE TO GET DEPTH STARTS
              let data = this.data;
              let dont_show_msg_popup = false;
              this.messageText = null;
              for (let focusAxis of this.focusAxisList) {
                if (!focusAxis) {
                  return;
                }
                let y = focusAxis["y"];
                let depth_curve_name = focusAxis["depth_curve_name"];
                let bisectDate = d3.bisector(function (d) {
                  return d;
                }).left; // **

                let y0 = 0;
                let index = 0;
                if (this.logType == "depth") {
                  y0 = y.invert(d3.pointer(event)[1]);
                } else {
                  y0 = moment(y.invert(d3.pointer(event)[1])).unix() * 1000;
                }
                try {
                  index = bisectDate(data[depth_curve_name], y0, 0);
                } catch (error) {}

                try {
                  let lo = index;
                  for (let up = index; up > index - 10; up--) {
                    if (Object.keys(data).includes(`comment-${this.clickedGraphIndex}`) && data[`comment-${this.clickedGraphIndex}`][up]) {
                      index = up;
                      this.messageText = data[`comment-${this.clickedGraphIndex}`][lo];
                      break;
                    }
                    if (Object.keys(data).includes(`comment-${this.clickedGraphIndex}`) && data[`comment-${this.clickedGraphIndex}`][lo]) {
                      this.messageText = data[`comment-${this.clickedGraphIndex}`][lo];
                      index = lo;
                      break;
                    }
                    lo++;
                  }
                } catch (error) {}

                if (!y0 || (!index && index != 0) || !data[depth_curve_name][index]) {
                  dont_show_msg_popup = true;
                  continue;
                }
                else{
                  dont_show_msg_popup = false;
                }

                let d = {};
                for (let i in data) {
                  d[i] = data[i][index];
                }
                this.messageDepth = d[depth_curve_name];
              }
              // CODE TO GET DEPTH ENDS
              if(!dont_show_msg_popup){
                this.messageX_pos = event.clientX;
                this.messageY_pos = event.clientY;
                this.show_message_adding_popup = true;
              }
              else{
                this.$toast.clear();
                this.$toast.error("Depth not available", {
                  duration: "2000",
                  position: "top",
                  pauseOnHover: true,
                });
                this.close_message_adding_popup();
              }
            }
          });

        //////////////  Calculate Axis & Scales =>//////////////
        //// Need to handle: zero curves, arbitrary number of curves, and min/max of all curves in single axis.
        //// For zero curves, need to look into rectange and lines for x scales maybe?
        //// Need to handle scales in linear, log, or arbitary user-provided scale.
        //// Also, need x function for gridlines! so....?
        //////////////  Calculate x domain extent for one or more than one curve, used in scaling =>//////////////
        let mins = [];
        let maxes = [];
        mins = mins;
        maxes = maxes;
        for (let i = 0; i < curve_names.length; i++) {
          let curveObj = {
            name: "",
            min: "",
            max: "",
          };
          let curveName = curve_names[i];
          curveObj.name = curve_names[i];
          let curveScale = curveScales[i];
          let scaleType = curveScaleType[i];
          let min_this = "";
          let max_this = "";

          if (
            scaleType == "manual" &&
            (curveScale != null || curveScale != undefined)
          ) {
            if (curveScale[0] != null || curveScale[0] != "") {
              min_this = curveScale[0];
            } else {
              min_this = this.minmax[curve_names[i]].min;
              curveScale[0] = min_this;
            }
            if (curveScale[1] != null || curveScale[1] != "") {
              max_this = curveScale[1];
            } else {
              max_this = this.minmax[curve_names[i]].max;
              curveScale[1] = max_this;
            }
          } else {
            let currentRange = defaultRanges.ranges.find(
              (data) => data.name == curve_names[i]
            );

            if (currentRange) {
              min_this = currentRange.low;
              max_this = currentRange.high;
            } else {
              // min_this = this.minmax[curve_names[i]].min;
              // max_this = this.minmax[curve_names[i]].max;
              min_this = 0;
              max_this = 500;
            }
            curveScale[0] = min_this;
            curveScale[1] = max_this;
          }
          curveObj.min = min_this;
          curveObj.max = max_this;
          curvesMinsMaxes.push(curveObj);

          mins.push(min_this);
          maxes.push(max_this);
          mins = mins;
          maxes = maxes;
        }

        let min_all_curves = d3.min(mins);
        let max_all_curves = d3.max(maxes);
        if (curve_names.length == 0) {
          //// THIS NEEDS TO CHANGE TO LOOK AT RECTANGLE AT SOME POINT!!!!!!
          min_all_curves = 0;
          max_all_curves = 100;
        }
        //////////////  Calculate x domain extent for one or more than one curve, used in scaling =>//////////////
        let x_func;
        let x;
        let y = d3
          .scaleLinear()
          .domain([this.scaleMax, this.scaleMin])
          .range([height_components, 0]);

        let yAxis2 = (g) =>
          g
            .attr("transform", `translate(${margin.left - 35},0)`)
            .call(d3.axisLeft(y))
            .call((g) => g.select(".domain"));

        //////////////  Building curvebox parts that aren't header. First define size & title =>//////////////

        x = d3
          .scaleLinear()
          .domain([min_all_curves, max_all_curves])
          .range([margin.left, width - margin.right]);
        // .nice();
        svg.attr("class", `components components-${index}`);
        svg.attr("width", width).attr("height", height - 100);
        svg.attr("id", "svg" + div_id).on("mousewheel", (e) => {
          this.scrollFunction(e);
        });

        svg.style("margin", "0");

        let distance_from_top = -15;
        if (title !== "") {
          svg
            .append("text") //
            .attr("x", margin.left / 3 + width / 2)
            .attr("y", 0 + -distance_from_top)
            .attr("text-anchor", "middle")
            .style("font-size", template_overall["title"]["title_font_size"])
            .text(title);
        }
        if (gridlines == "yes") {
          let xGrid;
          let gridlines_obj;
          if (
            this.left_bar_tracks[index]?.trackScale == "logarithmic" &&
            this.left_bar_tracks[index]?.logarithmicCycleScale
          ) {
            let logarithmic_cycle_scale = null;
            if (this.left_bar_tracks[index].logarithmicCycleScale == 2) {
              logarithmic_cycle_scale = 20;
            } else if (this.left_bar_tracks[index].logarithmicCycleScale == 3) {
              logarithmic_cycle_scale = 200;
            } else if (this.left_bar_tracks[index].logarithmicCycleScale == 4) {
              logarithmic_cycle_scale = 2000;
            }
            xGrid = d3
              .scaleLog()
              .domain([0.2, logarithmic_cycle_scale])
              .range([margin.left, width - margin.right]);

            gridlines_obj = d3
              .axisTop(xGrid)
              // .ticks((width - margin.left - margin.right) / 25)
              .ticks(10)
              .tickFormat("")
              .tickSize(-height - 50);
          } else {
            if (
              index == 1 &&
              this.left_bar_tracks[index]?.trackScale !== "linear"
            ) {
              // BY DEFAULT
              xGrid = d3
                .scaleLog()
                .domain([0.2, 2000])
                .range([margin.left, width - margin.right]);

              gridlines_obj = d3
                .axisTop(xGrid)
                // .ticks((width - margin.left - margin.right) / 25)
                .ticks(10)
                .tickFormat("")
                .tickSize(-height - 50);
            } else {
              xGrid = d3
                .scaleLinear()
                .domain([0, 500])
                .range([margin.left, width - margin.right]);

              gridlines_obj = d3
                .axisTop()
                // .ticks((width - margin.left - margin.right) / 25)
                .ticks(8)
                .tickFormat("")
                .tickSize(-height - 50)
                .scale(xGrid);
            }
          }
          svg
            .append("g")
            .attr("class", "grid")
            .call(gridlines_obj)
            // .style("stroke", this.darkDark == true ? gridlines_color : "black")
            .style("stroke", "#000")
            .style("color", "black")
            // .style("stroke-dasharray", "5 5")
            .style("z-index", -1)
            .style("stroke-width", 0.1);
          let yGrid = d3
            .scaleLinear()
            .domain([this.scaleMax, this.scaleMin])
            .range([height_components, -1]);

          this.setting_interval();
          // setting ticks code starts
          let tickValues = [];
          for (
            let tick = this.scaleMax;
            tick >= this.scaleMin;
            tick -= (this.interval/5)
          ) {
            let intervalValue = this.interval/5
            tickValues.push(tick + (intervalValue - (this.scaleMax % intervalValue)));
            // tickValues.push(
            //   tick + (this.interval - (this.scaleMin % this.interval))
            // );
          }
          tickValues.reverse();
          // setting ticks code ends
          let horizontalLines = d3
            .axisLeft()
            .tickFormat("")
            .tickSize((-width + margin.left + margin.right) * 1)
            // .ticks(tickValues.length * 5)
            .tickValues(tickValues)
            .scale(yGrid);

          // gYAxis.selectAll('.tick line').attr('opacity', 0.1)
          //   .style.color = "blue";
          let tempInterval = this.interval;
          svg
            .append("g")
            .attr("class", "grid horizontal")
            // .attr("class", this.darkDark == true  ? 'grid_dark' : 'grid_light')
            // .attr("transform", "translate(60, -10 )")
            .call(horizontalLines)
            .style("stroke", gridlines_color)
            // .style("color", this.darkDark == true ? gridlines_color : "black")
            .style("color", "black")
            // .style("color", "#424141")
            // .style("stroke-width", 0.1)
            // .style("opacity", 0.6)
            .style("z-index", 0)
            .attr("transform", "translate(0, 0 )");

          // Modify the style of every 5th grid line to make it bold
          svg
            .selectAll(".grid.horizontal line")
            .attr("stroke-width", function (d, i) {
              // console.log("🚀 ➽ file: LwdTrend.vue:4053  ➽ .attr  ➽ d ⏩" , d)
              return d % tempInterval === 0 ? 0.2 : 0.1; // Make every 5th line bold
            });
        }

        if (
          this.isZoom &&
          curve_names.indexOf("stickslipindex") == -1 &&
          curve_names.indexOf("washoutindex") == -1
        ) {
          let updateChart = async (event) => {
            let extent = event.selection;
            let min = y.invert(extent[0]);
            let max = y.invert(extent[1]);
            // min = moment(min).unix() * 1000;
            // max = moment(max).unix() * 1000;
            if (this.slider && this.slider.noUiSlider) {
              this.slider.noUiSlider.updateOptions({
                behaviour: "drag",
                start: [parseInt(min), parseInt(max)],
                range: {
                  min: this.scaleMinFixed,
                  max: this.scaleMaxFixed,
                },
              });
              this.scaleMin = parseInt(min);
              this.scaleMax = parseInt(max);
              this.zoomIt(
                this.scaleMax,
                this.scaleMin,
                this.feet,
                true,
                "13 called"
              );
            }
          };

          let updateStart = async (event) => {
            event.sourceEvent.stopPropagation();
          };

          let brushZoom1 = d3
            .brushY()
            .extent([
              [0, 0],
              [width, height],
            ])
            .on("start", updateStart)
            .on("end", (e) => {
              this.$store.dispatch("rect/zoomEnd", {
                event: e,
                widgetId: this.id,
                displayId: this.displayId,
                widgetIndex: this.widgetIndex,
                trendIndex: this.trendIndex,
              });
            });
          svg.call(brushZoom1);
        }

        let x_functions_for_each_curvename = {};
        for (let k = 0; k < curve_names.length; k++) {
          if (!this.data[curve_names[k]]) {
            this.isRerendering = false;
            continue;
          }
          if (curve_names[k] == "alert") {
          } else if (curve_names[k] == "activitycode") {
          } else {
            //// code that creates a line for each Curve in order provided and applies
            //// the color in the color array in order provided
            let curveUnit = "";
            if (curve_units[k]) {
              curveUnit = curve_units[k];
            }
            let min = mins[k];
            let max = maxes[k];
            let header_text_line = "";
            if (min != null && max != null) {
              header_text_line =
                parseFloat(min).toFixed(1) +
                " - " +
                curve_names[k] +
                "  " +
                curveUnit +
                " - " +
                parseFloat(max).toFixed(1);
            }
            let curveScale = curveScales[k];
            let min_this = "";
            let max_this = "";
            let scaleType = curveScaleType[k];
            if (
              scaleType == "manual" &&
              (curveScale != null || curveScale != undefined)
            ) {
              if (curveScale[0] != null || curveScale[0] != "") {
                min_this = curveScale[0];
              } else {
                min_this = this.minmax[curve_names[k]].min;
                curveScale[0] = min_this;
              }
              if (curveScale[1] != null || curveScale[1] != "") {
                max_this = curveScale[1];
              } else {
                max_this = this.minmax[curve_names[k]].max;
                curveScale[1] = max_this;
              }
            } else {
              let currentRange = defaultRanges.ranges.find(
                (data) => data.name == curve_names[k]
              );
              if (currentRange) {
                max_this = currentRange.high;
                min_this = currentRange.low;
              } else {
                min_this = d3.min(this.data[curve_names[k]]);
                max_this = d3.max(this.data[curve_names[k]]);
              }
              curveScale[0] = min_this;
              curveScale[1] = max_this;
              let foundObj = this.left_bar_tracks[index]?.subtracks?.find(
                (eachCurve) => eachCurve.sourceMnemonic == curve_names[k]
              );
              if (foundObj) {
                let descrptn = foundObj.pnrgDescription;
                if (
                  descrptn.replace(/\s/g, "").toLowerCase().includes("bitsize")
                ) {
                  curveScale[0] = 0;
                  curveScale[1] = 30;
                } else if (
                  descrptn.replace(/\s/g, "").toLowerCase().includes("gamma")
                ) {
                  curveScale[0] = 0;
                  curveScale[1] = 200;
                } else if (
                  descrptn.replace(/\s/g, "").toLowerCase().includes("caliper")
                ) {
                  curveScale[0] = 0;
                  curveScale[1] = 30;
                } else if (
                  descrptn
                    .replace(/\s/g, "")
                    .toLowerCase()
                    .includes("spontaneous")
                ) {
                  curveScale[0] = -160;
                  curveScale[1] = 40;
                } else if (
                  descrptn
                    .replace(/\s/g, "")
                    .toLowerCase()
                    .includes("resistivity")
                ) {
                  curveScale[0] = 0.2;
                  curveScale[1] = 2000;
                } else if (
                  descrptn.replace(/\s/g, "").toLowerCase().includes("neutron")
                ) {
                  curveScale[0] = 45;
                  curveScale[1] = -15;
                }
                // else if(descrptn.replace(/\s/g, '').toLowerCase().includes('neutronporosity')){
                //    curveScale[0] = 45 ;
                //    curveScale[1] = -15;
                // }
                else if (
                  descrptn
                    .replace(/\s/g, "")
                    .toLowerCase()
                    .includes("bulkdensity")
                ) {
                  curveScale[0] = 1.65;
                  curveScale[1] = 2.9;
                } else if (
                  descrptn.replace(/\s/g, "").toLowerCase().includes("sonic")
                ) {
                  curveScale[0] = 2000;
                  curveScale[1] = 40;
                } else if (
                  descrptn
                    .replace(/\s/g, "")
                    .toLowerCase()
                    .includes("mudweight")
                ) {
                  curveScale[0] = 8;
                  curveScale[1] = 18;
                }
                else{
                  curveScale[0] = 0;
                }
                min_this = curveScale[0];
                max_this = curveScale[1];
              }
               for (let key in this.curveProps){
                  let trackIndex = key.split('-')[0]
                  if(trackIndex == index && this.curveProps[key]?.curve?.name == curve_names[k] && this.curveProps[key]?.curve?.scaleType == 'manual'){
                    curveScale[0] = this.curveProps[key]?.curve?.scale[0];
                    curveScale[1] = this.curveProps[key]?.curve?.scale[1];
                    curve_colors[k] = this.curveProps[key]?.curve?.color
                    min_this = curveScale[0];
                    max_this = curveScale[1];
                  }
               }
            }
            let x;
            if (
              this.left_bar_tracks[index].trackScale == "logarithmic" &&
              this.left_bar_tracks[index].logarithmicCycleScale
            ) {
              let logarithmic_cycle_scale = null;
              if (this.left_bar_tracks[index].logarithmicCycleScale == 2) {
                logarithmic_cycle_scale = 20;
                curveScale[0] = 0.2;
                curveScale[1] = 20;
              } else if (
                this.left_bar_tracks[index].logarithmicCycleScale == 3
              ) {
                logarithmic_cycle_scale = 200;
                curveScale[0] = 0.2;
                curveScale[1] = 200;
              } else if (
                this.left_bar_tracks[index].logarithmicCycleScale == 4
              ) {
                logarithmic_cycle_scale = 2000;
                curveScale[0] = 0.2;
                curveScale[1] = 2000;
              }
              x = d3
                .scaleLog()
                .domain([0.2, logarithmic_cycle_scale])
                // .domain([0.1, 1000])
                .range([margin.left, width - margin.right]);
            } else {
              x = d3
                .scaleLinear()
                .domain([min_this, max_this])
                .range([margin.left, width - margin.right]);
            }
            // let x = d3
            //   .scaleLinear()
            //   .domain([min_this, max_this])
            //   .range([margin.left, width - margin.right]);
            // .nice();
            if (scale_linear_log_or_yours == "log") {
              x = d3
                .scaleLog()
                .domain([min_all_curves, max_all_curves])
                // .domain([min_this, max_this])

                .range([margin.left, width - margin.right]);
              // .nice();
            } else if (scale_linear_log_or_yours == "linear") {
            } else if (typeof scale_linear_log_or_yours !== "string") {
              x = scale_linear_log_or_yours["yours"];
            }
            if (k == 0) {
              x_func == x;
            }
            //// This creates an object to hold multiple x axis scale functions
            //// that will be used if 'between' style fill is selected.
            x_functions_for_each_curvename[curve_names[k]] = x;
            //////////////  Header text, two way depending on  =>//////////////

            let recent_point_x = 1;
            let another_line = function (x_arr, y_arr, trackScaleType) {
              return d3
                .line()
                .x(function (d, i) {
                  // if (x_arr[i]) recent_point_x = x_arr[i];
                  if (trackScaleType == "logarithmic") {
                    if (x_arr[i] <= 0) {
                      return x(0.2)
                    }
                  }
                  return x_arr[i] || x_arr[i] == 0 ? x(x_arr[i]) : x(recent_point_x);
                })
                .y(function (d, i) {
                  return y_arr[i] ? y(y_arr[i]) : y(0);
                })(Array(x_arr ? x_arr.length : 0));
            };

            let defs = svg.append("defs");

            let gradient = defs.append("linearGradient").attr("id", "gradient");

            gradient
              .append("stop")
              .attr("offset", "0%")
              .attr("stop-color", "red");
            gradient
              .append("stop")
              .attr("offset", "100%")
              .attr("stop-color", "green");
            svg
              .append("path")
              // .datum(data['DEPTH'])
              .attr("fill", "none")
              .attr("stroke", curve_colors[k])
              .attr("stroke-width", template_curves["stroke_width"][k])
              .attr("class", "area")
              .attr(
                "d",
                another_line(
                  data[curve_names[k]],
                  data["DEPTH"],
                  this.left_bar_tracks[index].trackScale
                )
              );

            // if (index == 0 && curve_names.length - 1 == k) {
            if (curve_names.length - 1 == k) {
              function wrap(text, width) {
                text.each(function (d) {
                  // this.bbox = this.getBBox();
                  // console.log("🚀 ➽ file: DisTrend.vue:3982  ➽ d.bbox ⏩" , d.bbox)
                  let text = d3.select(this),
                    words = text.text().split(/\s+/).reverse(),
                    word,
                    line = [],
                    lineNumber = 0,
                    lineHeight = 1, // ems
                    x = text.attr("x"),
                    y = text.attr("y"),
                    dy = 0, //parseFloat(text.attr("dy")),
                    tspan = text
                      .text(null)
                      .append("tspan")
                      .attr("class", "comment__style")
                      .attr("x", x)
                      .attr("y", y)
                      .attr("dy", dy + "em");

                  while ((word = words.pop())) {
                    line.push(word);
                    tspan.text(line.join(" "));

                    if (tspan.node().getComputedTextLength() > width) {
                      line.pop();
                      tspan.text(line.join(" "));
                      line = [word];
                      tspan = text
                        .append("tspan")

                        .attr("x", x)
                        .attr("y", y)
                        .attr("dy", ++lineNumber * lineHeight + dy + "em")
                        .attr("class", "comment__style")
                        .text(word);
                    }
                  }
                });
              }
              if (Object.keys(data).includes(`comment-${index}`) && data[`comment-${index}`].length) {
                let depth_data_arr = [];
                let alerts = data[`comment-${index}`].filter((each, currentIndx) => {
                  if (each !== null && each !== '') {
                    depth_data_arr.push(data["DEPTH"][currentIndx]);
                    return each;
                  }
                });
                // console.log('depth_data_arr', depth_data_arr, alerts)
                let y = d3
                  .scaleLinear()
                  .domain([this.scaleMax, this.scaleMin])
                  .range([height_components, 0]);
                alerts.map((text, alertindx) => {
                  // svg
                  //   .append('rect')
                  //   .attr('x', width/2 - margin.right) // Adjust x-coordinate spacing as needed
                  //   .attr('y', y(depth_data_arr[alertindx]) - 14) // Set the y-coordinate for the background
                  //   .attr('width', width/2 - margin.right) // Adjust the width of the background
                  //   .attr('height', 20) // Adjust the height of the background
                  //   .attr('fill', '#fff')
                  //   .attr('stroke', 'red')
                  //   .attr('rx', 5) // Set horizontal border-radius
                  //   .attr('ry', 5); // Set vertical border-radius
                  svg
                    .append("text")
                    .attr("x", 5) // Adjust x-coordinate spacing as needed
                    .attr("y", y(depth_data_arr[alertindx])) // Set the y-coordinate based on the 'y' array
                    .style("font-size", "0.9rem")
                    .style("font-weight", "500")
                    // .style("background", "red")
                    // .style("color", "white")
                    .style("stroke", "red")
                    .style("fill", "black")
                    .attr("text-anchor", "start")
                    .text(text)
                    .call(wrap, width - 10);
                  // textWarp.call(wrap, width - 15);
                });
              }
            }
          }
        }

        if (!this.isZoom && !(curve_names.indexOf("alert") > -1)) {
          //// appends start of mouseover rectange used for showing hover content
          let focus = svg.append("g").style("opacity", 0);

          focus
            .append("rect")
            .attr("class", "background")
            // .attr("fill", tooltipbg)
            .attr("fill", "#fff")
            .attr("height", 130)
            .attr("width", 95)
            .attr("rx", 8)
            // .transition().duration(1000).style("opacity", 1)
            // .attr("opacity", 1);
            .attr("opacity", 0.9);
          focus
            .append("line")
            .attr("class", "yl")
            // .style("stroke", this.darkDark == true ? "white" : "black")
            .style("stroke", "black")
            .style("stroke-array", "10")
            .style("opacity", 1)
            .attr("x1", 0)
            .attr("x2", height);
          if (
            mouseover_depth_or_depth_and_curve == "depth" ||
            mouseover_depth_or_depth_and_curve == "depth_and_curve"
          ) {
            focus
              .append("text")
              .attr("class", "y2")
              .attr("dx", 6)
              .attr("dy", "-.3em")
              .style("font-size", "12px")
              .style("z-index", 999999999)
              // .style("stroke", this.darkDark == true ? tooltipbg : "black");
              .style("stroke", "black");
          }
          //// curve value on hover

          if (
            mouseover_depth_or_depth_and_curve == "curve" ||
            mouseover_depth_or_depth_and_curve == "depth_and_curve"
          ) {
            for (let i = 0; i < curve_colors.length; i++) {
              focus
                .append("text")
                .attr("class", `data${i + 1}`)
                .attr("dx", 1)
                .attr("dy", `${0.5 * (i + 1)}cm`)
                .style("font-size", "12px")
                .style("fill", "black")
                .style("z-index", "black")
                .style("stroke", curve_colors[i])
                .style("stroke-width", "0.5px");
            }
          }
          // append the rectangle to capture mouse               // **********

          svg
            .append("rect") // **********
            .attr("width", width) // **********
            .style("height", height_components + "px")
            .attr("height", 10) // **********
            .attr("height", height) // **********
            .attr("id", "rect" + div_id)
            .style("fill", "none") // **********
            .style("pointer-events", "all") // **********

            .on("mouseover", (e) => {
              if (this.isShowTooltip) this.mouseover(focus);
            })
            .on("mouseout", (e) => {
              // if (this.isShowTooltip) this.mouseout(e);
            })
            .on("mousemove", (e) => {
              if (this.isShowTooltip) this.mousemove(e);
            })
            .on("mousewheel", (e) => {
              this.mousescroll(e);
            });

          // **********
          // }

          this.focusAxisList[index] = {
            focus,
            x,
            y,
            curve_colors,
            curve_names,
            mouseover_curvename,
            depth_curve_name,
            width,
            height,
          };
        }

        //////////////  Horizontal Lines AKA tops =>//////////////

        try {
          for (let i = 0; i < template_lines.length; i++) {
            let this_line = template_lines[i];
            svg
              .append("line")
              .attr("x1", 0 + margin.left)
              .attr("y1", y(this_line["depth"]))
              .attr("x2", width * 0.75)
              .attr("y2", y(this_line["depth"]))
              .style("stroke-width", this_line["stroke_width"])
              .style("stroke", this_line["color"])
              // .style("stroke", function (d, i) {
              //   if (d[curve_names[k]] < 0) return "white";
              //   if (d[curve_names[k]] < 50) return "red";
              //   if (d[curve_names[k]] < 150) return "green";
              // })
              .style("stroke-dasharray", this_line["stroke-dasharray"])
              .style("stroke-linecap", this_line["stroke_linecap"])
              .style("fill", "none");

            svg
              .append("text")
              .attr("x", width * 0.75)
              .attr("y", y(this_line["depth"]))
              .attr("text-anchor", "start")
              .style("font-size", "12px")
              .text(this_line["label"]);
          }
        } catch (err) {
          console.error(
            "could not do lines for tops in curveBox function, error: ",
            err
          );
        }
        if (this.plots.length - 1 == index) {
          this.isRerendering = false;
        }
      } catch (error) {
        this.isRerendering = false;
        console.error("error in create curve box : ", error);
        return true;
      }
    },
    getConfig() {
      let tconfig = {
        height: "500",
        width: "260",
        UWI: "00/01-01-095-19W4/0",
        curveName: "CHALKTALK",
        tracks: this.trackConfig.tracks,
        curveType: "DEPTH",
        depthName: "DEPTH",
      };

      this.trackConfig = tconfig;

      return tconfig;
    },
    configure() {
      let UWI = this.trackConfig.UWI;
      // let curveNames = curveNames;
      // let curveColors=curveColors;
      let curveName = this.trackConfig.curveName;
      let _plots = [];
      let tracks_temp = this.trackConfig.tracks;
      if (tracks_temp.length) {
        this.setTrackWidth(tracks_temp.length);
      }
      for (let i = 0; i < tracks_temp.length; i++) {
        let track = tracks_temp[i];
        let curveNames = track.map((curve) => curve.name);
        let curveColors = track.map((curve, k) =>
          curve.color ? curve.color : this.defaultColor[i][k]
        );
        // let fill = track.map((curve)=>curve.fill)

        // let fillColor = track.map((curve) => curve.fillC);
        let fillDir = track.map((curve) => curve.fillD);
        let fill = track.map((curve) => curve.fill);
        let strokes = track.map((curve) => curve.stroke);
        let units = track.map((curve) => {
          // return this.$store.state.disp.displays[this.displayId].units[
          //   this.$store.state.disp.displays[this.displayId].tags.indexOf(
          //     curve.name
          //   )
          // ];
          return "unit";
        });
        let scale = track.map((curve) => curve.scale);
        let scaleType = track.map((curve) => curve.scaleType);
        let trackId;
        if (track.length > 0) {
          trackId = track[0].trackId;
        }

        let trackObjs = [];
        for (let j = 0; j < track.length; j++) {
          let configObj = {
            curve_name: "",
            fill: "",
            fill_direction: "",
            cutoffs: [],
            fill_colors: [],
          };
          configObj.curve_name = track[j].name;
          configObj.fill_direction = track[j].fillD;
          configObj.fill = track[j].fill;
          configObj.cutoffs = [0, 4.25];
          // configObj.fill_colors = [track[j].fillC];
          // configObj.color = this.defaultColor[i][j]
          // track[j].color = this.defaultColor[i][j]
          configObj.color = track[j].color
            ? track[j].color
            : this.defaultColor[i][j];
          track[j].color = track[j].color
            ? track[j].color
            : this.defaultColor[i][j];
          trackObjs.push(configObj);
          trackObjs = trackObjs;
        }

        // if (this.singleTrackWidth > this.trackWidth) {
        //   this.singleTrackWidth = this.trackWidth;
        // }

        let graphType = "DEPTH";
        let plot = plotConfig(
          this.plotTemplate,
          UWI,
          curveNames,
          curveColors,
          units,
          strokes,
          scale,
          scaleType,
          trackObjs,
          this.div_id,
          this.singleTrackWidth,
          this.singleTrackHeight,
          graphType,
          this.tickColor,
          this.tickThickness,
          leftbarbg,
          tooltipbg,
          trackId
        );

        _plots.push(plot);
      }

      this.plots = _plots;
      return this.plots;
    },

    async getItFromLocalData(range, isLogChange) {
      this.$store.dispatch("data/startLoading");
      let graphType = "DEPTH";

      if (this.completeData[graphType]) {
        this.completeData[graphType].map((row, i) => {
          if (
            row == range[0] ||
            (row < range[0] && !(row > range[0])) ||
            (range[0] == 0 && i == 0)
          ) {
            this.lowerIndex = i;
          }

          if (row == range[1] || row < range[1]) {
            this.upperIndex = i;
          }
        });
        let tempData = { ...this.completeData };

        for (let i in tempData) {
          this.data[i] = tempData[i].slice(
            this.lowerIndex,
            this.upperIndex + 1
          );
        }
      } else {
        // this.data = this.completeData;
      }
      this.$store.dispatch("data/stopLoading");
    },

    async zoomIt(depthMax, depthMin, feet, isLogChange, from) {
      this.templateWidth = this.width;
      this.setTrackWidth(this.trackConfig.tracks.length);
      // console.log("🚀 ➽ file: CorrelationTrend.vue:4223  ➽ zoomIt  ➽ this.trackConfig.tracks.length ⏩", from, this.width, this.trackConfig.tracks.length)
      if (this.zoomItCalled) {
        return true;
      }
      this.zoomItCalled = true;
      let totalRows;
      let depthRange;

      if (depthMax > depthMin) {
        totalRows = Math.round(depthMax - depthMin);
        depthRange = [depthMin, depthMax];
      } else {
        totalRows = Math.round(depthMin - depthMax);
        depthRange = [depthMax, depthMin];
      }

      await this.getItFromLocalData(depthRange, isLogChange);
      // this.calGraphHeight(totalRows, feet);
      // if (false) {
      // if (this.liveDisabled && this.stopUpdation) {
      //   if (this.timer > 10000) {
      //     this.rerender("zoomIt");
      //     this.stopUpdation = false;
      //   }
      // } else {
      await this.rerender("zoomIt");
      // }
      this.zoomItCalled = false;
    },

    async rerender(from) {
      await this.$store.dispatch("data/startLoading");
      if (!this.trackConfig && !this.trackConfig.tracks) await this.getConfig();

      if (this.trackConfig.tracks && this.trackConfig.tracks.length) {
        this.tracksNbr = this.trackConfig.tracks.length;
        if (this.tracksNbr > 0) {
          // if (this.trackConfig.tracks[0][0].name != null) {
          this.readyToPlot = true;
          this.emptyPlot = false;

          this.configurePlots = await this.configure();

          if (this.plots) this.verticalplots(this.div_id, this.plots, true);

          let sliderId = "slider" + this.compId;
          let sliderEle = document.querySelector("#" + sliderId);
          if (sliderEle != null) {
            sliderEle.style.height = this.singleTrackHeight - 60 + "px";
            // }
            // this.setTrackInfo();
          }
        }
      } else {
      }
      await this.$store.dispatch("data/stopLoading");
    },


    changeTooltip() {
      this.isShowTooltip = !this.isShowTooltip;
      if(!this.isShowTooltip){
        this.mouseout();
      }
      // if(this.isZoom = true){
      //   this.isShowTooltip = false
      // }
    },

    changeZoom() {
      this.isZoom = !this.isZoom;
    },
  },
  watch: {
    curveProps:{
      handler(val){
        this.$emit('getting_curveProps', val)
      },
      deep: true
    },
    selected_files(val){
      this.track_wise_mnemonic_colors = [];
    },
    // hide_tooltip_zoom(val){
    //   if(val){
    //     this.singleTrackHeight += this.tooltip_zoom_height;
    //   }
    //   else{
    //     this.singleTrackHeight -= this.tooltip_zoom_height
    //   }
    //   this.rerender();
    // },
    async rangeValue(val) {
      if(this.initially_assinging_range){
        return;
      }
      if (this.clear_range_timeout) {
        clearTimeout(this.clear_range_timeout);
      }
      // if(this.rangeMinValue != 0 && this.rangeMaxValue != 0){
      if(this.rangeMaxValue != 0){
        this.clear_range_timeout = setTimeout(async () => {
          if(this.rangeMinValue == 0 && this.rangeMaxValue == 0){
             return;
          }
          let value = parseFloat(val);
          if((this.selectedType == 'witsml' || this.selectedType == 'wits0') && this.scaleMin > value){
            this.$emit("background_live");
          }
          this.scaleMin = value;
          if (this.total_leftbar_data?.selected_unit == "IMPERIAL") {
            if (this.scaleMax_Prop == 1200) {
              this.scaleMax = value + 500; // 5 Boxes * 100 each box = 500
            } else if (this.scaleMax_Prop == 600) {
              this.scaleMax = value + 250; // 5 Boxes * 50 each box = 250
            } else if (this.scaleMax_Prop == 240) {
              this.scaleMax = value + 100; // 5 Boxes * 20 each box = 100
            }
          } else if (this.total_leftbar_data?.selected_unit == "METRIC") {
            if (this.scaleMax_Prop == 200) {
              this.scaleMax = value + 50; // 5 Boxes * 10 each box = 50
            } else if (this.scaleMax_Prop == 500) {
              this.scaleMax = value + 125; // 5 Boxes * 25 each box = 125
            }
          }
          await this.rerender().then(() => {
            clearTimeout(this.clear_range_timeout);
          });
        }, 10);
      }
    },
    async data_points(val, oldVal) {
      if(this.selectedType != 'witsml' && this.selectedType != 'wits0' && JSON.stringify(val) == JSON.stringify(oldVal)){
        return;
      }
      await this.setting_max_scale(this.scaleMax_Prop);
    },
    async scaleMax_Prop(val) {
      await this.setting_max_scale(val);
    },
    async scaleMaxProp(val) {
      await this.setting_max_scale(this.scaleMax_Prop, val.scaleMax);
    },
    async selectedSavedRun(val, oldVal){
      this.curveProps = {};
      if(JSON.stringify(val) == JSON.stringify(oldVal)){
        return
      }
      if(val !== null && Object.keys(val).length && !val?.is_default){
        this.$store.dispatch("data/startLoading");
        setTimeout(async()=>{
          await this.setting_ranges_according_to_saved_file(val);
          await this.$store.dispatch("data/stopLoading");
        },0)
      }
      else{
        await this.setting_max_scale(this.scaleMax_Prop)
      }
    },
    left_bar_tracks: {
      async handler(val) {
        // SETTING TRACKS
        this.trackConfig.tracks = [];
        this.mnemonics_arr = {};
        this.filtered_mnemics_data = {};
        if (!val.length) {
          this.hide_mnemonics_div = true;
          return;
        } else {
          this.hide_mnemonics_div = false;
        }
        let one_track_had_data_show_mnemonics = false;
        let eachSubtrackLength = [];
        val.map(async (each, ind) => {
          eachSubtrackLength.push(each.subtracks.length);
          if (each.subtracks.length) {
            one_track_had_data_show_mnemonics = true;
            let arr = [];
            this.hide_mnemonics_div = false;
            each.subtracks.map((obj, mnemonicIndex) => {
              let data_obj = {
                ...obj,
                name: obj.sourceMnemonic,
                fullName: obj.sourceMnemonic,
                description: obj.pnrgDescription,
                unit: obj.pnrgUnit,
                color: "",
                trackNbr: 0,
                curveNbr: 0,
                trackId: "t0gihl",
                min: 51.41224,
                max: 52.6431,
                scale: [51.41224, 52.6431],
                scaleType: "auto",
                stroke: 2,
                lwdTrackScale: this.left_bar_tracks[ind].trackScale,
              };
              // arr.push(data_obj);
              let obj_with_curveprops = {
                ...data_obj
              }
              if(obj?.scaleType == 'manual'){
                obj_with_curveprops.scaleType = obj.scaleType
                if(obj?.scale){
                  obj_with_curveprops.scale = obj.scale
                }
                if(obj?.color){
                  obj_with_curveprops.color = obj.color
                }
                let cProps = {};
                cProps["show"] = false;
                cProps["curve"] = obj_with_curveprops;

                // IF HAD EDITED NAME THEN SHOWING EDITED NAME CODE STARTS
                if(Object.keys(this.curveProps).includes(ind + "-" + mnemonicIndex) && 
                  obj_with_curveprops.name == this.curveProps[ind + "-" + mnemonicIndex]["curve"]?.name && 
                  this.curveProps[ind + "-" + mnemonicIndex]["curve"]?.editedName
                ){
                   cProps["curve"]["editedName"] = this.curveProps[ind + "-" + mnemonicIndex]["curve"]?.editedName
                }
                // IF HAD EDITED NAME THEN SHOWING EDITED NAME CODE ENDS

                this.curveProps[ind + "-" + mnemonicIndex] = JSON.parse(JSON.stringify(cProps));
              }
              arr.push(obj_with_curveprops);

            });
            this.trackConfig.tracks.push(arr);
          } else if (
            val.length - 1 == ind &&
            !one_track_had_data_show_mnemonics
          ) {
            this.trackConfig.tracks = this.default_tracks_prop.slice(
              0,
              this.number_of_tracks
            );
            this.hide_mnemonics_div = true;
          } else {
            this.trackConfig.tracks.push([
              {
                hide: true,
                name: "zzz",
                fullName: "zzz",
                color: "#c530ab",
                trackNbr: 0,
                curveNbr: 0,
                trackId: "t0gihl",
                min: 51.41224,
                max: 52.6431,
                scale: [51.41224, 52.6431],
                scaleType: "auto",
                stroke: 2,
              },
            ]);
          }
        });
        if (eachSubtrackLength.length) {
          this.bheight = this.mnemonics_height * Math.max(...eachSubtrackLength) + 2;
          this.print_bheight = this.print_mnemonics_height * Math.max(...eachSubtrackLength) + 2;
        } else {
          this.bheight = 0;
        }
        this.setTrackHeight();

        // SETTING AND DELETING COLOR TO MNEMONIC CODE STARTS
        this.trackConfig.tracks.forEach((everyTrack, trackInx)=>{
          if(this.track_wise_mnemonic_colors.length && this.track_wise_mnemonic_colors.length > trackInx){
            let copy_of_track_colors = JSON.parse(JSON.stringify(this.track_wise_mnemonic_colors[trackInx]))
            this.track_wise_mnemonic_colors[trackInx] = [];
            copy_of_track_colors.map((storedMnemonicWsieColor, idnx)=>{
              let hadRecordInTrack = everyTrack.some((mnemon)=> mnemon.name == storedMnemonicWsieColor.name)
              if(hadRecordInTrack){
                this.track_wise_mnemonic_colors[trackInx].push(storedMnemonicWsieColor);
              }
            })
          }
          everyTrack.forEach((eachMnemonic, mnemonicInx)=>{
            if(this.track_wise_mnemonic_colors.length && this.track_wise_mnemonic_colors.length > trackInx){
              let foundMnemonicColor = this.track_wise_mnemonic_colors[trackInx].find((toFindMnemonic)=> eachMnemonic.name == toFindMnemonic?.name)
              if(foundMnemonicColor){
                eachMnemonic.color = foundMnemonicColor.color
              }
              else{
              
                // SETTING COLOR ACCORDING TO MNEMONIC CODE STARTS
                if(eachMnemonic?.description){
                  if(eachMnemonic?.description.replace(/\s/g, "").toLowerCase().includes('density') &&
                   eachMnemonic?.unit && eachMnemonic?.unit.replace(/\s/g, "").toLowerCase() == 'g/cc'){
                    let color = '#ff0000'  // RED
                    let obj = {name: eachMnemonic.name, color: color}
                    this.track_wise_mnemonic_colors[trackInx].push(obj);
                    eachMnemonic.color = color;
                    return eachMnemonic;
                  }
                  else if(eachMnemonic?.description.replace(/\s/g, "").toLowerCase().includes("neutron")){
                    let color = '#0000ff' // BLUE
                    let obj = {name: eachMnemonic.name, color: color}
                    this.track_wise_mnemonic_colors[trackInx].push(obj);
                    eachMnemonic.color = color;
                    return eachMnemonic;
                  }
                  else if(trackInx == 0 && eachMnemonic?.description.replace(/\s/g, "").toLowerCase().includes("caliper")){
                    let color = '#0000ff' // BLUE
                    let obj = {name: eachMnemonic.name, color: color}
                    this.track_wise_mnemonic_colors[trackInx].push(obj);
                    eachMnemonic.color = color;
                    return eachMnemonic;
                  }
                  else if(eachMnemonic?.description.replace(/\s/g, "").toLowerCase().includes("bitsize")){
                    let color = '#000000' // BLACK
                    let obj = {name: eachMnemonic.name, color: color}
                    this.track_wise_mnemonic_colors[trackInx].push(obj);
                    eachMnemonic.color = color;
                    return eachMnemonic;
                  }
                  else if(eachMnemonic?.description.replace(/\s/g, "").toLowerCase().includes("gamma")){
                    let color = '#008000' // GREEN
                    let obj = {name: eachMnemonic.name, color: color}
                    this.track_wise_mnemonic_colors[trackInx].push(obj);
                    eachMnemonic.color = color;
                    return eachMnemonic;
                  }
                }
                // SETTING COLOR ACCORDING TO MNEMONIC CODE ENDS


                let color = this.defaultColor[trackInx][mnemonicInx]
                let alreadyHadColor = this.track_wise_mnemonic_colors[trackInx].some((mnemonicWithColor)=> mnemonicWithColor?.color == color);
                if(!alreadyHadColor){
                  let obj = {name: eachMnemonic.name, color: color}
                  this.track_wise_mnemonic_colors[trackInx].push(obj);
                  eachMnemonic.color = color
                }
                else{
                  for(let clr = 0; clr < this.defaultColor[trackInx].length; clr++){
                    let alreadyHadColor = this.track_wise_mnemonic_colors[trackInx].some((mnemonicWithColor)=> mnemonicWithColor.color == this.defaultColor[trackInx][clr]);
                    if(!alreadyHadColor){
                      let color = this.defaultColor[trackInx][clr]
                      let obj = {name: eachMnemonic.name, color: color}
                      this.track_wise_mnemonic_colors[trackInx].push(obj);
                      eachMnemonic.color = color;
                      break;
                    }
                  }
                }
              }
            }
            else{
              let arr = [];
              let color = this.defaultColor[trackInx][mnemonicInx]
              arr.push({name: eachMnemonic.name, color: color})
              this.track_wise_mnemonic_colors.push(arr);
              eachMnemonic.color = color
            }
            return eachMnemonic
          })
          return everyTrack;
        })
        // SETTING AND DELETING COLOR TO MNEMONIC CODE ENDS

        if(Object.keys(this.curveProps).length){
          for (let key in this.curveProps){
            let trackIndex = key.split('-')[0]
            if(this.trackConfig.tracks.length > trackIndex){
              let hadCurveInTrack = this.trackConfig.tracks[trackIndex].some((eachMnemonic)=> eachMnemonic.name == this.curveProps[key]?.curve?.name)
              if(!hadCurveInTrack){
                delete this.curveProps[key];
              }
              else{
                // TO APPLY CHANGED COLOR
                if(this.curveProps[key]?.curve?.scaleType == 'manual'){
                  let ind = this.trackConfig.tracks[trackIndex].findIndex((mnem)=> mnem.name == this.curveProps[key]?.curve?.name)
                  if(ind >= 0){
                    this.trackConfig.tracks[trackIndex][ind].color =  this.curveProps[key]?.curve?.color;
                    if(Object.keys(this.curveProps[key]?.curve).includes('editedName') && this.curveProps[key]?.curve?.editedName !== this.trackConfig.tracks[trackIndex][ind].description) {
                      this.trackConfig.tracks[trackIndex][ind]["editedName"] = this.curveProps[key]?.curve?.editedName;
                    }
                  }
                }
              }
            }
            else{
                delete this.curveProps[key];
            }
          }
        }
      },
      deep: true,
    },
    async number_of_tracks(val) {
      if (val === null) {
        this.hide_mnemonics_div = true;
      } else {
        this.hide_mnemonics_div = false;
      }
    },
    width(newValue) {
      this.zoomIt(
        this.scaleMax,
        this.scaleMin,
        this.feet,
        false,
        "watch width"
      );
    },
    height(newValue) {
      this.rerender("height");
    },
    async wellDetails(newValue) {
      try {
        let well_depth =
          newValue["wellbores"][newValue.well_bore_name]["logs"]["depth"];
        this.wellId = newValue.well_id;
        this.wellboreId = newValue.well_bore_name;
        this.topic = well_depth.topicname;
      } catch (error) {}
    },
    plotConfigProps(newValue) {
      console.info("new config came ", newValue);
      if (newValue.option == "wellRelationOptimization") {
        this.trackConfig = newValue;
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "config change"
        );
      }
    },
  },
};

let trackNbr = 0;

let tooltipbg = "white";

let leftbarbg = "#202124";

let currentCurve = {};

let curvesMinsMaxes = [];
</script>
  
<style scoped>

.area {
  fill: lightsteelblue;
}

.td_trnk {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc((0.3em + 0.3vmin) + (0.2em + 0.2vmax));
}

.text_gray {
  color: #dee2e6;
}

.text_dark {
  font-weight: 600;
}

.tooltip__tip {
  text-align: center;
}

.tooltip__tip::after {
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
  color: white;
}

.tooltip__tip::before {
  content: " ";
  display: none;
  position: absolute;
  width: 7px;
  height: 7px;
  z-index: 999;
}

.tooltip__tip_dark::after {
  background: #1b1a1f !important;
  color: #ffffff;
}

.tooltip__tip_dark::before {
  background: #1b1a1f !important;
}

.tooltip__tip_light::after {
  background: #5dfce4 !important;
  color: #000000 !important;
}

.tooltip__tip_light::before {
  background: #fdffe3 !important;
}

.tooltip__tip:hover::after {
  display: block;
}

.tooltip__tip:hover::before {
  display: block;
}

.tooltip__tip.top::after {
  content: attr(data-tip);
  top: 7px;
  left: 50%;
  width: auto;
  font-size: 12px;
  padding: 3px 14px;
  transform: translate(-50%, calc(-100% - 10px));
  border-radius: 0px !important;
}

.tooltip__tip.top::before {
  top: 5px;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}


.selectedBtn_tooltip {
  width: 100%;
}


.light_trend-mode {
  /* background: #fdffe3 !important; */
  background: var(--widgetBg) !important;

  /* #fcffe2; */
  /* #fff8e4; */
  /* #ffffe7; */
  /* #fcfbda; */
  /* #fffff0 */
}

.light_trend-mode {
  /* background: #fdffe3 !important; */
}
.grid {
  stroke: 10;
}

.tool_toggle_input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.tool_toggle_label {
  cursor: pointer;
  text-indent: -9999px;
  width: 23px;
  height: 12px;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tool_toggle_label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 10px;
  height: 10px;
  background: var(--navBar2Bg);
  border-radius: 90px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.tool_toggle_label {
  /* background: #5aba81; */
  background: var(--toggleSliderBg);
}

.tool_toggle_input:checked + .tool_toggle_label:after {
  /* left: calc(100% - 2px); */
  -webkit-transform: translateX(9px);
  transform: translateX(9px);
}

.tool_toggle_label:active:after {
  /* width: 130px; */
}

.tool_toggle_input:checked + ._tooltip {
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._alert {
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._zoom {
  background: var(--activeTextColor);
}

.muted_bg {
  background: #3c3a3a;
}

.muted_bg:after {
  background: #616161;
}

.tool_section {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 4px;
}


.dv {
  fill: lightblue;
  font-weight: 700;
  letter-spacing: 0.1px;
}

.ddv {
  fill: #32e73f;
  font-weight: 700;
  letter-spacing: 0.1px;
}


[type="checkbox"],
[type="radio"] {
  content: "";
  background-color: #28282f !important;
  background-color: unset !important;
  margin-left: auto;
  border-radius: 0;
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  border: 1px solid #5aba81 !important;
  /* background-image: url(/img/check_ico.406e337a.svg); */
}


.disabled {
  pointer-events: none;
  cursor: default;
  display: inline-block;
  padding: 4px;
  background-color: #b6adb4;
  border-radius: 2px;
  border: 0px;
  text-align: center;
  /* color: #fff !important; */
  text-decoration: none !important;
  opacity: 0.5;
  z-index: -1;
}

.track_config_border {
  border-bottom: 2px solid #000;
}

.track_config_border:last-child {
  border-bottom: 0px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


.name_numaric_con {
  width: 100%;
  background: var(--sidebarListBgColor);
}

.dark_trend_mode {
  background: var(--widgetBg);
}


.colorBox_text_gray {
  color: var(--colorBoxHeadingText);
  font-size: 0.55rem;
  margin-top: 0.07rem;
}

.arrangementClass {
  /* display: flex;
    flex-direction: column-reverse; */
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
}


.settingPopColor {
  color: var(--textColor);
  font-weight: 600;
}

.__toggle__boxes {
  height: 20px;
  /* margin-top: 5px; */
  /* padding-left: 10px; */
  padding-right: 5px;
}

.__label_boxes {
  margin-top: auto;
}

.__trend_bg_seperation {
  border: solid 0.5px;
  border-color: dimgray;
  background: var(--sidebarbg);
}

.trackHeading {
  padding-bottom: 10px;
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
}

.mnemonics_container {
  /* height: calc(100% - 25px); */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  border: solid #000;
  border-width: 2px 0px 2px 2px;
  border-bottom: 0px;
}

.outer_mnemonics_container:first-child .mnemonics_container,
.outer_mnemonics_container:last-child .mnemonics_container {
  border-width: 2px;
}

.mnemonic_borderLine {
  border-bottom: 2px solid #00d100;
  width: 90%;
  margin: auto;
}

.mnemonic_min_max {
  color: #00d100;
}

.whiteBg {
  background: #fff;
}

.borderRightWidth {
  border-right-width: 2px;
}

.zoomTooltipContainer {
  width: auto;
  height: auto;
  background-color: var(--sidebarListBgColor);
  position: absolute;
  right: 5px;
  top: -10px;
  z-index: 9999;
}

.runInfo {
  position: fixed;
}

.fieldHeight {
  height: 25px;
}

.activeClass {
  border-bottom: 2px solid var(--activeTextColor);
  color: var(--activeTextColor);
}
.fontSize {
  font-weight: 400;
  font-size: calc(0.2vw + 0.2vh + 0.65vmin) !important;
}

.print_preview_popup{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.print_preview_data{
  width: 80%;
  height: 75%;
  position: relative;
  top: 8%;
  background: var(--sidebarbg);
  color: var(--textColor);
}
.printBtn[disabled]{
  opacity: 0.6;
  pointer-events: none;
}
</style>
  