<script setup>
import {
  ref,
  onMounted,
  nextTick,
  onUnmounted,
  getCurrentInstance,
  computed,
  reactive
} from "vue";
import zulipInit from "zulip-js";
import URLS from "../../config";
import MultiSelectDropdown from "./MultiSelectDropdown.vue";
import UserGroupIcon from "./UserGroupIcon.vue";
import ListDisplay from "./ListDisplay.vue";


const isOpen = ref(false);
const typedMessaige = ref("");
const totalMesaige = ref([]);
const selectedMszId = ref("");
const isDeleting = ref(false);
const isSending = ref(false);
const isEditMsz = ref(false);
const editableMsz = ref(null);
const editableIndex = ref(-1);
const zulip = ref(null);
const apiKey = ref("");
const allUsers = ref([]);
const selectedGroupUsers = ref([]);
const selectedUsers = ref([]);
const selectedGroup = ref("");
const { proxy } = getCurrentInstance();
const userGroupe = ref();
let zulipConfigDet = ref({});
const groupeMemberIds = ref([]);
const userGrouplist = ref([]);
const chatBotUser = ref(null);
const chatContainerStyle = reactive({
  left:"1px"
});
const chatBoxStyle = reactive({
  left:"1px"
});

function setIsOpen() {
  if (zulip.value && zulip.value.config.apiKey) {
    isOpen.value = !isOpen.value;
    if(!isOpen.value){
      selectedUsers.value=[];
      totalMesaige.value=[];
      selectedGroup.value="";
    }
    if (isOpen.value && totalMesaige.value.length > 0) {
      setTimeout(() => {
        scrollToButtom(true);
      }, 300);
    }
  } else {
    proxy.$toast.error("Access denied!", {
      duration: "2000",
      position: "top",
      pauseOnHover: true,
    });
  }
}
async function sendMessaige(file = false, uri) {
  if (typedMessaige.value != "") {
    isSending.value = true;
    // Send a message
    const res = await zulip.value.messages.send({
      to:
        selectedUsers.value.length > 0 ? selectedUsers.value : userGroupe.value,
      type: selectedUsers.value.length > 0 ? "direct" : "stream",
      subject: "test",
      content: typedMessaige.value,
    });
    if (res.result == "success") {
      if (!file) {
        totalMesaige.value.push({
          content: typedMessaige.value,
          type: "sent",
          client: "Mozilla",
          id: res.id,
          sender_id:chatBotUser.value.user_id
        });
      } else {
        totalMesaige.value.push({
          content: typedMessaige.value,
          type: "sent",
          uri: uri,
          client: "Mozilla",
          id: res.id,
          sender_id:chatBotUser.value.user_id
        });
      }
      typedMessaige.value = "";
      if (!file) {
        // registerMesQueEvent();
      }
      nextTick(() => {
        scrollToButtom(true);
      });
    }
    isSending.value = false;
  }
}
async function registerMesQueEvent() {
  // Register a queue
  const params = {
    event_types: ["message"],
  };

  const { queue_id } = await zulip.value.queues.register(params);
  // Retrieve events from a queue with given "queue_id"
  const eventParams = {
    queue_id,
    last_event_id: -1,
  };
  const eventQ = await zulip.value.events.retrieve(eventParams);
  if (eventQ.result == "success") {
    if (eventQ.events.length > 0 && eventQ.events[0].message) {
      let msz = eventQ.events[0].message.content;
      totalMesaige.value.push({ content: msz, type: "received" });
      nextTick(() => {
        scrollToButtom(true);
      });
    }
  }
  await zulip.value.queues.deregister({ queue_id: queue_id });
}
async function uploadAttachments(file) {
  const botEmailAddress = zulipConfigDet.value.username;
  const botApiKey = apiKey.value;
  const zulipDomain = zulipConfigDet.value.realm;

  const url = `${zulipDomain}/api/v1/user_uploads`;

  const formData = new FormData();
  formData.append("filename", file);

  const res = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Basic " + btoa(`${botEmailAddress}:${botApiKey}`),
    },

    body: formData,
  });
  const data = await res.json();
  if (data.result == "success") {
    typedMessaige.value = `[${file.name}](${data.uri})`;
    sendMessaige(true, data.uri);
  }
}
function uploadFile() {
  let input = document.createElement("input");
  input.type = "file";
  input.onchange = (_) => {
    // you can use this method to get file and perform respective operations
    let files = Array.from(input.files);
    if (files.length > 0) {
      uploadAttachments(files[0]);
    }
  };
  input.click();
}
function scrollToButtom(isFirst = false) {
  let chatDiv = document.getElementsByClassName("messaige_overflow");
  if (isFirst && chatDiv && chatDiv.length && chatDiv.length > 0) {
    chatDiv[0].scrollTop = chatDiv[0].scrollHeight;
  }
}
function resizeHandler(){
  chatContainerStyle.left =`${window.innerWidth-55}px`
  chatBoxStyle.left =`${window.innerWidth-410}px`;
}
onMounted(async () => {
  // await loggedInUserDetails();
  chatContainerStyle.left =`${window.innerWidth-55}px`
  chatBoxStyle.left =`${window.innerWidth-410}px`;
  window.addEventListener("resize", resizeHandler);
  setTimeout(() => {
    initChatBot();
  }, 7000);
});
onUnmounted(() => {
  zulip.value = null;
  window.removeEventListener("resize", resizeHandler);
});
async function getPreviousMezFromServer(isPrivate=false) {
  const readParams = {
    anchor: "newest",
    num_before: 100,
    num_after: 0,
    narrow: [
      // { operator: "stream", operand: userGroupe.value },
      {'operator': isPrivate?'is':'stream', 'operand':isPrivate?'private':userGroupe.value}
    ],
  };

  // Get the 100 last messages sent by "santosh.k@apollodart.com" to the stream "general"
  let res = await zulip.value.messages.retrieve(readParams);
  if (res.result == "success") {
    console.log("success 1");
    if(selectedUsers.value.length>0){
      console.log("success 2");
      totalMesaige.value = await prepareMessaige(
      // res.messages.filter((m) => m.client != "Internal" && m.type=='private' && m.sender_id==chatBotUser.value.user_id && selectedUsers.value.find(u=>m.recipient_id))
      res.messages.filter((m) => m.client != "Internal" && m.type=='private' && m.display_recipient.some((r) => selectedUsers.value.includes(r.id)) && m.display_recipient.some((r) =>r.id==chatBotUser.value.user_id ))
    )
    }else{
      console.log("success 3");
      totalMesaige.value = await prepareMessaige(
      res.messages.filter((m) => m.client != "Internal")
    )
    }
    ;
    nextTick(() => {
      scrollToButtom(true);
    });
  }
}
async function prepareMessaige(messaiges) {
  let tempMsz = [];
  for (let i = 0; i < messaiges.length; i++) {
    let uri = "";
    let attachmentName = "";
    if (messaiges[i].content.includes("user_uploads")) {
      const pattern = /\<img.*?\>/gi;
      const re = /\ssrc=(?:(?:'([^']*)')|(?:"([^"]*)")|([^\s]*))/i;
      let temp = pattern.exec(messaiges[i].content);
      let imgSrc = re.exec(temp[0]);
      if (imgSrc && imgSrc.length == 4) {
        uri = imgSrc[2];
        let tempFile = imgSrc[2].split("/");
        let tempFileLength = tempFile.length;
        attachmentName = tempFile[tempFileLength - 1];
      }
    }
    tempMsz.push({
      content: messaiges[i].content
        .replaceAll("<p>", "")
        .replaceAll("</p>", ""),
      client: messaiges[i].client,
      uri: uri != "" ? uri : undefined,
      name: uri != "" ? attachmentName : undefined,
      id: messaiges[i].id,
      sender_id: messaiges[i].sender_id,
    });
  }
  return tempMsz;
}
function showMoreOptions(msz) {
  if (msz) {
    selectedMszId.value = selectedMszId.value == "" ? msz.id : "";
  }
}
async function deleteMszFromserver(id, ind) {
  isDeleting.value = true;
  const url = `${zulipConfigDet.value.realm}/api/v1/messages/${id}`;
  const res = await fetch(url, {
    method: "DELETE",
    headers: {
      Authorization:
        "Basic " + btoa(`${zulipConfigDet.value.username}:${apiKey.value}`),
    },
  });
  const data = await res.json();
  if (data.result == "success") {
    totalMesaige.value.splice(ind, 1);
  } else {
    proxy.$toast.error(data.msg, {
      duration: "2000",
      position: "top",
      pauseOnHover: true,
    });
  }
  isDeleting.value = false;
}
function editMszClickHandler(msz, ind) {
  const { content } = msz;
  typedMessaige.value = content;
  editableMsz.value = msz;
  editableIndex.value = ind;
  isEditMsz.value = true;
}
async function editMessaige() {
  isSending.value = true;
  // Update a message with the given "message_id"
  const params = {
    message_id: editableMsz.value.id,
    content: typedMessaige.value,
  };
  const res = await zulip.value.messages.update(params);
  if (res.result == "success") {
    //update content in chat ui
    totalMesaige.value[editableIndex.value].content = typedMessaige.value;
  } else {
    proxy.$toast.error(res.msg, {
      duration: "2000",
      position: "top",
      pauseOnHover: true,
    });
  }
  isSending.value = false;
  isEditMsz.value = false;
  editableMsz.value = null;
  typedMessaige.value = "";
  selectedMszId.value = "";
}
async function getAllUsers() {
  const res = await zulip.value.users.retrieve();
  if (res.result == "success") {
    allUsers.value = res.members.filter(
      (e) => e.is_bot == false && e.email != zulipConfigDet.value.username && e.is_active
    );
    // groupeMemberIds.value.forEach(member => {
    //   const user = res.members.find(e=>e.user_id == member && e.is_bot == false && e.email != zulipConfigDet.value.username);
    //   if(user){
    //     allUsers.value.push(user);
    //   }
    // });
  }
}
async function selectedUser(users) {
  selectedUsers.value = users.map((e) => e.user_id);
  await getPreviousMezFromServer(true);
  
}

function getMassaigeSenderName(id) {
  return allUsers.value.find((u) => u.user_id == id)?.full_name;
}

async function getUserGroup(userId) {
  const res = await fetch(`${zulipConfigDet.value.realm}/api/v1/user_groups`, {
    headers: {
      Authorization:
        "Basic " + btoa(`${zulipConfigDet.value.username}:${apiKey.value}`),
    },
  });
  const data = await res.json();
  if (data.result == "success") {
    //filter related user groups
    userGrouplist.value = data.user_groups.filter(
      (e) => e.is_system_group == false && e.members.find((m) => m == userId)
    );
    // let userSToberibed = data.user_groups.filter(e=>e.name==userGroupe.value);
    // groupeMemberIds.value =userSToberibed[0].members;
    // await suscribeStreamByUserGroupe(userSToberibed[0].members);
  }
}
async function suscribeStreamByUserGroupe(members) {
  const anotherUserParams = {
    subscriptions: JSON.stringify([{ name: userGroupe.value }]),
    principals: JSON.stringify(members),
    authorization_errors_fatal: false,
    invite_only: true,
    history_public_to_subscribers: true,
  };
  await zulip.value.users.me.subscriptions.add(anotherUserParams);
}
async function loggedInUserDetails() {
  const userDet = proxy.$serviceHelpers.getDetails("apollodart");
  if (userDet.user) {
    userGroupe.value = `${userDet.user.entity.entity_name}_${userDet.user.entity.sub_entity_name}_${userDet.user.entity.entity_id}`;
  }
}
async function initChatBot() {
  console.log("init chat",proxy.$serviceHelpers.getDetails("zulip_details"));
  const zConfig = proxy.$serviceHelpers.getDetails("zulip_details");
  const config = {
    username: zConfig.username,
    apiKey: zConfig.apiKey,
    realm: URLS.HOST_URL.ZULIP_REALM,
  };
  console.log('2',zConfig);
  zulipConfigDet.value = config;
  console.log('3',config);
  zulip.value = await zulipInit(config);
  if (zulip.value && zulip.value.config.apiKey) {
    apiKey.value = zConfig.apiKey;
    // getPreviousMezFromServer();
    const data = await zulip.value.users.me.getProfile();
    if (data.result === "success") {
      chatBotUser.value = data;
      await getUserGroup(data.user_id);
      await getAllUsers();
    }
    await getEveryEventFromZulip();
  }
}
async function selectedGroupHandler(group) {
  if (group && group.length > 0) {
    selectedGroup.value = group[0].name;
    userGroupe.value = group[0].name;
    selectedGroupUsers.value = [];
    group[0].members.forEach((member) => {
      const user = allUsers.value.find((e) => e.user_id == member);
      if (user) {
        selectedGroupUsers.value.push(user);
      }
    });
    groupeMemberIds.value = group[0].members;
    await getPreviousMezFromServer();
    console.log("group[0].members",group[0].members);
    await suscribeStreamByUserGroupe(group[0].members);
  }
}
async function getEveryEventFromZulip() {
  zulip.value.callOnEachEvent((e) => {
    if (e.type == "message") {
      if (
        (e.message.client == "website" || e.message.client == "Mozilla") &&
        e.message.sender_id != chatBotUser.value.user_id
      ) {
        let uri = "";
        let attachmentName = "";
        if (e.message.content.includes("user_uploads")) {
          const pattern = /\(([^)]+)\)/;
          const matches = e.message.content.match(pattern);
          if (matches) {
            uri = matches[1];
            let tempFile = matches[1].split("/");
            let tempFileLength = tempFile.length;
            attachmentName = tempFile[tempFileLength - 1];
          }
        } 
          totalMesaige.value.push({
            content: e.message.content,
            client:e.message.client,
            uri: uri != "" ? uri : undefined,
            name: uri != "" ? attachmentName : undefined,
            id:e.message.id,
            sender_id: e.message.sender_id,
          });

        nextTick(() => {
          scrollToButtom(true);
        });
      }
    }
  });
}
function draggedHandler(e){
  const totW=window.innerWidth;
  const mWidth = (totW*98)/100;
  const actualPosition = e.pageX<0?Math.abs(5):e.pageX>mWidth?mWidth:e.pageX;
  console.log('e.pageX',e.pageX,'actualPosition:',actualPosition,"mWidth",mWidth);
  chatContainerStyle.left =`${actualPosition}px`;
  // if(actualPosition<410){
    console.log('if',actualPosition+410>mWidth);
    chatBoxStyle.left =`${actualPosition+410>mWidth?actualPosition-410:actualPosition}px`;
  

}
</script>
<template>
  <div class="sticky bottom-0 chatcontainer" draggable="true" @dragend="draggedHandler" :style="chatContainerStyle">
    <button @click="setIsOpen">
      <svg xmlns="http://www.w3.org/2000/svg" width="40.744" height="35.553" viewBox="0 0 55.744 48.553">
        <g id="Group_97" data-name="Group 97" transform="translate(845 181)">
          <path id="Path_116" data-name="Path 116" d="M38.321,34.949a14.448,14.448,0,0,1-10.407,4.136H24.7a1.165,1.165,0,0,0-.948.39c-2.414,2.542-4.849,5.067-7.246,7.6a2.414,2.414,0,0,1-1.817.853,2.174,2.174,0,0,1-2.168-2.23V39.381c0-.4-.111-.557-.518-.635C6.059,37.218,2.436,33.344.674,27.6A32.33,32.33,0,0,1,0,24.3V21.248a18.026,18.026,0,0,1,.557-2.876c1.455-5.284,4.587-9.113,9.8-11.1A13.149,13.149,0,0,1,15.05,6.45h12.6a14.649,14.649,0,0,1,13.26,7.469A17.19,17.19,0,0,1,38.321,34.949Z" transform="translate(-845 -187.449)" fill="#3cabff"/>
          <path id="Path_117" data-name="Path 117" d="M71.332,40.74a11.025,11.025,0,0,0-5.513-2.67,1.646,1.646,0,0,1-.05.323,18.868,18.868,0,0,1-2.23,7.681c-3.445,6.243-8.668,9.654-15.908,9.743H45.34c-.624,0-.9.479-.585,1a10.9,10.9,0,0,0,7.6,5.429,30.35,30.35,0,0,0,4.4.217,1.115,1.115,0,0,1,.9.373c.981,1.048,1.984,2.074,2.976,3.11.764.8,1.522,1.611,2.308,2.4a1.912,1.912,0,0,0,2.692.19,2.046,2.046,0,0,0,.663-1.672c0-1.321,0-2.648,0-3.969,0-.474.128-.641.6-.819a15.261,15.261,0,0,0,3.3-1.5A12.8,12.8,0,0,0,71.332,40.74Z" transform="translate(-864.75 -201.445)" fill="#3cabff"/>
        </g>
      </svg>
    </button>

    <div v-if="isOpen" class="chatbox-holder" :style="chatBoxStyle">
      <div class="chatbox">
        <div
          class="flex justify-between px-2 py-0 rounded-sm "
        >
          <div class="flex justify-center items-center">
            <div>Support</div>
            <div class="px-2 cursor-pointer" title="Group">
              <!-- User associated groups name -->
              <!-- <MultiSelectDropdown
                :lists="userGrouplist"
                display-prop="name"
                value-prop="name"
                :choosed="selectedGroupHandler"
              >
                <template #icon>
                  <UserGroupIcon />
                </template>
              </MultiSelectDropdown> -->
            </div>
            <div class="px-2 cursor-pointer" title="Users">
              <!-- selected group's users list -->
              <!-- <MultiSelectDropdown
                :lists="selectedGroupUsers"
                display-prop="full_name"
                value-prop="user_id"
                :multiple="true"
                :choosed="selectedUser"
              ></MultiSelectDropdown> -->
            </div>
          </div>
          <div
            @click="setIsOpen"
            class="cursor-pointer hover:bg-red-500 p-1 rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>

        <!-- <MultiSelectDropdown  :lists="allUsers" :choosed="selectedUser"/> -->
        <div class="chat-messages">
          <div class="group_user_list">
            <div class="group_list">
              <p>Groups</p>
              <ListDisplay
                :lists="userGrouplist"
                display-prop="name"
                value-prop="name"
                :choosed="selectedGroupHandler"
               />
            </div>
            <div class="users_list">
              <p>Users</p>
              <ListDisplay
                :lists="selectedGroupUsers"
                display-prop="full_name"
                value-prop="user_id"
                :multiple="true"
                :choosed="selectedUser"
               />
            </div>
          </div>
          <div class="messaige_list">
            <div class="group_or_user_list">
              {{ selectedGroup }}
            </div>
            <div class="all_messaiges">
              <div class="messaige_overflow">
                  <div
                      v-for="(msz, i) in totalMesaige"
                      :key="i"
                      class="message-box-holder"
                    >
                      <!-- Sent Messaige -->
                      <div
                        v-if="
                          msz.client == 'Mozilla' && chatBotUser.user_id == msz.sender_id
                        "
                        class="message-box-holder"
                      >
                        <div class="message-box">
                          <a
                            v-if="msz.content.includes('user_uploads')"
                            :href="`https://zulip.adart.apl-local${msz.uri}`"
                            target="_blank"
                            title="Click to view"
                          >
                            <img
                              :src="`https://zulip.adart.apl-local${msz.uri}`"
                              :alt="msz.name"
                              width="180"
                            />
                          </a>
                          <span v-else> {{ msz.content }}</span>

                          <svg
                            class="w-3 h-3 ml-2 cursor-pointer"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 4 15"
                            @click="showMoreOptions(msz)"
                          >
                            <path
                              d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                            />
                          </svg>
                          <div
                            id="dropdownDots"
                            :class="selectedMszId == msz.id ? '' : 'hidden'"
                          >
                            <div
                              v-if="isDeleting"
                              class="w-4 h-4 rounded-full animate-spin border-2 border-solid border-gray-600 border-t-transparen"
                            ></div>
                            <ul
                              v-else
                              class="flex py-1 text-sm text-gray-700 dark:text-gray-200"
                              aria-labelledby="dropdownMenuIconButton"
                            >
                              <li
                                v-if="!msz.content.includes('user_uploads')"
                                @click="editMszClickHandler(msz, i)"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-4 h-4 cursor-pointer"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                  />
                                </svg>
                              </li>
                              <li @click="deleteMszFromserver(msz.id, i)">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-4 h-4 cursor-pointer text-red-500"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                  />
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <!-- Received Messaige -->
                      <div v-else class="message-box-holder">
                        <div class="message-sender">
                          {{ getMassaigeSenderName(msz.sender_id) }}
                        </div>
                        <div class="message-box message-partner">
                          <a
                            v-if="msz.content.includes('user_uploads')"
                            :href="`https://zulip.adart.apl-local${msz.uri}`"
                            target="_blank"
                            title="Click to view"
                          >
                            <img
                              :src="`https://zulip.adart.apl-local${msz.uri}`"
                              :alt="msz.name"
                              width="180"
                            />
                          </a>
                          <!-- <span v-else> {{ msz.content }}</span> -->
                          <div v-else v-html="msz.content"></div>
                        </div>
                      </div>
                      <!-- Sent Messaige -->
                      <!-- <div v-if="msz.client == 'Mozilla'" class="message-box">
                        <a
                          v-if="msz.content.includes('user_uploads')"
                          :href="`https://zulip.adart.apl-local${msz.uri}`"
                          target="_blank"
                          title="Click to view"
                        >
                          <img
                            :src="`https://zulip.adart.apl-local${msz.uri}`"
                            :alt="msz.name"
                            width="180"
                          />
                        </a>
                        <span v-else> {{ msz.content }}</span>

                        <svg
                          class="w-3 h-3 ml-2 cursor-pointer"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 4 15"
                          @click="showMoreOptions(msz)"
                        >
                          <path
                            d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                          />
                        </svg>
                        <div
                          id="dropdownDots"
                          :class="selectedMszId == msz.id ? '' : 'hidden'"
                        >
                          <div
                            v-if="isDeleting"
                            class="w-4 h-4 rounded-full animate-spin border-2 border-solid border-gray-600 border-t-transparen"
                          ></div>
                          <ul
                            v-else
                            class="flex py-1 text-sm text-gray-700 dark:text-gray-200"
                            aria-labelledby="dropdownMenuIconButton"
                          >
                            <li
                              v-if="!msz.content.includes('user_uploads')"
                              @click="editMszClickHandler(msz, i)"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-4 h-4 cursor-pointer"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                />
                              </svg>
                            </li>
                            <li @click="deleteMszFromserver(msz.id, i)">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-4 h-4 cursor-pointer text-red-500"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>
                            </li>
                          </ul>
                        </div>
                      </div> -->
                      <!-- Received Messaige -->
                      <!-- <div v-else class="message-box message-partner">
                      
                        <a
                          v-if="msz.content.includes('user_uploads')"
                          :href="`https://zulip.adart.apl-local${msz.uri}`"
                          target="_blank"
                          title="Click to view"
                        >
                          <img
                            :src="`https://zulip.adart.apl-local${msz.uri}`"
                            :alt="msz.name"
                            width="180"
                          />
                        </a>
                        <span v-else> {{ msz.content }}</span>
                      </div> -->
                    </div>
                    <div
                      v-if="totalMesaige.length == 0"
                      class="flex flex-col items-center w-full"
                    >
                      <p class="font-medium">Welcome to Chat.</p>
                      <p class="text-xs">To send message Please select Group</p>
                    </div>
              </div>
              <div v-if="!isEditMsz" class="chat-input-holder">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 40 32">
                  <g id="Group_96" data-name="Group 96" transform="translate(2045 -1184)">
                    <path id="Path_113" data-name="Path 113" d="M85.107,55.8H84v5.535h1.107a1.911,1.911,0,0,0,1.912-1.912V57.712A1.911,1.911,0,0,0,85.107,55.8Z" transform="translate(-2092.019 1147.033)"/>
                    <path id="Path_114" data-name="Path 114" d="M10,57.712v1.711a1.911,1.911,0,0,0,1.912,1.912h1.107V55.8H11.912A1.911,1.911,0,0,0,10,57.712Z" transform="translate(-2055 1147.033)"/>
                    <path id="Path_115" data-name="Path 115" d="M47.553,29.08H34.524v-8.2a1.552,1.552,0,0,0,1.1-1.491,1.6,1.6,0,0,0-3.195,0,1.552,1.552,0,0,0,1.1,1.491v8.2H20.4A2.391,2.391,0,0,0,18,31.465v15.95A2.391,2.391,0,0,0,20.4,49.8H47.5a2.391,2.391,0,0,0,2.4-2.385V31.465A2.349,2.349,0,0,0,47.553,29.08ZM27.535,36.235a.549.549,0,0,1-1.1,0,2.1,2.1,0,0,1,4.193,0,.549.549,0,0,1-1.1,0,1.021,1.021,0,0,0-1.048-1.043A.969.969,0,0,0,27.535,36.235Zm11.931,6.559a7.818,7.818,0,0,1-5.491,1.938,7.6,7.6,0,0,1-5.491-1.938.539.539,0,0,1,0-.745.545.545,0,0,1,.749,0,6.715,6.715,0,0,0,4.743,1.59,6.715,6.715,0,0,0,4.743-1.59.545.545,0,0,1,.749,0A.539.539,0,0,1,39.466,42.794Zm1.448-6.012a.553.553,0,0,1-.549-.547,1.048,1.048,0,0,0-2.1,0,.549.549,0,0,1-1.1,0,2.1,2.1,0,0,1,4.193,0A.428.428,0,0,1,40.914,36.781Z" transform="translate(-2058.975 1166.2)"/>
                  </g>
                </svg>
                <input
                  v-model="typedMessaige"
                  @keyup.enter="sendMessaige(false)"
                  placeholder="enter to send"
                  class="chat-input"
                />
                <div @click="uploadFile" class="attachment-panel">
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" viewBox="0 0 28 32">
                  <path id="Path_99" data-name="Path 99" d="M879.208,973.095a10.014,10.014,0,0,0-14.154,0l-10.811,10.81a.374.374,0,0,0-.107.265.378.378,0,0,0,.107.266l1.529,1.529a.372.372,0,0,0,.526,0l10.811-10.81A7.1,7.1,0,0,1,877.154,985.2l-11.018,11.022-1.785,1.787a4.279,4.279,0,0,1-6.051-6.052l10.931-10.935a1.46,1.46,0,0,1,1.031-.426h0a1.431,1.431,0,0,1,1.023.426,1.45,1.45,0,0,1,0,2.056l-8.934,8.933a.382.382,0,0,0,0,.531l1.529,1.53a.374.374,0,0,0,.526,0l8.93-8.937a4.363,4.363,0,1,0-6.172-6.168l-1.06,1.065-9.867,9.87a7.186,7.186,0,0,0,10.16,10.164l12.807-12.808a10.027,10.027,0,0,0,0-14.164Z" transform="translate(-854.137 -970.166)" fill="#333"/>
                </svg>
                </div>
                <button
                  @click="sendMessaige(false)"
                  type="button"
                  class="message-send"
                  :disabled="isSending"
                >
                  <!-- {{ isSending ? "Senging..." : "Send" }} -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 78 78">
                    <g id="Group_98" data-name="Group 98" transform="translate(-777 -1237)">
                      <g id="Group_98-2" data-name="Group 98" transform="translate(-855 294)">
                        <path id="Path_104" data-name="Path 104" d="M1632,972.8c0-9.131,0-13.7,1.46-17.308a20.015,20.015,0,0,1,11.03-11.029C1648.1,943,1652.67,943,1661.8,943h18.4c9.13,0,13.7,0,17.31,1.463a20.015,20.015,0,0,1,11.03,11.029c1.46,3.611,1.46,8.177,1.46,17.308v18.4c0,9.13,0,13.7-1.46,17.31a20.036,20.036,0,0,1-11.03,11.03c-3.61,1.46-8.18,1.46-17.31,1.46h-18.4c-9.13,0-13.7,0-17.31-1.46a20.036,20.036,0,0,1-11.03-11.03c-1.46-3.61-1.46-8.18-1.46-17.31Z" fill="#32a7ff"/>
                      </g>
                      <g id="Group_99" data-name="Group 99" transform="translate(1350.995 -49)">
                        <path id="Path_83" data-name="Path 83" d="M25.705,58.005a3.763,3.763,0,0,1-2.943-1.472,3.589,3.589,0,0,1-.623-3.177l2.835-10.775H36.788a2.571,2.571,0,0,0,0-5.143H24.974L22.132,26.663a3.591,3.591,0,0,1,.63-3.177,3.662,3.662,0,0,1,4.607-1.067L55.961,36.71a3.693,3.693,0,0,1,0,6.609L27.37,57.61a3.634,3.634,0,0,1-1.665.4Z" transform="translate(-575 1285.002)" fill="#fff"/>
                        <path id="Path_84" data-name="Path 84" d="M25.176,22.666a2.466,2.466,0,0,1,1.127.281l29.65,14.82a2.5,2.5,0,0,1,0,4.479L26.3,57.067a2.466,2.466,0,0,1-1.127.281,2.571,2.571,0,0,1-2-1.013,2.386,2.386,0,0,1-.414-2.141l2.679-10.18H36.667a4,4,0,0,0,0-8H25.441l-2.679-10.18a2.388,2.388,0,0,1,.414-2.141,2.555,2.555,0,0,1,2-1.013m0-2.682a5.179,5.179,0,0,0-4.992,6.5L23.39,38.665H36.675a1.333,1.333,0,0,1,0,2.667H23.39L20.184,53.5a5.154,5.154,0,0,0,7.312,5.94l29.65-14.82a5.168,5.168,0,0,0,0-9.247L27.493,20.558a5.15,5.15,0,0,0-2.32-.56Z" transform="translate(-575 1285.002)" fill="#fff"/>
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
              <div v-else class="chat-input-holder">
                <input
                  v-model="typedMessaige"
                  @keyup.enter="editMessaige(false)"
                  placeholder="enter to send"
                  class="chat-input"
                />
                <button
                  @click="editMessaige(false)"
                  type="button"
                  class="message-send"
                  :disabled="isSending"
                >
                
                  {{ isSending ? "Updating..." : "Update" }}
                </button>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  </div>
</template>
<style scoped>
.chatcontainer {
  width: 50px;
  /* background: #0e2f92; */
  height: 50px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 9999;
  position: absolute;
  /* right: 1px; */
}
.chatbox-holder {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  height: 0;
  top: 93%;
  padding: 15px;
  /* right: -33px; */
}

.chatbox {
  width: 600px;
  height: 500px;
  margin: 0px 25px 0px -205px;
  position: relative;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-flow: column;
  border-radius: 10px 10px 0 0;
  /* background: var(--sidebarbg); */
  background: #EFF6FC;
  bottom: 0;
  transition: 0.1s ease-out;
}

.chatbox-top {
  position: relative;
  display: flex;
  padding: 10px 0;
  border-radius: 10px 10px 0 0;
  background: rgba(5, 5, 6, 0.05);
}

.chat-messages {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  /* flex: 1; */
  /* flex-direction: column; */
  column-gap: 10px;
  height: 100%;
}

.message-box-holder {
  width: 100%;
  margin: 0 0 15px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}
.message-sender {
  font-size: 12px;
  margin: 0 0 15px;
  /* color: var(--textColor); */
  align-self: flex-start;
}
.message-box {
  padding: 6px 10px;
  border-radius: 6px 0 6px 0;
  position: relative;
  /* background: var(--central_bg); */
  background: #32A7FF;
  /* rgba(100, 170, 0, 0.1); */
  border: 2px solid rgba(100, 170, 0, 0.1);
  color: var(--textColor);
  font-size: 12px;
  display: flex;
  align-items: center;
}

.message-box:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  /* border-top: 10px solid var(--widgetBg); */
  border-top: 10px solid #32A7FF;
  border-right: none;
  bottom: -22px;
  right: 10px;
}

.message-partner {
  /* background: var(--sidebarListBgColor); */
  /* color: var(--textColor); */
  /* color: var(--textColor); */
  /* color: var(--textColor); */
  /* rgba(0, 114, 135, 0.1); */
  color: #0e0e0e;
  border: 2px solid rgba(0, 114, 135, 0.1);
  align-self: flex-start;
  background-color: #E7E7E7;
}

.message-partner:after {
  right: auto;
  bottom: auto;
  top: -22px;
  left: 9px;
  border: 10px solid transparent;
  border-bottom: 10px solid #E7E7E7;
  border-left: none;
}

.chat-input-holder {
  display: flex;
  margin-bottom: 7px;
}

.chat-input {
  resize: none;
  padding: 5px 10px;
  height: 40px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  /* color: #999999; */
  flex: 1;
  border: none;
  background: #EFF6FC;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.chat-input:focus,
.message-send:focus {
  outline: none;
}

.message-send::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.message-send {
  font-size: 12px;
  padding: 0px 5px;
  border: none;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.attachment-panel {
  padding: 4px 5px;
  text-align: right;
  background: #EFF6FC;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.group_user_list{
  width: 25%;
  /* height: 100%; */
  height: 92%
  /* background: #FFFFFF; */
}
.group_list{
  height: 40%;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}
.group_or_user_list{
  height: 35px;
  display: flex;
  flex: 1;
  background: #FFFFFF;
}
.messaige_list{
  width: 73%;
}
.group_or_user_list{
  max-height: 10%;
  background: #FFFFFF;
  border-bottom: 2px solid #e7e5e5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px;
}
.all_messaiges{
  background: #FFFFFF;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
}
.users_list{
  margin-top: 12px;
  height: 57%;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.messaige_overflow{
  height: 355px;
  overflow-y: auto;
}

</style>
