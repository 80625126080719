<template>
  <div
    class="list w-auto title-bar-controls"
    v-bind:id="compId"
    :style="whStyles"
    @drop="onDrop($event, 1)"
    @dragenter="onDragEnter($event, 2)"
    @dragover.prevent
  >
    <div>
      <div>
        
        <VueDragResize
          v-for="(rect, index) in rects"
          :snapToGrid="false"
          :gridX="20"
          :key="rect.widgetId"
          :id="rect.widgetId"
          :w="setting_width(rect)"
          :h="setting_height(rect)"
          :x="rect.left"
          :y="rect.top"
          :parentW="width"
          :parentH="height"
          :axis="rect.axis"
          :isActive="rect.active"
          :minw="setting_min_width(rect)"
          :minh="setting_min_height(rect)"
          :isDraggable="rect.draggable"
          :isResizable="rect.resizable"
          :parentLimitation="rect.parentLim"
          :aspectRatio="rect.aspectRatio"
          :z="rect.name == 'line-graph-tvd' || rect.name == 'line-graph' ? 'var(--higherZIndex)': rect.zIndex"
          :contentClass="rect.class"
          v-on:activated="activateEv(rect.widgetId)"
          v-on:deactivated="deactivateEv(rect.widgetId)"
          v-on:dragging="changePosition($event, rect.widgetId, rect)"
          v-on:resizing="changeSize($event, rect.widgetId, rect)"
          :class="[
            rect.name == 'data-table' || rect.name == 'well-detail'
              ? ''
              : rect.isMinimize
              ? 'displayNone'
              : '',
            rect.name == 'gauge' ? 'gauge_view' : '',
          ]"
        >
          <div
            class="filler"
            :style="{
              backgroundColor: rect.name == 'Background' ? rect.color : '',
              borderRadius:
                rect.name == 'line-graph' || rect.name == 'line-graph-tvd'
                  ? '3%'
                  : '',
            }"
          >
          <!-- {{console.log('trend__codeeeee', rect)}} -->
            <DisTrend
              v-if="rect.name == 'trend' && !rect.isReplay"
              :id="rect.widgetId"
              :key="index"
              :height="rect.height - 2"
              :width="rect.width - 2"
              :top="rect.top"
              :left="rect.left"
              :displayId="rect.displayId"
              :plotConfigProps="rect.plotConfig"
              :isDisProps="rect.isDis"
            ></DisTrend>
            <Trend
              v-if="rect.name == 'trend' && rect.isReplay == true"
              :id="rect.widgetId"
              :key="index"
              :height="rect.height - 2"
              :width="rect.width - 2"
              :top="rect.top"
              :left="rect.left"
              :displayId="rect.displayId"
              :plotConfigProps="rect.plotConfig"
              :name="rect.name"
              :isReplay="rect.isReplay"
              :isDisProps="rect.isDis"
            >
            </Trend>
            <Background
              v-if="rect.name == 'Background'"
              :id="rect.widgetId"
              :key="index"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :backgroundId="rect.backgroundId"
              :cardBackground="rect.backgroundColor"
              :height="rect.height"
              :width="rect.width"
              :top="rect.top"
              :left="rect.left"
              :onMinMax="windowToggle"
              aria-label="Minimize"
              :openPropertyFn="openProperty"
              v-on:dblclick="openProperty(index, rect)"
            >
            </Background>

            <label-widget
              v-if="rect.name == 'label'"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :titleSize="rect.titleSize"
              :titleDisplay="rect.titleDisplay"
              :autoFit="rect.autoFit"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :title="rect.title"
              :titleColor="rect.titleColor"
              v-bind:class="[
                isRightOpen || $store.state.uis.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
            ></label-widget>
            <numeric-widget
              v-if="rect.name == 'numeric'"
              :key="index"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :isChange="rect.isChange"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :borderColor="rect.borderColor"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :alertColo="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :cardBackground="rect.cardBackground"
              :autoFit="rect.autoFit"
              :titleDisplay="rect.titleDisplay"
              :titleSize="rect.titleSize"
              :titleColor="rect.titleColor"
              :titleBgColor="rect.titleBgColor"
              :title="rect.title"
              :fullName="rect.fullName"
              :valueDisplay="rect.valueDisplay"
              :valueSize="rect.valueSize"
              :valueColor="rect.valueColor"
              :valueBgColor="rect.valueBgColor"
              :decimalValue="rect.decimalValue"
              :value="rect.value"
              :unitBgColor="rect.unitBgColor"
              :unitDisplay="rect.unitDisplay"
              :unitSize="rect.unitSize"
              :unitColor="rect.unitColor"
              :widgetUnitL="rect.unit"
              :isDragStarted="isDragStarted"
              :isReplay="rect.isReplay"
              :unit_conversion_factor="rect.unit_conversion_factor"
              v-bind:class="[
                isRightOpen || $store.state.uis.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
              href="#pablo"
              ref="btnDropdownRef"
            ></numeric-widget>
            <labelNumeric
              v-if="rect.name == 'label_numeric'"
              :key="index"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :numericWidgetData="rect.numericWidgetData"
              :backgroundId="rect.backgroundId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :parentH="height"
              :topPosition="rect.top"
              v-bind:class="[
                isRightOpen || $store.state.uis.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              @dblclick.prevent.stop="openProperty(index, rect)"
              @openProperty="(emitIndex, emitRect)=> openProperty(emitIndex, emitRect)"
            >
            </labelNumeric>
            <KillSheetWidget
              v-if="rect.name == 'killsheet'"
              :key="index"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :isChange="rect.isChange"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :borderColor="rect.borderColor"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :alertColo="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :cardBackground="rect.cardBackground"
              :autoFit="rect.autoFit"
              :titleDisplay="rect.titleDisplay"
              :titleSize="rect.titleSize"
              :titleColor="rect.titleColor"
              :titleBgColor="rect.titleBgColor"
              :fullName="rect.fullName"
              :valueDisplay="rect.valueDisplay"
              :valueSize="rect.valueSize"
              :valueColor="rect.valueColor"
              :valueBgColor="rect.valueBgColor"
              :decimalValue="rect.decimalValue"
              :value="rect.value"
              :unitBgColor="rect.unitBgColor"
              :unitDisplay="rect.unitDisplay"
              :unitSize="rect.unitSize"
              :unitColor="rect.unitColor"
              :widgetUnitL="rect.widgetUnitL"
              :conversionFactors="rect.unitConversion?rect.unitConversion:{}"
              v-bind:class="[
                isRightOpen || $store.state.uis.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
              href="#pablo"
              ref="btnDropdownRef"
            ></KillSheetWidget>
            <SwabOrSurge
              v-if="rect.name == 'swaborsurge'"
              :key="index"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :isChange="rect.isChange"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :borderColor="rect.borderColor"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :alertColo="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :cardBackground="rect.cardBackground"
              :autoFit="rect.autoFit"
              :titleDisplay="rect.titleDisplay"
              :trip_Depth="rect.trip_Depth"
              :trip_Depth_TVD="rect.trip_Depth_TVD"
              :mud_wght="rect.mud_wght"
              :hole_size="rect.hole_size"
              :dc1_od="rect.dc1_od"
              :dc1_id="rect.dc1_id"
              :dc1_length="rect.dc1_length"
              :dc2_od="rect.dc2_od"
              :dc2_id="rect.dc2_id"
              :dc2_length="rect.dc2_length"
              :hwdp_od="rect.hwdp_od"
              :hwdp_id="rect.hwdp_id"
              :hwdp_length="rect.hwdp_length"
              :dp_od="rect.dp_od"
              :dp_id="rect.dp_id"
              :dp_length="rect.dp_length"
              :selected_tripmode="rect.selected_tripmode"
              :flow_index="rect.flow_index"
              :selected_tripstatus="rect.selected_tripstatus"
              :r300="rect.r300"
              :r600="rect.r600"
              :plastic_Viscosity="rect.plastic_Viscosity"
              :Consistency_index="rect.Consistency_index"
              :rect_index="index"
              :rect_data="rect"
              :static_casing_length="rect.static_casing_length"
              :static_casing_id="rect.static_casing_id"
              :xAutoScale="rect?.xAutoScale"
              :yAutoScale="rect?.yAutoScale"
              :xRangeStart="rect?.xRangeStart"
              :xRangeEnd="rect?.xRangeEnd"
              :yRangeStart="rect?.yRangeStart"
              :yRangeEnd="rect?.yRangeEnd"
              :xAutoScalePpg="rect?.xAutoScalePpg"
              :yAutoScalePpg="rect?.yAutoScalePpg"
              :xRangeStartPpg="rect?.xRangeStartPpg"
              :xRangeEndPpg="rect?.xRangeEndPpg"
              :yRangeStartPpg="rect?.yRangeStartPpg"
              :yRangeEndPpg="rect?.yRangeEndPpg"
              :conversionFactors="rect.unitConversion?rect.unitConversion:{}"
              @to_openProperty="openProperty"
              v-bind:class="[
                isRightOpen || $store.state.uis.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              href="#pablo"
              ref="btnDropdownRef"
            ></SwabOrSurge>

            <DirectionDifficultyWidget
              v-if="rect.name == 'DirectionalDifficulty'"
              :key="index"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :isChange="rect.isChange"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :borderColor="rect.borderColor"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :alertColo="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :cardBackground="rect.cardBackground"
              :autoFit="rect.autoFit"
              :titleDisplay="rect.titleDisplay"
              :titleSize="rect.titleSize"
              :titleColor="rect.titleColor"
              :titleBgColor="rect.titleBgColor"
              :title="rect.title"
              :fullName="rect.fullName"
              :valueDisplay="rect.valueDisplay"
              :valueSize="rect.valueSize"
              :valueColor="rect.valueColor"
              :valueBgColor="rect.valueBgColor"
              :decimalValue="rect.decimalValue"
              :value="rect.value"
              :unitBgColor="rect.unitBgColor"
              :unitDisplay="rect.unitDisplay"
              :unitSize="rect.unitSize"
              :unitColor="rect.unitColor"
              :widgetUnitL="rect.widgetUnitL"
              :tableData="rect.table"
              :tag="rect.tag"
              :getTableColumns="getTableColumns(cols)"
              :headColor="rect.headColor"
              :headBackground="rect.headBackground"
              :color="rect.gridColor"
              :background="rect.backgroundColor"
              :headFontSize="rect.headFontSize"
              :graphLabelColor="rect.graphLabelColor"
              :graphColor="rect.graphColor"
              :xAxisLabelSize="rect.xaxisLabelSize"
              :yAxisLabelSize="rect.yaxisLabelSize"
              :gridColor="rect.gridColor"
              :gridBackgroundColor="rect.gridBackgroundColor"
              :isXmanualRange="rect.xManualRange"
              :xStartRange="rect.xStartRange"
              :xEndRange="rect.xEndRange"
              :isYmanualRange="rect.yManualRange"
              :yStartRange="rect.yStartRange"
              :yEndRange="rect.yEndRange"
              :unit="rect.unit"
              :unit_conversion_factor="rect.unit_conversion_factor"
              v-bind:class="[
                isRightOpen || $store.state.uis.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
              href="#pablo"
              ref="btnDropdownRef"
            >
            </DirectionDifficultyWidget>
            <XYAxisWidget
              v-if="rect.name == 'XYAxis'"
              :key="index"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :isChange="rect.isChange"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :borderColor="rect.borderColor"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :alertColo="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :cardBackground="rect.cardBackground"
              :autoFit="rect.autoFit"
              :titleDisplay="rect.titleDisplay"
              :titleSize="rect.titleSize"
              :titleColor="rect.titleColor"
              :titleBgColor="rect.titleBgColor"
              :title="rect.title"
              :fullName="rect.fullName"
              :valueDisplay="rect.valueDisplay"
              :valueSize="rect.valueSize"
              :valueColor="rect.valueColor"
              :valueBgColor="rect.valueBgColor"
              :decimalValue="rect.decimalValue"
              :value="rect.value"
              :unitBgColor="rect.unitBgColor"
              :unitDisplay="rect.unitDisplay"
              :unitSize="rect.unitSize"
              :unitColor="rect.unitColor"
              :widgetUnitL="rect.widgetUnitL"
              :isSecondaryAxis="rect.isSecondaryAxis || false"
              :gridColor="
                rect.gridColor ||
                ($store.state.rect.darkmode != 'white' ? 'white' : 'black')
              "
              :gridBackground="rect.graphBackgroundColor"
              :axisLabelColor="
                rect.axisLabelColor ||
                ($store.state.rect.darkmode != 'white' ? 'white' : 'black')
              "
              :graphScaleColor="rect.graphScaleColor"
              :xAxisLabelSize="rect.xAxisLabelSize"
              :yAxisLabelSize="rect.yAxisLabelSize"
              :secondaryAxisLabelColor="
                rect.sevondaryAxisLabelColor ||
                ($store.state.rect.darkmode != 'white' ? 'white' : 'black')
              "
              :graphSecondaryScaleColor="rect.graphSecondaryScaleColor"
              :yAxisSecondaryLabelSize="rect.yAxisSecondaryLabelSize"
              :primaryXaxisFullName="rect.primaryXaxisFullName"
              :primaryXaxisUnit="rect.primaryXaxisUnit"
              :primaryYaxisFullName="rect.primaryYaxisFullName"
              :primaryYaxisUnit="rect.primaryYaxisUnit"
              :secondaryYaxisFullName="rect.secondaryYaxisFullName"
              :secondryYaxisUnit="rect.secondryYaxisUnit"
              :primaryYaxisUnitFactor="rect.primaryYaxisUnitFactor"
              :secondaryYaxisUnitFactor="rect.secondaryYaxisUnitFactor"
              :isSlider="rect?.isSlider || false"
              :themeColor="$store.state.rect.darkmode"
              v-bind:class="[
                isRightOpen || $store.state.uis.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
              @open_prop="() => openProperty(index, rect)"
              href="#pablo"
              ref="btnDropdownRef"
            >
            </XYAxisWidget>
            <Reports
              v-if="rect.name == 'Reports'"
              @openprop="(index,val) => openProperty(index, val)"
              :displayIndex="displayIndex"
              :widgetId="rect.widgetId"
              :key="index"
              :widgetIndex="index"
              :height="rect.height - 15"
              :width="rect.width"
              :top="rect.top"
              :left="rect.left"
              :displayId="rect.displayId"
              :parentW="width"
              :parentH="height"
              :values = "[index,rect]"
              :id="rect.widgetId"
             
            >
            </Reports>
            <mud-motor-health
              v-if="rect.name == 'mud-motor'"
              :key="index"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :isChange="rect.isChange"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :borderColor="rect.borderColor"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :alertColo="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :cardBackground="rect.cardBackground"
              :autoFit="rect.autoFit"
              :titleDisplay="rect.titleDisplay"
              :titleSize="rect.titleSize"
              :titleColor="rect.titleColor"
              :titleBgColor="rect.titleBgColor"
              :title="rect.title"
              :fullName="rect.fullName"
              :valueDisplay="rect.valueDisplay"
              :valueSize="rect.valueSize"
              :valueColor="rect.valueColor"
              :valueBgColor="rect.valueBgColor"
              :decimalValue="rect.decimalValue"
              :value="rect.value"
              :unitBgColor="rect.unitBgColor"
              :unitDisplay="rect.unitDisplay"
              :unitSize="rect.unitSize"
              :unitColor="rect.unitColor"
              :widgetUnitL="rect.widgetUnitL"
              v-bind:class="[
                isRightOpen || $store.state.uis.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
              href="#pablo"
              ref="btnDropdownRef"
            ></mud-motor-health>

            <hydraulics-calculator
              v-if="rect.name == 'hydraulic-calculator'"
              :key="index"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :isChange="rect.isChange"
              :dry_pipe_length="rect.dry_pipe_length"
              :slug_weight="rect.slug_weight"
              :slug_dry_pipe_length="rect.slug_dry_pipe_length"
              :slug_volume="rect.slug_volume"
              :annular_capacity="rect.annular_capacity"
              :annular_slug_volume="rect.annular_slug_volume"
              :washout_factor="rect.washout_factor"
              :drill_string_volume_left="rect.drill_string_volume_left"
              :stroke_required_displace_surface="
                rect.stroke_required_displace_surface
              "
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :borderColor="rect.borderColor"
              :alertStartRange="rect.alertStartRange"
              :height_spot_column="rect.height_spot_column"
              :alertEndRange="rect.alertEndRange"
              :alertColo="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :cardBackground="rect.cardBackground"
              :autoFit="rect.autoFit"
              :titleDisplay="rect.titleDisplay"
              :titleSize="rect.titleSize"
              :titleColor="rect.titleColor"
              :titleBgColor="rect.titleBgColor"
              :fullName="rect.fullName"
              :valueDisplay="rect.valueDisplay"
              :valueSize="rect.valueSize"
              :valueColor="rect.valueColor"
              :valueBgColor="rect.valueBgColor"
              :decimalValue="rect.decimalValue"
              :value="rect.value"
              :unitBgColor="rect.unitBgColor"
              :unitDisplay="rect.unitDisplay"
              :unitSize="rect.unitSize"
              :unitColor="rect.unitColor"
              :widgetIndex="index"
              :widgetConfig="rect"
              :conversionFactors="rect.unitConversion?rect.unitConversion:{}"
              v-on:dblclick="openProperty(index, rect)"
              href="#pablo"
              ref="btnDropdownRef"
            >
            </hydraulics-calculator>

            <vertical-bar-widget
              v-if="rect.name == 'vertical-bar'"
              :id="rect.widgetId"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :title="rect.title"
              :fullName="rect.fullName"
              :unit="rect.unit"
              :autoFit="rect.autoFit"
              :width="rect.width"
              :height="rect.height * 1.05"
              :barWidth="rect.width - 60"
              :barHeight="rect.height - rect.height * 0.3"
              :barValue="rect.barValue"
              :rangeStart="rect.rangeStart"
              :alertColor="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :rangeEnd="rect.rangeEnd"
              :interval="rect.interval"
              :borderColor="rect.borderColor"
              :borderWidth="rect.borderWidth"
              :borderDisplay="rect.borderDisplay"
              :titleColor="rect.titleColor"
              :valueColor="rect.valueColor"
              :unitColor="rect.unitColor"
              :unitDisplay="rect.unitDisplay"
              :titleDisplay="rect.titleDisplay"
              :titleSize="rect.titleSize"
              :valueSize="rect.valueSize"
              :unitSize="rect.unitSize"
              :valueDisplay="rect.valueDisplay"
              :decimalValue="rect.decimalValue"
              :cardBackground="'black'"
              :isChanged="rect.isChanged"
              :barBreakPoints="rect.breakPoints"
              :isReplay="rect.isReplay"
              :unit_conversion_factor="rect.unit_conversion_factor"
              v-bind:class="[
                isRightOpen || $store.state.uis.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
            ></vertical-bar-widget>

            <rosebud-widget
              v-if="rect.name == 'directional'"
              :log_type="rect.log_type"
              :directionalID="index"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :textColor="rect.textColor"
              :backgroundColor="rect.backgroundColor"
              :rangeStart="rect.rangeStart"
              :rangeEnd="rect.rangeEnd"
              :radarStart="rect.radarStart"
              :radarEnd="rect.radarEnd"
              :showGrid="rect.showGrid"
              :logTypes="rect.logTypes"
              :isRadarVisible="rect.isRadarVisible"
              :numberOfDataPoints="rect.numberOfDataPoints"
              v-bind:class="[
                isRightOpen || $store.state.uis.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
            ></rosebud-widget>

            <gauge-widget
              v-if="rect.name == 'gauge'"
              :id="rect.widgetId"
              :displayId="displayId"
              :autoFit="rect.autoFit"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.width"
              :widgetWidth="rect.height"
              :fontSize="rect.width * 0.08"
              :title="rect.title"
              :fullName="rect.fullName"
              :unit="rect.unit"
              :squareDisplay="rect.squareDisplay"
              :titleDisplay="rect.titleDisplay"
              :gaugeStartAngle="rect.startAngle"
              :gaugeEndAngle="rect.endAngle"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :rangeStart="rect.rangeStart"
              :rangeEnd="rect.rangeEnd"
              :bgColor="rect.bgColor"
              :saveColor="rect.saveColor"
              :valueDecimals="rect.valueDecimals"
              :interval="rect.interval"
              :niddleAngle="rect.niddleAngle"
              :saveValueColor="rect.saveValueColor"
              :saveTitleColor="rect.saveTitleColor"
              :saveNeedleColor="rect.saveNeedleColor"
              :labelFontPercent="rect.labelFontPercent"
              :alertColor="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :borderDisplay="rect.borderDisplay"
              :gaugeBreakPoints="rect.breakPoints"
              :isChanged="rect.isChanged"
              :arcCount="rect.arcCount"
              :isReplayEnable="rect.isReplay"
              :unit_conversion_factor="rect.unit_conversion_factor"
              :unitColor="rect.unitColor"
              v-bind:class="[
                isRightOpen || $store.state.uis.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
            ></gauge-widget>

            <DataTable
              v-if="rect.name == 'data-table'"
              :id="rect.widgetId"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :headColor="rect.headColor"
              :headBackground="rect.headBackground"
              :color="rect.gridColor"
              :background="rect.backgroundColor"
              :headFontSize="rect.headFontSize"
              :alertColor="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :tableData="rect.table"
              :tag="rect.tag"
              :unit="rect.unit"
              :unit_conversion_factor="rect.unit_conversion_factor"
              :getTableColumns="getTableColumns(cols)"
              v-on:dblclick="openProperty(index, rect)"
            ></DataTable>

            <WellDetail
              v-if="rect.name == 'well-detail'"
              :id="rect.widgetId"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :headColor="rect.headColor || '#cccccc'"
              :headBackground="rect.headBackground || '#0d0d0d'"
              :color="rect.gridColor || '#fff'"
              :background="rect.backgroundColor || '#28282B'"
              :headFontSize="rect.headFontSize"
              :alertColor="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :tableData="rect.table"
              :tag="rect.tag"
              :getTableColumns="getTableColumns(cols)"
            ></WellDetail>

            <plotly-graph
              v-if="rect.name == 'plotly-graph'"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :gridColor="rect.gridColor"
              :backgroundColor="rect.backgroundColor"
              v-on:dblclick="openProperty(index, rect)"
              :mnemonicTag1="rect.mnemonicTag1"
              :mnemonicTag2="rect.mnemonicTag2"
              :mnemonicTag3="rect.mnemonicTag3"
              :mnemonicTag4="rect.mnemonicTag4"
            ></plotly-graph>

            <PlanVsActual
              v-if="rect.name == 'plan-vs-actual'"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :gridColor="rect.gridColor"
              :backgroundColor="rect.backgroundColor"
              v-on:dblclick="openProperty(index, rect)"
              :mnemonicTag1="rect.mnemonicTag1"
              :mnemonicTag2="rect.mnemonicTag2"
              :mnemonicTag3="rect.mnemonicTag3"
              :mnemonicTag4="rect.mnemonicTag4"
              :xRangeStart="rect.xRangeStart"
              :xRangeEnd="rect.xRangeEnd"
              :zRangeStart="rect.zRangeStart"
              :zRangeEnd="rect.zRangeEnd"
              :yRangeStart="rect.yRangeStart"
              :yRangeEnd="rect.yRangeEnd"
              :xAutoScale="rect.xAutoScale"
              :yAutoScale="rect.yAutoScale"
              :zAutoScale="rect.zAutoScale"
            ></PlanVsActual>

            <TvdSection
              v-if="rect.name == 'line-graph'"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :xAxisLabel="rect.xAxisLabel"
              :yAxisLabel="rect.yAxisLabel"
              :gridColor="rect.gridColor"
              :gridBackground="rect.gridBackground"
              :xAxisLabelSize="rect.xAxisLabelSize"
              :yAxisLabelSize="rect.yAxisLabelSize"
              :isSwitched="rect.isSwitched"
              :actualColor="rect.actualColor"
              :planColor="rect.planColor"
              :backgroundColor="rect.backgroundColor"
              v-on:dblclick="openProperty(index, rect)"
              :xRangeStart="rect.xRangeStart"
              :xRangeEnd="rect.xRangeEnd"
              :yRangeStart="rect.yRangeStart"
              :yRangeEnd="rect.yRangeEnd"
              :xAutoScale="rect.xAutoScale"
              :yAutoScale="rect.yAutoScale"
              :unit="rect.unit"
              :unit_conversion_factor="rect.unit_conversion_factor"
            ></TvdSection>
            <VerticalSection
              v-if="rect.name == 'line-graph-tvd'"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :xAxisLabel="rect.xAxisLabel"
              :yAxisLabel="rect.yAxisLabel"
              :gridColor="rect.gridColor"
              :gridBackground="rect.gridBackground"
              :xAxisLabelSize="rect.xAxisLabelSize"
              :yAxisLabelSize="rect.yAxisLabelSize"
              :isSwitched="rect.isSwitched"
              :actualColor="rect.actualColor"
              :planColor="rect.planColor"
              :backgroundColor="rect.backgroundColor"
              v-on:dblclick="openProperty(index, rect)"
              :xRangeStart="rect.xRangeStart"
              :xRangeEnd="rect.xRangeEnd"
              :yRangeStart="rect.yRangeStart"
              :yRangeEnd="rect.yRangeEnd"
              :xAutoScale="rect.xAutoScale"
              :yAutoScale="rect.yAutoScale"
              :unit="rect.unit"
              :unit_conversion_factor="rect.unit_conversion_factor"
            ></VerticalSection>

            <RigChart
              v-if="rect.name == 'rig-chart'"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :xAxisLabel="rect.xAxisLabel"
              :yAxisLabel="rect.yAxisLabel"
              :gridColor="rect.gridColor"
              :gridBackground="rect.gridBackground"
              :xAxisLabelSize="rect.xAxisLabelSize"
              :yAxisLabelSize="rect.yAxisLabelSize"
              :isSwitched="rect.isSwitched"
              :actualColor="rect.actualColor"
              :planColor="rect.planColor"
              :backgroundColor="rect.backgroundColor"
              v-on:dblclick="openProperty(index, rect)"
              :xRangeStart="rect.xRangeStart"
              :xRangeEnd="rect.xRangeEnd"
              :yRangeStart="rect.yRangeStart"
              :yRangeEnd="rect.yRangeEnd"
              :xAutoScale="rect.xAutoScale"
              :yAutoScale="rect.yAutoScale"
            ></RigChart>

            <BroomStickPlot
              v-if="rect.name == 'broom-stick-plot'"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :xAxisLabel="rect.xAxisLabel"
              :yAxisLabel="rect.yAxisLabel"
              :gridColor="rect.gridColor"
              :gridBackground="rect.gridBackground"
              :xAxisLabelSize="rect.xAxisLabelSize"
              :yAxisLabelSize="rect.yAxisLabelSize"
              :isSwitched="rect.isSwitched"
              :actualColor="rect.actualColor"
              :planColor="rect.planColor"
              :backgroundColor="rect.backgroundColor"
              :bhaStartTime="rect.bhaStartTime"
              :bhaEndTime="rect.bhaEndTime"
              :isReplay="rect.isReplay"
              v-on:dblclick="openProperty(index, rect)"
            >
            </BroomStickPlot>
            <WellCorrelation
              v-if="rect.name == 'correlation-plot'"
              :displayIndex="displayIndex"
              :widgetId="rect.widgetId"
              :key="index"
              :widgetIndex="index"
              :height="rect.height * (1 - 0.02)"
              :width="rect.width"
              :top="rect.top"
              :left="rect.left"
              :displayId="rect.displayId"
              :plotConfig="rect.plotConfig"
              :parentW="width"
              :parentH="height"
            >
            </WellCorrelation>
            <Lwd
              v-if="rect.name == 'LWD'"
              :displayIndex="displayIndex"
              :widgetId="rect.widgetId"
              :key="index"
              :widgetIndex="index"
              :height="rect.height - 15"
              :width="rect.width"
              :top="rect.top"
              :left="rect.left"
              :displayId="rect.displayId"
              :plotConfig="rect.plotConfig"
              :parentW="width"
              :parentH="height"
            >
            </Lwd>

            <alert-screen
              v-if="rect.name == 'alert-screen'"
              :selectedWellBore="selectedWellBore"
              :selectedWell="selectedWell"
              :displayId="rect.displayId"
              @alertWidgetClicked="alertWidgetClicked(index, rect)"
            ></alert-screen>
          </div>
        </VueDragResize>

      </div>
    </div>
  </div>
</template>

<script>
var dragX = null;
var dragY = null;
var gx = null;
var gy = null;
var elProps = null;

var test = function (a, b) {
  gx = a;
  gy = b;
};
var test1 = function (e) {
  (dragX = e.pageX)((dragY = e.pageY));
  let obj = document.getElementById(e.target.id);

  if (obj.offsetParent) {
    do {
      dragX = dragX - obj.offsetLeft;

      dragY = dragY - obj.offsetTop;
    } while ((obj = obj.offsetParent));
  }
  dragY = dragY - offsetY;
  dragX = dragX - offsetX;

  test(dragX, dragY);
};
var elId = null;
var elDisId = null;
var compId = null;
var targetId = null;

document.addEventListener(
  "dragenter",
  function (event) {
    targetId = event.target.id;
    //

    // highlight potential drop target when the draggable element enters it
    if (event.target.id == compId) {
      //
    }
  },
  false
);
document.addEventListener(
  "dragend",
  function (event) {
    targetId = event.target.id;
    //
  },
  false
);

import { defineAsyncComponent } from "vue";
import { ref } from "vue";
export default {
  name: "Maindash",
  props: {
    displayIndex: Number,
    height: Number,
    width: Number,
    displayId: String,
    isMinimize: {
      type: Boolean,
      default: false,
    },
    isScreenMaximized: Boolean,
  },
  components: {
    KillSheetWidget: defineAsyncComponent(() =>
      import("./Widgets/Killsheet/KillSheetWidget.vue")
    ),
    MudMotorHealth: defineAsyncComponent(() =>
      import("./Widgets/MudMotorHealth/MudMotorHealth.vue")
    ),
    PlanVsActual: defineAsyncComponent(() =>
      import("./Widgets/PlanVsActual/PlanVsActual.vue")
    ),
    NumericWidget: defineAsyncComponent(() =>
      import("./Widgets/Numeric/NumericWidget.vue")
    ),
    VueDragResize: defineAsyncComponent(() =>
      import("./DragAndDrop/vue-drag-resize.vue")
    ),
    AdminSubNavbar: defineAsyncComponent(() =>
      import("./Navbars/AdminSubNavbar.vue")
    ),
    LabelWidget: defineAsyncComponent(() =>
      import("./Widgets/Label/LabelWidget.vue")
    ),
    labelNumeric: defineAsyncComponent(() =>
      import("./Widgets/LabelNumeric/labelNumeric.vue")
    ),
    RosebudWidget: defineAsyncComponent(() =>
      import("./Widgets/Rosebud/Rosebud.vue")
    ),
    GaugeWidget: defineAsyncComponent(() =>
      import("./Widgets/Gauge/GaugeWidget.vue")
    ),
    DataTable: defineAsyncComponent(() =>
      import("./Widgets/DataTable/DataTableWidget.vue")
    ),
    VerticalBarWidget: defineAsyncComponent(() =>
      import("./Widgets/VerticalBar/VerticalBarWidget.vue")
    ),

    PlotlyGraph: defineAsyncComponent(() =>
      import("./Widgets/Babylon3dGraph/PlotlyGraph.vue")
    ),
    Background: defineAsyncComponent(() =>
      import("./Widgets/Background/Background.vue")
    ),
    Trend: defineAsyncComponent(() => import("./Widgets/Trend/Trends.vue")),
    DisTrend: defineAsyncComponent(() => import("./Widgets/DIS/DisTrend.vue")),

    TvdSection: defineAsyncComponent(() =>
      import("./Widgets/TvdSection/TvdSection.vue")
    ),
    VerticalSection: defineAsyncComponent(() =>
      import("./Widgets/VerticalSection/VerticalSection.vue")
    ),
    BroomStickPlot: defineAsyncComponent(() =>
      import("./Widgets/BroomStickPlot/BroomStickPlot.vue")
    ),
    WellDetail: defineAsyncComponent(() =>
      import("./Widgets/WellInfo/DataTableWidget.vue")
    ),
    WellCorrelation: defineAsyncComponent(() =>
      import("./Widgets/WellCorrelation/CorrelationPlot.vue")
    ),
    RigChart: defineAsyncComponent(() =>
      import("./Widgets/RigPieChart/RigChart.vue")
    ),

    HydraulicsCalculator: defineAsyncComponent(() =>
      import("./Widgets/HydraulicsCalculator/HydraulicsCalculator.vue")
    ),
    AlertScreen: defineAsyncComponent(() =>
      import("./Widgets/Alerts/AlertScreen.vue")
    ),
    SwabOrSurge: defineAsyncComponent(() =>
      import("./Widgets/SwabOrSurge/SwabOrSurge.vue")
    ),
    DirectionDifficultyWidget: defineAsyncComponent(() =>
      import("./Widgets/DDI/DirectionalDifficultyIndex.vue")
    ),
    XYAxisWidget: defineAsyncComponent(() =>
      import("./Widgets/XYAxis/xyaxis.vue")
    ),
    Reports: defineAsyncComponent(() =>
      import("./Widgets/Reports/Reports.vue")
    ),
    Lwd: defineAsyncComponent(() => import("./Widgets/Lwd/LwdMain.vue")),
  },

  data() {
    return {
      isDragStarted: false,
      classes: "",
      listWidth: 0,
      listHeight: 0,
      toggle_right_Sidebar: "",
      toggle_right_margin: "",
      show: true,
      propertyIndex: -1,
      selectedWidgetConfig: {},
      isRightSideBar: false,
      activatedIndex: -1,
      navbarOpen: false,
      isOpen: false,
      isRightOpen: false,
      childId: null,
      compId: null,
      entered: false,
      set_height: true,
      set_width: true,
    };
  },
  setup() {
    const childComponentRef = ref();

    const test = ref("Maindash Menu");
    return {
      test,
      childComponentRef,
    };
  },
  beforeMount() {
    this.compId = "id" + Math.random().toString(16).slice(2);
    // this.childId = id;

    // //
    // // elId = id;

    // // elDisId = this.displayId;
    // // compId = elId + elDisId;
    // this.compId = id;
    //
  },
  mounted() {
    try {
      let listEl = document.getElementById(this.dashId);
      this.listWidth = listEl.clientWidth;
      this.listHeight = listEl.clientHeight;
      console.log("rects ", this.rects);
      window.addEventListener("keypress", (e) => {
        //
        // if (e.key == "Delete" && this.activatedIndex > -1) {
        //   this.$store.dispatch("disp/deleteWidget", { id: this.activatedIndex });
        //   this.activatedIndex = -1
        // }
      });
      window.addEventListener("resize", () => {
        this.listWidth = listEl.clientWidth;
        this.listHeight = listEl.clientHeight;
      });
    } catch (error) {
      //
    }
    let listEl = document.getElementById(this.compId);
    this.listWidth = listEl.clientWidth;
    this.listHeight = listEl.clientHeight;

    window.addEventListener("resize", () => {
      this.listWidth = listEl.clientWidth;
      this.listHeight = listEl.clientHeight;
    });
  },
  watch: {
    rects() {
      let allRects = this.$store.state.rect.rects;
      return allRects.filter(this.noDisplays);
    },
    isScreenMaximized(val) {
      let allRects = this.$store.state.rect.rects;
      allRects.map((each) => {
        if (each?.name == "LWD") {
          let payload = {};
          payload.widgetId = each.widgetId;
          payload.left = each.left;
          payload.top = each.top;
          payload.type = "widget";
          if (
            val &&
            each.displayId == this.displayId &&
            Object.keys(this.$store.state.data).includes("main_nav_show") &&
            !this.$store.state.data.main_nav_show
          ) {
            payload.height = window?.innerHeight - 55;
            payload.width = window?.innerWidth - 20;
          } else {
            payload.height = window?.innerHeight - 145;
            payload.width = window?.innerWidth - 20;
          }
          this.$store.dispatch("rect/setDimensions", payload);
        }
      });
    },
  },

  computed: {
    rects() {
      let allRects = this.$store.state.rect.rects;
      allRects.forEach((each) => {
        if (each?.name == "LWD") {
          if (
            each.displayId == this.displayId &&
            Object.keys(this.$store.state.data).includes("main_nav_show") &&
            !this.$store.state.data.main_nav_show
          ) {
            //     each.height = window?.innerHeight - 55
            //     each.width = window?.innerWidth - 20
            each.resizable = false;
          } else {
            // if(!this.isScreenMaximized){
            each.height = window?.innerHeight - 145;
            each.width = window?.innerWidth - 20;
            each.resizable = true;
            // }
          }
        }
        return each;
      });
      return allRects.filter(this.noDisplays);
    },
    whStyles() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`,
      };
    },
  },

  methods: {
    setting_min_width(rect){
      if(rect?.name == "trend"){
        if(rect?.isDis){
          // FOR DIS
          return 1150;
        }
        else{
          // FOR REMAINING--- GENERAL DRILLING, HYDRAULICS
          if(rect?.plotConfig?.tracks?.length == 3){ // HYDRAULICS
            return 520;
          }
          return 800; // GENERAL DRILLING
        }
      }
      else if(rect?.name == "line-graph-tvd" || rect?.name == "line-graph"){ // FOR TVD AND VERTICAL SECTIONS
        return 490;
      }
      else{
        return rect.minw
      }
    },
    setting_min_height(rect){
      if(rect?.name == "trend"){
        if(rect?.isDis){
          // FOR DIS
          return 380;
        }
        else{
          // FOR REMAINING--- GENERAL DRILLING, HYDRAULICS
          return 380;
        }
      }
      else if(rect?.name == "line-graph-tvd" || rect?.name == "line-graph"){ // FOR TVD AND VERTICAL SECTIONS
        return 200;
      }
      else{
        return rect.minh
      }
    },
    onClickOfReports(){

    },
    // changeFontSizeNumaric(value) {
    //   this.isDragStarted = value;
    //   console.log("form maindash", value);
    // },
    alertWidgetClicked(index, rect) {
      console.log("checking for added parameters", index, rect);
      this.openProperty(index, rect);
    },
    getTableColumns(numOfCols) {},

    deleteWidget() {
      let menu = document.querySelector("#menu" + this.displayId);
      menu.style.visibility = "";
      this.$store.dispatch("disp/deleteDisplay", {
        displayId: this.displayId,
      });
      this.closePropertyFile();
    },

    resizable() {
      this.toggleResizable();
    },

    change() {
      this.toggleDraggable();
    },

    windowToggle(name) {
      this.classes = name;
    },

    noDisplays(rect) {
      return (
        rect.name != "display" &&
        rect.displayId == this.displayId &&
        rect.bgId == null
      );
    },

    startDrag(ev) {
      const rect = ev.target.getBoundingClientRect();
      let offsetX = ev.clientX - rect.x;
      let offsetY = ev.clientY - rect.y;
    },

    cravings: function () {
      if (this.$store.state.rect.rects) {
        return widge_resp_width;
      }
      if ((isRightOpen = true)) {
        return isRightOpenWidth;
      }
    },

    onDragEnter(e) {
      e.preventDefault();
      this.entered = true;
    },

    onDrop(event) {
      let ondropTargetId = event.target.id;
      if (this.compId === ondropTargetId) {
        let obj1 = document.getElementById(compId);

        elProps = JSON.parse(event.dataTransfer.getData("elProps"));
        let id = "id" + Math.random().toString(16).slice(2);

        this.wId = id;
        if (elProps.name != "display") {
          elProps.widgetId = id;
        }
        if (elProps.name == "Background" || elProps.name == "label_numeric") {
          elProps.backgroundId = "id" + Math.random().toString(16).slice(2);
        }

        elProps.displayId = this.displayId;
        let displayDtls = this.$store.state.disp.displays[this.displayId];

        let isDraggable = false;

        for (let i = 0; i < this.$store.state.disp.rects.length; i++) {
          if (this.displayId == this.$store.state.disp.rects[i].displayId) {
            isDraggable = this.$store.state.disp.rects[i].isDraggableWidget
              ? true
              : false;
          }
        }
        elProps.draggable = isDraggable;
        const offsetY = elProps.offsetY;
        const offsetX = elProps.offsetX;
        
        if (elProps.name == "trend") {
          console.log(
            "needed trend height: ",
            event.target.offsetWidth,
            event.target.offsetHeight
          );
          elProps.top = 5;
          elProps.left = 5;
          elProps.height = event.target.offsetHeight - 50;
          elProps.width = event.target.offsetWidth - 15;
        }
        if (elProps.name == "replay") {
          elProps.width = this.width - 50;
          elProps.height = this.height * 0.95;
        }
        if (elProps.name == "Dis") {
          elProps.width = this.width - 50;
          elProps.height = this.height * 0.95;
        }
        (dragX = event.pageX), (dragY = event.pageY);

        let obj = document.getElementById(event.target.id);

        if (obj.offsetParent) {
          do {
            dragX = dragX - obj.offsetLeft;

            dragY = dragY - obj.offsetTop;
          } while ((obj = obj.offsetParent));
        }
        dragY = dragY - offsetY;
        dragX = dragX - offsetX;

        test(dragX, dragY);

        setTimeout(this.test3, 500);


        if (elProps.widgets) {
          // alert(elProps.widgets.length);

          for (let widget of elProps.widgets) {
            let bgWiId = "bg" + Math.random().toString(16).slice(2);
            setTimeout(
              this.addSavedWidgets(
                widget,
                0,
                0,
                this.displayId,
                bgWiId,
                elProps.backgroundId
              ),
              500
            );
          }

        }
      } else {
      }
    },

    addSavedWidgets(widget, gx, gy, displayId, widgetId, backgroundId) {
      widget.displayId = displayId;
      if (widgetId) widget.widgetId = widgetId;
      if (backgroundId) {
        widget.bgId = backgroundId;
      }

      this.$store.dispatch("rect/addNewRect", {
        x: widget.left,
        y: widget.top,
        elProps: widget,
      });
    },

    test3() {
      this.$store.dispatch("rect/addNewRect", {
        x: gx,
        y: gy,
        elProps: elProps,
      });
      //to display default uint if dragged from menoninic list in screen
      if(elProps.name=='numeric'){
        this.$store.dispatch("rect/changeNumericUnit", {
          displayId:  elProps.displayId,
          widgetId: elProps.widgetId,
          value: elProps.units,
        });
      }
    },

    activateEv(index) {
      //
      this.activatedIndex = index;
      this.$store.dispatch("rect/setActive", { id: index, type: "widget" });
    },

    deactivateEv(index) {
      //
      this.$store.dispatch("rect/unsetActive", { id: index, type: "widget" });
    },

    changePosition(newRect, index, rect) {
      let payload = {};

      payload.widgetId = index;
      payload.height = newRect.height;
      payload.width = newRect.width;
      payload.left = newRect.left;
      payload.top = newRect.top;
      payload.type = "widget";
      if (rect?.name == "swaborsurge") {
        if (newRect.left >= 0) {
          payload.left = newRect.left;
        } else {
          rect.left = 0;
          payload.left = 0;
          let ele = document.getElementById(rect.widgetId);
          ele.getAttribute("class").includes("vdr") ? (ele.style.left = 0) : "";
        }
        if (newRect.top >= 0) {
          payload.top = newRect.top;
        } else {
          rect.top = 0;
          payload.top = 0;
          let ele = document.getElementById(rect.widgetId);
          ele.getAttribute("class").includes("vdr") ? (ele.style.top = 0) : "";
        }
      } else if (rect?.name == "LWD" && this.isScreenMaximized) {
        return;
      }
      this.$store.dispatch("rect/setDimensions", payload);
    },

    changeSize(newRect, index, rect) {
      this.changePosition(newRect, index, rect);
    },

    changeProperties(newRect, index) {
      this.changePosition(newRect, index);
    },

    openProperty(index, config) {
      console.log('this.$store.state.rect.selectedWidgetProps', config, index)
      if (
        this.$store.state.rect.selectedWidgetProps &&
        this.$store.state.rect.selectedWidgetProps.widgetId
      ) {
        if (
          config.widgetId == this.$store.state.rect.selectedWidgetProps.widgetId
        ) {
          this.$store.dispatch("disp/togglrRightSideBarWidth", { id: -1 });
        } else {
        }
      } else {
        this.$store.dispatch("disp/togglrRightSideBarWidth", { id: -1 });
      }
      this.$store.dispatch("rect/toggleRightSideBar", {
        id: index,
        value: config,
      });
    },

    closeRightNavigation(isClose) {
      this.isRightSideBar = false;
      this.toggle_right_Sidebar = "-16";
      this.toggle_right_margin = "0";
      this.isRightOpen = false;
    },

    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
      this.isRightOpen = !this.isRightOpen;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    dark() {
      document.querySelector("nav").classList.add("dark-mode");
      document.querySelector("div").classList.add("dark-mode");
      document.querySelector("button").classList.add("dark-mode");
      document.querySelector("a").classList.add("text-red-600");
      this.darkMode = true;
      this.$emit("dark");
    },

    light() {
      document.querySelector("nav").classList.remove("dark-mode");
      document.querySelector("div").classList.remove("dark-mode");
      document.querySelector("button").classList.remove("dark-mode");
      document.querySelector("a").classList.remove("text-red-600");
      this.darkMode = false;
      this.$emit("light");
    },
    setting_width(rect) {
      if (rect?.name == "swaborsurge" && this.set_width) {
        this.set_width = false;
        rect.width = window?.innerWidth - 20;
        rect.left = 5;
        return window?.innerWidth - 20;
      } else {
        return rect.width;
      }
    },
    setting_height(rect) {
      if (rect?.name == "swaborsurge" && this.set_height) {
        this.set_height = false;
        rect.height = window?.innerHeight - 127;
        return window?.innerHeight - 127;
      } else {
        return rect.height;
      }
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

:root {
  --text: #ffffff;
  --background: #1d1d23;
}

body {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background: #04070e;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: geometricPrecision;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
}

.drag-el {
  background-color: #3498db;
  color: white;
  padding: 5px;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
}

#app {
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  /* position: relative; */
  /* font-family: "Open Sans", sans-serif; */
  /* font-family: 'Lato', sans-serif; */
  /* "Lato", sans-serif; */
  background: #0e1016;
  overflow-x: hidden;
}

.filler {
  width: 100%;
  height: 100%;
  display: inline-block;
  /* position: absolute; */
}

.resize {
  resize: both;
  background-color: blue;
  overflow: hidden;
}

/* width */
::-webkit-scrollbar {
  height: 3px;
  width: 3px;
  /* display: none; */
}

.top-16 {
  top: 3.9rem;
}

.left_bg {
  width: 9%;
  background: #1b1a1f;
}

.graph_down {
  right: 500px !important;
  position: absolute !important;
}

.resp_width {
  width: 100vw !important;
  /* width: 80% !important; */
  /* margin-left: auto !important;
  margin-right: auto !important; */
}

/* .widge_resp_width {
  width: 120px !important;
  right: 0 !important;
} */
/* .isRightOpenWidth {
  width: 500px !important;
} */
.graph_down {
  right: 330px !important;
  position: absolute;
}

/* .resp_width {
  width: 95% !important;
  margin-left: auto;
  margin-right: auto;
} */
.over_bg {
  background: #727174;
}

/* .minimize {
  width: 0px;
  height: 0px;
  animation-name: animate-minimize;
  animation-duration: 2s;
  border: solid 1px;
} */

.maximize {
  /* width: 400px;
  height: 400px; */
  height: 20px !important;
  position: fixed;
  bottom: 0;
  animation-name: animate-maximize;
  animation-duration: 2s;
  border: solid 1px;
  width: 250px !important;
}

.item {
  flex: 1;
  width: 100%;
  font-weight: 100;
  /* background: #04070e; */
  font-size: 8px;
  color: var(--deleteDragRizeWindowBgColor);
  letter-spacing: 1px;
  /* color: #5aba81; */

  /* border-bottom: 1px solid lightgrey; */
  box-sizing: border-box;
  padding: 5px 10px;

  display: flex;
  align-items: center;

  transition: background 0.2s, color 0.1s;

  /* &:hover {
		cursor: pointer;
		background: slateblue;
		color: white;
		transition: background .2s, color .1s;
	} */
}

.context_img {
  width: 15px;
  margin-right: 8px;
}

.delete {
  /* color: red !important; */
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  color: white !important;
  font-weight: 700;
  /* background: red !important; */
}

.delete:hover {
  color: white !important;
  /* background: red !important; */
}

.checkbox {
  position: relative;
  right: 9px;
}

.label_heading {
  /* color: #5aba81 !important; */
  font-size: 8px !important;
  letter-spacing: 0.5px;
}

.label_heading:hover {
  /* color: white !important; */
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  border-color: transparent;
  content: "";
  background-color: unset !important;
}

[type="checkbox"],
[type="radio"] {
  content: "";
  /* background-color: #28282f !important; */
  background-color: unset !important;
  margin-left: auto;
}

[type="checkbox"]:checked {
  background-image: url("../assets/img/check_ico.svg") !important;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
}

.displayNone {
  display: none;
}

.dynamic {
  z-index: 999999;
}

.right_bg {
  z-index: 99999;
}

.lables_with_ico {
  display: flex;
}

.lables_with_ico > img {
  margin-right: 10px;
  background: #000000;
  padding: 3px 3px;
  border-radius: 100%;
  object-fit: cover;
}

.mneminics_mt {
  margin-top: 0px;
}

[type="checkbox"] {
  border: 1px solid gray;
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  border: 1px solid var(--activeTextColor) !important;
}

[type="checkbox"]:focus,
[type="radio"]:focus {
  outline-offset: none;
  --tw-ring-offset-color: none;
  --tw-ring-color: none;
}

/* .text-xs{
  font-size: 10px !important;
} */
.left_close_btn {
  padding: 0 1px;
  display: flex;
  height: 100%;
  align-self: center;
}

.righ_side_head_ {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 6px;
  width: 100%;
}

.hide_show_btn {
  top: 0.1rem;
}

.modal_width {
  width: 22%;
}

.modal_body {
  font-size: 10px;
  padding: 0 15px 18px 15px;
}

.label_ {
  font-size: 8px;
}

.font-color {
  /* color: white; */
  /* font-size: 0.95rem; */
  font-size: 10px;
  padding: 0px 8px;
  height: 20px;
  width: 53%;
  /* height:100%; */
  /* text-align: center; */
}

input[type="color"] {
  -webkit-appearance: none;
  padding: 0;
  border: none;
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 10px;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 10px;
}

.dark-mode {
  background: #1b1a1f;
  color: white !important;
  transition-delay: 150ms;
}

.well_drop_icon {
  float: right;
  margin-right: 10px;
  position: relative;
  top: -2px;
}
/* Date picker css start */
.dp__input {
  outline: none;
  width: 100%;
  font-size: min(0.9vw, 12px);
  font-weight: 600;
  line-height: 0.1rem;
  padding: 4px 0 4px 6px;
  color: #ffffff;
  border: 0.5px solid #898989;
  /* text-align: center; */
  /* height: 15px; */
}
.dp__input_icons {
  padding: 6px 4px;
  /* color: #ffffff; */
}
.dp__input_icon {
  top: 50%;
  width: 12%;
  color: var(--selectingTimeFeetColor);
  padding: 2px;
  /* display: none; */
  width: 20px;
  height: 11px;
  right: 0;
  left: auto;
}
.dp__clear_icon {
  top: 50%;
  right: 0;
  width: 8px;
}
.dp__menu_min_width {
  width: 100px !important;
}
.dp__cell_inner {
  height: 16px;
  width: 17px;
  font-size: 10px;
  /* color: white; */
}
.dp__action_row {
  padding: 6px 10px;
  height: 27px;
  font-size: 10px;
}
.dp__selection_preview {
  font-size: 0.53rem;
}
.dp__calendar_header_item {
  height: 20px;
  padding: 5px;
  width: 18px;
  font-size: 9px;
  /* color: white; */
}
.dp__month_year_select {
  width: 25%;
  height: 14px;
  font-size: 10px;
  /* color: white; */
}
.dp__calendar_wrap {
  width: 150px;
  /* height: 140px; */
}
.dp__menu {
  min-width: 150px;
}
.dp__action {
  font-size: 8px;
  padding: 2px 2px;
}
.dp__selection_preview {
  font-size: 0.46rem;
  color: #1976d2;
}
.dp__button {
  padding: 8px;
  height: 16px;
  position: relative;
}
.dp__button svg {
  height: 15px;
}
.dp__time_display {
  font-size: 10px;
  /* color: white; */
}
.dp__calendar {
  margin-bottom: 8px;
}
.dp__inc_dec_button {
  height: 36px;
  width: 26px;
}
.disTimeLogDatePicker input.dp__input {
  background: var(--colorBoxPopUpInputBg) !important;
  border: none !important;
  color: var(--textColor) !important;
  margin-left: 0px;
  font-weight: 600 !important;
  height: 20px !important;
  border-radius: 0px;
  width: 110px !important;
  text-align: left !important;
  padding-top: 2px !important;
}
.disTimeLogDatePicker .dp__input_icon {
  margin-top: -1px;
  fill: var(--textColor);
}
.tick line {
  stroke: var(--textColor);
}
.domain {
  color: var(--textColor);
}
.LwdMain .tick line {
  stroke: #000;
}
.LwdMain .tick text,
.LwdMain text.label,
.LWD_forPrint .leftbar-outer .tick text {
  fill: #000;
}
.LWD_forPrint .tick line {
  stroke: #d5d5d5;
}
.LWD_forPrint .tick text,
.LWD_forPrint text.label {
  fill: #d5d5d5;
}
.LwdMain .domain {
  color: #000;
}
.title-bar-controls {
  color: var(--textColor);
}
/* scale color end  */
.actcpopup .dp__input{
  padding-left: 10px !important;
  text-align: left !important;
  background: var(--searchBarBg);
  color: var(--searchColor);
  border: 0px !important;
}
.actcpopup .actc_datepicker input.dp__input::placeholder{
  font-size: 9px !important;
}
</style>
