<template>
  <div class="support_container flex justify-center items-center">
    <div class="support_body w-9/12 lg:w-4/12  py-4 px-5 overflow-auto">
      <div class="pb-2 flex justify-between items-center flex-col">
        <img :src="logoURL" alt="" class="login_logo" style="
            /* width: 100px;
            margin-bottom: 15px; */
            width: 50%;
            margin-bottom: -0px;
        ">
        <div class="fontWeight-600 hidden text-xs uppercase flex items-center w-full">
          <div>Send Mail To :</div>
          <div class="relative">
            <input
              ref="toMailInput"
              class="addBg fontWeight-600 bg-transparent border-0 text-xs px-2 m-0 ml-2 w-full"
              type="email"
              placeholder="To Mail"
              id="toMail"
              v-model="toMail"
              :disabled="tomail_disabled"
              style="
                height: 25px;
                margin-bottom: 2px;
                font-size: 13px;
                /* border-radius: 3px; */
                padding-right: 25px;
              "
              required
              :class="tomail_disabled ? 'bg-none' : ''"
              @blur="() => (tomail_disabled = true)"
            />
          </div>
        </div>
        <p class="text-xl font-bold">Contact Us</p>
      </div>

      <div
        class="w-full flex px-5 py-3 overflow-auto"
        style="gap: 30px; height: calc(100% - 50px)"
      >
        <div style="width: 100%" class="emailSubmit_Form">
          <form @submit.prevent="sendEmail" class="flex flex-col">
            <label for="from" class="text-xs fontWeight-600 mb-1">Enter Your Mail :</label>
            <input
              class="mb-3 bg_input"
              type="text"
              placeholder="Enter Your Mail"
              id="from"
              v-model="from"
              required
            />

            <label for="contact" class="text-xs fontWeight-600 mb-1">Enter Your Mobile Number :</label>
            <input
              class="mb-3 bg_input"
              type="text"
              placeholder="Enter Your Mobile Number"
              id="contact"
              v-model="contact"
              maxlength="10"
              @input="(e)=>e.target.value = e.target.value.replace(/\D/g,'')"
              accept="number"
              required
            />

            <label for="subject" class="text-xs fontWeight-600 mb-1" >Subject :</label>
            <input
              class="mb-3 bg_input"
              type="text"
              id="subject"
              placeholder="Subject"
              v-model="subject"
              required
            />

            <label for="body" class="text-xs fontWeight-600 mb-1">Notes :</label>
            <textarea
              class="mb-3 px-2 py-1 bg_input"
              id="body"
              v-model="body"
              required
            ></textarea>

            <div class="flex justify-between">
              <button
                class="sendMailbtn bgClr text-white text-xs font-semibold uppercase px-4 py-2  shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
                type="submit"
                :disabled="mail_Sending"
                style="background: var(--activeTextColor)"
              >
                <span v-if="!mail_Sending">Send</span>
                <span v-if="mail_Sending">Sending...</span>
              </button>

              <button
                class="text-xs font-semibold border-b p-0 self-end outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
                type="button"
                style="color: var(--activeTextColor); border-color: currentColor;"
                @click="()=> $router.push('/auth/login')"
              >
                <i class="fas fa-long-arrow-alt-left"></i> Go back to login page
              </button>
            </div>
          </form>
        </div>
        <!-- <div style="width:60%">
                  <canvas id="image_to_show" class="hidden"></canvas>
                  <p class="text-xs fontWeight-600 mb-1">Attachment :</p>
                  <img :src="capturedImage" v-if="capturedImage && fileType?.includes('image')" style="max-height: 40vh;" />
                  <iframe :src="capturedImage" v-if="capturedImage && fileType?.includes('application/pdf')" style="height: 40vh; width: 100%;"></iframe>
                  <div class="mt-3 flex items-center relative" style="gap: 10px;">
                    <div class="attachmentInfoDiv absolute p-2 text-xs" v-if="showAttachmentInfo">
                      <div>
                        <p>This form supports single file upload, compress/zip to upload multiple files.</p>
                      </div>
                    </div>
                      <button class="ml-1"
                        :class="showAttachmentInfo ? {color: 'var(--activeTextColor)'} : ''"
                        @click="()=> showAttachmentInfo = !showAttachmentInfo"
                        @blur="()=> showAttachmentInfo = false">
                          <i class="fas fa-info-circle"></i>
                      </button>
                      <input class="text-xs" ref="Upld_Attchmnt" type="file" @change="Upload_Attachment"/>
                  </div>
                </div> -->
      </div>
    </div>
    <Loader v-if="loader" />
  </div>
</template>
<script>
import Logo from "@/assets/img/apollodart_logo_white.png";
import darklogoImage from "@/assets/img/apollodart_logo_white.png";
import lightlogoImage from "@/assets/img/apollodart_logo_black.png";
// import lightlogoImage from "@/assets/img/apollodart_logo_black.png";
// import Logo from "@/assets/img/drillinktech_logo.png";
import Loader from "../layouts/Loader.vue";
import api from "../api/services"
export default {
  data() {
    return {
      Logo,
      tomail_disabled: true,
      toMail: "support@apollodart.com",
      from: "",
      contact: null,
      subject: "Issue-",
      body: "",
      // attachment: null,
      mail_Sending: false,
      loader: false,
      // capturedImage: null,
      // fileType: null,
      // showAttachmentInfo: false,
      // login_user_as_cc: '',
    };
  },
  components: {
    Loader,
  },
  methods: {
    async sendEmail() {
      let body_content = 'From: ' + this.from + '\n' + 'Contact: ' +  this.contact + '\n' + this.body
      let formData = new FormData();
      formData.append("recipients", this.toMail);
      formData.append("cc", '');
      formData.append("messageBody", body_content);
      formData.append("subject", this.subject);
      // if(this.attachment){
      //     formData.append("files",this.attachment[0])
      // }

      this.mail_Sending = true;
      this.loader = true;
      api.SupportServices.sendingEmailToselectedUsers(formData)
        .then(async (res) => {
          if(res.status == 200){

            let body_for_sender = this.body + '\n' + 'Thank you for reaching out to us. We have received your message and will contact you back shortly.'

            let toMailAddresses = this.from.replaceAll(" ", "").split(",");
            toMailAddresses = [...new Set(toMailAddresses)];
            //filtering empty values in array code starts
            toMailAddresses = await toMailAddresses.filter(
              (mail) => mail.replace(/\s/g, "") !== ""
            );
            let formData = new FormData();

            formData.append("recipients", toMailAddresses);
            formData.append("cc", '');
            formData.append("messageBody", body_for_sender);
            formData.append("subject", "Confirmation of your support request");

            api.SupportServices.sendingEmailToselectedUsers(formData)
            .then((response)=>{
              if(response.status == 200){
                this.mail_Sending = false;
                this.loader = false;
                this.$toast.success(`Mail sent successfully`, {
                  duration: "2000",
                  position: "top",
                  pauseOnHover: true,
                });
                this.subject = "Issue-";
                this.body = "";
                this.from = "";
                this.contact = "";
              }
            })
          }
        })
        .catch((err) => {
          this.mail_Sending = false;
          this.loader = false;
          this.$toast.error(err, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        });
    },
    // editToAddress(){
    //     this.tomail_disabled = false;
    //     setTimeout(()=>{
    //         this.$refs.toMailInput.focus();
    //     },0)
    // },
  },
  computed:{
    selectedThemeName(){
      return this.$store.state.rect.darkmode
    },
    logoURL(){
      if(this.$store.state.data.isVendor || this.$store.state.data?.themeDetails?.vendorDetails?.logo){
        return this.$store.state.data?.themeDetails?.vendorDetails?.logo;
      }
      else{
        if(this.selectedThemeName !== 'white'){
          return this.$store.state.data?.themeDetails?.defaultDarkThemeLogo || darklogoImage;
        }
        else{
          return this.$store.state.data?.themeDetails?.defaultLightThemeLogo || lightlogoImage;
        }
      }
    },
  },
};
</script>
<style scoped>
.support_container {
  background: var(--central_bg);
  height: 100vh;
}
.support_body {
  max-height: 90%;
  /* background: var(--sidebarbg); */
  background: var(--navBar2Bgsame);
  color: var(--textColor);
}
.sendMailbtn[disabled] {
  opacity: 0.6;
  pointer-events: none;
}
.bg-none {
  filter: opacity(0.6);
}
.addBg {
  background: var(--searchBarBg);
  color: var(--searchColor);
}
.emailSubmit_Form input {
  border: none;
  /* border-radius: 3px; */
  left: 0;
  height: 30px;
  font-size: 13px;
  padding: 0px 20px 2px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  appearance: auto;
  background: var(--searchBarBg);
  color: var(--searchColor);
}
.emailSubmit_Form textarea {
  border: none;
  /* border-radius: 3px; */
  font-size: 13px;
  background: var(--searchBarBg);
  color: var(--searchColor);
}
.emailSubmit_Form textarea:focus-visible {
    outline: none;
}
.captr_N_SendMail {
  color: var(--searchColor);
}
.fontWeight-600 {
  font-weight: 600;
}
</style>
