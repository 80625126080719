import api from "../../../api/services";
import { openIndexedDB } from "../../../helper-services.js";
import { db } from "../../../db";
// Placeholder function to open IndexedDB

const dept = "dept";
export default {
  settingUsersData({ commit }, dtls) {
    commit("settingUsersData", dtls);
  },
  async getWells({ commit, state }) {
    console.log("🚀 ➽ file: actions.js:9  ➽ getWells  ➽ commit ⏩");

    // try {
    //   let result = await api.WellServices.getWells({ customer: state.customer })
    //   try {
    //     const db = await openIndexedDB();
    //     const transaction = db.transaction(['apollo'], 'readwrite');
    //     const objectStore = transaction.objectStore('apollo');

    //     objectStore.put(result.data);

    //     transaction.oncomplete = () => {
    //       console.log('Object stored in IndexedDB.');
    //     };
    //   } catch (error) {
    //     console.error('Error storing object in IndexedDB:', error);
    //   }
    //   commit("setWells", result.data);
    // } catch (e) {
    //   console.error("Error in set wells : ", e);
    // }
  },

  setSingleValue({ commit }, dtls) {
    commit("setSingleValue", dtls);
  },
  setVendorDetils({commit},{vendor}){
    commit("setVendor", vendor);
  },
  async setCustomer({ commit }, { customer, isInitial }) {
    console.log(
      "🚀 ➽ file: actions.js:24  ➽ setCustomer  ➽ isInitial ⏩",
      isInitial
    );
    commit("setCustomer", customer);

    try {
      // try {
      //   const db = await openIndexedDB();
      //   const transaction = db.transaction(["apollo"], "readwrite");
      //   const objectStore = transaction.objectStore("apollo");
      //   const clearRequest = objectStore.clear();

      //   clearRequest.onsuccess = function () {
      //     console.log("Cleared data in ");
      //   };
      //   clearRequest.onerror = function (error) {
      //     console.error("Error clearing data in " + ": ", error);
      //   };
      // } catch (error) {
      //   console.error("error in clear indexdb : ", error);
      // }
      try {
        await db.well.clear();
      } catch (error) {
        console.error("error in db well clear", error.message);
      }
      let result = await api.WellServices.getWells({ customer: customer });
      let well_to_store_details = {};
      try {
        // console.time("set customer");
        // const db = await openIndexedDB();
        // const transaction = db.transaction(["apollo"], "readwrite");
        // const objectStore = transaction.objectStore("apollo");

        for (let i in result.data) {
          let well_obj = result.data[i];
          well_to_store_details[i] = { ...result.data[i] };
          for (let j in well_obj["wellbores"]) {
            well_obj = { ...result.data[i], ...result.data[i]["wellbores"][j] };
            // objectStore.put({ well_id: i, data: well_obj });
            try {
              await db.well.add({ well_id: i, data: well_obj });
            } catch (error) {}

            // delete well_obj.wellbores
            for (let k in well_obj["wellbores"][j]["logs"]) {
              delete well_to_store_details[i]["wellbores"][j]["logs"][k].tags;
              delete well_to_store_details[i]["wellbores"][j]["logs"][k]
                .displayTags;
              delete well_to_store_details[i]["wellbores"][j]["logs"][k].units;
              delete well_to_store_details[i]["wellbores"][j]["logs"][k]
                .description;
              delete well_to_store_details[i]["wellbores"][j]["logs"][k]
                .mnemonics;
            }
            delete well_obj.wellbores;
          }
        }

        // transaction.oncomplete = () => {
        //   console.timeEnd("set customer");
        //   console.log("Object stored in IndexedDB.");
        // };
      } catch (error) {
        console.error("Error storing object in IndexedDB:", error);
      }
      commit("setWells", well_to_store_details);
      console.log(" set customer isInitial: ", isInitial);
      if (!isInitial) {
        commit("setCustomerUpdate", true);
      }
    } catch (e) {
    } finally {
    }
  },
  async setCustomerLogo({ commit }, { customerLogo, isInitial }) {
    console.log(
      "🚀 ➽ file: actions.js:24  ➽ setCustomer  ➽ isInitial ⏩",
      isInitial
    );
    commit("setCustomerLogo", customerLogo);
  },
  async openedTab({ commit }, { tabNumber, isInitial }) {
    console.log(
      "🚀 ➽ file: actions.js:24  ➽ openedTab  ➽ isInitial ⏩",
      isInitial
    );
    commit("openedTab", tabNumber);
  },
  async setUser({ commit }, { userDetails, isInitial }) {
    console.log('settingUserDetails', userDetails)
    console.log(
      "🚀 ➽ file: actions.js:24  ➽ setCustomer  ➽ isInitial ⏩",
      isInitial
    );
    commit("setUser", userDetails);
  },

  setCustomerUpdate({ commit }, isCustomerUpdate) {
    commit("setCustomerUpdate", isCustomerUpdate);
  },

  async setTndWells({ commit, state }) {
    try {
      let response = await api.WellServices.getWellsInformation({
        customer: state.customer,
      });

      let wells = response.data.rows;
      commit("setTndWells", wells);
    } catch (error) {
      console.error(error);
    }
  },

  startLoading({ commit }) {
    commit("startLoader");
  },

  stopLoading({ commit }) {
    commit("stopLoader");
  },

  async getOptimizations({ commit }) {
    try {
      let response = await api.WellServices.getOptimizations();
      commit("getOptimizations", response.data.rows);
    } catch (error) {
      console.error("error in get optimizations : ", error);
    }
  },
  async getAlertAnnotations({ commit }) {
    try {
      let response = await api.AlertServices.listNodeAnnotation();
      console.log("alert annotations", response.data);
      commit("getAlertAnnotations", response.data);
    } catch (error) {
      console.error("error in get optimizations : ", error);
    }
  },

  async getAlertsList({ commit }) {
    try {
      let response = await api.AlertServices.listDefaultAlerts();
      console.log("alert s", response.data);
      commit("getAlertsList", response.data);
    } catch (error) {
      console.error("error in get optimizations : ", error);
    }
  },

  setAccessPermissions({ commit }) {
    commit("setAccessPermissions");
  },
  saveReplayDetailsToStore({ commit }, details) {
    commit("setReplayDetails", { details });
  },
  setBooleanValue({ commit }, newValue) {
    commit("SET_BOOLEAN_VALUE", newValue);
  },
  showHideHeader({ commit }, newValue) {
    commit("showHideHeader", newValue);
  },
  commonDispatcher({ commit }, { field, value }) {
    commit('commonDispatcher', { field, value })
  }
};
