<template>
  <nav
    class="right_bg close-right-sidebar-16rem z-40 md:right-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-w-2/12 z-10"
    v-bind:style="{ right: toggle_right_Sidebar + 'rem' }"
  >
    <div class="">
      <div class="flex bg-emerald-500 justify-between px-2 py-0 mneminics_mt">
        <div class="righ_side_head_">
          <svg
            class="twobarsRight"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="4"
            viewBox="0 0 12 4"
            fill="none"
            data-v-6a52651e=""
          >
            <path
              d="M11.3333 0H0.666626V1.33333H11.3333V0ZM0.666626 4H11.3333V2.66667H0.666626V4Z"
              fill="#777777"
              data-v-6a52651e=""
            ></path>
          </svg>
          <p class="sidebar-heading">Label</p>
        </div>
        <div>
          <svg
            v-on:click="closeNavigation"
            class="cursor-pointer ml-auto mt-0 w-2 left_close_btn"
            style="text-align: right"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
          >
            <path
              id="close_2_"
              data-name="close (2)"
              d="M8.874,7.636,14.715,1.8A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.8L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0"
              transform="translate(0 -0.136)"
              fill="#fff"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_one"
        class="flex bg-right_ justify-between pt-4 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/genaral_settings.png" alt="" />
            General Settings
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureOne"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureOne ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-3">
          <div class="custom-radio-wrap mb-2">
            <div class="flex flex-col mt-3">
              <p class="label_heading">Display Name</p>
              <input
                style="z-index: 9999"
                type="text"
                class="focus:ring-indigo-500 h-9 border-0 block w-5/12 fields font-color"
                @input="changeTitleHandler($event)"
                :value="title"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-on:click="toggle_height_handler_two"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/note.png" alt="" />
            Appearance
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureTwo"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureTwo ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-5 pt-3">
          <div class="form-group-checkbox">
            <label class="w-100 flex font-thin">
              <span class="label_heading"> Text Autofit</span>
              <input
                class="ml-auto leading-tight"
                type="checkbox"
                @click="toggleAutoHandler()"
                :checked="autoFit"
              />
            </label>
          </div>
          <div
            v-if="!this.autoFit"
            class="flex items-center justify-between mt-3"
          >
            <p class="label_heading">Title Font {{ this.titleSize }} %</p>
            <input
              min="0"
              max="100"
              type="range"
              class="focus:ring-indigo-500 h-9 block w-5/12"
              @input="fontValueHandler($event)"
            />
            <!-- :value="properties.titleSize" -->
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Title</p>
            <input
              type="color"
              min="0"
              max="13"
              id="color1"
              :value="this.titleColor"
              class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md"
              @input="colorValueHandler($event)"
            />
          </div>
        </div>
      </div>

      <div
        v-on:click="toggle_height_handler_three"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/shared_vision.png" alt="" />
            Visibility
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureThree"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureThree ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="px-3 pt-3">
          <div>
            <div class="form-group-checkbox">
              <label class="w-100 flex font-thin">
                <span class="label_heading"> Border </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                  @click="toggleBorderVisibiltyHandler()"
                  :checked="properties.borderDisplay"
                />
              </label>
              <!-- <input
                class="checkbox"
                type="checkbox"
                id="menu4"
                @click="toggleBorderVisibiltyHandler()"
              />
              <label class="check-label label_heading" for="menu4"
                >Border</label
              > -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <UpDownButtons
      :isExpandFeatureSix="isExpandFeatureSix"
      :toggle_height_handler_six="toggle_height_handler_six"
      :id="id"
      :displayId="properties.displayId"
      :widgetId="properties.widgetId"
      :closePropertyFile="closeNavigation"
    />
  </nav>
</template>

<script>
import UpDownButtons from "../UpDownButtons/UpDownButtons";
export default {
  name: "label-properties",
  components: {
    UpDownButtons,
  },
  props: {
    displayId: String,
    toggle_right_Sidebar: {
      type: String,
      default: "",
    },
    toggle_right_margin: {
      type: String,
      default: "",
    },
    id: [Number, String],
    properties: {
      default: {},
    },
    closeNavigation: Function,
  },
  data() {
    return {
      isExpandFeatureOne: true,
      isExpandFeatureTwo: false,
      isExpandFeatureThree: false,
      isExpandFeatureFour: false,
      isExpandFeatureFive: false,
      isExpandFeatureSix: false,
      idx: -1,
      // title: this.properties.title,
    };
  },
  methods: {
    changeTitleHandler(e) {
      this.$store.dispatch("rect/changeTitle", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    colorValueHandler(e) {
      this.$store.dispatch("rect/changeTitleColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    toggleAutoHandler() {
      this.$store.dispatch("rect/toggleAutoFit", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    toggleBorderVisibiltyHandler() {
      this.$store.dispatch("rect/toggleBorderVisibility", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    toggleTitleVisibiltyHandler() {
      this.$store.dispatch("rect/toggleTitleVisibility", {
        displayId: this.displayId,
        id: this.id,
      });
    },
    fontValueHandler(e) {
      this.$store.dispatch("rect/titleFontValue", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value ? parseInt(e.target.value) : 0,
      });
    },

    toggle_height_handler_one() {
      if (this.isExpandFeatureOne == true) {
        this.isExpandFeatureOne = false;
      } else {
        this.isExpandFeatureOne = true;
      }
    },
    toggle_height_handler_two() {
      if (this.isExpandFeatureTwo == true) {
        this.isExpandFeatureTwo = false;
      } else {
        this.isExpandFeatureTwo = true;
      }
    },
    toggle_height_handler_three() {
      if (this.isExpandFeatureThree == true) {
        this.isExpandFeatureThree = false;
      } else {
        this.isExpandFeatureThree = true;
      }
    },
    toggle_height_handler_four() {
      if (this.isExpandFeatureFour == true) {
        this.isExpandFeatureFour = false;
      } else {
        this.isExpandFeatureFour = true;
      }
    },
    toggle_height_handler_five() {
      if (this.isExpandFeatureFive == true) {
        this.isExpandFeatureFive = false;
      } else {
        this.isExpandFeatureFive = true;
      }
    },
    toggle_height_handler_six() {
      if (this.isExpandFeatureSix == true) {
        this.isExpandFeatureSix = false;
      } else {
        this.isExpandFeatureSix = true;
      }
    },
    dark() {
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      this.darkMode = false;
      this.$emit("light");
    },
  },
  mounted() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.properties.widgetId &&
          rects[i].displayId == this.properties.displayId
        )
          this.idx = i;
      }
    }
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
    autoFit() {
      if (this.$store.state.rect.rects[this.idx])
        return this.$store.state.rect.rects[this.idx].autoFit || false;
      return true;
    },
    titleSize(){
      if (this.$store.state.rect.rects[this.idx])
        return this.$store.state.rect.rects[this.idx].titleSize;
      // return true;
    },
    titleColor(){
      if (this.$store.state.rect.rects[this.idx])
        return this.$store.state.rect.rects[this.idx].titleColor || (this.darkDark !== 'white' ? '#63CDFF':  getComputedStyle(document.documentElement).getPropertyValue(
          "--activeTextColor"
        ) );
      // return true;
    },
    title(){
      if (this.$store.state.rect.rects[this.idx])
        return this.$store.state.rect.rects[this.idx].title;
      // return true;
    }
  },
};
</script>

<style scoped src="../../assets/styles/propstyle.css"></style>
