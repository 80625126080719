<script setup>
import { toRefs, ref } from "vue";
import UsersIcon from "./UersIcon.vue";
import { title } from "process";
const props = defineProps({
  lists: {
    type: Array,
    require: true,
    default: [],
  },
  displayProp: {
    type: String,
    require: true,
    default: "full_name",
  },
  valueProp: {
    type: String,
    require: true,
    default: "user_id",
  },
  multiple: {
    type: Boolean,
    require: false,
    default: false,
  },
  choosed: {
    type: Function,
    require: false,
  },
});
const { lists, choosed, displayProp, valueProp, multiple } = toRefs(props);
const isShowDrow = ref(false);
const selected = ref([]);
const selectHadler = (det) => {
  //check if available
  const ind = selected.value.findIndex(
    (e) => e[valueProp.value] == det[valueProp.value]
  );
  if (ind == -1) {
    if (multiple.value) {
      selected.value.push(det);
    } else {
      selected.value = [];
      selected.value.push(det);
    }
    choosed.value(selected.value);
  } else {
    selected.value.splice(ind, 1);
    choosed.value(selected.value);
  }
};
const checkedSelected = (id) => {
  //check if available
  const ind = selected.value.findIndex((e) => e[valueProp.value] == id);
  if (ind == -1) {
    return false;
  } else {
    return true;
  }
};
</script>
<template>
  <ul>
    <li v-for="list in lists" class="group_names_li"  @click="selectHadler(list)" :title="list[displayProp]">
      <span  :class="checkedSelected(list[valueProp])?'active_group':'dactive_group'"></span>{{ list[displayProp] }}
    </li>
  </ul>
</template>
<style scoped>
.group_names_li{
  padding: 5px;
  font-size: 14px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  white-space: nowrap; 
  width: 130px; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.active_group{
  border-left: 6px solid #0956b7;
  padding-right: 3px;
}
.dactive_group{
  /* border-left: 6px solid #0956b7; */
  padding-right: 3px;
}
</style>
