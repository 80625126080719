<template>
  <div
    class="__trend_bg_seperation flex relative"
    @contextmenu="this.setContextIdWidget"
  >
    <!-- without image -->
    <div
      class="block tool_group"
      :style="{
        top: this.mouse_y + 'px',
        left: this.mouse_x + 'px',
      }"
    >
      <p v-if="isActcBarHover" class="actc_tool text-white">
        {{ actcBarMessage }}
      </p>
    </div>
    <!-- without image end -->
    <div
      :id="'main' + compId"
      :style="{
        height: (isDrillingDis ? screenHeight : wh.height) + 'px',
        width: isDrillingDis ? '100%' : wh.width + 'px',
      }"
      :class="darkDark !== 'white' ? 'noUi-target_dup' : 'light_trend-mode'"
      class="arrangementClass"
    >
      <div class="flex justify-between">
        <div
          v-if="logType == 'time'"
          class="ml-0 mr-1 flex text-center text-sm mt-1"
        >
          <span class="flex" style="font-size: 5px">
            <div
              v-if="logType == 'time'"
              class="flex flex-col"
              style="height: fit-content"
            >
              <Datepicker
                class="disTimeLogDatePicker"
                v-model="scaleMinDate"
                :minDate="
                  new Date(scaleMinFixed).toLocaleString('en-US', {
                    timeZone: this.time_zone,
                  })
                "
                :maxDate="
                  new Date(scaleMaxFixed).toLocaleString('en-US', {
                    timeZone: this.time_zone,
                  })
                "
                :dark="darkDark != 'white'"
                textInput
                placeholder="MM/DD/YYYY"
                :enableTimePicker="false"
                locale="en-US"
                @update:modelValue="onFromDateChange"
              />
            </div>
            <div
              class="flex ml-1 mb-1"
              :class="this.isDis == false ? 'non_dis_sec' : ''"
              :style="{ gap: '6px', width: leftbarWidth + sliderWidth + 'px' }"
              style="width: 100px"
            >
              <select
                @change="selectTimeRange($event.target.value, true)"
                class="depth_pick newStWDeptInput"
                :style="{ borderRadius: '0px', padding: '0px' }"
              >
                <option
                  v-if="
                    zoomed_Y_Axis &&
                    timeRanges.indexOf(Number(currentTimeRange)) == -1
                  "
                  :value="currentTimeRange"
                  :selected="timeRanges.indexOf(currentTimeRange) == -1"
                >
                  {{ currentTimeRange }} HRS
                </option>
                <option
                  :selected="currentTimeRange == time"
                  v-for="(time, i) in timeRanges"
                  :key="i"
                  :value="time"
                >
                  {{ timeRangesNames[i] }}
                </option>
              </select>
            </div>
          </span>
        </div>

        <div
          v-if="logType == 'depth'"
          class="ml-0 mr-1 flex text-center text-sm mt-1"
        >
          <!-- <span class="text_xs feet_">Feet :</span> -->
          <span style="font-size: 5px">
            <div
              class="flex ml-1 mb-1"
              :class="this.isDis == false ? 'non_dis_sec' : ''"
              :style="{ gap: '6px', width: leftbarWidth + sliderWidth + 'px' }"
            >
              <select
                @change="selectFeet($event.target.value, true)"
                class="depth_pick newStWDeptInput"
                :style="{ borderRadius: '10px', padding: '0px' }"
              >
                <option
                  v-if="feetRange.indexOf(Number(currentFeetRange)) == -1"
                  :value="currentFeetRange"
                  :selected="feetRange.indexOf(currentFeetRange) == -1"
                >
                  {{ currentFeetRange }} FEET
                </option>
                <option
                  :selected="currentFeetRange == feet"
                  v-for="(feet, i) in feetRange"
                  :key="i"
                  :value="feet"
                >
                  {{ feet }} FEET
                </option>
              </select>
            </div>
          </span>
        </div>

        <div v-if="logType">
          <div class="flex justify-around mt-1">
            <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex" v-if="!isDrillingDis">
                <div class="mt-1 settingPopColor mb-1" :class="'white-white'">
                  Alert Annotations
                </div>
                <div class="ml-0.5 tool_section">
                  <input
                    type="checkbox"
                    :id="'alertswitch' + this.id"
                    class="tool_toggle_input"
                    v-bind:value="isShowAlerts"
                    @click="changeAlert()"
                  />
                  <label
                    class="tool_toggle_label ml-1 mt-1"
                    :class="'_alert'"
                    :for="'alertswitch' + this.id"
                    >Alerts :</label
                  >
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex">
                <div class="block mr-3" v-if="isDrillingDis">
                  <div
                    class="ml-0.5 tool_section live_section_btn_"
                    :title="status === 'UPDATING' ? 'Live' : 'No live data'"
                  >
                    <div
                      :class="
                        status == 'UPDATING'
                          ? ''
                          : 'tooltip__tip_live drillingDisTrend top'
                      "
                      :data-tip="
                        status === 'UPDATING' ? 'Live' : 'No live data'
                      "
                    >
                      <button
                        :class="status == 'UPDATING' ? '' : 'disabled '"
                        v-if="logType == 'time' || logType == 'depth'"
                        @click="makeItLive"
                        :disabled="liveDisabled"
                        class="button-depth noselect text_xs float-right ml-2 newStylesLiveBtn"
                        :style="
                          liveDisabled
                            ? 'color: var(--liveBtnGreen);' +
                              (this.logType == 'depth' ? '' : '')
                            : '' + (this.logType == 'depth' ? '' : '')
                        "
                      >
                        <div
                          class="liveBtnDot"
                          :style="
                            liveDisabled === true
                              ? 'background:#ffffff'
                              : 'background:gray'
                          "
                        ></div>
                        LIVE
                      </button>
                    </div>
                    <span
                      class="liveActiveBtn"
                      v-if="
                        status == 'UPDATING' && !liveDisabled && zoomed_Y_Axis
                      "
                    >
                      &nbsp;Click here for live !
                    </span>
                  </div>
                  <!-- <div
                    v-if="
                      status == 'UPDATING' && !liveDisabled && zoomed_Y_Axis
                    "
                    class="zoomResetMsz"
                  >
                    &nbsp;Click here for live !
                  </div> -->
                </div>

                <div
                  class="mt-1 settingPopColor mb-1"
                  :class="false ? 'text-gray-600' : 'white-white'"
                >
                  Tooltip
                </div>
                <div class="ml-0.5 tool_section">
                  <input
                    type="checkbox"
                    :id="'switch' + this.id"
                    class="tool_toggle_input"
                    v-bind:checked="isShowTooltip"
                    @click="changeTooltip()"
                  />
                  <label
                    class="tool_toggle_label ml-1 mt-1"
                    :class="false ? 'muted_bg' : '_tooltip'"
                    :for="'switch' + this.id"
                    >Tooltip :</label
                  >
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex">
                <div class="mt-1 settingPopColor mb-1">Zoom</div>
                <div class="ml-0.5 tool_section">
                  <!-- <input
                    type="checkbox"
                    :id="'zoomswitch' + this.id"
                    :checked="this.isZoom"
                    class="tool_toggle_input"
                    v-bind:value="isZoom"
                    @click="changeZoom()"
                  /> -->
                  <input
                    type="checkbox"
                    :id="'zoomswitch' + this.id"
                    :checked="this.isZoom"
                    :ref="'zoomswitch' + this.id"
                    class="tool_toggle_input"
                    v-bind:value="isZoom"
                    @click="changeZoom()"
                  />
                  <label
                    class="tool_toggle_label _zoom ml-1 mt-1"
                    :for="'zoomswitch' + this.id"
                    >Zoom :</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="logType"
        class="flex justify-start bg-white"
        :style="{
          width: isDrillingDis ? '100%' : templateWidth + 'px',
          height: bheight + 'px',
          background: 'var(--widgetBg)',
          transition: 'var(--transition)',
        }"
      >
        <div
          class="text-white"
          :style="{
            zIndex: 0,
            height: bheight + 'px',
            width: leftbarWidth + sliderWidth - (isDrillingDis ? 40 : 0) + 'px',
          }"
        >
          <div
            id="tempdiv"
            class="relative flex track_config_border bg-opacity-99 __bg_label_boxes"
            :class="{ drillingDisClass: isDrillingDis }"
            :style="{
              width:
                leftbarWidth + sliderWidth - (isDrillingDis ? 35 : 0) + 'px',
              marginRight: 0 + 'px',
              height: 45 + 'px',
              borderBottom: 'solid 0.5px darkgray',
            }"
          >
            <div class="t_d_col_1">
              <button
                class="colorbox"
                :style="{
                  backgroundColor: 'darkgray',
                }"
              />
            </div>
            <div
              :class="'curve_pop_opened'"
              :style="{
                right: singleTrackWidth + (!isDrillingDis ? 2 : 0) + 'px',
              }"
            ></div>
            <div
              class="name_numaric_con flex flex-col justify-center"
              :class="
                this.darkDark != 'white'
                  ? ' tooltip__tip_dark'
                  : ' tooltip__tip_light'
              "
            >
              <div
                v-if="logType == 'time'"
                class="flex flex-col"
                style="height: fit-content"
                title="Well Start/End Date"
              >
                <div
                  class="mt-0 flex flex-col text_xs"
                  style="
                    font-size: 0.6rem;
                    color: var(--textColor);
                    font-weight: var(--fontWeight);
                    margin: auto;
                  "
                  :style="{
                    'font-size': isDrillingDis ? '0.4rem' : '0.6rem',
                  }"
                >
                  <span>WELL START/END TIME</span>
                </div>
                <div style="gap: 10px; margin: auto" class="mt-1 flex flex-col">
                  <div
                    class="mt-0 flex flex-col text_xs"
                    style="
                      font-size: 0.58rem;
                      color: var(--textColor);
                      font-weight: var(--fontWeight);
                    "
                  >
                    <span>
                      {{
                        this.moment(scaleMinFixed).format("MM-DD-YYYY HH:mm:ss")
                      }}
                    </span>
                  </div>
                </div>
                <div
                  class="mt-0 flex flex-col text_xs"
                  style="
                    font-size: 0.58rem;
                    color: var(--textColor);
                    font-weight: var(--fontWeight);
                    margin: auto;
                  "
                >
                  <span>{{
                    this.moment(scaleMaxFixed).format("MM-DD-YYYY HH:mm:ss")
                  }}</span>
                </div>
              </div>
              <div v-if="logType == 'depth'" class="flex mt-1">
                <div class="block">
                  <div :style="{ rowGap: '2px' }" class="flex flex-col">
                    <div
                      class="mt-0 flex flex-col text_xs"
                      style="
                        font-size: 0.6rem;
                        color: var(--textColor);
                        font-weight: var(--fontWeight);
                        margin: auto;
                      "
                      :style="{
                        'font-size': isDrillingDis ? '0.4rem' : '0.6rem',
                      }"
                    >
                      <span>WELL DEPTHS</span>
                    </div>
                    <div class="ml-0.5 tool_section newStWDepth">
                      <input
                        :style="{ height: '10px !important' }"
                        disabled
                        type="number"
                        :value="
                          !isNaN(this.scaleMinFixed)
                            ? this.scaleMinFixed.toFixed(0)
                            : 0
                        "
                        class="depth_pick newStWDeptInput"
                      />
                    </div>
                    <div class="ml-0.5 tool_section w-full newStWDepth">
                      <input
                        :style="{ height: '10px !important' }"
                        disabled
                        type="number"
                        :value="
                          !isNaN(this.scaleMaxFixed)
                            ? this.scaleMaxFixed.toFixed(0)
                            : 0
                        "
                        class="depth_pick newStWDeptInput"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="tempdiv"
            class="relative flex track_config_border bg-opacity-99 __bg_label_boxes"
            :class="[
              getAlertClassOnCondition(curve),
              isDrillingDis ? 'name_numaric_con drillingDisClass' : '',
            ]"
            :style="{
              width:
                leftbarWidth + sliderWidth - (isDrillingDis ? 35 : 0) + 'px',
              marginRight: 0 + 'px',
              height: 30 + 'px',

              borderBottom: 'solid 0.5px darkgray',
            }"
          >
            <div class="t_d_col_1">
              <button
                class="colorbox"
                :style="{
                  backgroundColor: 'darkgray',
                }"
              ></button>
            </div>

            <div
              class="name_numaric_con flex flex-col justify-center"
              :class="
                this.darkDark != 'white'
                  ? ' tooltip__tip_dark'
                  : ' tooltip__tip_light'
              "
              :style="{ display: 'flex !important' }"
            >
              <!-- <div v-if="logType == 'time'" class="flex flex-col" style="height: fit-content">
                <Datepicker v-model="scaleMinDate" :minDate="new Date(scaleMinFixed).toLocaleString('en-US', {
                  timeZone: this.time_zone,
                })
                  " :maxDate="new Date(scaleMaxFixed).toLocaleString('en-US', {
    timeZone: this.time_zone,
  })
    " :dark="darkDark != 'white'" textInput placeholder="MM/DD/YYYY" :enableTimePicker="false"
                  locale="en-US" @update:modelValue="onFromDateChange" />
              </div> -->
              <div v-if="logType == 'depth'" class="flex mt-1">
                <div class="block"></div>
              </div>
            </div>
          </div>
          <div
            id="tempdiv"
            class="relative flex track_config_border bg-opacity-99 __bg_label_boxes"
            :class="[
              getAlertClassOnCondition(curve),
              isDrillingDis ? 'drillingDisClass' : '',
            ]"
            :style="{
              width:
                leftbarWidth + sliderWidth - (isDrillingDis ? 35 : 0) + 'px',
              marginRight: 0 + 'px',
              height: 45 + 'px',
            }"
          >
            <div class="t_d_col_1">
              <button
                class="colorbox"
                :style="{
                  backgroundColor: 'darkgray',
                }"
              />
            </div>

            <div
              class="name_numaric_con flex flex-col justify-center"
              :class="
                this.darkDark != 'white'
                  ? ' tooltip__tip_dark'
                  : ' tooltip__tip_light'
              "
            >
              <div
                v-if="logType == 'time'"
                class="flex flex-col"
                style="height: fit-content"
                title="Scale Start/End Date"
              >
                <div
                  class="mt-0 flex flex-col text_xs"
                  style="
                    font-size: 0.6rem;
                    color: var(--textColor);
                    font-weight: var(--fontWeight);
                    margin: auto;
                  "
                  :style="{
                    'font-size': isDrillingDis ? '0.4rem' : '0.6rem',
                  }"
                >
                  <span>SCALE START/END TIME</span>
                </div>
                <div style="gap: 10px; margin: auto" class="mt-1 flex flex-col">
                  <div
                    class="mt-0 flex flex-col text_xs"
                    style="
                      font-size: 0.58rem;
                      color: var(--textColor);
                      font-weight: var(--fontWeight);
                    "
                  >
                    <span>
                      {{
                        zoomed_Y_Axis
                          ? this.moment(zoomed_Y_Axis.domain()[1]).format(
                              "MM-DD-YYYY HH:mm:ss"
                            )
                          : this.moment(scaleMin).format("MM-DD-YYYY HH:mm:ss")
                      }}
                    </span>
                  </div>
                </div>
                <div
                  class="mt-0 flex flex-col text_xs"
                  style="
                    font-size: 0.58rem;
                    color: var(--textColor);
                    font-weight: var(--fontWeight);
                    margin: auto;
                  "
                >
                  <span>{{
                    zoomed_Y_Axis
                      ? this.moment(zoomed_Y_Axis.domain()[0]).format(
                          "MM-DD-YYYY HH:mm:ss"
                        )
                      : this.moment(scaleMax).format("MM-DD-YYYY HH:mm:ss")
                  }}</span>
                </div>
              </div>
              <div v-if="logType == 'depth'" class="flex">
                <div class="block">
                  <div :style="{ rowGap: '0.1px' }" class="flex flex-col">
                    <div
                      class="mt-0 flex flex-col text_xs"
                      style="
                        font-size: 0.6rem;
                        color: var(--textColor);
                        font-weight: var(--fontWeight);
                        margin: auto;
                      "
                      :style="{
                        'font-size': isDrillingDis ? '0.4rem' : '0.6rem',
                      }"
                    >
                      <span>SCALE DEPTHS</span>
                    </div>
                    <div class="ml-0.5 mb-0.5 tool_section newStWDepth">
                      <input
                        :style="{ height: '12px !important' }"
                        type="number"
                        :value="
                          !isNaN(this.scaleMin) ? this.scaleMin.toFixed(0) : 0
                        "
                        class="depth_pick newStWDeptInput"
                        @input="
                          ($event) => {
                            !isNaN($event.target.value) &&
                              selectStartFeet($event.target.value);
                          }
                        "
                        @keyup.enter="selectStartFeet($event.target.value)"
                      />
                    </div>

                    <div class="ml-0.5 tool_section w-full newStWDepth">
                      <input
                        :style="{ height: '12px !important' }"
                        type="number"
                        :value="
                          !isNaN(this.scaleMax) ? this.scaleMax.toFixed(0) : 0
                        "
                        @input="
                          ($event) => {
                            !isNaN($event.target.value) &&
                              selectEndFeet($event.target.value);
                          }
                        "
                        @keyup.enter="selectEndFeet($event.target.value)"
                        class="depth_pick newStWDeptInput"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center"></div>
        </div>
        <div
          class="flex"
          :class="darkDark !== 'white' ? 'dark_trend_mode' : 'light_trend-mode'"
          :style="{
            width: isDrillingDis ? '100%' : templateWidth + 'px',
            maxWidth: isDrillingDis ? '100%' : templateWidth + 'px',
            height: bheight + 'px',
            background: 'var(--widgetBg)',
            transition: 'var(--transition)',
          }"
        >
          <div
            v-if="trackConfig.tracks"
            :class="{
              'w-full': isDrillingDis,
            }"
          >
            <div
              class="flex h-full __label_boxes_container"
              :class="{
                'w-full': isDrillingDis,
              }"
              ref="disTrendRef"
            >
              <div
                class="__label_boxes"
                v-for="(track, j) in trackConfig.tracks"
                :key="j"
              >
                <button
                  id="tempdiv"
                  v-if="isDrillingDis && track.length < 3"
                  class="flex track_config_border bg-opacity-99 __bg_label_boxes justify-center items-center"
                  :class="{ drillingDisClass: isDrillingDis }"
                  :style="{
                    width: distrenddiv_sectionWidth / 3.5 + 'px',
                    marginRight: 0 + 'px',
                    height: 45 + 'px',
                    border: '0.4px solid black',
                  }"
                  @click="opneMnemonics"
                >
                  <i class="fas fa-plus-circle"></i>
                </button>

                <div
                  id="tempdiv"
                  v-for="(curve, i) in track"
                  :key="i"
                  class="relative flex track_config_border bg-opacity-99 __bg_label_boxes"
                  :class="[
                    getAlertClassOnCondition(curve || {}),
                    isDrillingDis ? 'drillingDisClass' : '',
                  ]"
                  :style="{
                    width:
                      (isDrillingDis
                        ? distrenddiv_sectionWidth / 3.5
                        : singleTrackWidth) + 'px',
                    marginRight: 0 + 'px',
                    height: 45 + 'px',
                  }"
                  :draggable="true"
                  @dragstart="
                    startDrag($event, {
                      name: 'child',
                      trackNbr: j,
                      color: 'green',
                      curveNbr: i,
                      title: curve,
                      origin: compId,
                    });

                    $event.stopPropagation();
                  "
                >
                  <div class="t_d_col_1">
                    <button
                      v-if="curve"
                      v-on:click="showProps1(j, i)"
                      id='j+"-"+i'
                      class="colorbox"
                      :style="{
                        backgroundColor: curve.color,
                      }"
                    ></button>
                  </div>
                  <div
                    v-if="
                      curveProps[j + '-' + i] && curveProps[j + '-' + i]['show']
                    "
                    :class="'curve_pop_opened'"
                    :style="{ right: singleTrackWidth + 2 + 'px' }"
                  >
                    <Props
                      :width="120"
                      :height="100 + '%'"
                      :curve="curveProps[j + '-' + i]['curve']"
                      @closeIt="closeIt(j, i)"
                      @apply="setCurveProps(j, i, $event)"
                      @delete="deleteCurve(j, i, $event)"
                      :alertArray="this.alertArr"
                      @conversion_factor="
                        (convFactors, dispToSelectedUnitFactor) =>
                          unitChangeHandler(
                            j,
                            i,
                            convFactors,
                            dispToSelectedUnitFactor
                          )
                      "
                      :fromDIS="true"
                      :showManualScaleCheckbox="true"
                    />
                  </div>
                  <div
                    v-if="curve"
                    class="name_numaric_con flex flex-col justify-center tooltip__tip top"
                    :class="
                      this.darkDark != 'white'
                        ? ' tooltip__tip_dark'
                        : ' tooltip__tip_light'
                    "
                    :data-tip="getLatestValuesToDisplay(curve)"
                  >
                    <div
                      style="paddingleft: 0.8px"
                      class="td_trnk"
                      :class="
                        [
                          'activitycode',
                          'alert',
                          'washoutindex',
                          'stickslipindex',
                        ].includes(curve.name)
                          ? 'colorBox_text_gray margin_top_title'
                          : 'colorBox_text_gray '
                      "
                    >
                      <!-- {{ curve.fullName.toUpperCase() }} -->
                      {{ getCrveNameFilter(curve) }}
                    </div>
                    <div
                      class="leading-none value_of_Mnemonic_in_DIS flex justify-center"
                    >
                      <p class="truncate">
                        {{ getLatestValuesToDisplay(curve) }}
                        &nbsp;
                      </p>
                    </div>
                    <div
                      class="font_xs flex justify-around rages_of_mnemonics_in_DIS"
                      v-if="
                        ![
                          'activitycode',
                          'alert',
                          'washoutindex',
                          'stickslipindex',
                        ].includes(curve.name)
                      "
                    >
                      <p>{{ returningOfScale(curve) }}&nbsp;</p>
                      <p :title="curve.unit" class="unit_wrap_word">
                        {{ curve.unit }}
                      </p>
                      <p>&nbsp;{{ returningOfScale1(curve) }}</p>
                    </div>
                  </div>
                  <div v-else class="mx-auto">
                    <i class="fa-solid fa-plus"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- live btn -->
      <Teleport
        v-if="!isDrillingDis"
        :to="'#teleportFromTrendsLiveBtn' + displayId"
      >
        <div class="flex">
          <div class="ml-0.5 tool_section live_section_btn">
            <div
              :class="status == 'UPDATING' ? '' : 'tooltip__tip_live top'"
              :data-tip="status === 'UPDATING' ? 'Live' : 'No live data'"
            >
              <button
                :class="status == 'UPDATING' ? '' : 'disabled '"
                v-if="logType == 'time' || logType == 'depth'"
                @click="makeItLive"
                :disabled="liveDisabled"
                class="button-depth noselect text_xs float-right ml-2 newStylesLiveBtn"
                :style="
                  liveDisabled
                    ? 'color: var(--liveBtnGreen);' +
                      (this.logType == 'depth' ? '' : '')
                    : '' + (this.logType == 'depth' ? '' : '')
                "
              >
                <div
                  class="liveBtnDot"
                  :style="
                    liveDisabled === true
                      ? 'background:#ffffff'
                      : 'background:gray'
                  "
                ></div>
                LIVE
              </button>
            </div>
          </div>
          <div
            v-if="status == 'UPDATING' && !liveDisabled && zoomed_Y_Axis"
            class="zoomResetMsz"
          >
            &nbsp;Click here for live !
          </div>
        </div>
      </Teleport>

      <div
        id="distrenddiv"
        class="flex justify-start"
        :style="{ width: isDrillingDis ? '100%' : width + 'px' }"
      >
        <div
          class="flex"
          :class="darkDark == true ? 'bg-black' : 'light_trend-mode'"
          :style="{
            height: (isDrillingDis ? screenHeight : singleTrackHeight) + 'px',
            width: isDrillingDis ? '100%' : templateWidth + 'px',
          }"
          :ref="isDrillingDis ? 'distrenddiv_section' : ''"
        >
          <div
            :style="{
              width: sliderWidth - (isDrillingDis ? 40 : 0) + 'px',
              minWidth: sliderWidth - (isDrillingDis ? 40 : 0) + 'px',
              height: wh.height - bheight - sliderWidth + 'px',
            }"
            :id="compId + 'localSliderParent'"
            class="slider-container flex relative noUi-target_dup"
          >
            <input
              v-if="isDrillingDis || wellId"
              :style="{
                top: -sliderWidth / 2 + 'px',
                left: isDrillingDis ? 'auto' : sliderWidth / 2 + 'px',
                width:
                  (isDrillingDis
                    ? this.screenHeight - 300
                    : singleTrackHeight) + 'px',
                height: sliderWidth - (isDrillingDis ? 40 : 0) + 'px',
              }"
              @input="onInputSliderHandler"
              @change="onChangeSliderHandler"
              id="range1"
              type="range"
              name="range1"
              :min="this.scaleMinFixed"
              :max="this.scaleMaxFixed"
              :step="
                logType == 'time' && logInterval
                  ? logInterval.split('_')[0] * 1000
                  : 1
              "
              :value="rangeInput"
            />
          </div>
          <div
            class="scrolldiv bg-opcity-95 flex"
            :class="[
              darkDark !== 'white' ? 'bg-black_mattie' : 'light_trend-mode',
              isDrillingDis ? '__label_boxes_container' : '',
            ]"
            :style="{
              height: (isDrillingDis ? screenHeight : singleTrackHeight) + 'px',
              width: isDrillingDis ? '100%' : templateWidth + 'px',
              maxWidth: isDrillingDis ? '100%' : width + 'px',
            }"
            :id="div_id"
            @drop="onDrop($event, 1)"
          ></div>
        </div>
        <div
          class="dropzone_newmnemonics"
          :id="div_id"
          @drop="onDrop($event, 1)"
        >
          <i id="drop_ocon_new" class="fas fa-plus"></i>
        </div>
      </div>
    </div>
    <div
      v-if="isShowTooltip && mouse_y_tooltip > 0"
      class="pointer-events-none"
    >
      <div
        class="fixed w-full pointer-events-none"
        style="background: var(--textColor); height: 1px"
        :style="{
          left:
            (this.$refs.disTrendRef.getBoundingClientRect().left || 0) +
            (this.isDisProps ? 5 : 0) +
            'px',
          top: tooltip_horizontalline_ypos + 'px',
          width: isDrillingDis
            ? trackConfig.tracks.length
              ? distrenddiv_sectionWidth / 1.1 - 22 + 'px'
              : '100%'
            : trackConfig.tracks.length
            ? singleTrackWidth * trackConfig.tracks.length +
              5 * trackConfig.tracks.length -
              2 +
              'px'
            : '100%',
        }"
      ></div>
      <div
        class="absolute track_config_border_tooltip"
        :style="{
          width:
            (isDrillingDis
              ? distrenddiv_sectionWidth / 4
              : singleTrackWidth * 0.98) + 'px',
          overflow: 'hidden',
          backgroundColor:
            this.darkDark != 'white'
              ? 'rgba(255,255,255,0.9)'
              : 'rgba(51,65,85,0.9)',
          top: this.mouse_y_tooltip + 'px',
          minHeight: isDrillingDis ? 'auto' : '4.5em',
          // left:
          //   10 +
          //   this.singleTrackWidth * j +
          //    5 +
          //   sliderWidth +
          //   leftbarWidth +
          //   'px',
          left: isDrillingDis
            ? leftbarWidth -
              sliderWidth +
              55 +
              (distrenddiv_sectionWidth / 3.5) * j +
              (j + 3) * 4 +
              'px'
            : leftbarWidth +
              sliderWidth +
              singleTrackWidth * j +
              (j + 1) * 5 +
              'px',
        }"
        style="color: var(--textColorTooltip)"
        v-for="(track, j) in trackConfig.tracks"
        :key="j"
      >
        <p
          class="relative"
          :style="{
            width: singleTrackWidth + 'px',
            textAlign: 'left',
            fontSize: '0.8em',
            marginLeft: '10%',
          }"
        >
          {{
            logType == "time"
              ? this.moment(this.tooltip_d["time"]).format("HH:mm:ss")
              : this.tooltip_d["dmea"]
          }}
        </p>
        <p
          v-for="(curve, i) in track"
          :key="i"
          class="flex"
          :style="{
            width: singleTrackWidth + 'px',
            textAlign: 'left',

            fontSize: '0.7rem',
            wordBreak: 'break-all',
          }"
        >
          <span
            v-show="curve.name == 'alert' ? this.tooltip_d[curve.name] : true"
            :style="{
              color: curve.color,
              fontSize: '3.5em',
              lineHeight: '0rem',
              marginTop: '0.4rem',
            }"
          >
            &#8226;
          </span>
          &nbsp;{{
            this.tooltip_d[curve.name]
              ? this.tooltip_d[curve.name]
              : curve.name == "alert"
              ? ""
              : 0
          }}
        </p>
      </div>
    </div>
    <div
      v-if="this.isShowAlerts"
      class="block tool_group"
      :style="{
        top: this.mouse_y + 'px',
        left: this.mouse_x + 'px',
      }"
    >
      <div
        v-for="i in alertAnnotations"
        :key="i"
        class="px-1 py-1 border-green-700 text-green-700 tooltip_actc"
      >
        <template v-if="i.alert_name == hoveringAlert">
          <span class="tooltiptext">
            <div class="grid-container">
              <div class="item1 _item_">
                <div class="flex">
                  <div class="left_width font_xs">
                    <div class="flex justify-between">
                      <p class="ann_head">
                        {{ i.alert_name }}
                        <br />
                      </p>
                    </div>
                    <ul class="ann_para">
                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description1"
                      ></div>
                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description2"
                      ></div>

                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description3"
                      ></div>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- <div class="item2 _item_">
                <div class="flex ...">
                  <div class="left_width font_xs">
                    <ul class="ann_para">

                    </ul>
                  </div>
                </div>
              </div> -->
            </div>
          </span>
        </template>
      </div>
    </div>

    <div
      v-if="this.isShowAlerts"
      class="block tool_group"
      :style="{
        top: this.mouse_y_clicked + '%',
        right: this.mouse_x_clicked + '%',
      }"
    >
      <div
        v-for="i in alertAnnotations"
        :key="i"
        class="px-1 py-1 border-green-700 text-green-700 tooltip_actc"
      >
        <template v-if="i.alert_name == clickedAlert">
          <span class="tooltiptext">
            <div class="grid-container">
              <div class="item1 _item_">
                <div class="flex">
                  <div class="left_width font_xs">
                    <div class="flex justify-between">
                      <p class="ann_head">
                        {{ i.alert_name }}
                        <br />
                      </p>
                      <p
                        @click="clickedAlert = ''"
                        :class="!show ? '' : 'text-white'"
                        class="cursor-pointer"
                      >
                        <svg
                          @click="clickedAlert = ''"
                          class="crossToHide"
                          :class="{ lightIcon: darkDark == 'white' }"
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="11"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <path
                            @click="clickedAlert = ''"
                            d="M5.93996 5.00004L8.80662 2.14004C8.93216 2.01451 9.00269 1.84424 9.00269 1.66671C9.00269 1.48917 8.93216 1.31891 8.80662 1.19338C8.68109 1.06784 8.51083 0.997314 8.33329 0.997314C8.15576 0.997314 7.9855 1.06784 7.85996 1.19338L4.99996 4.06004L2.13996 1.19338C2.01442 1.06784 1.84416 0.997314 1.66663 0.997314C1.48909 0.997314 1.31883 1.06784 1.19329 1.19338C1.06776 1.31891 0.997231 1.48917 0.997231 1.66671C0.997231 1.84424 1.06776 2.01451 1.19329 2.14004L4.05996 5.00004L1.19329 7.86004C1.13081 7.92202 1.08121 7.99575 1.04737 8.07699C1.01352 8.15823 0.996094 8.24537 0.996094 8.33337C0.996094 8.42138 1.01352 8.50852 1.04737 8.58976C1.08121 8.671 1.13081 8.74473 1.19329 8.80671C1.25527 8.86919 1.329 8.91879 1.41024 8.95264C1.49148 8.98648 1.57862 9.00391 1.66663 9.00391C1.75463 9.00391 1.84177 8.98648 1.92301 8.95264C2.00425 8.91879 2.07798 8.86919 2.13996 8.80671L4.99996 5.94004L7.85996 8.80671C7.92193 8.86919 7.99567 8.91879 8.07691 8.95264C8.15815 8.98648 8.24528 9.00391 8.33329 9.00391C8.4213 9.00391 8.50844 8.98648 8.58968 8.95264C8.67092 8.91879 8.74465 8.86919 8.80662 8.80671C8.86911 8.74473 8.91871 8.671 8.95255 8.58976C8.9864 8.50852 9.00382 8.42138 9.00382 8.33337C9.00382 8.24537 8.9864 8.15823 8.95255 8.07699C8.91871 7.99575 8.86911 7.92202 8.80662 7.86004L5.93996 5.00004Z"
                            fill="white"
                          />
                        </svg>
                      </p>
                    </div>
                    <ul class="ann_para">
                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description1"
                      ></div>
                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description2"
                      ></div>

                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description3"
                      ></div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </template>
      </div>
    </div>
    <ACTCPopup
      v-if="isShowACTACPopup"
      :scaleFromDate="scaleMinFixed"
      :scaleToDate="scaleMaxFixed"
      :timeZone="time_zone"
      :wellId="wellId"
      :wellboreId="wellboreId"
      :actc_list="actc_list"
      :active_actc="active_actc_code"
      :displayName="displayName ? displayName : ''"
      @close_actc="isShowACTACPopup = false"
      @fetch_data="fetching_data_after_actc_save"
    />
    <div v-if="isDataLoading" class="loadingtext">Loading...</div>
    <div
      v-if="isDataLoading"
      class="loadingbgckground"
      :class="{ drillingDisLoader: isDrillingDis }"
      :style="{
        height: singleTrackHeight + 'px',
        width: templateWidth - 170 + 'px',
        maxWidth: width - 170 + 'px',
      }"
    ></div>
  </div>
</template>

<script>
import { template } from "../../../js/vertical/template.js";
import plotConfig from "../../../js/vertical/wellconfig.js";
import * as d3 from "d3";
import Props from "../Trend/Trendprops.vue";
import CustomSelect from "../../Select/SelectComponent.vue";
import apiService from "../../../api/services";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import defaultRanges from "../../../js/ranges";
import moment from "moment-timezone";
import { useReplay } from "../../../composable/useReplay.js";
import helperServices from "../../../helper-services.js";
import ACTCPopup from "./ACTCPopup.vue";
// import actcCodes from "./actccodes.json"

export default {
  name: "DisTrend",
  components: {
    Props,
    CustomSelect,
    Datepicker,
    ACTCPopup,
  },
  data() {
    return {
      unit_conversion: false,
      tooltip_upper_scale: 288,
      tooltipUpdateIndex: null,
      alertTimes: [],
      zoomed_Y_Axis: null,
      tracks_left_position: [],
      tooltip_d: {},
      mouse_y_tooltip: 0,
      tooltip_horizontalline_ypos: null,
      sliderValue: 0,
      mnemonic_groups: {},
      alertArr: [
        "ERRATIC MSE",
        "DRLG INEFF.",
        "ERRATIC ROP",
        "STACKING WEIGHT",
        "PRESSURE SPIKE",
        "BIT IMPACT",
        "MUD MOTOR DAMAGE POSSIBLE",
        "MUD MOTOR DAMAGE PROBABLE",
        "MOTOR STALL PROBABLE",
        "DIFF STICK",
      ],
      hoveringAlert: "",
      clickedAlert: "",
      mouse_y_clicked: 25,
      mouse_x_clicked: 2,
      scaleMinDate: "",
      isZoom: false,
      isRerendering: false,
      moment: moment,
      zero: [
        "wob",
        "mse",
        "diff",
        "spm1",
        "spm2",
        "spm3",
        "spp",
        "tgpm",
        "mfop",
        "rpm",
        "mg1c",
        "simplifiedmse",
        "chkp",
      ],
      double: [
        "hkl",
        "bpos",
        "dbtm",
        "dmea",
        "rop",
        "tor",
        "drtm",
        "inc",
        "az",
        "tvd",
        "dens_in",
        "dens_out",
        "temp_in",
        "temp_out",
      ],
      mouse_x: 0,
      mouse_y: 0,
      isEraticMse: false,
      isMotorStalled: false,
      isMotorDamagePossible: false,
      isMotorDamageProbable: false,
      isPressSpike: false,
      isErraticRop: false,
      isStackingWeight: false,
      isDrillInEfficiency: false,
      isDiffPressAlert: false,
      isBitImpact: false,
      // Socket Variables:
      stopUpdation: false,
      isShowTooltip: false,
      isShowAlerts: false,
      depthMaxValue: 0,
      depthMinValue: 0,
      currentTimeRange: 1,
      currentFeetRange: 500,
      topic: null,
      socketId: null,
      socket: null,

      liveDisabled: false,
      timeRanges: [0.0833333, 0.1666666, 0.25, 0.5, 1, 2, 8, 12, 24],
      timeRangesNames: [
        "5 MINS",
        "10 MINS",
        "15 MINS",
        "30 MINS",
        "1 HR",
        "2 HRS",
        "8 HRS",
        "12 HRS",
        "24 HRS",
      ],
      secondsdataavailability: false,
      focusAxisList: [],
      bgColor: "#202124",
      configurePlots: {},
      templateWidth: this.width - 10,
      div_id: "divId",
      tickColor: "#ededed",
      visible: false,
      sliderWidth: 100,
      sliderBG: "lightgray",
      readyToPlot: false,
      emptyPlot: true,
      currentCurve: null,
      selectedTrackValue: null,
      isNewTrack: false,
      curve: "",
      trackConfig: {},
      bheight: 138,
      wellId: null,
      wellboreId: null,
      logId: null,
      wellboreState: null,
      logType: null,
      data: [],
      completeData: [],
      zoomMin: null,
      zoomMax: null,
      plots: [],
      plotTemplate: template(""),

      sliderWidth: 50,
      leftbarWidth: this.isDrillingDis ? 50 : 120,
      trackWidth: 300,
      scaleMin: 0,
      scaleMax: 1000,
      rangeInput: 1000,
      scaleMinFixed: 0,
      scaleMaxFixed: 1000,
      singleTrackHeight: null,
      slider: null,
      singleTrackWidth: 120,
      totTracks: [],
      compId: "",
      curveProps: {},
      templateHeight: this.height - 10,
      tracksNbr: 6,
      tickThickness: 0.1,
      tracks: [],
      renderedHeight: false,
      renderedWidth: false,
      widthChanged: false,
      heightChanged: false,
      // wh: {
      //   width: this.width,
      //   height: this.height,
      // },
      feetRange: [50, 100, 500, 1000],
      feetRangeNames: [50, 100, 500, 1000],
      // hrsRange: ["default", 4, 8, 12, 24],
      feet: 500,
      hrs: "",
      track1Id: "",
      track2Id: "",
      track3Id: "",
      track4Id: "",
      track5Id: "",
      isSpecialtyScreenCreated: false,
      updateTimeout: null,
      status: -1,
      active_popover: false,
      isActcBarHover: false,
      actcBarMessage: "",
      timer: -1,
      myDisWatcher: null,
      allow_to_change: null,
      isDis: false,
      replayInterval: null,
      logInterval: null,
      hrs_24_data: null,
      interval_based_hrs_data: null,
      alertStickslip: [],
      alertWashout: [],
      isRenderGraph: true,
      streamingData: {},
      enableStoreStreamingData: false,
      displayName: null,
      time_zone: null,
      changedConversionFactors: {},
      dispToSelectedUnitConversionFactors: {},
      currentChangedMnemonicUnit: "",
      savedId: null,
      isShowACTACPopup: false,
      actcRectDetails: [],
      actc_list: [],
      time_zone: null,
      active_actc_code: "",
      isDataLoading: false,
      wellActcCodes: {},
      activity_codes_result: [],
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      distrenddiv_sectionWidth: 500,
      isDataLoading: false,
      openMnemoncsPOP: false,
    };
  },
  props: {
    opneMnemonicsModel: Function,
    height: {
      type: [Number, String],
      default: "50",
    },
    width: {
      type: [Number, String],
      default: "50",
    },
    id: String,
    divid: String,
    displayId: String,
    plotConfigProps: {
      type: Object,
      default: {},
    },
    isReplay: {
      type: Boolean,
      require: false,
      default: false,
    },
    isDisProps: {
      type: Boolean,
      default: false,
    },

    sectionWidth: {
      type: [Number, String],
      default: "50",
    },
    canvasSection: {
      type: [Number, String],
      default: "50",
    },
    isDrillingDis: {
      type: Boolean,
      default: false,
    },
  },
  beforeUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
    this.data = null;
    this.completeData = null;
    this.mnemonic_groups = null;
    this.takeOffSocket();
    if (this.myDisWatcher) this.myDisWatcher();
  },
  computed: {
    wh() {
      let wh = {};
      wh.width = this.width;
      wh.height = this.height;
      wh.templateWidth = this.width - 10;
      wh.templateHeight = this.height - 10;
      this.templateHeight = this.height - 10;
      this.templateWidth = this.width - 10;
      // if (this.trackConfig.tracks)
      //   this.setTrackWidth(this.trackConfig.tracks.length);
      // else this.setTrackWidth(0);
      // this.setTrackHeight();
      // this.rerender('WH');

      return wh;
    },
    darkDark() {
      d3.selectAll(".y2").style(
        "fill",
        this.$store.state.rect.darkmode !== "white" ? "white" : "black"
      );
      setTimeout(() => {
        this.rerender("darkDark");
      }, 0);
      return this.$store.state.rect.darkmode;
    },
    alertAnnotations() {
      return this.$store.state.data.annotations;
    },
  },
  sockets: {
    connect: function () {},
    disconnect(data) {
      console.log("data : disconnect : ", data);
      this.$socket.emit("unsubscribe", {
        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
        topic: this.topic,
        id: this.id,
        clientId: this.$clientId,
      });
    },
    error(data) {
      console.log("data : disconnect : ", data);
    },
    reconnect() {
      this.$socket.emit("subscribe", {
        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
        topic: this.topic,
        fields: this.fields,
        id: this.id,
        clientId: this.$clientId,
      });
    },
    reconnect_failed() {},
    reconnect_error() {},
    connect_error() {},
    connecting() {},
    connect_timeout() {},
  },
  beforeDestroy() {
    if (this.isDrillingDis)
      window.removeEventListener("resize", this.updateScreenSize);
  },
  methods: {
    checkigAndPlacingDiffStickAlert(result) {
      if (
        result.hasOwnProperty("diffstick_alert") &&
        result["diffstick_alert"].some((val) => val)
      ) {
        //diffstick_alert:DIFF STICK&2022-06-03 04:56:25.0
        let record = result["diffstick_alert"].find((val) => val);
        if (record) {
          let spliting = record.split("&");
          let alertName = spliting[0];
          let alertTime = spliting[1];
          if (!alertTime.includes("Z")) alertTime = alertTime + "Z";
          let alertEpochTime = new Date(alertTime).getTime();
          let hadTimeInCompleteData = this.completeData.hasOwnProperty("time")
            ? this.completeData["time"].findIndex(
                (eachTime) => eachTime == alertEpochTime
              )
            : -1;
          if (hadTimeInCompleteData >= 0) {
            this.completeData["alert"][hadTimeInCompleteData] = alertName;
          }
        }
      }
    },
    compareDateOnly(scalMindate) {
      try {
        if (
          this.scaleMinDate.split(",")[0] ==
          new Date(scalMindate)
            .toLocaleString("en-US", { timeZone: this.time_zone })
            .split(",")[0]
        ) {
          return false;
        } else {
          return true;
        }
      } catch (error) {
        return true;
      }
    },
    convertingDataFromDisptoSelectedUnit() {
      for (const factor in this.dispToSelectedUnitConversionFactors) {
        if (this.dispToSelectedUnitConversionFactors[factor] != "") {
          const mappedData = this.data[factor].map(
            (e) => e * this.dispToSelectedUnitConversionFactors[factor]
          );
          this.data[factor] = mappedData;
        }
      }
    },
    defaultRangesValidation(curveName, currentUnit, defaultRangeObj) {
      let obj = { ...defaultRangeObj };
      if (defaultRangeObj.m_units.replace(/[()]/g, "") != currentUnit) {
        if (defaultRangeObj.units.replace(/[()]/g, "") != currentUnit) {
          obj = null;
        } else {
          obj = {
            ...obj,
            m_units: obj.units,
            m_low: obj.low,
            m_high: obj.high,
          };
        }
      }
      return obj;
    },
    getCrveNameFilter(curve) {
      let name = curve.editedName
        ? curve.editedName
        : curve.full_name
        ? curve.full_name
        : curve.name
        ? curve.name.toUpperCase()
        : "";
      return name == "ACTIVITYCODE" ? "RIG STATE" : name;
    },
    async fetching_data_after_actc_save() {
      this.zoomed_Y_Axis = null;
      this.leftbarWidth = 120;
      this.timer = -1;
      this.takeOffSocket();
      this.data = [];
      this.completeData = [];
      this.mnemonic_groups = {};
      let minMax = await this.getMinMaxRange();
      this.scaleMin =
        minMax.max - 3600000 > minMax.min ? minMax.max - 3600000 : minMax.min;
      this.scaleMax =
        minMax.max - 3600000 > minMax.min ? minMax.max : minMax.min + 3600000;
      if (minMax.min > this.scaleMin) this.scaleMin = minMax.min;
      // if(minMax.max < this.scaleMax) this.scaleMax = minMax.max;
      this.scaleMinDate = new Date(this.scaleMin).toLocaleString("en-US", {
        timeZone: this.time_zone,
      });
      this.scaleMaxDate = new Date(this.scaleMax);
      this.scaleMinFixed = minMax.min;
      this.scaleMaxFixed = minMax.max;
      this.rangeInput = this.scaleMax;
      if (this.status == "UPDATING") {
        let timeout_var_livedata = setTimeout(() => {
          clearTimeout(timeout_var_livedata);
          this.enableStoreStreamingData = true;
          console.log("Get real time data before data ");
          this.getRealtimeData();
        }, 600);
      } else {
      }
      if (this.logType == "time") {
        // console.time('getting 24 hrs data with rendering from after actc save')
        this.enableStoreStreamingData = true;
        await this.fetch_data_and_render(
          "fromMounted",
          this.savedId ? false : true
        );
        // this.enableStoreStreamingData = false;
        // console.timeEnd('getting 24 hrs data with rendering from after actc save')
        await this.get_actc_codes();
      }
    },
    async setting_manual_activity_code_in_result(result) {
      let kafka_result = result;
      // CODE TO CHECK AND REPLACE THE MANUAL ACTC STARTS
      kafka_result["time"].map((val, inx) => {
        let manual_actc_code = null;
        if (this.activity_codes_result?.length) {
          manual_actc_code = this.activityTableRecords(
            parseInt(val),
            this.activity_codes_result
          );
        }
        if (manual_actc_code !== null && kafka_result?.activitycode) {
          kafka_result["activitycode"].splice(inx, 1, manual_actc_code);
        }
      });
      return kafka_result;
      // CODE TO CHECK AND REPLACE THE MANUAL ACTC ENDS
    },
    activityTableRecords(time, activity_codes_result) {
      if (activity_codes_result && activity_codes_result?.length) {
        let time_formatter = (tm) => new Date(tm).getTime();
        let activity_table_record = activity_codes_result.find((actc_obj) => {
          return (
            (time >= time_formatter(actc_obj.start_time) &&
              !actc_obj?.end_time) ||
            (time >= time_formatter(actc_obj.start_time) &&
              actc_obj?.end_time &&
              time <= time_formatter(actc_obj.end_time))
          );
        });
        if (activity_table_record) {
          return parseFloat(activity_table_record?.actc);
        }
      }
      return null;
    },
    async get_actc_codes() {
      try {
        let actc_codes = await apiService.actc.actc_search({
          well_id: this.wellId,
          wellbore_name: this.wellboreId,
        });
        if (actc_codes?.data && actc_codes?.data.length) {
          this.activity_codes_result = actc_codes?.data;
        } else {
          this.activity_codes_result = [];
        }
        let found = actc_codes.data.find((each) => each.status == 1);
        if (found) {
          this.active_actc_code = found;
          this.$toast.clear();
          this.$toast.info("Please close the active activity code", {
            position: "top",
            duration: 2000,
          });
        } else {
          this.active_actc_code = "";
        }
      } catch (err) {
        console.error(err);
        this.active_actc_code = "";
      }
    },
    async get_actc_list() {
      try {
        let actc_list = await apiService.actc.get_actc_list();
        if (actc_list.status == 200) {
          let found = actc_list.data.find(
            (obj) =>
              obj?.solutionType?.replace(/\s/g, "")?.toLowerCase() ==
              "actccodes"
          );
          this.actc_list = [];
          this.wellActcCodes = {};
          if (found && found?.mnemonicsList) {
            for (let actcKey in found?.mnemonicsList) {
              this.actc_list.push(found.mnemonicsList[actcKey]);
              this.wellActcCodes[found.mnemonicsList[actcKey].actcCode] = {
                color: found.mnemonicsList[actcKey].colorCode,
                name: found.mnemonicsList[actcKey].displayName,
              };
            }
            this.actc_list.push({
              activityName: "MISCELLANEOUS",
              colorCode: "#3f7c69",
              actcCode: "miscellaneous",
            });
          }
        } else {
          this.actc_list = [];
          this.wellActcCodes = {};
        }
      } catch (err) {
        console.error(err);
      }
    },
    async actcCodeClickHandler(xstart, yend) {
      console.log("event ", xstart, yend);
      this.actcRectDetails.every((r) => {
        if (yend <= r.end) {
          console.log("log details::", r);
          return false;
        } else {
          return true;
        }
      });
      await this.get_actc_codes();
      this.isShowACTACPopup = true;
    },
    handleVisibilityChange(event) {
      if (document.visibilityState === "hidden") {
        this.isRenderGraph = false;
      } else {
        this.isRenderGraph = true;
      }
    },
    recursiveFunction(arr, x, start, end, from) {
      let time_interval = Number(
        this.logInterval ? this.logInterval.split("_")[0] * 1000 : 1000
      );
      // Base Condition
      if (start > end || isNaN(end)) return null;

      console.log(" ~~~ start ~~~ end ~~~ ", start, end);
      // Find the middle index
      let mid = Math.floor((start + end) / 2);

      // Compare mid with given key x
      if (arr[mid] === x) return mid;

      // If element at mid is greater than x,
      // search in the left half of mid
      if (arr[mid] > x) {
        if (from == "scaleMax" && Math.abs(arr[mid] - x) < time_interval) {
          // SCALEMAX TO FIND NEAREST VALUE
          return mid;
        }
        return this.recursiveFunction(arr, x, start, mid - 1, from);
      } else {
        if (from == "scaleMin" && Math.abs(x - arr[mid]) < time_interval) {
          // SCALEMIN TO FIND NEAREST VALUE
          return mid;
        }
        // If element at mid is smaller than x,
        // search in the right half of mid
        return this.recursiveFunction(arr, x, mid + 1, end, from);
      }
    },
    async fetch_missing_data(maximumTime, minimumTime) {
      // this.$store.dispatch("data/startLoading");
      this.isDataLoading = true;
      let complete_data = {};
      if (this.logType == "time") {
        let fields = this.getOnScreenMnemonics();
        // await this.getMnemonicType(fields);
        let range = [];
        if (maximumTime > minimumTime) {
          range = [minimumTime, maximumTime];
        } else {
          range = [maximumTime, minimumTime];
        }
        console.log(" nth called 7th ");
        let result = await apiService.WellServices.getTimeLogs({
          fields: fields,
          type: this.isDrillingDis ? "time" : "SOLUTION",
          well_id: this.wellId,
          wellbore_name: this.wellboreId,
          secondsdataavailability: this.secondsdataavailability,
          isParse: 1,
          // days: 0.2,
          from: parseInt(range[1]),
          to: parseInt(range[0]),
          log_interval: this.logInterval
            ? this.logInterval.split("_")[0] * 1000
            : 1000,
          twentyfour_hrs: true,
          well_min: this.scaleMinFixed,
          well_max: this.scaleMaxFixed,
          topic: this.$store.state.disp?.displays[this.displayId]?.topicName,
        });
        if (result && result.data && result.data.data) {
          complete_data = { ...complete_data, ...result.data.data };
        }
      }
      // this.$store.dispatch("data/stopLoading");
      this.isDataLoading = false;
      return complete_data;
    },

    async fetch_data_for_stream(maximumTime, minimumTime) {
      // this.$store.dispatch("data/startLoading");
      this.isDataLoading = true;
      if (this.logType == "time") {
        let fields = this.getOnScreenMnemonics();
        // await this.getMnemonicType(fields);
        let range = [];
        if (maximumTime > minimumTime) {
          range = [minimumTime, maximumTime];
        } else {
          range = [maximumTime, minimumTime];
        }
        let complete_data = {};
        // for (let i in this.mnemonic_groups) {
        // if(thisgraph is replay)
        console.log(" nth called 1 st ");
        let result = await apiService.WellServices.getTimeLogs({
          fields: fields,
          type: this.isDrillingDis ? "time" : "SOLUTION",
          well_id: this.wellId,
          wellbore_name: this.wellboreId,
          secondsdataavailability: this.secondsdataavailability,
          isParse: 1,
          // days: 0.2,
          from: parseInt(range[1]),
          to: parseInt(range[0]),
          log_interval: this.logInterval
            ? this.logInterval.split("_")[0] * 1000
            : 1000,
          twentyfour_hrs: true,
          well_min: this.scaleMinFixed,
          well_max: this.scaleMaxFixed,
          topic: this.$store.state.disp?.displays[this.displayId]?.topicName,
        });
        if (result && result.data && result.data.data) {
          if (
            result.data.data["time"] &&
            result.data.data["time"].length == 0
          ) {
            complete_data = { ...complete_data, ...result.data.data };
          } else {
            // this.completeData = {
            //   ...this.completeData,
            //   ...result.data.data,
            // };
            complete_data = { ...complete_data, ...result.data.data };
            // this.data = this.completeData;
            // this.minmax = { ...this.minmax, ...result.data.minmax };
          }
        } else {
        }
        // }
        this.completeData = complete_data;
      }
      // this.$store.dispatch("data/stopLoading");
      this.isDataLoading = false;
    },
    async fetch_interval_based_hrs_data(domains) {
      // this.$store.dispatch("data/startLoading");
      this.isDataLoading = true;
      let fields = this.getOnScreenMnemonics();
      // await this.getMnemonicType(fields);
      let range = [];
      let scaleMin = this.scaleMin;
      let scaleMax = this.scaleMax;
      if (domains) {
        scaleMin = domains[0];
        scaleMax = domains[1];
      }
      if (this.currentTimeRange < 2) {
        let min = scaleMin - this.currentTimeRange * 1000 * 60 * 60;
        let max = scaleMax + this.currentTimeRange * 1000 * 60 * 60;
        let minDiff = 0;
        let maxDiff = 0;
        if (min < this.scaleMinFixed) {
          minDiff = this.scaleMinFixed - min;
          min = this.scaleMinFixed;
          max = max + minDiff;
        }
        if (max > this.scaleMaxFixed) {
          maxDiff = this.scaleMaxFixed - max;
          max = this.scaleMaxFixed;
          min = min + maxDiff;
        }
        range = [min, max];
      } else {
        range = [scaleMin, scaleMax];
      }
      console.log(" nth called 2 st ");
      // for (let i in this.mnemonic_groups) {
      let result = await apiService.WellServices.getTimeLogs({
        fields: fields,
        type: this.isDrillingDis ? "SOLUTIONS" : "SOLUTION",
        well_id: this.wellId,
        wellbore_name: this.wellboreId,
        secondsdataavailability: this.secondsdataavailability,
        topic: this.$store.state.disp?.displays[this.displayId]?.topicName,
        isParse: 1,
        // type: i,
        // days: 0.2,
        from:
          parseInt(this.scaleMaxFixed) < parseInt(range[1])
            ? parseInt(this.scaleMaxFixed)
            : parseInt(range[1]),
        to:
          parseInt(this.scaleMinFixed) > parseInt(range[0])
            ? parseInt(this.scaleMinFixed)
            : parseInt(range[0]),
        log_interval: this.logInterval
          ? this.logInterval.split("_")[0] * 1000
          : 1000,
        well_min: this.scaleMinFixed,
        well_max: this.scaleMaxFixed,
      });
      if (result.data.data.length == 0) {
        this.$toast.error(`Data Not Found`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
      if (result && result.data && result.data.data) {
        if (result.data.data["time"] && result.data.data["time"].length == 0) {
          this.interval_based_hrs_data = {
            ...this.interval_based_hrs_data,
            ...result.data.data,
          };
        } else {
          this.interval_based_hrs_data = {
            ...this.interval_based_hrs_data,
            ...result.data.data,
          };
        }
      } else {

      }
      // }
      // this.$store.dispatch("data/stopLoading");
      this.isDataLoading = false;
    },
    async fetch_data_and_render(from, issetDisplay = false) {
      await this.fetching_24_hours_data(from);
      await this.filterData(null, 1313);
      await this.rerender(issetDisplay);
    },
    async fetching_24_hours_data(from) {
      // this.$store.dispatch("data/startLoading");
      this.isDataLoading = true;
      if (this.logType == "time") {
        let fields = this.getOnScreenMnemonics();
        // await this.getMnemonicType(fields);
        let range = [];
        console.log(
          " fields ",
          fields,
          this.scaleMax,
          this.scaleMin,
          this.mnemonic_groups
        );
        if (this.scaleMax > this.scaleMin) {
          // if (
          //   from &&
          //   from == "fromMounted" &&
          //   Object.keys(this.streamingData).length
          // ) {
          //   this.scaleMax = this.streamingData["time"][0];
          //   this.scaleMin =
          //     this.scaleMax - this.currentTimeRange * 1000 * 60 * 60;
          // }
          let min = this.scaleMax - 4 * 1000 * 60 * 60; // TO FETCH 6 HRS DATA
          range = [min, this.scaleMax];
        } else {
          // if (
          //   from &&
          //   from == "fromMounted" &&
          //   Object.keys(this.streamingData).length
          // ) {
          //   this.scaleMin =
          //     this.streamingData["time"][0] -
          //     Number(
          //       this.logInterval ? this.logInterval.split("_")[0] * 1000 : 1000
          //     );
          //   this.scaleMax =
          //     this.scaleMin - this.currentTimeRange * 1000 * 60 * 60;
          // }
          let max = this.scaleMin - 4 * 1000 * 60 * 60; // TO FETCH 6 HRS DATA
          range = [max, this.scaleMin];
        }
        // for (let i in this.mnemonic_groups) {
        // if(thisgraph is replay)
        console.log(" nth called 3 st ");
        let result = await apiService.WellServices.getTimeLogs({
          fields: fields,
          type: this.isDrillingDis ? "SOLUTIONS" : "SOLUTION",
          well_id: this.wellId,
          wellbore_name: this.wellboreId,
          secondsdataavailability: this.secondsdataavailability,
          topic: this.$store.state.disp?.displays[this.displayId]?.topicName,
          isParse: 1,
          // type: i,
          // days: 0.2,
          from:
            parseInt(this.scaleMaxFixed) < parseInt(range[1])
              ? parseInt(this.scaleMaxFixed)
              : parseInt(range[1]),
          to:
            parseInt(this.scaleMinFixed) > parseInt(range[0])
              ? parseInt(this.scaleMinFixed)
              : parseInt(range[0]),
          log_interval: this.logInterval
            ? this.logInterval.split("_")[0] * 1000
            : null,
          twentyfour_hrs: true,
          display_name: this.displayName
            ? this.displayName.replace(/\s/g, "").toLowerCase()
            : null,
          well_min: this.scaleMinFixed,
          well_max: this.scaleMaxFixed,
        });
        if (result && result.data && result.data.data) {
          if (result.data.data?.time && result.data.data?.time.length == 0) {
            this.hrs_24_data = {};
            this.$toast.error("Data is not Found!", {
              position: "top",
              duration: 3000,
            });
          }
          if (
            result.data.data["time"] &&
            result.data.data["time"].length == 0
          ) {
          } else {
            this.hrs_24_data = {
              ...this.hrs_24_data,
              ...result.data.data,
            };
            // this.data = this.completeData;
            // this.minmax = { ...this.minmax, ...result.data.minmax };
          }
        } else {
        }
        // }
      }
      // this.$store.dispatch("data/stopLoading");
      this.isDataLoading = false;
    },
    async filterData(domains, from) {
      try {
        this.enableStoreStreamingData = true;
        console.log("~~~~~~~~~~~~~ from ~~~~~~~~~~ ", from, domains);
        // console.time(" filter data ")
        let indexs = [];
        let time_interval = Number(
          this.logInterval ? this.logInterval.split("_")[0] * 1000 : 1000
        );
        let recursiveFunction = function (arr, x, start, end, from) {
          // Base Condition
          if (start > end || isNaN(end)) return null;

          console.log(" ~~~ start ~~~ end ~~~ ", start, end);

          // Find the middle index
          let mid = Math.floor((start + end) / 2);

          // Compare mid with given key x
          if (arr[mid] === x) return mid;

          // If element at mid is greater than x,
          // search in the left half of mid
          if (arr[mid] > x) {
            if (from == "scaleMax" && Math.abs(arr[mid] - x) < time_interval) {
              // SCALEMAX TO FIND NEAREST VALUE
              return mid;
            }
            return recursiveFunction(arr, x, start, mid - 1, from);
          } else {
            if (from == "scaleMin" && Math.abs(x - arr[mid]) < time_interval) {
              // SCALEMIN TO FIND NEAREST VALUE
              return mid;
            }
            // If element at mid is smaller than x,
            // search in the right half of mid
            return recursiveFunction(arr, x, mid + 1, end, from);
          }
        };
        let scaleMin = this.scaleMin;
        let scaleMax = this.scaleMax;
        if (domains) {
          scaleMin = domains[0];
          scaleMax = domains[1];
        }
        // console.log('domains : ', domains)
        // console.time("Start Index");
        let startIndex = recursiveFunction(
          this.hrs_24_data["time"],
          scaleMin,
          0,
          this.hrs_24_data["time"]?.length - 1,
          "scaleMin"
        );
        // console.timeEnd("Start Index");
        // console.time("End Index");
        let endIndex = recursiveFunction(
          this.hrs_24_data["time"],
          scaleMax,
          0,
          this.hrs_24_data["time"]?.length - 1,
          "scaleMax"
        );
        // console.timeEnd("End Index");

        let temp = {
          2: 10,
          3: 20,
          4: 30,
          5: 40,
          6: 50,
          7: 60,
          8: 70,
          9: 80,
          10: 90,
          11: 90,
          12: 100,
          13: 100,
          14: 100,
          15: 100,
          16: 100,
          17: 100,
          18: 100,
          19: 100,
          20: 100,
          21: 100,
          22: 100,
          23: 100,
          24: 100,
        };
        let log_interval = this.logInterval
          ? this.logInterval.split("_")[0]
          : 1;
        console.log(
          " canvas : interval : start index : ",
          this.logInterval,
          startIndex,
          endIndex,
          scaleMin,
          scaleMax
        );
        if (log_interval > 4)
          temp = {
            2: 5,
            3: 10,
            4: 20,
            5: 20,
            6: 30,
            7: 30,
            8: 40,
            9: 40,
            10: 40,
            10: 40,
            12: 40,
            13: 40,
            14: 40,
            15: 40,
            16: 40,
            17: 40,
            18: 40,
            19: 40,
            20: 40,
            21: 40,
            22: 40,
            23: 40,
            24: 40,
          };

        let special_keys = [
          "alert",
          "stickslip",
          "stickslipindex",
          "washout",
          "washoutindex",
          "time_alerts",
        ];
        if ((!startIndex && startIndex != 0) || !endIndex) {
          // if index not found then fetching interval_based_hrs_data
          if (
            this.interval_based_hrs_data != null &&
            Object.keys(this.interval_based_hrs_data).length
          ) {
            startIndex = recursiveFunction(
              this.interval_based_hrs_data["time"],
              scaleMin,
              0,
              this.interval_based_hrs_data["time"].length - 1,
              "scaleMin"
            );
            endIndex = recursiveFunction(
              this.interval_based_hrs_data["time"],
              scaleMax,
              0,
              this.interval_based_hrs_data["time"].length - 1,
              "scaleMax"
            );
          }
          // IF DON'T HAVE DATA IN interval_based_hrs_data CODE STARTS
          if ((!startIndex && startIndex != 0) || !endIndex) {
            await this.fetch_interval_based_hrs_data(domains);

            startIndex = recursiveFunction(
              this.interval_based_hrs_data["time"],
              scaleMin,
              0,
              this.interval_based_hrs_data["time"].length - 1,
              "scaleMin"
            );
            endIndex = recursiveFunction(
              this.interval_based_hrs_data["time"],
              scaleMax,
              0,
              this.interval_based_hrs_data["time"].length - 1,
              "scaleMax"
            );
          }
          if (isNaN(parseInt(endIndex)))
            endIndex = this.interval_based_hrs_data["time"].length - 1;
          console.log("startIndex", startIndex, "endIndex", endIndex, scaleMax);
          let minmaxObj = {};
          this.completeData = {};

          // if (this.currentTimeRange > 2) {
          //   console.log(
          //     " current selected time range inside greater than : ",
          //     this.currentTimeRange
          //   );
          //   console.time("new intermediate logic");

          //   let interval = temp[parseInt(this.currentTimeRange)];
          //   for (
          //     let data_index = startIndex;
          //     data_index <= endIndex + 1;
          //     data_index = data_index + interval
          //   ) {
          //     for (let key in this.interval_based_hrs_data) {
          //       if (special_keys.indexOf(key) > -1) {
          //         continue;
          //       }
          //       if (this.completeData[key]) {
          //         this.completeData[key].push(
          //           this.interval_based_hrs_data[key][data_index]
          //         );
          //       } else {
          //         this.completeData[key] = [
          //           this.interval_based_hrs_data[key][data_index],
          //         ];
          //       }
          //       minmaxObj[key] = { min: 0, max: 1000 };
          //     }
          //   }

          //   for (let key of special_keys) {
          //     if (this.interval_based_hrs_data[key]) {
          //       this.completeData[key] = this.interval_based_hrs_data[
          //         key
          //       ].slice(startIndex, endIndex + 1);
          //       minmaxObj[key] = { min: 0, max: 1000 };
          //     }
          //     if (key == "time_alerts") {
          //       this.completeData[key] = this.interval_based_hrs_data[
          //         "time"
          //       ].slice(startIndex, endIndex + 1);
          //       minmaxObj[key] = { min: 0, max: 1000 };
          //     }
          //   }
          //   console.timeEnd("new intermediate logic");
          // } else {
          if ((startIndex || startIndex == 0) && (endIndex || endIndex == 0)) {
            for (let key in this.interval_based_hrs_data) {
              this.completeData[key] = this.interval_based_hrs_data[key].slice(
                startIndex,
                endIndex + 1
              );
              minmaxObj[key] = {
                min: 0,
                max: Math.max(...this.completeData[key]),
              };
            }
          } else {
            this.completeData = { ...this.interval_based_hrs_data };
          }
          // }

          // for (let key in this.interval_based_hrs_data) {
          //   this.completeData[key] = this.interval_based_hrs_data[key].slice(
          //     startIndex,
          //     endIndex + 1
          //   );
          //   minmaxObj[key] = {
          //     min: 0,
          //     max: Math.max(...this.completeData[key]),
          //   };
          // }
          this.data = { ...this.completeData };
          this.minmax = { ...this.minmax, ...minmaxObj };
          //For unit conversion

          // display_unit to recently changed conversion factor needed to convert data
          this.convertingDataFromDisptoSelectedUnit();

          // for (const mnemonickey in this.data) {
          //   if (Object.hasOwn(this.changedConversionFactors, mnemonickey)) {
          //     const mappedData = this.data[mnemonickey].map(
          //       (e) => e * this.changedConversionFactors[mnemonickey]
          //     );
          //     this.data[mnemonickey] = mappedData;
          //   }
          // }
          // await this.rerender("zoomIt");
          // console.log('startIndex from interval_based_hrs_data', startIndex, 'endIndex', endIndex)
          // console.timeEnd(" filter data ");
          return;
          // IF DONT HAVE DATA IN interval_based_hrs_data CODE ENDS
        }

        console.log("startIndex in 24 hrs ", startIndex, "endIndex", endIndex);
        let minmaxObj = {};
        this.completeData = {};
        // console.time("splice 24 hrs data");
        // for (let key in this.hrs_24_data) {
        //   this.completeData[key] = this.hrs_24_data[key].slice(
        //     startIndex,
        //     endIndex + 1
        //   );
        //   minmaxObj[key] = { min: 0, max: Math.max(...this.completeData[key]) };
        // }
        console.log(" current selected time range : ", this.currentTimeRange);
        if (this.currentTimeRange > 2) {
          console.log(
            " current selected time range inside greater than : ",
            this.currentTimeRange
          );
          console.time("new intermediate logic");

          let interval = temp[parseInt(this.currentTimeRange)];
          for (
            let data_index = startIndex;
            data_index <= endIndex + 1;
            data_index = data_index + interval
          ) {
            for (let key in this.hrs_24_data) {
              if (special_keys.indexOf(key) > -1) {
                continue;
              }
              if (this.completeData[key]) {
                this.completeData[key].push(this.hrs_24_data[key][data_index]);
              } else {
                this.completeData[key] = [this.hrs_24_data[key][data_index]];
              }
              minmaxObj[key] = { min: 0, max: 1000 };
            }
          }
          console.log(" this.completeData ", this.completeData);
          for (let key of special_keys) {
            if (this.hrs_24_data[key]) {
              this.completeData[key] = this.hrs_24_data[key].slice(
                startIndex,
                endIndex + 1
              );
              minmaxObj[key] = { min: 0, max: 1000 };
            }
            if (key == "time_alerts") {
              this.completeData[key] = this.hrs_24_data["time"]?.slice(
                startIndex,
                endIndex + 1
              );
              minmaxObj[key] = { min: 0, max: 1000 };
            }
          }

          console.timeEnd("new intermediate logic");
        } else {
          let last_length = this.hrs_24_data[this.logType].length;
          for (let key in this.hrs_24_data) {
            this.completeData[key] = this.hrs_24_data[key].slice(
              startIndex,
              endIndex + 1
            );
            minmaxObj[key] = { min: 0, max: 1000 };
          }
        }

        // console.timeEnd("splice 24 hrs data");
        // console.time("data Assign");
        this.data = { ...this.completeData };
        this.minmax = { ...this.minmax, ...minmaxObj };
        //For unit conversion
        // display_unit to recently changed conversion factor needed to convert data
        this.convertingDataFromDisptoSelectedUnit();

        // for (const mnemonickey in this.data) {
        //   if (Object.hasOwn(this.changedConversionFactors, mnemonickey)) {
        //     const mappedData = this.data[mnemonickey].map(
        //       (e) => e * this.changedConversionFactors[mnemonickey]
        //     );
        //     this.data[mnemonickey] = mappedData;
        //   }
        // }
        // console.timeEnd("data Assign");
        // console.timeEnd(" filter data ")
      } catch (error) {
        console.error("error : ", error);
      }
    },
    async unitChangeHandler(
      j,
      i,
      connversionfactor,
      displayUnitToSelectedUnitFactor
    ) {
      this.currentChangedMnemonicUnit = "";
      const name = this.trackConfig.tracks[j][i].name;
      this.currentChangedMnemonicUnit = name;
      this.changedConversionFactors[name] = connversionfactor.conversion_factor;
      var newConnversionfactor = {
        ...connversionfactor,
        name: name,
      };
      // this.$emit("unitConv_details", newConnversionfactor);
      let prevConvFactor = this.dispToSelectedUnitConversionFactors[name];
      this.dispToSelectedUnitConversionFactors[name] =
        displayUnitToSelectedUnitFactor.conversion_factor;
      // if (!this.liveDisabled) {
      if (connversionfactor.conversion_factor == 1) {
        if (this.trackConfig.tracks[j][i].scaleType == "manual") {
          let min = this.trackConfig.tracks[j][i].scale[0];
          let max = this.trackConfig.tracks[j][i].scale[1];
          this.trackConfig.tracks[j][i].scale = [
            min / prevConvFactor,
            max / prevConvFactor,
          ];
        } else {
          let defConf = defaultRanges.ranges.find((e) => e.name == name);

          if ((name == "spp" || name == "diff") && this.unit_conversion) {
            defConf = this.defaultRangesValidation(
              name,
              connversionfactor.to_unit,
              defConf
            );
          }
          // defConf = null;
          if (defConf) {
            this.trackConfig.tracks[j][i].scale[0] = Number(
              this.unit_conversion ? defConf.m_low : defConf.low
            );
            this.trackConfig.tracks[j][i].scale[1] = Number(
              this.unit_conversion ? defConf.m_high : defConf.high
            );
          } else {
            this.trackConfig.tracks[j][i].scale[0] = d3.min(
              this.completeData[name]
            );
            this.trackConfig.tracks[j][i].scale[1] = d3.max(
              this.completeData[name]
            );
          }
        }
        const mappedData = this.completeData[name].map(
          (e) => e * connversionfactor.conversion_factor
        );
        this.data[name] = mappedData;
      } else {
        this.trackConfig.tracks[j][i].scale[0] *=
          connversionfactor.conversion_factor;
        this.trackConfig.tracks[j][i].scale[1] *=
          connversionfactor.conversion_factor;

        const mappedData = this.data[name].map(
          (e) => e * connversionfactor.conversion_factor
        );
        this.data[name] = mappedData;
      }
      console.log("mapped__data", this.data);
      // this.$emit("unitConv_details", this.data);
      // }
      // console.log('mapped__data', this.data[name])
      setTimeout(async () => {
        console.log("Rerender after conversion");
        this.trackConfig.tracks[j][i].unit = connversionfactor.to_unit;
        this.trackConfig.tracks[j][i].conversion_factor =
          connversionfactor.conversion_factor;
        await this.rerender();
        this.currentChangedMnemonicUnit = "";
        // this.trackConfig.tracks[j][i].unit = connversionfactor.to_unit;
        // this.trackConfig.tracks[j][i].conversion_factor = connversionfactor.conversion_factor;
      }, 400);
    },
    clickZoomHandler(isZoomIn) {
      let tempScaleMin = 0;
      let tempScaleMax = 0;
      let zoomAmount = parseInt((this.currentTimeRange * 3600 * 1000) / 8);
      // console.log(
      //   "🚀 ➽ file: DisTrend.vue:1009  ➽ clickZoomHandler  ➽ zoomAmount ⏩",
      //   zoomAmount
      // );
      if (this.logType == "depth") {
        zoomAmount = parseInt(this.currentFeetRange / 8);
      }
      if (isZoomIn) {
        tempScaleMin = this.scaleMin + zoomAmount;
        tempScaleMax = this.scaleMax - zoomAmount;
      } else {
        tempScaleMin = this.scaleMin - zoomAmount;
        tempScaleMax = this.scaleMax + zoomAmount;
      }

      if (
        tempScaleMax != tempScaleMin &&
        tempScaleMax > tempScaleMin &&
        this.scaleMax > this.scaleMin &&
        tempScaleMax <= this.scaleMaxFixed &&
        this.scaleMinFixed <= tempScaleMin
      ) {
        this.scaleMax = tempScaleMax;
        this.rangeInput = this.scaleMax;
        this.scaleMin = tempScaleMin;
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "clickZoomHandler"
        );
      } else {
        this.$toast.info("Maximum Zoomed", {
          position: "top",
          duration: 1000,
        });
      }
    },
    onInputSliderHandler(event) {
      // console.log("🚀 ➽ file: DisTrend.vue:914  ➽ onInputSliderHandler  ➽ event ⏩" , event.target.value)
      this.sliderValue = event.target.value;
      if (this.logType == "depth") {
        if (
          event.target.value <=
          parseFloat(this.scaleMinFixed) + parseFloat(this.currentFeetRange)
        ) {
          this.scaleMin = this.scaleMinFixed;
          this.scaleMax =
            parseFloat(this.scaleMinFixed) + parseFloat(this.currentFeetRange);
        } else {
          this.scaleMin = event.target.value - this.currentFeetRange;
          this.scaleMax = parseInt(event.target.value);
        }
        this.rangeInput = event.target.value;
        // this.scaleMin = event.target.value - this.currentFeetRange;
        // this.scaleMax = parseFloat(event.target.value);
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "onInputSliderHandler"
        );
      }
    },
    async onChangeSliderHandler(event) {
      // console.log(
      //   "🚀 ➽ file: DisTrend.vue:913  ➽ onChangeSliderHandler  ➽ event.target.value ⏩",
      //   event.target.value
      // );
      if (this.liveDisabled) {
        this.takeOffSocket();
        this.liveDisabled = false;
      }

      this.isZoom = false;
      this.zoomed_Y_Axis = null;
      if (this.logType == "time" && event.target.value > 3600000) {
        if (
          event.target.value <=
          this.scaleMinFixed + this.currentTimeRange * 3600000
        ) {
          this.scaleMin = this.scaleMinFixed;
          this.scaleMax = this.scaleMinFixed + this.currentTimeRange * 3600000;
        } else {
          this.scaleMin = event.target.value - this.currentTimeRange * 3600000;
          this.scaleMax = parseInt(event.target.value);
        }
        this.rangeInput = event.target.value;
        if (this.logType == "time") {
          // console.time('rendering on slider with filteredData')
          await this.filterData(null, 1665);
          await this.rerender();
          // console.timeEnd('rendering on slider with filteredData')
        } else {
          this.zoomIt(
            this.scaleMax,
            this.scaleMin,
            this.feet,
            false,
            "onChangeSliderHandler"
          );
        }
      }
    },
    getAlertClassOnCondition(curve) {
      let name = curve.name;
      let alert = curve.alert;
      if (curve.isAlert) {
        // console.log("alert in get alert class", alert);
        if (
          name == "alert" ||
          name == "washoutindex" ||
          name == "activitycode"
        ) {
          return "";
        } else {
          if (this.data[name] && this.data[name][this.data[name].length - 1]) {
            let value = this.data[name][this.data[name].length - 1];
            if (
              !isNaN(value) &&
              alert &&
              !isNaN(alert[0]) &&
              !isNaN(alert[1])
            ) {
              if (alert[0] >= value || alert[1] <= value) {
                return "___quadrat";
              } else {
                return "";
              }
            } else {
              return "";
            }
          } else {
            return "";
          }
        }
      } else {
        return "";
      }
    },
    getFullNameDisplay(name) {
      try {
        if (name == "diff") return "DIFF PRESS";
        else if (name == "simplifiedmse") return "MSE";
        else if (name == "bpos") return "BLOCK POS";
        else if (name == "stickslipindex") return "STICK SLIP";
        else if (name == "washoutindex") return "WASH OUT";
        else if (name == "alert") return "ALERTS";
        else if (name == "depthofcut") return "DEPTH OF CUT";
        else
          return this.$store.state.disp.displays[this.displayId].displayTags[
            this.$store.state.disp.displays[this.displayId].tags.indexOf(name)
          ].toUpperCase();
      } catch (error) {
        // console.log("🚀 ➽ file: DisTrend.vue:959  ➽ getFullNameDisplay  ➽ error ⏩", name, error)
        return "";
      }
    },
    getLatestValuesToDisplay(curve) {
      // console.log('___what_is_curve__', curve)
      // this.$emit("getlatestValue", curve);
      let name = curve.name;
      let value = "0";
      let decimals = curve.decimals || 0;
      if (this.data[name] && this.data[name][this.data[name].length - 1]) {
        let value = this.data[name][this.data[name].length - 1];
        // console.log('___checking_value___', value)
        if (!isNaN(value)) {
          if (name == "activitycode") {
            // console.log('___check_curve__!NaN', this.wellActcCodes[value]?.name)
            // let values = {
            //   1: "ROT DRLG",

            //   2: "SLD DRLG",

            //   3: "CIRC WITH ROT",

            //   4: "UNKNOWN",

            //   10: "CIRCULATION",

            //   11: "REAMING",

            //   12: "BACKREAM",

            //   13: "CONNECTION",

            //   14: "TIH-DRLG",

            //   15: "POOH-DRLG",

            //   16: "CIRC WITH ROT",

            //   17: "CIRCULATION",

            //   30: "STATIONARY",

            //   5: "TIH",

            //   6: "POOH",

            //   20: "PUMPOUT",

            //   21: "REAM-TRIP",

            //   22: "BACK REAM-TRIP",

            //   23: "CONN.TRIP",

            //   24: "CIRC WITH ROT-TRIP",

            //   25: "CIRC W/O ROT-TRIP",

            //   26: "CIRCULATION",

            //   27: "WASHDOWN",
            // };
            // return values[value];
            return this.wellActcCodes[value]?.name;
          } else {
            return !isNaN(value) ? Number(value).toFixed(decimals) : "";
          }
        } else if (name == "alert" && value) {
          console.log("___check_curve__isNaN", value);
          return value;
        } else if (name == "washoutindex") {
          return "";
        }
      } else {
        if (
          name == "alert" ||
          name == "washoutindex" ||
          name == "activitycode"
        ) {
          return "";
        } else {
          // console.log('___check_curve__', value)
          return value;
        }
      }
      // this.data[name] && this.data[name][this.data[name].length - 1]
      //   ? (name == "alert" && this.data[name][this.data[name].length - 1]) ||
      //     this.data[name][this.data[name].length - 1].toFixed(2)
      //   : "0";
    },
    returningOfScale1(curve) {
      try {
        return curve.scale[1] ? curve.scale[1].toFixed(2) : 0.0;
      } catch (error) {
        console.log(
          "🚀 ➽ file: DisTrend.vue:1044  ➽ returningOfScale1  ➽ error ⏩",
          error
        );
        return 0;
      }
    },
    returningOfScale(curve) {
      try {
        return curve.scale[0] ? curve.scale[0].toFixed(2) : "0.00";
      } catch (error) {
        console.log(
          "🚀 ➽ file: DisTrend.vue:1052  ➽ returningOfScale  ➽ error ⏩",
          error
        );
        return 0;
      }
    },
    selectStartFeet(feet) {
      feet = parseInt(feet);
      if (feet < this.scaleMax && feet >= this.scaleMinFixed) {
        this.scaleMin = feet;
        (this.currentFeetRange = this.scaleMax - this.scaleMin),
          this.zoomIt(
            this.scaleMax,
            this.scaleMin,
            this.feet,
            false,
            "update 14092"
          );
      } else {
        this.$toast.error("Invalid Depth Selection", {
          position: "top",
          duration: 1000,
        });
      }
    },
    selectEndFeet(feet) {
      feet = parseInt(feet);
      if (feet > this.scaleMin) {
        this.scaleMax = feet;
        this.rangeInput = this.scaleMax;
        (this.currentFeetRange = this.scaleMax - this.scaleMin),
          this.zoomIt(
            this.scaleMax,
            this.scaleMin,
            this.feet,
            false,
            "update 14093"
          );
      } else {
        this.$toast.error("Invalid Depth Selection", {
          position: "top",
          duration: 1000,
        });
      }
    },
    onFromDateChange: async function (e) {
      if (e) {
        this.takeOffSocket();
        this.scaleMinDate = e;
        // console.log(
        //   "date trend : ",
        //   e,
        //   moment(this.scaleMinDate).format("HH:MM:SS mm:ss")
        // );
        let timeDifference = this.scaleMax - this.scaleMin;
        // this.scaleMax = moment(this.scaleMinDate).unix() * 1000;
        // let selectedDate = moment(new Date(this.scaleMinDate).toISOString().slice(0, 16)).format();
        this.scaleMax =
          moment(
            new Date(this.scaleMinDate).toISOString().slice(0, 16)
          ).unix() *
            1000 -
          new Date().getTimezoneOffset() * 60 * 1000;
        this.rangeInput = this.scaleMax;
        this.scaleMin = this.scaleMax - timeDifference;
        if (this.scaleMin < this.scaleMinFixed) {
          this.scaleMin = this.scaleMinFixed;
          this.scaleMax = parseInt(this.scaleMin) + parseInt(timeDifference);
          this.rangeInput = this.scaleMax;
        }
        // this.zoomIt(
        //   this.scaleMax,
        //   this.scaleMin,
        //   this.feet,
        //   false,
        //   "update 14094"
        // );
        await this.filterData(null, 1666);
        await this.rerender();
      }
    },
    alertValueChange(value, alert) {
      alert = alert.replace(/ /g, "");

      if (this.isShowAlerts && value) {
        this.hoveringAlert = alert;
      }
    },
    alertValueClicked(value, alert) {
      alert = alert.replace(/ /g, "");
      if (this.isShowAlerts) {
        this.clickedAlert = alert;
      } else {
        this.clickedAlert = "";
      }
    },
    getRandomColor() {
      let letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    takeOffSocket() {
      this.timer = -1;
      this.liveDisabled = false;
      if (this.$socket) {
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.id,
          clientId: this.$clientId,
        });
        this.sockets.unsubscribe(this.$clientId);
      }
      this.stopUpdation = false;
    },
    async trackPropsMapping() {
      let saved = false;

      if (
        this.plotConfigProps.tracks !== undefined &&
        this.plotConfigProps.tracks !== null
      ) {
        this.trackConfig = this.plotConfigProps;
        saved = true;
        this.trackConfig = {};

        let tracks = this.plotConfigProps.tracks;
        this.templateWidth = this.width;
        let mnemonics = [];

        for (let i = 0; i < tracks.length; i++) {
          let tags = tracks[i];
          for (let j = 0; j < tags.length; j++) {
            if (!tags[j].trackId) {
              let trackId = "t" + Math.random().toString(36).slice(8);
              tags[j].trackId = trackId;
            }
            mnemonics.push(tags[j].name);
          }
          tracks[i] = tags;
        }
        tracks.forEach((track) => {
          track.map((eachMn) => {
            eachMn["description"] = eachMn.fullName;
          });
        });
        this.plotConfigProps.tracks = tracks;
        this.trackConfig = this.plotConfigProps;
        if (this.trackConfig.tracks)
          this.setTrackWidth(this.trackConfig.tracks.length);
        else this.setTrackWidth(0);
        this.tracks = tracks;
      } else {
        this.trackConfig = this.plotConfigProps;
      }
      if (this.isDrillingDis) {
        // this.$emit("plot_config", this.trackConfig.tracks);
        var runningTime = {
          start: this.scaleMaxFixed,
          end: this.scaleMinFixed,
        };
        // this.$emit("well_liveTime", runningTime);
      }
    },
    getOnScreenMnemonics() {
      let tracks = this.trackConfig.tracks;
      let mnemonics = [];
      if (tracks) {
        for (let i = 0; i < tracks.length; i++) {
          let tags = tracks[i];
          for (let j = 0; j < tags.length; j++) {
            // let trackId = "t" + Math.random().toString(36).slice(8);
            // tags[j].trackId = trackId;

            let mn_name =
              tags[j].name == "DBTM"
                ? tags[j].name.toLowerCase()
                : tags[j].name;
            mnemonics.push(mn_name);

            mnemonics.push("time");
            // this.getMnemonicType(mn_name);
          }
          tracks[i] = tags;
        }
      }
      let extra = [
        "activitycode",
        "stickslip",
        "washout",
        "dmea",
        "alert",
        "time",
      ];
      for (let a of extra) {
        if (
          mnemonics.indexOf("stickslipindex") > -1 &&
          mnemonics.indexOf("stickslip") == -1
        ) {
          mnemonics.push(a);
          // this.getMnemonicType(a);
        }

        if (
          mnemonics.indexOf("washoutindex") > -1 &&
          mnemonics.indexOf("washout") == -1
        ) {
          mnemonics.push(a);
          // this.getMnemonicType(a);
        }

        if (mnemonics.indexOf("dmea") == -1) {
          mnemonics.push(a);
          // this.getMnemonicType(a);
        }
        if (mnemonics.indexOf("alert") == -1) {
          mnemonics.push(a);
          // this.getMnemonicType(a);
        }
      }

      mnemonics = [...new Set(mnemonics)];

      return mnemonics;
    },
    async getMnemonicType(mnemonic) {
      // console.time("get mnemonic type", mnemonic, this.logType);
      // console.log(" mnemonic : ", Array.isArray(mnemonic));
      let mnemonic_groups = {};
      if (this.wellId) {
        let logDetails = await helperServices.getIndexDetails(this.wellId);
        let all_mnemonics = logDetails.data.logs[this.logType].mnemonics;
        const source_mnemonice = logDetails.data.logs[this.logType].source;
        const tags = logDetails.data.logs[this.logType].tags;
        let mnemonic_type = "UNPREFERRED";

        if (Array.isArray(mnemonic)) {
          for (let tag of mnemonic) {
            const mnemonicKey = tags.findIndex((t) => t == tag);
            const actualKey = source_mnemonice[mnemonicKey];
            // console.log("~~~~ mnemonics ~~~~~~ ",tag, actualKey, mnemonicKey)
            for (let i in all_mnemonics) {
              if (!actualKey || tag == "tvd") {
                mnemonic_type = "SOLUTION";
                break;
              }
              if (all_mnemonics[i][actualKey]) {
                mnemonic_type = i;
                mnemonic_groups[i] = [];
                break;
              }
            }
            if (!this.mnemonic_groups[mnemonic_type])
              this.mnemonic_groups[mnemonic_type] = [];
            if (this.mnemonic_groups[mnemonic_type].indexOf(tag) == -1)
              this.mnemonic_groups[mnemonic_type] = [
                ...this.mnemonic_groups[mnemonic_type],
                tag,
              ];
          }
          delete this.mnemonic_groups["UNPREFERRED"];
          delete this.mnemonic_groups["MISCELLANEOUS"];
          if (
            this.logType == "time" &&
            Object.keys(this.mnemonic_groups).includes("SOLUTION") &&
            this.mnemonic_groups["SOLUTION"].length > 0 &&
            !this.mnemonic_groups["SOLUTION"].includes("time")
          ) {
            this.mnemonic_groups["SOLUTION"].unshift("time");
          }
          return mnemonic_type;
        } else {
          const mnemonicKey = tags.findIndex((t) => t == mnemonic);
          const actualKey = source_mnemonice[mnemonicKey];

          for (let i in all_mnemonics) {
            if (!actualKey || mnemonic == "tvd") {
              mnemonic_type = "SOLUTION";
              break;
            }
            if (all_mnemonics[i][actualKey]) {
              mnemonic_type = i;
              mnemonic_groups[i] = [];
              break;
            }
          }

          if (this.mnemonic_groups[mnemonic_type].indexOf(mnemonic) == -1)
            this.mnemonic_groups[mnemonic_type] = [
              ...this.mnemonic_groups[mnemonic_type],
              mnemonic,
            ];
          if (
            this.logType == "time" &&
            Object.keys(this.mnemonic_groups).includes("SOLUTION") &&
            this.mnemonic_groups["SOLUTION"].length > 0 &&
            !this.mnemonic_groups["SOLUTION"].includes("time")
          ) {
            this.mnemonic_groups["SOLUTION"].unshift("time");
          }
          return mnemonic_type;
        }
      }
    },
    async getMultiTagsData(mnemonics, from) {
      // this.$store.dispatch("data/startLoading");
      this.isDataLoading = true;
      try {
        let result = null;
        if (mnemonics.indexOf("dmea") == -1) mnemonics.push("dmea");
        if (mnemonics.indexOf("alert") == -1) mnemonics.push("alert");
        await this.getMnemonicType(mnemonics);
        if (this.logType == "time") {
          console.log(" nth called 4 st ");
          // for (let i in this.mnemonic_groups) {
          result = await apiService.WellServices.getTimeLogs({
            fields: mnemonics,
            type: this.isDrillingDis ? "SOLUTIONS" : "SOLUTION",
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            secondsdataavailability: this.secondsdataavailability,
            topic: this.$store.state.disp?.displays[this.displayId]?.topicName,
            // days: 0.2,

            // from: this.scaleMax,
            // to: this.scaleMin,
            from:
              parseInt(this.scaleMaxFixed) < parseInt(this.scaleMax)
                ? parseInt(this.scaleMaxFixed)
                : parseInt(this.scaleMax),
            to:
              parseInt(this.scaleMinFixed) > parseInt(this.scaleMin)
                ? parseInt(this.scaleMinFixed)
                : parseInt(this.scaleMin),
            well_min: this.scaleMinFixed,
            well_max: this.scaleMaxFixed,
            log_interval: this.logInterval
              ? this.logInterval.split("_")[0] * 1000
              : null,
          });

          if (result && result.data && result.data.data) {
            if (
              result.data.data["time"] &&
              result.data.data["time"].length == 0
            ) {
              // this.$toast.error("Data is not available for this interval", {
              //   position: "top",
              //   duration: 3000,
              // });
            } else {
              this.completeData = {
                ...result.data.data,
              };
              this.data = { ...this.completeData };
              this.minmax = { ...this.minmax, ...result.data.minmax };
              // display_unit to recently changed conversion factor needed to convert data
              this.convertingDataFromDisptoSelectedUnit();
              // for (const mnemonickey in this.data) {
              //   if (Object.hasOwn(this.changedConversionFactors, mnemonickey)) {
              //     const mappedData = this.data[mnemonickey].map(
              //       (e) => e * this.changedConversionFactors[mnemonickey]
              //     );
              //     this.data[mnemonickey] = mappedData;
              //   }
              // }
            }
          } else {
            // this.$toast.error("Server is taking longer than usual", {
            //   position: "top",
            //   duration: 3000,
            // });
          }
          // }
        } else {
          apiService.WellServices.getDepthLogs({
            fields: mnemonics,
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            // isPartial: false,
          })
            .then(async (data) => {
              if (data && data.data && data.data.data) {
                this.completeData = { ...this.completeData, ...data.data.data };
              }
            })
            .catch((e) => {
              console.error("whole data error : ", e);
            });
          result = await apiService.WellServices.getDepthLogs({
            fields: mnemonics,
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            isPartial: true,
            start: this.scaleMin,
            end: this.scaleMax,
          });

          // result = result.data;
          // for (let i of mnemonics) {
          //   await this.addData(result, i);
          // }
          if (result && result.data && result.data.data) {
            if (
              result.data.data["dmea"] &&
              result.data.data["dmea"].length == 0
            ) {
              this.$toast.error("Data is not available for this well", {
                position: "top",
                duration: 3000,
              });
            }
          }
          this.completeData = { ...this.completeData, ...result.data.data };
          this.data = { ...this.data, ...result.data.data };
          this.minmax = { ...this.minmax, ...result.data.minmax };
        }

        if (this.logType == "time") {
          this.depthMaxValue = 0;
          this.depthMinValue = 0;
        } else {
          let depth_min_sec = d3.min(this.data["time"]) || null;
          let depth_max_sec = d3.max(this.data["time"]) || null;
          this.depthMaxValue = parseInt(depth_max_sec);
          this.depthMinValue = parseInt(depth_min_sec);
        }

        if (!this.isDis) {
          if (isNaN(this.depthMaxValue) && isNaN(this.depthMinValue)) {
            this.leftbarWidth = 80;
          } else {
            this.leftbarWidth = 130;
          }
        } else {
          if (isNaN(this.depthMaxValue) && isNaN(this.depthMinValue)) {
            this.leftbarWidth = 70;
          } else {
            this.leftbarWidth = 120;
          }
        }
      } catch (error) {
        console.error(error);
      }

      setTimeout(() => {
        // this.$store.dispatch("data/stopLoading");
        this.isDataLoading = false;
      }, 700);
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.id,
        contextType: "widget",
      });
    },
    async getRealtimeData(callingFrom = null) {
      this.liveDisabled = true;
      let from = callingFrom;
      let test = this.id;
      if (this.data === undefined || this.data === null) {
        this.data = [];
        this.mnemonic_groups = {};
      }
      let topic = this.topic;
      let wells = this.$store.state.data.wells;
      let well = wells[this.wellId];

      if (well) {
        let wellbore = well.wellbores[this.wellboreId];
        console.log("wwwww", wellbore["logs"]);
        let log = wellbore["logs"][this.logType];
        this.logId = log.id;
        let fields = this.getOnScreenMnemonics();
        await this.getMnemonicType(fields);
        fields.push("time");
        if (fields.includes("alert") && !fields.includes("diffstick_alert")) {
          fields.push("diffstick_alert");
        }
        this.fields = fields;
        let topicExists = this.$store.state.live.isSubscribed[topic];
        if (this.wellId && this.wellboreId && this.logType) {
          this.$socket.emit("subscribe", {
            logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
            topic: this.topic,
            fields: this.fields,
            id: this.id,
            clientId: this.$clientId,
            logType: this.logType,
          });
          if (!topicExists) {
            this.$store.dispatch("live/setSubscriptionFlag", {
              topic,
              flag: true,
            });
          }
          let storingKafkaData = {};
          let fetchedMissedData = {};
          let fetchingMissingData = false;
          this.sockets.subscribe(this.$clientId, async (sResult) => {
            try {
              let result = sResult[this.id];
              if (result) {
                if (
                  this.logType == "time" &&
                  this.enableStoreStreamingData &&
                  this.completeData &&
                  this.completeData["time"] &&
                  this.completeData["time"].length &&
                  !this.isDataLoading
                ) {
                  this.enableStoreStreamingData = false;
                  let log_interval = Number(
                    this.logInterval
                      ? this.logInterval.split("_")[0] * 1000
                      : 1000
                  );
                  let completeDataLastRecord =
                    this.completeData["time"][
                      this.completeData["time"].length - 1
                    ];
                  let kafakResultFirstRecord = result["time"][0];
                  if (
                    Number(kafakResultFirstRecord) -
                      Number(completeDataLastRecord) >
                    log_interval
                  ) {
                    fetchingMissingData = true;
                    setTimeout(async () => {
                      fetchedMissedData = await this.fetch_missing_data(
                        kafakResultFirstRecord - Number(log_interval),
                        completeDataLastRecord + Number(log_interval)
                      );
                      fetchingMissingData = false;
                      // }, 6000);
                    }, 0);
                  }
                }

                // STORING KAFKA DATA WHILE ABOVE API IS CALLING
                if (this.logType == "time" && fetchingMissingData) {
                  this.isDataLoading = true;
                  for (let k in result) {
                    if (!storingKafkaData[k]) storingKafkaData[k] = [];
                    storingKafkaData[k] = [
                      ...storingKafkaData[k],
                      ...result[k],
                    ];
                  }
                  // console.log('storingKafkaData --- inside', storingKafkaData, this.isDataLoading, fetchingMissingData)

                  return;
                }

                if (
                  this.logType == "time" &&
                  !fetchingMissingData &&
                  this.isDataLoading
                )
                  this.isDataLoading = false;

                if (
                  this.logType == "time" &&
                  !fetchingMissingData &&
                  Object.keys(fetchedMissedData).length &&
                  fetchedMissedData["time"] &&
                  fetchedMissedData["time"].length
                ) {
                  if (
                    Object.keys(storingKafkaData).length &&
                    storingKafkaData["time"] &&
                    storingKafkaData["time"].length &&
                    storingKafkaData["time"].some((t) => t == result["time"][0])
                  ) {
                    for (let k in result) {
                      if (fetchedMissedData[k]) {
                        result[k] = [...fetchedMissedData[k], ...result[k]];
                      }
                    }
                  } else {
                    for (let k in result) {
                      if (fetchedMissedData[k]) {
                        result[k] = [
                          ...fetchedMissedData[k],
                          ...storingKafkaData[k],
                          ...result[k],
                        ];
                      }
                    }
                  }
                  storingKafkaData = {};
                  fetchedMissedData = {};
                }
                // console.log('storingKafkaData', storingKafkaData, this.isDataLoading, fetchingMissingData)
                let isLiveDepth = false;

                // //Unit conversion logic
                // for (const factor in this.changedConversionFactors) {
                //   if (this.changedConversionFactors[factor] != "") {
                //     // console.log(" result[factor] ", factor, result[factor]);
                //     const mappedData = result[factor].map(
                //       (e) => e * this.changedConversionFactors[factor]
                //     );
                //     result[factor] = mappedData;
                //     // console.log(" result[factor] ", result[factor]);
                //   }
                // }

                if (
                  this.logType == "depth" &&
                  this.completeData["dmea"] &&
                  this.completeData["dmea"].length &&
                  result["dmea"] &&
                  result["dmea"].length
                ) {
                  if (
                    this.completeData["dmea"][
                      this.completeData["dmea"].length - 1
                    ] < result["dmea"][result["dmea"].length - 1]
                  )
                    isLiveDepth = true;
                }

                if (this.logType == "time") {
                  //CHECKING AND FILLING DATA GAP CODE STARTS
                  let settingData = { ...result };
                  let dataGapExisted = false;
                  result["time"].map((val, inx) => {
                    if (inx > 0) {
                      let log_interval = Number(
                        this.logInterval
                          ? this.logInterval.split("_")[0] * 1000
                          : 1000
                      );
                      let difference = val - result["time"][inx - 1];
                      if (difference > log_interval) {
                        dataGapExisted = true;
                        let times_to_loop =
                          Math.floor(difference / log_interval) - 1;
                        for (let loop = 0; loop < times_to_loop; loop++) {
                          let foundIndex = settingData["time"].indexOf(val);
                          Object.keys(settingData).map((field) => {
                            if (field == "time") {
                              let timeToAdd =
                                parseInt(settingData["time"][foundIndex - 1]) +
                                parseInt(log_interval);
                              settingData[field].splice(
                                foundIndex,
                                0,
                                timeToAdd
                              );
                            } else if (
                              "washout" == field ||
                              "stickslip" == field
                            ) {
                              settingData[field].splice(foundIndex, 0, "NONE");
                            } else if ("alert" == field) {
                              settingData[field].splice(foundIndex, 0, "");
                            } else if (field == "makinghole") {
                              settingData[field].splice(foundIndex, 0, 0);
                            } else if (field == "dmea") {
                              settingData[field].splice(
                                foundIndex,
                                0,
                                result[field][inx]
                              );
                            } else if (field == "activitycode") {
                              settingData[field].splice(
                                foundIndex,
                                0,
                                result[field][inx]
                              );
                            } else {
                              settingData[field].splice(foundIndex, 0, 0);
                            }
                          });
                        }
                      }
                    } else if (
                      inx == 0 &&
                      Object.keys(this.completeData).length &&
                      !Object.keys(this.streamingData).length &&
                      from != "makeItLive"
                    ) {
                      let log_interval = Number(
                        this.logInterval
                          ? this.logInterval.split("_")[0] * 1000
                          : 1000
                      );
                      let difference =
                        val -
                        this.completeData["time"][
                          this.completeData["time"].length - 1
                        ];
                      if (difference > log_interval) {
                        dataGapExisted = true;
                        let times_to_loop =
                          Math.floor(difference / log_interval) - 1;
                        let timeToAdd = parseInt(
                          this.completeData["time"][
                            this.completeData["time"].length - 1
                          ]
                        );
                        for (let loop = 0; loop < times_to_loop; loop++) {
                          let foundIndex = settingData["time"].indexOf(val);
                          Object.keys(settingData).map((field) => {
                            if (field == "time") {
                              timeToAdd += parseInt(log_interval);
                              settingData[field].splice(
                                foundIndex,
                                0,
                                timeToAdd
                              );
                            } else if (
                              "washout" == field ||
                              "stickslip" == field
                            ) {
                              settingData[field].splice(foundIndex, 0, "NONE");
                            } else if ("alert" == field) {
                              settingData[field].splice(foundIndex, 0, "");
                            } else if (field == "makinghole") {
                              settingData[field].splice(foundIndex, 0, 0);
                            } else if (field == "dmea") {
                              settingData[field].splice(
                                foundIndex,
                                0,
                                result[field][inx]
                              );
                            } else if (field == "activitycode") {
                              settingData[field].splice(
                                foundIndex,
                                0,
                                result[field][inx]
                              );
                            } else {
                              settingData[field].splice(foundIndex, 0, 0);
                            }
                          });
                        }
                      }
                    }
                  });
                  if (dataGapExisted) {
                    result = { ...settingData };
                  }

                  //CHECKING AND FILLING DATA GAP CODE ENDS

                  result = await this.setting_manual_activity_code_in_result(
                    result
                  );

                  // ON SETDISPLAY, TO ADD MISSED DATA, STORING IN streamingData VARIABLE CODE STARTS
                  // if (this.enableStoreStreamingData) {
                  //   for (let i in result) {
                  //     if (!Object.keys(this.streamingData).includes(i)) {
                  //       this.streamingData[i] = [];
                  //     }
                  //     this.streamingData[i] = [
                  //       ...this.streamingData[i],
                  //       ...result[i],
                  //     ];
                  //   }
                  // }
                  // ON SETDISPLAY, TO ADD MISSED DATA, STORING IN streamingData VARIABLE CODE ENDS

                  if (
                    this.liveDisabled &&
                    from != "makeItLive" &&
                    // this.data["time"][this.data["time"].length - 1] <
                    // result["time"][result["time"].length - 1] &&
                    // this.data["time"][this.data["time"].length - 1] <
                    // result["time"][0]
                    true
                  ) {
                    // IF ALREADY HAD DATA IN COMPLETEDATA THEN REMOVING DATA CODE STARTS
                    // if (
                    //   Object.keys(this.streamingData).length &&
                    //   Object.keys(this.completeData).length &&
                    //   this.completeData["time"].includes(
                    //     this.streamingData["time"][0]
                    //   )
                    // ) {
                    //   let findIndex = this.completeData["time"].indexOf(
                    //     this.streamingData["time"][0]
                    //   );
                    //   for (let i in this.completeData) {
                    //     let removedLength =
                    //       this.completeData[i].splice(findIndex).length;
                    //     let dataToAppend = this.streamingData[i].splice(
                    //       0,
                    //       removedLength
                    //     );
                    //     this.completeData[i] = [
                    //       ...this.completeData[i],
                    //       ...dataToAppend,
                    //     ];
                    //   }
                    // }
                    // IF ALREADY HAD DATA IN COMPLETEDATA THEN REMOVING DATA CODE ENDS

                    let isSplice =
                      this.completeData &&
                      this.completeData.hasOwnProperty("time") &&
                      this.completeData["time"].length >=
                        (this.currentTimeRange * 60 * 60 * 1000) /
                          Number(
                            this.logInterval
                              ? this.logInterval.split("_")[0] * 1000
                              : 1000
                          ) +
                          500;

                    for (let i in this.completeData) {
                      // ADDING STORED DATA CODE STARTS
                      // if (
                      //   Object.keys(this.streamingData).length &&
                      //   Object.keys(this.streamingData).includes(i)
                      // ) {
                      //   if(isSplice){
                      //     this.completeData[i].splice(
                      //       0,
                      //       this.streamingData[i].length
                      //     );
                      //   }
                      //   this.completeData[i] = [
                      //     ...this.completeData[i],
                      //     ...this.streamingData[i],
                      //   ];
                      //   if (this.enableStoreStreamingData) {
                      //     this.enableStoreStreamingData = false;
                      //   }
                      // }
                      // ADDING STORED DATA CODE ENDS

                      if (result[i]) {
                        if (this.logType == "time") {
                          if (isSplice) {
                            this.completeData[i].splice(0, result[i].length);
                          }
                        }
                        this.completeData[i] = [
                          ...this.completeData[i],
                          ...result[i],
                        ];
                      }
                    }

                    // TO SHOW EXACT DATA BASED ON SELECTED TIME RANGE CODE STARTS
                    // if (
                    //   Object.keys(this.completeData).length &&
                    //   this.completeData["time"]?.length &&
                    //   Object.keys(this.streamingData).length
                    // ) {
                    //   let lastVal =
                    //     this.completeData["time"][
                    //       this.completeData["time"].length - 1
                    //     ];
                    //   let scale_min =
                    //     lastVal - this.currentTimeRange * 1000 * 60 * 60;
                    //   if (this.completeData["time"][0] > scale_min) {
                    //     let startIndex = this.recursiveFunction(
                    //       this.hrs_24_data["time"],
                    //       scale_min,
                    //       0,
                    //       this.hrs_24_data["time"]?.length - 1,
                    //       "scaleMin"
                    //     );
                    //     let endIndex = this.recursiveFunction(
                    //       this.hrs_24_data["time"],
                    //       this.completeData["time"][0],
                    //       0,
                    //       this.hrs_24_data["time"]?.length - 1,
                    //       "scaleMax"
                    //     );
                    //     if ((startIndex || startIndex == 0) && endIndex) {
                    //       for (let i in this.completeData) {
                    //         if (Object.keys(this.streamingData).includes(i)) {
                    //           this.completeData[i] = [
                    //             ...this.hrs_24_data[i].slice(
                    //               startIndex,
                    //               endIndex
                    //             ),
                    //             ...this.completeData[i],
                    //           ];
                    //         }
                    //       }
                    //     }
                    //   } else if (this.completeData["time"][0] < scale_min) {
                    //     let endIndex = this.recursiveFunction(
                    //       this.completeData["time"],
                    //       scale_min,
                    //       0,
                    //       this.completeData["time"].length - 1,
                    //       "scaleMax"
                    //     );
                    //     if (endIndex) {
                    //       for (let i in this.completeData) {
                    //         if (Object.keys(this.streamingData).includes(i)) {
                    //           this.completeData[i].splice(0, endIndex);
                    //         }
                    //       }
                    //     }
                    //   }
                    // }
                    // TO SHOW EXACT DATA BASED ON SELECTED TIME RANGE CODE ENDS

                    // if (!this.enableStoreStreamingData) {
                    //   // ASSIGNING EMPTY OBJECT AFTER CALLING OF APIS FROM SETDISPLAY AND TIME RANGE SELECTION
                    //   this.streamingData = {};
                    // }
                  }
                } else {
                  if (isLiveDepth)
                    for (let i in this.completeData) {
                      if (result[i]) {
                        this.completeData[i] = [
                          ...this.completeData[i],
                          ...result[i],
                        ];
                      }
                    }
                  else {
                    let indexOfDepth = this.completeData["dmea"].indexOf(
                      result["dmea"][result["dmea"].length - 1]
                    );
                    if (indexOfDepth > -1)
                      for (let i in this.completeData) {
                        if (result[i]) {
                          this.completeData[i] = this.completeData[i].slice(
                            0,
                            indexOfDepth
                          );
                        }
                      }
                  }
                }
                if (this.logType == "time") {
                  if (from != "makeItLive") {
                    this.checkigAndPlacingDiffStickAlert(result);
                    this.data = { ...this.completeData };

                    // display_unit to recently changed conversion factor needed to convert data
                    this.convertingDataFromDisptoSelectedUnit();
                    // this.scaleMin = this.data["time"][0];
                    // this.scaleMax =
                    //   this.data["time"][this.data["time"].length - 1];
                    let minScaleValue =
                      this.data["time"][this.data["time"].length - 1] -
                      this.currentTimeRange * 1000 * 60 * 60;
                    this.scaleMin =
                      this.scaleMinFixed > minScaleValue
                        ? this.scaleMinFixed
                        : minScaleValue;
                    this.scaleMax =
                      this.scaleMin + this.currentTimeRange * 1000 * 60 * 60;
                    this.scaleMaxFixed =
                      this.data["time"][this.data["time"].length - 1];
                    if (this.compareDateOnly(this.scaleMin)) {
                      this.scaleMinDate = new Date(
                        this.scaleMin
                      ).toLocaleString("en-US", {
                        timeZone: this.time_zone,
                      });
                    }

                    localStorage.setItem("time", this.scaleMaxFixed);
                  }
                  // this.scaleMin = parseInt(this.scaleMin);
                  // this.scaleMax = parseInt(this.scaleMax);
                } else {
                  this.scaleMin +=
                    result["dmea"][result["dmea"].length - 1] - this.scaleMax;
                  if (this.scaleMin < 0) {
                    this.scaleMin = this.completeData["dmea"][0];
                  }
                  this.scaleMax = result["dmea"][result["dmea"].length - 1];
                  // this.scaleMin = parseInt(this.scaleMin);
                  // this.scaleMax = parseInt(this.scaleMax);
                  this.scaleMaxFixed = this.scaleMax;
                  localStorage.setItem("time", this.scaleMaxFixed);
                }
                // console.log(this.data );

                // console.log(
                //   "value of scalemax is smaller than scale Min : ",
                //   new Date(this.scaleMax).toISOString(),
                //   new Date(this.scaleMin).toISOString()
                // );

                if (this.scaleMax < this.scaleMin) {
                  let temp = this.scaleMax;
                  this.scaleMax = this.scaleMin;
                  this.scaleMin = temp;
                }
                // console.log("this.data[time']", this.data["time"].length)
                if (
                  this.logType == "time" &&
                  this.liveDisabled &&
                  from &&
                  from == "makeItLive"
                ) {
                  // ON CLICK OF LIVE BUTTON FETCHING DATA FROM THE EXISTED DATA
                  from = null;
                  let maxTime = null;
                  let maxOf24Hrs = null;
                  let maxOfIntervalBasedHrs = null;
                  if (
                    this.hrs_24_data != null &&
                    Object.keys(this.hrs_24_data).length &&
                    this.hrs_24_data["time"]
                  ) {
                    maxOf24Hrs = Math.max(...this.hrs_24_data["time"]);
                  }
                  if (
                    this.interval_based_hrs_data != null &&
                    Object.keys(this.interval_based_hrs_data).length &&
                    this.interval_based_hrs_data["time"]
                  ) {
                    maxOfIntervalBasedHrs = Math.max(
                      ...this.interval_based_hrs_data["time"]
                    );
                  }
                  maxTime = Math.max(...[maxOf24Hrs, maxOfIntervalBasedHrs]);
                  let resultMinTime = Math.min(...result["time"]);
                  let lg_interval = Number(
                    this.logInterval
                      ? this.logInterval.split("_")[0] * 1000
                      : 1000
                  );
                  if (
                    maxTime < resultMinTime &&
                    resultMinTime - lg_interval - maxTime >= lg_interval
                  ) {
                    this.completeData = {};
                    this.enableStoreStreamingData = true;
                    // await this.fetch_data_for_stream(
                    //   resultMinTime -
                    //     Number(
                    //       this.logInterval
                    //         ? this.logInterval.split("_")[0] * 1000
                    //         : 1000
                    //     ),
                    //   maxTime
                    // );
                    // this.enableStoreStreamingData = false;
                    // ADDING DATA BASED ON INTERVAL SELECTION STARTS
                    let resultMaxTime = Math.max(...result["time"]);
                    // let time_difference = resultMaxTime - maxTime;
                    // let dataToAppend =
                    //   this.currentTimeRange * 1000 * 60 * 60 - time_difference;
                    let startTimeToFetch =
                      resultMaxTime - this.currentTimeRange * 1000 * 60 * 60;
                    startTimeToFetch =
                      this.scaleMinFixed > startTimeToFetch
                        ? this.scaleMinFixed
                        : startTimeToFetch;
                    let endTimeToFetch = maxTime;
                    this.enableStoreStreamingData = true;
                    await this.fetch_data_for_stream(
                      resultMinTime -
                        Number(
                          this.logInterval
                            ? this.logInterval.split("_")[0] * 1000
                            : 1000
                        ),
                      startTimeToFetch
                    );
                    // this.enableStoreStreamingData = false;

                    // if (
                    //   maxTime == maxOf24Hrs &&
                    //   time_difference != this.currentTimeRange * 1000 * 60 * 60
                    // ) {
                    //   let startIndex = this.recursiveFunction(
                    //     this.hrs_24_data["time"],
                    //     startTimeToFetch,
                    //     0,
                    //     this.hrs_24_data["time"]?.length - 1,
                    //     "scaleMin"
                    //   );
                    //   let endIndex = this.recursiveFunction(
                    //     this.hrs_24_data["time"],
                    //     endTimeToFetch,
                    //     0,
                    //     this.hrs_24_data["time"]?.length - 1,
                    //     "scaleMax"
                    //   );
                    //   for (let key in this.hrs_24_data) {
                    //     this.completeData[key] = [
                    //       ...this.hrs_24_data[key].slice(startIndex, endIndex),
                    //       ...this.completeData[key],
                    //     ];
                    //   }
                    // } else if (
                    //   maxTime == maxOfIntervalBasedHrs &&
                    //   time_difference != this.currentTimeRange * 1000 * 60 * 60
                    // ) {
                    //   let startIndex = this.recursiveFunction(
                    //     this.interval_based_hrs_data["time"],
                    //     startTimeToFetch,
                    //     0,
                    //     this.interval_based_hrs_data["time"].length - 1,
                    //     "scaleMin"
                    //   );
                    //   let endIndex = this.recursiveFunction(
                    //     this.interval_based_hrs_data["time"],
                    //     endTimeToFetch,
                    //     0,
                    //     this.interval_based_hrs_data["time"].length - 1,
                    //     "scaleMax"
                    //   );
                    //   for (let key in this.interval_based_hrs_data) {
                    //     this.completeData[key] = [
                    //       ...this.interval_based_hrs_data[key].slice(
                    //         startIndex,
                    //         endIndex
                    //       ),
                    //       ...this.completeData[key],
                    //     ];
                    //   }
                    // }
                    // ADDING DATA BASED ON INTERVAL SELECTION ENDS

                    let minmaxObj = {};
                    for (let i in this.completeData) {
                      if (result[i]) {
                        this.completeData[i] = [
                          ...this.completeData[i],
                          ...result[i],
                        ];
                        minmaxObj[i] = {
                          min: 0,
                          max: Math.max(...this.completeData[i]),
                        };
                      }
                    }
                    this.checkigAndPlacingDiffStickAlert(result);
                    this.data = { ...this.completeData };

                    // display_unit to recently changed conversion factor needed to convert data
                    this.convertingDataFromDisptoSelectedUnit();

                    this.minmax = { ...this.minmax, ...minmaxObj };
                    // this.scaleMin = this.data["time"][0];
                    // this.scaleMax =
                    //   this.data["time"][this.data["time"].length - 1];
                    // this.scaleMin = this.scaleMax - this.currentTimeRange * 1000 * 60 * 60;
                    let minScaleValue =
                      this.data["time"][this.data["time"].length - 1] -
                      this.currentTimeRange * 1000 * 60 * 60;
                    this.scaleMin =
                      this.scaleMinFixed > minScaleValue
                        ? this.scaleMinFixed
                        : minScaleValue;
                    this.scaleMax =
                      this.scaleMin + this.currentTimeRange * 1000 * 60 * 60;
                    this.scaleMaxFixed =
                      this.data["time"][this.data["time"].length - 1];
                    if (this.compareDateOnly(this.scaleMin)) {
                      this.scaleMinDate = new Date(
                        this.scaleMin
                      ).toLocaleString("en-US", {
                        timeZone: this.time_zone,
                      });
                    }
                    this.rangeInput =
                      this.data["time"][this.data["time"].length - 1];
                    localStorage.setItem("time", this.scaleMaxFixed);
                    // if (this.isRenderGraph) await this.rerender("zoomIt");
                    await this.rerender();
                  } else {
                    let minmaxObj = {};
                    for (let i in this.completeData) {
                      if (result[i]) {
                        this.completeData[i] = [
                          ...this.completeData[i],
                          ...result[i],
                        ];
                        minmaxObj[i] = {
                          min: 0,
                          max: Math.max(...this.completeData[i]),
                        };
                      }
                    }
                    this.checkigAndPlacingDiffStickAlert(result);
                    this.data = { ...this.completeData };
                    // display_unit to recently changed conversion factor needed to convert data
                    this.convertingDataFromDisptoSelectedUnit();

                    this.minmax = { ...this.minmax, ...minmaxObj };
                    // this.scaleMin = this.data["time"][0];
                    // this.scaleMax =
                    //   this.data["time"][this.data["time"].length - 1];
                    // this.scaleMin = this.scaleMax - this.currentTimeRange * 1000 * 60 * 60;
                    let minScaleValue =
                      this.data["time"][this.data["time"].length - 1] -
                      this.currentTimeRange * 1000 * 60 * 60;
                    this.scaleMin =
                      this.scaleMinFixed > minScaleValue
                        ? this.scaleMinFixed
                        : minScaleValue;
                    this.scaleMax =
                      this.scaleMin + this.currentTimeRange * 1000 * 60 * 60;
                    this.scaleMaxFixed =
                      this.data["time"][this.data["time"].length - 1];
                    if (this.compareDateOnly(this.scaleMin)) {
                      this.scaleMinDate = new Date(
                        this.scaleMin
                      ).toLocaleString("en-US", {
                        timeZone: this.time_zone,
                      });
                    }
                    this.rangeInput =
                      this.data["time"][this.data["time"].length - 1];
                    localStorage.setItem("time", this.scaleMaxFixed);
                    // if (this.isRenderGraph) await this.rerender("zoomIt");
                    await this.rerender();
                  }
                  // else{
                  //   await this.fetch_data_for_stream(this.scaleMax, this.scaleMin);
                  //   this.scaleMin = this.data["time"][0];
                  //   this.scaleMax = this.data["time"][this.data["time"].length - 1];
                  //   this.scaleMaxFixed = this.scaleMax;
                  //   localStorage.setItem("time", this.scaleMaxFixed);
                  //   await this.rerender("zoomIt");
                  // }
                } else {
                  if (this.currentFeetRange > this.scaleMax - this.scaleMin) {
                    this.scaleMax = this.scaleMin + this.currentFeetRange;
                  }
                  this.rangeInput = this.scaleMax;
                  // console.log(this.completeData["dmea"], result["dmea"]);
                  this.zoomIt(
                    this.scaleMax,
                    this.scaleMin,
                    this.feet,
                    false,
                    "update live"
                  );
                }
              }
            } catch (error) {
              console.error("error in socket subscribe : ", error);
            }
          });
          if (
            this.logType == "time" &&
            this.liveDisabled &&
            from &&
            from == "makeItLive"
          ) {
            from = null;
            await this.filterData(null, 4101);
            await this.rerender();
          }
        }
        topicExists = this.$store.state.live.isSubscribed[topic];
      }
    },
    selectFeet(feet) {
      let scaleMin = 0;
      this.currentFeetRange = feet;
      if (feet == "default") {
        this.feet = 1000;
        scaleMin = this.scaleMax - 1000;
      } else {
        this.feet = feet;
        scaleMin = this.scaleMax - feet;
        if (scaleMin < 0) {
          scaleMin = this.scaleMinFixed;
          this.scaleMax = parseFloat(scaleMin) + parseFloat(feet);
        }
      }

      this.scaleMin = scaleMin;
      this.zoomIt(
        this.scaleMax,
        this.scaleMin,
        this.feet,
        false,
        "update 14095"
      );
    },
    async makeItLive() {
      // it fetches the latest 4 hour of data and start the socket data stream

      if (this.isZoom) {
        this.isZoom = false;
        this.zoomed_Y_Axis = null;
      }
      let minMax = await this.getMinMaxRange();

      let difference = this.currentTimeRange * 3600000 || 3600000;
      if (this.logType == "time") {
        // if (this.currentTimeRange > 2)
        this.currentTimeRange = 1;
        difference = this.currentTimeRange * 3600000 || 3600000;
        this.scaleMin =
          minMax.max - difference > minMax.min
            ? minMax.max - difference
            : minMax.min;
        this.scaleMax =
          minMax.max - difference > minMax.min
            ? minMax.max
            : minMax.min + difference;
        this.scaleMinDate = new Date(this.scaleMin).toLocaleString("en-US", {
          timeZone: this.time_zone,
        });
        this.scaleMaxDate = new Date(this.scaleMax);

        this.scaleMinFixed = minMax.min;
        this.scaleMaxFixed = minMax.max;
      } else {
        difference = this.currentFeetRange || 500;
        let minMax = await this.getMinMaxRange();
        if (minMax.max == minMax.min || minMax.max - difference < minMax.min)
          minMax.max = minMax.min + (difference + 500);
        this.scaleMin = minMax.max - difference;

        this.scaleMax = minMax.max;

        this.scaleMinFixed = minMax.min;
        this.scaleMaxFixed = minMax.max;
      }

      this.scaleMax = this.scaleMaxFixed;
      this.rangeInput = this.scaleMax;
      // console.log(" minMax ", minMax);
      if (this.logType == "time") {
        // this.completeData = [];
        // this.data = [];
        // this.mnemonic_groups = {};
        // difference = this.currentTimeRange
        // this.currentTimeRange = difference;
        // this.selectTimeRange(difference);
      } else {
        // this.currentFeetRange = difference;
        // this.selectFeet(difference);
      }
      // console.log(
      //   " make it live : before : ",
      //   this.scaleMax,
      //   this.scaleMin,
      //   difference,
      //   this.data
      // );
      if (this.logType == "time") {
        this.getRealtimeData("makeItLive");
      } else {
        await this.zoomIt(this.scaleMax, this.scaleMin, difference, false, "6");
        this.getRealtimeData();
      }
      // console.log(" make it live : after : ", this.data);
    },
    async selectTimeRange(hrs) {
      // if (hrs < 1) hrs = Number(hrs).toFixed(2);
      if (this.isZoom) {
        this.isZoom = false;
        this.zoomed_Y_Axis = null;
      }
      this.currentTimeRange = hrs;
      if (this.liveDisabled) {
        this.takeOffSocket();
        if (hrs <= 2) {
          this.liveDisabled = false;
          if (hrs <= 2)
            setTimeout(() => {
              this.liveDisabled = true;
              this.getRealtimeData();
            }, 400);
        } else this.liveDisabled = false;
      }

      // this.socket = io(URL.HOST_URL);

      let scaleMin = 0;
      if (hrs == "default") {
        this.hrs = 1;
        scaleMin = this.scaleMax - 1 * 1000 * 60 * 60;
      } else {
        this.hrs = hrs;
        scaleMin = this.scaleMax - hrs * 1000 * 60 * 60;
        if (this.scaleMinFixed > scaleMin) {
          scaleMin = this.scaleMinFixed;
          this.scaleMax = scaleMin + hrs * 1000 * 60 * 60;
        } else {
          scaleMin = this.scaleMax - hrs * 1000 * 60 * 60;
        }

        console.log(" fixed min date of well : ", scaleMin, this.scaleMax);
      }

      this.scaleMin = scaleMin;
      this.completeData = {};
      // this.enableStoreStreamingData = true;
      await this.filterData([this.scaleMin, this.scaleMax], 2854);
      await this.rerender();
      // this.enableStoreStreamingData = false;
      // this.zoomIt(
      //   this.scaleMax,
      //   this.scaleMin,
      //   this.currentTimeRange,
      //   false,
      //   "6"
      // );
    },
    showProps1(j, i) {
      let cProps = {};
      cProps["show"] = true;
      cProps["curve"] = this.trackConfig.tracks[j][i];
      this.curveProps[j + "-" + i] = cProps;
    },
    closeIt(j, i) {
      this.curveProps[j + "-" + i]["show"] = false;
    },
    setCurveProps(j, i, curve) {
      // console.log("@@@@@@@@@@@", j, i, curve, this.trackConfig.tracks[j][i]);
      this.trackConfig.tracks[j][i]["editedDescription"] =
        curve.editedDescription;
      if (this.trackConfig.tracks[j][i].name == "alert") {
        this.alertArr = curve.alertsArr;
      }
      this.curveProps[j + "-" + i]["show"] = false;
      let stroke = curve.thickness;
      let scaleMin = curve.scaleMin;
      let scaleMax = curve.scaleMax;
      let alertMin = curve.alertMin;
      let alertMax = curve.alertMax;
      let decimals = curve.decimals;
      let editedName = curve.editedDescription;
      let scaleType = curve.scaleType;
      if (stroke != null) {
        stroke = Number(stroke);
        this.trackConfig.tracks[j][i].stroke = stroke;
      }
      let current_curve_name = this.trackConfig.tracks[j][i].name;
      if (
        scaleMin != null &&
        scaleMin !== "" &&
        scaleMax != null &&
        scaleMax != "" &&
        scaleType !== null &&
        scaleType !== ""
      ) {
        scaleMin = Number(scaleMin);
        scaleMax = Number(scaleMax);
        if (
          scaleType == "auto" &&
          this.trackConfig.tracks[j][i].scaleType !== scaleType &&
          this.dispToSelectedUnitConversionFactors.hasOwnProperty(
            current_curve_name
          ) &&
          this.dispToSelectedUnitConversionFactors[current_curve_name] != ""
        ) {
          let convert_fact =
            this.dispToSelectedUnitConversionFactors[current_curve_name];
          const mappedData = this.completeData[current_curve_name].map(
            (e) => e * convert_fact
          );
          this.data[current_curve_name] = mappedData;

          let xMinValue = 0;
          let xMaxValue = 0;
          let currentRange = defaultRanges.ranges.find(
            (data) => data.name == current_curve_name
          );

          if (
            (current_curve_name == "spp" || current_curve_name == "diff") &&
            this.unit_conversion
          ) {
            currentRange = this.defaultRangesValidation(
              current_curve_name,
              this.trackConfig.tracks[j][i].display_unit,
              currentRange
            );
          }
          // currentRange = null;
          if (currentRange) {
            if (this.unit_conversion) {
              xMaxValue = Number(currentRange.m_high);
              xMinValue = Number(currentRange.m_low);
            } else {
              xMaxValue = Number(currentRange.high);
              xMinValue = Number(currentRange.low);
            }
          } else {
            xMinValue = d3.min(this.completeData[curve_names[k]]);
            xMaxValue = d3.max(this.completeData[curve_names[k]]);
          }
          this.trackConfig.tracks[j][i].scale = [
            xMinValue * convert_fact,
            xMaxValue * convert_fact,
          ];
        } else {
          this.trackConfig.tracks[j][i].scale = [scaleMin, scaleMax];
        }
        // this.trackConfig.tracks[j][i].scaleType = "manual";
        this.trackConfig.tracks[j][i].scaleType = scaleType;
      }
      if (alertMin != null && alertMax != null) {
        alertMin = Number(alertMin);
        alertMax = Number(alertMax);

        this.trackConfig.tracks[j][i].alert = [alertMin, alertMax];
      }
      if (!isNaN(decimals)) {
        this.trackConfig.tracks[j][i].decimals = decimals;
      }
      if (editedName !== this.trackConfig.tracks[j][i].description) {
        this.trackConfig.tracks[j][i].editedName = editedName;
      } else if (
        editedName == this.trackConfig.tracks[j][i].description &&
        this.trackConfig.tracks[j][i]["editedName"]
      ) {
        delete this.trackConfig.tracks[j][i].editedName;
      }
      this.trackConfig.tracks[j][i].color = curve.color;
      this.trackConfig.tracks[j][i].isAlert = curve.isAlert;
      const name = this.trackConfig.tracks[j][i].name;
      if (
        Object.hasOwn(this.changedConversionFactors, name) &&
        this.changedConversionFactors[name] != ""
      ) {
        this.rerender();
      } else {
        this.rerender("setCurveProps");
      }
    },
    async deleteCurve(j, i) {
      this.curveProps[j + "-" + i]["show"] = false;
      let canvas = document.getElementById(
        this.trackConfig.tracks[j][i].trackId
      );
      let tempTrackId = "";
      if (canvas) {
        tempTrackId = this.trackConfig.tracks[j][i].trackId;
      }
      this.trackConfig.tracks[j].splice(i, 1);
      // console.log("delete config updates : ", this.trackConfig.tracks);
      if (this.trackConfig.tracks[j].length == 0) {
        // console.log(" this.trackConfig.tracks[j] ", canvas);
        if (canvas) canvas.remove();
        this.trackConfig.tracks.splice(j, 1);
      } else {
        if (tempTrackId) this.trackConfig.tracks[j][0].trackId = tempTrackId;
      }

      await this.configure();
      // setTimeout(() => {
      this.verticalplots(this.div_id, this.plots, true);
      // }, timeout);
      this.rerender();
      // visible = !visible;
    },
    startDrag(ev, props) {
      ev.dataTransfer.dropEffect = "move";
      ev.dataTransfer.effectAllowed = "move";
      let propString = JSON.stringify(props);
      ev.dataTransfer.setData("elProps", propString);
    },
    async onDrop(event) {
      // console.log("dropped::", this.liveDisabled);
      let title;
      let min;
      let max;
      let color;
      let fullName;
      let singleTagData = null;
      let target = event.target.id;
      if (this.liveDisabled) {
        this.takeOffSocket();
        if (this.currentTimeRange <= 2) {
          this.liveDisabled = false;
          if (this.currentTimeRange <= 2)
            setTimeout(() => {
              this.liveDisabled = true;
              if (this.logType != "time") {
                this.getRealtimeData();
              }
            }, 700);
        } else this.liveDisabled = false;
      }
      // console.log(
      //   "🚀 ➽ file: DisTrend.vue:2209  ➽ onDrop  ➽ target ⏩",
      //   target
      // );
      let elProps = JSON.parse(event.dataTransfer.getData("elProps"));

      for (let j = 0; j < this.tracks.length; j++) {
        let trackId = this.tracks[j][0].trackId;
        const found = this.tracks[j].find((i) => i.name == elProps.title);
        if (found) {
          this.$toast.error("Mnemonic already exist", {
            position: "top",
            duration: "3000",
          });
          return;
        }
        if (target === "rect" + trackId) {
          if (this.tracks[j].length == 3) {
            this.$toast.error("Max limit is over", {
              position: "top",
              duration: "1000",
            });
            return false;
          }
        }
      }

      if (elProps.name == "child") {
        title = elProps.title.name;
        min = elProps.title.min;
        max = elProps.title.max;
        color = elProps.title.color;
        if (elProps.origin === this.compId) {
          this.deleteChildCurve(elProps.trackNbr, elProps.curveNbr);
        }
      } else {
        elProps.title = elProps.title
          ? elProps.title.toLowerCase()
          : elProps.fullName.toLowerCase();
        title = elProps.title;
        singleTagData = await this.getSingleTagData(
          elProps.title,
          false,
          "onDrop"
        );
        if (this.logType == "depth") {
          min = Number.POSITIVE_INFINITY;
          max = Number.NEGATIVE_INFINITY;
          let tmp;
          for (let i = singleTagData.length - 1; i >= 0; i--) {
            tmp = parseFloat(singleTagData[i][title.toLowerCase()]);
            if (tmp < min) min = tmp;
            if (tmp > max) max = tmp;
          }
        } else {
          // min = this.minmax[elProps.title].min
          // max = this.minmax[elProps.title].max
          min = d3.min(this.data[elProps.title]);
          max = d3.max(this.data[elProps.title]);
        }
      }
      fullName = elProps.fullName;
      this.emptyPlot = false;

      if (this.div_id == target) {
        let trackId = this.addTrack();
        let j = this.tracks.length - 1;
        await this.addNewCurve({
          title,
          min,
          max,
          trackId,
          j,
          color,
          fullName,
        });
      } else {
        for (let j = 0; j < this.tracks.length; j++) {
          let trackId = this.tracks[j][0].trackId;

          if (target === "rect" + trackId) {
            await this.addNewCurve({
              title,
              min,
              max,
              trackId,
              j,
              color,
              fullName,
            });
            break;
          }
        }
      }

      this.$store.dispatch("rect/setConfig", {
        disId: this.displayId,
        widId: this.id,
        config: this.trackConfig,
      });
      // this.selectedMnenonics.unshift(title);

      this.setTrackWidth(this.trackConfig.tracks.length);
      let noDIV = d3
        .select("#" + this.div_id)
        .selectAll("div")
        .remove();
      let noSVG = d3
        .select("#" + this.div_id)
        .selectAll("svg")
        .remove();
      this.rerender("onDrop");
      // this.rerenderTest(this.compId);
      if (this.isReplay) {
        this.liveDisabled = false;
      } else {
        if (this.liveDisabled && this.status == "UPDATING") {
          this.getRealtimeData();
        }
      }
    },
    deleteChildCurve(trackNbr, curveNbr) {
      this.trackConfig.tracks[trackNbr].splice(curveNbr, 1);
      if (this.trackConfig.tracks[trackNbr].length == 0) {
        this.trackConfig.tracks.splice(trackNbr, 1);
        this.totTracks.splice(trackNbr, 1);
      }
    },
    setTrackWidth(nbr) {
      let usableWidth =
        // this.templateWidth - this.sliderWidth - this.leftbarWidth - 50;
        this.isDrillingDis
          ? this.templateWidth - this.sliderWidth - this.leftbarWidth
          : this.templateWidth - this.sliderWidth - this.leftbarWidth - nbr * 5;
      this.singleTrackWidth = Math.floor(usableWidth / nbr);
      if (this.singleTrackWidth > this.trackWidth) {
        this.singleTrackWidth = this.trackWidth;
      }
    },
    setTrackHeight() {
      this.singleTrackHeight = this.templateHeight - this.bheight - 20;
    },
    addNewCurve(title) {
      let currentCurve = {};
      currentCurve.name = title.title;
      currentCurve.fullName = title.fullName || "";
      if (title.color != null) {
        currentCurve.color = title.color;
      } else {
        currentCurve.color = this.getRandomColor();
      }
      if (title.j != null) {
        currentCurve.trackNbr = title.j;
        if (this.tracks.length > 0) {
          currentCurve.curveNbr = this.tracks[title.j].length;
        }
      } else {
        currentCurve.trackNbr = 0;
        currentCurve.curveNbr = 0;
      }
      currentCurve.trackId = title.trackId;
      currentCurve.min = title.min;
      currentCurve.max = title.max;
      currentCurve.scale = [title.min, title.max];
      currentCurve.scaleType = "auto";
      this.trackConfig.tracks[currentCurve.trackNbr].push(currentCurve);
    },
    getRandomColor() {
      let letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    createLeftBar(div_id, templates) {
      try {
        let template_for_plotting = templates[0];
        let template_overall = template_for_plotting[0]["curve_box"];

        let yAxisHolder = d3.select("#" + this.id + "leftbarcontainer");
        const trendtimeStampCol = this.darkDark !== "white" ? "dv" : "ddv";
        if (!document.getElementById(this.id + "leftbarcontainer")) {
          yAxisHolder = d3.select("#" + div_id).append("div");

          yAxisHolder
            .attr("id", this.id + "leftbarcontainer")
            .attr("class", "light_trend_scale")
            .style("display", "flex")
            .style("vertical-align", "top")
            // .style("margin-right", this.isDis? "-5px": "-2px")
            .style("margin-right", "0px")
            .style("width", this.leftbarWidth + "px");
        }

        this.leftRangeSliderSet = true;
        let height_components = this.isDrillingDis
          ? this.screenHeight - 300
          : template_overall["height"];
        if (this.logType == "depth") {
          this.yLeftScale = d3
            .scaleLinear()
            .domain([this.scaleMax, this.scaleMin])
            .range([height_components - 20, 0]);
          // .nice();
          if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue))
            this.yLeftScaleCompare = d3
              .scaleTime()
              .domain([this.depthMaxValue, this.depthMinValue])
              .range([height_components - 20, 0]);
          // .nice();
        } else {
          this.yLeftScale = d3
            .scaleTime()
            .domain([this.scaleMax, this.scaleMin])
            .range([height_components - 20, 0]);
          // .nice();
        }
        let y = this.yLeftScale;
        if (this.isZoom && this.zoomed_Y_Axis)
          y = this.zoomed_Y_Axis.range([height_components - 20, 0]);
        d3.select("#" + this.id + "leftbar-outer").remove();
        let leftbarOuter = yAxisHolder
          .append("div")
          .attr("id", this.id + "leftbar-outer")
          .style("width", this.leftbarWidth + "px");

        let leftbarInner = leftbarOuter
          .append("div")
          .style("max-height", height_components + "px")
          .style("scrollbar-width", "thin")
          .style("width", this.leftbarWidth + "px")
          .style("height", height_components + "px")
          .style("direction", "rtl")
          .style("scrollbar-track", "red");

        let svg = leftbarInner
          .append("svg")
          .style("width", this.leftbarWidth + "px")
          .style("overflow", "visible")
          .attr("height", height_components);
        let yTicks = null;
        let yTicksCompare = null;
        if (this.logType == "time") {
          let tickInterval = parseInt((this.scaleMax - this.scaleMin) / 9);
          let tempScaleMax = this.scaleMax;
          let tempScaleMin = this.scaleMin;
          if (this.isZoom && this.zoomed_Y_Axis) {
            y = this.zoomed_Y_Axis.range([height_components - 20, 0]);
            let zoomed_min_max = this.zoomed_Y_Axis.domain();
            tempScaleMax = moment(zoomed_min_max[0]).unix() * 1000;
            tempScaleMin = moment(zoomed_min_max[1]).unix() * 1000;
            tickInterval = parseInt((tempScaleMax - tempScaleMin) / 9);
          }
          this.timeTicks = [];
          for (
            let tick = tempScaleMin;
            tick < tempScaleMax;
            tick = tick + tickInterval
          ) {
            if (this.timeTicks.length <= 8) this.timeTicks.push(tick);
          }
          this.timeTicks.push(tempScaleMax);
          // console.log("time ticks calculation for zoom : ",tickInterval, this.timeTicks)
          yTicks = d3
            .axisLeft()
            .scale(y)
            .tickValues(this.timeTicks)
            .tickSizeInner(120);
        } else {
          let tickInterval = (this.scaleMax - this.scaleMin) / 9;
          let tempScaleMax = this.scaleMax;
          let tempScaleMin = this.scaleMin;
          if (this.isZoom && this.zoomed_Y_Axis) {
            y = this.zoomed_Y_Axis.range([height_components - 20, 0]);
            let zoomed_min_max = this.zoomed_Y_Axis.domain();
            tempScaleMax = zoomed_min_max[0];
            tempScaleMin = zoomed_min_max[1];
            tickInterval = (zoomed_min_max[0] - zoomed_min_max[1]) / 9;
          }

          this.depthTicks = [];
          for (
            let tick = tempScaleMin;
            tick <= tempScaleMax;
            tick = tick + tickInterval
          ) {
            if (this.depthTicks.length <= 8) this.depthTicks.push(tick);
          }

          this.depthTicks.push(tempScaleMax);

          let tickIntervalCompare = parseInt(
            (this.depthMaxValue - this.depthMinValue) / 9
          );

          this.timeTicks = [];
          if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue)) {
            for (
              let tick = this.depthMinValue;
              tick < this.depthMaxValue;
              tick = tick + tickIntervalCompare
            ) {
              this.timeTicks.push(tick);
            }

            if (this.timeTicks.length == 0) {
              this.yLeftScaleCompare = this.yLeftScaleCompare.domain([
                this.depthMaxValue + 9,
                this.depthMinValue,
              ]);
            }
            let generating_ticks = [];
            for (
              let remaining = this.timeTicks.length;
              remaining < 10;
              remaining++
            ) {
              // this.timeTicks.push(this.depthMinValue + remaining * 1);
              if (!this.timeTicks.length) {
                let val_to_push = this.depthMinValue + remaining;
                // if(val_to_push <= this.depthMaxValue){    // SOMETIMES GETTING SAME VALUE TO THE depthMinValue AND depthMaxValue
                generating_ticks.push(val_to_push);
                // }
              } else {
                let val_to_push =
                  this.timeTicks[this.timeTicks.length - 1] +
                  tickIntervalCompare;
                if (val_to_push <= this.depthMaxValue) {
                  generating_ticks.push(val_to_push);
                }
              }
            }
            this.timeTicks = [...this.timeTicks, ...generating_ticks];

            if (this.data["dmea"] && this.data["dmea"].length > 0) {
              yTicks = d3
                .axisLeft()
                .scale(this.yLeftScaleCompare)
                .tickValues(this.timeTicks)
                .tickSize(-65);
              // yTicks.tickFormat(d3.timeFormat("%Y-%m-%d %H:%M:%S"));
            }
          }
          yTicksCompare = d3
            .axisLeft()
            .scale(y)
            .tickValues(this.depthTicks)
            .tickSizeInner(
              isNaN(this.depthMaxValue) && isNaN(this.depthMinValue) ? 70 : 120
            );
        }

        if (this.logType == "time") {
          let timeAxis = svg
            .append("g")
            .attr("transform", "translate(35,0)")
            .call(yTicks);

          timeAxis
            .selectAll("line")
            .style("opacity", "0.2")
            .attr("transform", "translate(85,0)");
          timeAxis
            .selectAll("path")
            .style("opacity", "0")
            .attr("transform", "translate(85,0)");
          // timeAxis.selectAll("path").style("opacity", "1").attr("transform", "translate(10,0)");
          let bisectDate = d3.bisector(function (d) {
            return d;
          }).left;
          let scale = y;
          let data = this.data;
          timeAxis.selectAll("text").call(function (t) {
            t.each(function (d) {
              // for each one
              let self = d3.select(this);

              let s = [];
              s[1] = moment(new Date(d).toLocaleString("en-US")).format(
                "MM-DD-YYYY"
              );
              s[0] = moment(new Date(d).toLocaleString("en-US")).format(
                "HH:mm:ss"
              );
              // self.path("")
              self.text(""); // clear it out

              // self
              //   .append("tspan")
              //   .attr("x", 40)
              //   .attr("dy", "-0.5em")
              //   .attr("class", trendtimeStampCol)
              //   .text(s[1])
              //   .text("")
              //   .style("letter-spacing", "2.5px")
              //   .style("font-size", "min(1vw, 9px)");

              self
                .append("tspan") // insert two tspans
                .attr("x", "-0.5vw")
                .attr("dy", "-0.4em")
                .text(s[1])
                .style("font-size", "calc(5px + 0.3vw)");

              self
                .append("tspan") // insert two tspans
                .attr("x", 30)
                .attr("dy", "1.5em")
                .text(s[0])
                .style("font-size", "calc(5px + 0.3vw)");

              if (data["dmea"] && data["dmea"].length) {
                let y0 = scale.invert(d),
                  i = bisectDate(data["time"], d, 1),
                  d0 = data["dmea"][i - 1];
                // d1 = data[i];

                self
                  .append("tspan") // insert two tspans
                  .attr("x", "-2.5em")
                  .attr("dy", "0em")
                  .text(d0 ? "" + Number(d0).toFixed(2) : 0)
                  .style("letter-spacing", "0px")
                  .style("font-size", "calc(5px + 0.3vw)");
                // .style("font-size", "8px");
              }
            });
          });
        } else {
          if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue))
            if (this.data["dmea"] && this.data["dmea"].length > 0) {
              let timeAxis = svg
                .append("g")
                .attr("transform", "translate(35,0)")
                .call(yTicks);

              timeAxis
                .selectAll("line")
                .style("opacity", "0")
                .attr("transform", "translate(10,0)");
              timeAxis
                .selectAll("path")
                .style("opacity", "0")
                .attr("transform", "translate(10,0)");
              // timeAxis.selectAll("path").style("opacity", "0");
              timeAxis.selectAll("text").call(function (t) {
                t.each(function (d) {
                  // for each one
                  let self = d3.select(this);
                  let s = [];
                  s[1] = moment(new Date(d).toLocaleString("en-US")).format(
                    "MM-DD-YYYY"
                  );
                  s[0] = moment(new Date(d).toLocaleString("en-US")).format(
                    "HH:mm:ss"
                  );
                  // self.path("")
                  self.text(""); // clear it out

                  self
                    .append("tspan")
                    .attr("x", "-0.5em")
                    .attr("dy", "-0.5em")
                    .attr("class", trendtimeStampCol)
                    .style("font-size", "calc(5px + 0.3vw)")
                    // .text(s[1]);
                    .text(s[1]);
                  self
                    .append("tspan") // insert two tspans
                    .attr("x", "-2em")
                    .attr("dy", "1.5em")
                    .style("font-size", "calc(5px + 0.3vw)")
                    .text(s[0]);
                });
              });
            }

          let depthAxis = svg
            .append("g")
            .attr("transform", "translate(35,0)")
            .call(yTicksCompare);
          isNaN(this.depthMaxValue) && isNaN(this.depthMinValue)
            ? depthAxis
                .selectAll("line")
                .style("opacity", "0.2")
                .attr("transform", "translate(36,0)")
            : depthAxis
                .selectAll("line")
                .style("opacity", "0.2")
                .attr("transform", "translate(85,0)");

          depthAxis
            .selectAll("path")
            .style("opacity", "0")
            .attr("transform", "translate(10,0)");

          depthAxis
            .selectAll("text")
            .attr(
              "x",
              isNaN(this.depthMaxValue) && isNaN(this.depthMinValue)
                ? -20
                : "40"
            )
            .attr("dy", "1em")
            .style("font-size", "calc(5px + 0.3vw)");
        }
      } catch (error) {
        console.error(
          "🚀 ➽ file: DisTrend.vue:2523  ➽ createLeftBar  ➽ error ⏩",
          error
        );
      }
    },
    createActcBar(div_id, templates) {
      try {
        let template_for_plotting = templates[0];
        let template_overall = template_for_plotting[0]["curve_box"];

        d3.select("#" + this.id + "leftbarcontainer").style(
          "width",
          this.leftbarWidth + "px"
        );

        let height_components = template_overall["height"];
        let y = this.yLeftScale;
        if (this.isZoom && this.zoomed_Y_Axis) y = this.zoomed_Y_Axis;
        let width = 10;
        let graph_type = this.logType == "time" ? this.logType : "dmea";

        d3.select("#" + this.id + "left_actc_bar").remove();

        let canvas = document.getElementById(this.id + "left_actc_bar");

        if (canvas) {
          canvas.width = width;
          canvas.height = height_components;
        } else {
          d3.select("#" + this.id + "leftbarcontainer")
            .append("canvas")
            .attr("id", this.id + "left_actc_bar")
            .attr("class", "track_styles")
            // .attr(
            //   "style",
            //   `border: 1px solid ${this.darkDark ? "white" : "#c1c0c0"
            //   }`
            // )
            .style("background-color", "var(--colorBoxBg)")
            .attr("height", height_components + "px")
            .attr("width", width + "px");
          canvas = document.getElementById(this.id + "left_actc_bar");
        }

        let ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, width, height_components);
        let startActcCode = 0;
        let endActcCode = 0;
        let startActcTime = 0;
        let endActcTime = 0;
        let totalRecords = this.data["activitycode"].length - 1;
        let actcColor = "white";
        let logType = this.logType;
        ctx.beginPath();
        for (let i = 0; i < this.data["activitycode"].length; i++) {
          let d = this.data["activitycode"][i];
          if (i == 0) {
            startActcCode = d;
            startActcTime = y(this.data[graph_type][i]);
          } else {
            endActcCode = d;
            endActcTime = y(this.data[graph_type][i]);

            if (
              parseInt(startActcCode) != parseInt(endActcCode) ||
              i == totalRecords
            ) {
              let tooltip = "";
              // switch (startActcCode) {
              //   case 1:
              //     actcColor = "#92D050";
              //     tooltip = "ROT DRLG";
              //     break;
              //   case 2:
              //     actcColor = "#00B050";
              //     tooltip = "SLD DRLG";
              //     break;
              //   case 3:
              //     actcColor = "#FFC000";
              //     tooltip = "CIRC WITH ROT";
              //     break;
              //   case 4:
              //     actcColor = "#FFFFFF";
              //     tooltip = "UNKNOWN";
              //     break;
              //   case 10:
              //     actcColor = "#833C0C";
              //     tooltip = "CIRCULATION";
              //     break;
              //   case 11:
              //     actcColor = "#00CC99";
              //     tooltip = "REAMING";
              //     break;
              //   case 12:
              //     actcColor = "#008000";
              //     tooltip = "BACKREAM";
              //     break;
              //   case 13:
              //     actcColor = "#CC3300";
              //     tooltip = "CONNECTION";
              //     break;
              //   case 14:
              //     actcColor = "#2F75B5"; // Bright Blue
              //     tooltip = "TIH-DRLG";
              //     break;
              //   case 15:
              //     actcColor = "#8EA9DB"; //Cadet Blue
              //     tooltip = "POOH-DRLG";
              //     break;
              //   case 16:
              //     actcColor = "#DBDBDB";
              //     tooltip = "CIRC WITH ROT";
              //     break;
              //   case 17:
              //     actcColor = "#FFFF00";
              //     tooltip = "CIRCULATION";
              //     break;
              //   case 30:
              //     actcColor = "#FFE699";
              //     tooltip = "STATIONARY";
              //     break;
              //   case 5:
              //     actcColor = "#1F4E78";
              //     tooltip = "TIH";
              //     break;
              //   case 6:
              //     actcColor = "#BDD7EE";
              //     tooltip = "POOH";
              //     break;
              //   case 20:
              //     actcColor = "#8497B0";
              //     tooltip = "PUMPOUT";
              //     break;
              //   case 21:
              //     actcColor = "#9900FF";
              //     tooltip = "REAM-TRIP";
              //     break;
              //   case 22:
              //     actcColor = "#FF3399";
              //     tooltip = "BACK REAM-TRIP";
              //     break;
              //   case 23:
              //     actcColor = "#CCCC00";
              //     tooltip = "CONN. TRIP";
              //     break;
              //   case 24:
              //     actcColor = "#CC9900";
              //     tooltip = "CIRC W/O ROT-TRIP";
              //     break;
              //   case 25:
              //     actcColor = "#FF9933";
              //     tooltip = "CIRC W/O ROT-TRIP";
              //     break;
              //   case 26:
              //     actcColor = "#833C0C";
              //     tooltip = "CIRCULATION";
              //     break;
              //   case 27:
              //     actcColor = "#945E0D";

              //     tooltip = "WASHDOWN";
              //     break;

              //   default:
              //     break;
              // }
              if (this.wellActcCodes[startActcCode]) {
                actcColor = this.wellActcCodes[startActcCode].color;
                tooltip = this.wellActcCodes[startActcCode].name;
              }
              ctx.lineWidth = 20;
              ctx.beginPath();
              ctx.moveTo(0, startActcTime);
              ctx.lineTo(0, endActcTime);
              ctx.strokeStyle = actcColor;
              ctx.stroke();
              if (logType == "time") {
                startActcCode = d;
                startActcTime = y(this.data[graph_type][i]);
              } else {
                startActcCode = d;
                startActcTime = y(this.data[graph_type][i]);
              }
            } else {
            }
          }
        }

        let showActcBarToolTip = (e, text_to_show, isShow) => {
          let index = 0;
          let log = "time";
          if (this.logType == "depth") {
            log = "dmea";
          }
          if (e) {
            let bisectDate = d3.bisector(function (d) {
              return d;
            }).left;

            let y = this.yLeftScale;
            if (this.isZoom && this.zoomed_Y_Axis) y = this.zoomed_Y_Axis;
            let y0 = 0;
            if (this.logType == "depth") {
              y0 = y.invert(d3.pointer(e)[1]);
            } else {
              y0 = moment(y.invert(d3.pointer(e)[1])).unix() * 1000;
            }

            index = bisectDate(
              this.data[log],
              y0,
              0,
              this.data[log].length - 1
            );
            // let text = {
            //   1: "ROT DRLG",
            //   2: "SLD DRLG",
            //   3: "CIRC WITH ROT",
            //   4: "UNKNOWN",
            //   10: "CIRCULATION",
            //   11: "REAMING",
            //   12: "BACKREAM",
            //   13: "CONNECTION",
            //   14: "TIH-DRLG",
            //   15: "POOH-DRLG",
            //   16: "CIRC WITH ROT",
            //   17: "CIRCULATION",
            //   30: "STATIONARY",
            //   5: "TIH",
            //   6: "POOH",
            //   20: "PUMPOUT",
            //   21: "REAM-TRIP",
            //   22: "BACK REAM-TRIP",
            //   23: "CONN.TRIP",
            //   24: "CIRC WITH ROT-TRIP",
            //   25: "CIRC W/O ROT-TRIP",
            //   26: "CIRCULATION",
            //   27: "WASHDOWN",
            // };
            this.mouse_x = e.clientX + 5;
            this.mouse_y = e.clientY - 20;
            // this.actcBarMessage = text[this.data["activitycode"][index]];
            this.actcBarMessage =
              this.wellActcCodes[this.data["activitycode"][index]].name;
            this.isActcBarHover = isShow;
          }
        };
        let hideActcBarToolTip = (event, text, isShow) => {
          if (this.tooltipTimeOut) {
            clearTimeout(this.tooltipTimeOut);
          }
          this.tooltipTimeOut = setTimeout(() => {
            this.mouse_x = 0;
            this.mouse_y = 0;
            this.actcBarMessage = "";
            this.isActcBarHover = isShow;
          }, 500);
        };

        canvas.addEventListener("mouseover", (event) => {
          if (this.isShowTooltip) {
            showActcBarToolTip(event, "", true);
          }
        });
        // Function to handle mousemove event and display tooltips
        canvas.addEventListener("mousemove", (event) => {
          if (this.isShowTooltip) {
            showActcBarToolTip(event, "", true);
          }
        });

        // Function to handle mouseout event and clear the tooltip
        canvas.addEventListener("mouseout", (event) => {
          hideActcBarToolTip(event, "", false);
        });
      } catch (error) {
        console.log(
          "🚀 ➽ file: DisTrend.vue:2523  ➽ createActcBar  ➽ error ⏩",
          error
        );
      }
    },
    verticalplots(
      div_id,
      templates,
      show_all = true,
      remove_preexisting = true
    ) {
      // let noDIV = d3
      //   .select("#" + div_id)
      //   .selectAll("div")
      //   .remove();
      // let noSVG = d3
      //   .select("#" + div_id)
      //   .selectAll("svg")
      //   .remove();

      if (templates.length > 0) {
        this.createLeftBar(div_id, templates);
        if (!this.isDis) this.createActcBar(div_id, templates);
      }
      for (let i = 0; i < templates.length; i++) {
        //  templates.forEach((it, i)=>{
        let arr = [];
        let track_bg_s = this.darkDark !== "white" ? "" : this.bgColor;

        if (!document.getElementById(templates[i][0]["curve_box"]["trackId"])) {
          // console.log(
          //   "🚀 ➽ file: DisTrend.vue:2675",
          //   templates[i][0]["curve_box"]["trackId"],
          //   document.getElementById(templates[i][0]["curve_box"]["trackId"])
          // );
          let curvebox_holder = d3.select("#" + div_id).append("div");
          curvebox_holder
            .style("vertical-align", "middle")
            .style("background", track_bg_s)
            .style("transition:", "var(--transition)")
            .attr("id", templates[i][0]["curve_box"]["trackId"])
            .attr(
              "class",
              this.isDrillingDis ? "__bg_label_boxes drillingDisClass" : ""
            );
          // console.log("_______ 1 height _________ ");
          //// to control view of plots on site, user can show-hide by triggering action here. However, if show_all = false then none will show, so developer will need to change CSS with another function one by one!
          if (show_all) {
            curvebox_holder.style("display", "inline-block");
            curvebox_holder.style("margin-right", "0px");
          } else {
            curvebox_holder.style("display", "none");
          }
        }

        // templates[i][0]["curve_box"]["div_id"] = div_id + "curvebox_holder" + i;

        templates[i][0]["curve_box"]["div_id"] =
          templates[i][0]["curve_box"]["trackId"];

        // new_templates.push(templates[i]);

        this.curveBox(templates[i], i, div_id, this.data);
      }
    },
    annotationClick(e, isAnnotation, annotation_type) {
      this.hoverEvent = e;
      // let offsetYPercent =
      //   (e.offsetY / e.target.getBoundingClientRect().height) * 100;
      // let parentHeight = e.target.getBoundingClientRect().height;
      let timeRangeInSeconds =
        this.logType == "time"
          ? this.scaleMax - this.scaleMin
          : this.scaleMax - this.scaleMin;
      // let tooltipShift = this.logType == "time" ? 100 : timeRangeInSeconds / 2;

      let data = this.data;
      let bisectDate = d3.bisector(function (d) {
        return d;
      }).left; // **

      let y = this.yLeftScale;
      if (this.isZoom && this.zoomed_Y_Axis) y = this.zoomed_Y_Axis;
      let y0 = 0;
      let index = 0;
      let log = "time";
      if (this.logType == "depth") {
        y0 = y.invert(d3.pointer(e)[1]);
        log = "dmea";
      } else {
        y0 = moment(y.invert(d3.pointer(e)[1])).unix() * 1000;
      }
      index = bisectDate(data[log], y0, 1);
      let mouse_y,
        mouse_x = -1;
      if (isAnnotation) {
        if (annotation_type == "ALERTS") {
          for (let i of this.alertTimes) {
            if (e.y - 300 >= i.y && e.y - 300 <= i.yEnd) {
              mouse_y = e.y;
              mouse_x = e.x - 50;
              this.clickedAlert = i.alert;
              break;
            } else {
            }
          }
        } else if (annotation_type == "STICK SLIP") {
          for (let i of this.alertStickslip) {
            if (e.y - 300 >= i.y && e.y - 300 <= i.yEnd) {
              mouse_y = e.y;
              mouse_x = e.x - 50;
              this.clickedAlert = i.alert;
              break;
            } else {
            }
          }
        } else if (annotation_type == "WASH OUT") {
          for (let i of this.alertWashout) {
            if (e.y - 300 >= i.y && e.y - 300 <= i.yEnd) {
              mouse_y = e.y;
              mouse_x = e.x - 50;
              this.clickedAlert = i.alert;
              break;
            } else {
            }
          }
        }
      }
    },
    mousemove(e, isAnnotation, isStickSlip = false, isWashout = false) {
      try {
        let index = this.tooltipUpdateIndex || 0;
        let parentHeight = 0;
        let parentTop = 0;
        let log = "time";
        if (this.logType == "depth") {
          log = "dmea";
        }

        let timeRangeInSeconds = 0;
        if (e) {
          this.tooltip_horizontalline_ypos = e.y;
          parentHeight = e.target.getBoundingClientRect().height;
          parentTop = e.target.getBoundingClientRect().top;
          timeRangeInSeconds =
            this.logType == "time"
              ? this.scaleMax - this.scaleMin
              : this.scaleMax - this.scaleMin;

          let bisectDate = d3.bisector(function (d) {
            return d;
          }).left; // **

          let y = this.yLeftScale;
          if (this.isZoom && this.zoomed_Y_Axis) y = this.zoomed_Y_Axis;
          let y0 = 0;
          if (this.logType == "depth") {
            y0 = y.invert(d3.pointer(e)[1]);
          } else {
            y0 = moment(y.invert(d3.pointer(e)[1])).unix() * 1000;
          }

          index = bisectDate(this.data[log], y0, 0, this.data[log].length - 1);
          this.tooltipUpdateIndex = index;

          if (this.isShowTooltip) {
            if (parentHeight < 200) this.mouse_y_tooltip = e.y - parentTop;
            else {
              if (window.screen.width <= 1280) {
                this.mouse_y_tooltip = e.y - parentTop * 0.8;
              } else {
                if (this.isDrillingDis) {
                  this.mouse_y_tooltip =
                    e.y - parentTop < parentHeight / 2
                      ? e.y - parentTop + parentHeight / 3.5
                      : e.y - parentTop / 3.5;
                } else {
                  this.mouse_y_tooltip =
                    e.y - parentTop < parentHeight / 2
                      ? e.y - parentTop + parentHeight / 2
                      : e.y - parentTop;
                }
              }
            }
            if (this.isDrillingDis) this.mouse_y_tooltip_line = e.y - parentTop;
            else this.mouse_y_tooltip_line = e.y - parentTop + 138 + 28;

            // console.log(" mouse move event : ", e)
          }

          // if (this.mouse_y_tooltip >= this.yLeftScale.range()[0]) {
          //   this.mouse_y_tooltip = -1;
          //   this.mouse_y_tooltip_line = -1 ;
          //   console.log("false return condition");
          //   return false;
          // }
        } else {
        }

        let d = {};
        let all_curves = [];
        if (this.trackConfig.tracks && this.trackConfig.tracks.length) {
          all_curves = this.trackConfig.tracks.flat();
        }
        for (let i in this.data) {
          let decimals_to_fix = 2;
          if (all_curves && all_curves.length) {
            let found = all_curves.find((e) => e.name == i);
            if (found)
              decimals_to_fix =
                found?.decimals || found?.decimals == 0
                  ? parseInt(found?.decimals)
                  : 2;
          }
          if (i == "activitycode") {
            // let text = {
            //   1: "ROT DRLG",
            //   2: "SLD DRLG",
            //   3: "CIRC WITH ROT",
            //   4: "UNKNOWN",
            //   10: "CIRCULATION",
            //   11: "REAMING",
            //   12: "BACKREAM",
            //   13: "CONNECTION",
            //   14: "TIH-DRLG",
            //   15: "POOH-DRLG",
            //   16: "CIRC WITH ROT",
            //   17: "CIRCULATION",
            //   30: "STATIONARY",
            //   5: "TIH",
            //   6: "POOH",
            //   20: "PUMPOUT",
            //   21: "REAM-TRIP",
            //   22: "BACK REAM-TRIP",
            //   23: "CONN.TRIP",
            //   24: "CIRC WITH ROT-TRIP",
            //   25: "CIRC W/O ROT-TRIP",
            //   26: "CIRCULATION",
            //   27: "WASHDOWN",
            // };
            // this.tooltip_d[i] = text[this.data[i][index]];
            this.tooltip_d[i] = this.wellActcCodes[this.data[i][index]]?.name;
          } else if (i == "stickslipindex" || i == "washoutindex") {
            let key_to_get_data =
              i == "stickslipindex" ? "stickslip" : "washout";
            this.tooltip_d[i] =
              isNaN(parseFloat(this.data[i][index])) ||
              i == "time" ||
              this.data[i][index] == 0
                ? this.data[i][index]
                : this.data[i][index] == "null" || this.data[i][index] == null
                ? ""
                : this.data[key_to_get_data][index] !== "NONE" &&
                  this.data[key_to_get_data][index]
                ? this.data[key_to_get_data][index]
                : parseFloat(this.data[i][index]).toFixed(4);
          } else
            this.tooltip_d[i] =
              isNaN(parseFloat(this.data[i][index])) ||
              i == "time" ||
              this.data[i][index] == 0
                ? this.data[i][index]
                : this.data[i][index] == "null" || this.data[i][index] == null
                ? ""
                : parseFloat(this.data[i][index]).toFixed(decimals_to_fix);
        }

        if (this.isShowAlerts && isAnnotation) {
          clearTimeout(this.hoveringAlertTimmer);
          this.hoveringAlertTimmer = setTimeout(() => {
            this.mouse_y = -1;
            this.mouse_x = -1;
            this.hoveringAlert = "";
          }, 0);

          let remaining_height = window.innerHeight - e.clientY;

          for (let i of this.alertTimes) {
            if (e.y - 300 >= i.y && e.y - 300 <= i.yEnd) {
              this.mouse_y =
                remaining_height < 388 ? window.innerHeight - 388 : e.clientY;
              this.mouse_x = e.x - 60;
              this.hoveringAlert = i.alert;
              clearTimeout(this.hoveringAlertTimmer);
              break;
            } else {
            }
          }
        } else if (this.isShowAlerts && isStickSlip) {
          clearTimeout(this.hoveringAlertTimmer);
          this.hoveringAlertTimmer = setTimeout(() => {
            this.mouse_y = -1;
            this.mouse_x = -1;
            this.hoveringAlert = "";
          }, 0);

          let remaining_height = window.innerHeight - e.clientY;

          this.mouse_y =
            remaining_height < 388 ? window.innerHeight - 388 : e.clientY;

          for (let i of this.alertStickslip) {
            if (e.y - 300 >= i.y && e.y - 300 <= i.yEnd) {
              this.mouse_y =
                remaining_height < 388 ? window.innerHeight - 388 : e.clientY;
              this.mouse_x = e.x - 60;
              this.hoveringAlert = i.alert;
              clearTimeout(this.hoveringAlertTimmer);
              break;
            } else {
            }
          }
        } else if (this.isShowAlerts && isWashout) {
          clearTimeout(this.hoveringAlertTimmer);
          this.hoveringAlertTimmer = setTimeout(() => {
            this.mouse_y = -1;
            this.mouse_x = -1;
            this.hoveringAlert = "";
          }, 0);

          let remaining_height = window.innerHeight - e.clientY;

          this.mouse_y =
            remaining_height < 388 ? window.innerHeight - 388 : e.clientY;

          for (let i of this.alertWashout) {
            if (e.y - 300 >= i.y && e.y - 300 <= i.yEnd) {
              this.mouse_y =
                remaining_height < 388 ? window.innerHeight - 388 : e.clientY;
              this.mouse_x = e.x - 60;
              this.hoveringAlert = i.alert;
              clearTimeout(this.hoveringAlertTimmer);
              break;
            } else {
            }
          }
        } else {
          this.mouse_y = -1;
          this.mouse_x = -1;
          this.hoveringAlert = "";
        }
      } catch (error) {
        console.error(
          "🚀 ➽ file: DisTrend.vue:3916  ➽ mousemove  ➽ error ⏩",
          error
        );
      }
    },
    mouseout(e) {
      // for (let focusAxis of this.focusAxisList) {
      //   let focus = focusAxis["focus"];
      //   focus
      //     .style("display", "none")
      //     .transition()
      //     .duration(250)
      //     .style("opacity", 0);
      // }
    },
    async mousescroll(e) {
      if (this.logType == "time") {
        this.takeOffSocket();
        this.liveDisabled = false;
        let scrollAmount = parseInt((this.scaleMax - this.scaleMin) / 4);
        // downscroll code
        if (e.deltaY > 0) {
          if (this.scaleMax == this.scaleMaxFixed) {
            if (this.mousewheel_toast_timeout)
              clearTimeout(this.mousewheel_toast_timeout);
            this.mousewheel_toast_timeout = setTimeout(() => {
              this.$toast.info("Well end reached", {
                position: "top",
                duration: 1000,
              });
              clearTimeout(this.mousewheel_toast_timeout);
            }, 300);
            return true;
          }
          if (this.scaleMaxFixed - scrollAmount - 2 > this.scaleMax) {
            this.scaleMax = this.scaleMax + scrollAmount;
            this.scaleMin = this.scaleMin + scrollAmount;
          } else {
            this.scaleMax = this.scaleMaxFixed;
            this.scaleMin = this.scaleMax - this.currentTimeRange * 3600000;
          }
          this.rangeInput = this.scaleMax;
        }
        // upscroll code
        if (e.deltaY < 0) {
          if (this.scaleMin == this.scaleMinFixed) {
            this.rangeInput = this.scaleMin;
            if (this.mousewheel_toast_timeout)
              clearTimeout(this.mousewheel_toast_timeout);
            this.mousewheel_toast_timeout = setTimeout(() => {
              this.$toast.info("Well start reached", {
                position: "top",
                duration: 1000,
              });
              clearTimeout(this.mousewheel_toast_timeout);
            }, 300);
            return true;
          }
          if (this.scaleMinFixed < this.scaleMin - scrollAmount) {
            this.scaleMax = this.scaleMax - scrollAmount;
            this.scaleMin = this.scaleMin - scrollAmount;
          } else {
            this.scaleMin = this.scaleMinFixed;
            this.scaleMax =
              this.scaleMinFixed + this.currentTimeRange * 3600000;
          }
          this.rangeInput = this.scaleMax;
        }
        // console.time('on mouse scroll')

        // await this.rerender("zoomIt");
        // console.timeEnd('on mouse scroll')
        this.scaleMinDate = new Date(this.scaleMin).toLocaleString("en-US", {
          timeZone: this.time_zone,
        });
        if (this.mousewheeltimeout) clearTimeout(this.mousewheeltimeout);
        this.mousewheeltimeout = setTimeout(async () => {
          await this.filterData(null, 4093);
          this.rerender();
          clearTimeout(this.mousewheeltimeout);
        }, 300);
      } else {
        this.takeOffSocket();
        this.liveDisabled = false;

        // downscroll code
        if (e.deltaY > 0)
          if (
            this.scaleMaxFixed - Number(this.currentFeetRange) >
            this.scaleMax
          ) {
            this.scaleMax = this.scaleMax + Number(this.currentFeetRange);
            this.scaleMin = this.scaleMin + Number(this.currentFeetRange);
            this.rangeInput = this.scaleMax;
          } else {
            this.scaleMax = this.scaleMaxFixed;
            this.scaleMin = this.scaleMax - Number(this.currentFeetRange);
            this.rangeInput = this.scaleMax;
          }
        // upscroll code
        if (e.deltaY < 0)
          if (
            this.scaleMinFixed <
            this.scaleMin - Number(this.currentFeetRange)
          ) {
            this.scaleMax = this.scaleMax - Number(this.currentFeetRange);
            this.scaleMin = this.scaleMin - Number(this.currentFeetRange);
            this.rangeInput = this.scaleMax;
          } else {
            this.scaleMin = this.scaleMinFixed;
            this.rangeInput = this.scaleMin;
            this.scaleMax = this.scaleMin + Number(this.currentFeetRange);
          }

        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "mousewheel"
        );
      }
    },
    zoomed(e) {
      // console.time("zoomed called")
      let transform = e.transform;
      // Update scales with zoom
      // if (this.isZoom) {
      if (this.$refs["zoomswitch" + this.id].checked) {
        if (this.zoomed_Y_Axis) {
          this.takeOffSocket();
          this.liveDisabled = false;
        }
        const new_yScale = transform.rescaleY(this.yLeftScale);

        // console.log("y re scale range : ", new_yScale);
        this.zoomed_Y_Axis = new_yScale;

        let maxY = new_yScale.domain()[0];
        let minY = new_yScale.domain()[1];

        this.scaleMinDate = new Date(minY).toLocaleString("en-US", {
          timeZone: this.time_zone,
        });

        if (this.logType == "depth") {
          this.currentFeetRange = Number(maxY - minY).toFixed(2);
          this.rerender();
        } else {
          minY = moment(minY).unix() * 1000;
          maxY = moment(maxY).unix() * 1000;
          this.currentTimeRange = Number(
            (maxY - minY) / (60 * 60 * 1000)
          ).toFixed(2);
          if (this.currentTimeRange > 4) {
            clearTimeout(this.zoomTimer);
            this.zoomTimer = setTimeout(async () => {
              await this.filterData([minY, maxY], 4172);
              await this.rerender();
            }, 400);
          } else {
            this.filterData([minY, maxY], 4172);
            this.rerender();
          }
        }

        // if (this.isShowTooltip && this.tooltipUpdateIndex != null){
        //   this.mousemove(null, false);
        // }
      } else {
        // console.log("source event : zoomed : ", e);
        if (e.sourceEvent && e.sourceEvent.deltaY)
          this.mousescroll(e.sourceEvent);
      }
      // console.timeEnd("zoomed called")
    },
    async curveBox(template_for_plotting, index, divId, data) {
      try {
        // console.time("curve box divId " + divId);
        let template_overall = template_for_plotting[0]["curve_box"];
        let template_components = template_for_plotting[0]["components"];
        let template_curves = template_components[0]["curves"][0];
        let height_components = template_overall["height"];
        let margin = template_overall["margin"];
        let width = template_overall["width"];

        if (data == null || data.length == 0 || !data["time"]) {
          this.isRerendering = false;
          return;
        }
        //// Variables related to curves, these should all be arrays with one or more values!
        let curve_names = template_curves["curve_names"];
        let curve_colors = template_curves["curve_colors"];
        let curveScales = template_curves["scale"];
        let curveScaleType = template_curves["scaleType"];

        let div_id = template_overall["div_id"];

        let canvas = document.getElementById("rect" + div_id);
        if (this.isDrillingDis) this.calculateSectionDimensions();

        if (canvas) {
          canvas.width = this.isDrillingDis
            ? this.distrenddiv_sectionWidth / 3.5
            : width;
          if (!this.isDrillingDis) canvas.height = height_components;

          // let  new_element = old_element.cloneNode(true);
          // canvas.parentNode.replaceChild(new_element, canvas);
          let isAnnotation = curve_names.indexOf("alert") > -1;
          let isStickSlip = curve_names.indexOf("stickslipindex") > -1;
          let isWashout = curve_names.indexOf("washoutindex") > -1;
          if (this.isShowTooltip && this.tooltipUpdateIndex != null)
            this.mousemove(null, isAnnotation, isStickSlip, isWashout);
        } else {
          d3.select("#" + div_id)
            .style("margin-left", "5px")
            .append("canvas")
            .attr("id", "rect" + div_id)
            .attr("class", "track_styles for_tooltip_left_position")
            // .attr(
            //   "style",
            //   `border: 1px solid ${this.darkDark ? "white" : "#c1c0c0"
            //   }`
            // )
            .style("background-color", "var(--colorBoxBg)")
            .attr(
              "height",
              (this.isDrillingDis ? this.screenHeight : height_components) +
                "px"
            )
            .attr(
              "width",
              (this.isDrillingDis
                ? this.distrenddiv_sectionWidth / 3.5
                : width) + "px"
            );
          canvas = document.getElementById("rect" + div_id);
          let isAnnotation = curve_names.indexOf("alert") > -1;
          let isStickSlip = curve_names.indexOf("stickslipindex") > -1;
          let isWashout = curve_names.indexOf("washoutindex") > -1;
          let isActivitycode = curve_names.indexOf("activitycode") > -1;
          canvas.addEventListener("mouseover", (event) => {
            this.mousemove(event, isAnnotation, isStickSlip, isWashout);
          });
          // Function to handle mousemove event and display tooltips
          canvas.addEventListener("mousemove", (event) => {
            this.mousemove(event, isAnnotation, isStickSlip, isWashout);
          });

          if (isAnnotation || isStickSlip || isWashout)
            canvas.addEventListener("click", (event) => {
              let foundArray = this.trackConfig.tracks.find(
                (eachArr) =>
                  eachArr.length &&
                  eachArr[0]?.trackId == event.target.parentNode.id &&
                  eachArr.some(
                    (eachObj) =>
                      eachObj?.name == "alert" ||
                      eachObj?.name == "stickslipindex" ||
                      eachObj?.name == "washoutindex"
                  )
              );
              if (foundArray) {
                // TO HIDE THE HOVERING ALERT CODE STARTS
                this.mouse_y = -1;
                this.mouse_x = -1;
                this.hoveringAlert = "";
                // TO HIDE THE HOVERING ALERT CODE ENDS

                for (let obj = 0; obj < foundArray.length; obj++) {
                  if (foundArray[obj]?.name == "alert") {
                    // this.annotationClick(event, isAnnotation || isStickSlip || isWashout, 'ALERTS');
                    this.annotationClick(event, isAnnotation, "ALERTS");
                    break;
                  } else if (foundArray[obj]?.name == "stickslipindex") {
                    this.annotationClick(event, isStickSlip, "STICK SLIP");
                    break;
                  } else if (foundArray[obj]?.name == "washoutindex") {
                    this.annotationClick(event, isWashout, "WASH OUT");
                    break;
                  }
                }
              }

              // this.annotationClick(event, isAnnotation || isStickSlip || isWashout);
            });

          if (isActivitycode) {
            canvas.addEventListener("dblclick", (event) => {
              const mouseX =
                event.clientX - canvas.getBoundingClientRect().left;
              const mouseY = event.clientY - canvas.getBoundingClientRect().top;
              if (this.logType == "time") {
                this.actcCodeClickHandler(mouseX, mouseY);
              }
            });
          }
          // Function to handle mouseout event and clear the tooltip
          // canvas.addEventListener("mouseout", (event) => {
          // this.mouse_y_tooltip = -1;
          // this.tooltipUpdateIndex = null;
          // this.tooltip_horizontalline_ypos = null;
          // });

          // canvas.addEventListener("mousewheel", (event) => {
          //   this.mousescroll(event);
          // });

          this.zoom1 = d3
            .zoom()
            .scaleExtent([1, 50])
            .translateExtent([
              [0, 0],
              [width, height_components - 20],
            ])
            .extent([
              [0, 0],
              [width, height_components - 20],
            ])
            .on("zoom", this.zoomed);
          let id = this.div_id;
          d3.select("#" + id).call(this.zoom1);
          d3.select("#" + id).on("wheel", (e) => {
            if (!this.isZoom) {
              this.mousescroll(e);
            }
          });
        }

        let mins = [];
        let maxes = [];

        let ctx = canvas.getContext("2d");
        let plotWidth = canvas.width;
        let plotHeight = canvas.height;

        let y = this.yLeftScale;
        if (this.isZoom && this.zoomed_Y_Axis) y = this.zoomed_Y_Axis;
        let drawTrend = () => {
          ctx.clearRect(0, 0, plotWidth, plotHeight);
          // Draw horizontal grid lines
          ctx.globalAlpha = 0.5;
          const numHorizontalLines =
            this.logType == "time" ? this.timeTicks : this.depthTicks;
          const stepY = plotHeight / (numHorizontalLines + 1);
          for (let i = 1; i <= numHorizontalLines.length; i++) {
            // const y = i * stepY;
            if (this.isDrillingDis) ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(0, y(numHorizontalLines[i]));
            ctx.lineTo(plotWidth, y(numHorizontalLines[i]));
            ctx.setLineDash([5, 5]); // An array of lengths for the dashes and gaps
            ctx.lineDashOffset = 0; // Offset for the dash pattern
            ctx.strokeStyle = "gray"; // Grid line color
            ctx.stroke();
          }

          // Draw vertical grid lines
          const numVerticalLines = 2;
          const stepX = plotWidth / (numVerticalLines + 1);
          for (let i = 1; i <= numVerticalLines; i++) {
            const x = i * stepX;
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, plotHeight);
            ctx.strokeStyle = "gray"; // Grid line color

            ctx.stroke();
          }
          ctx.setLineDash([]); // An array of lengths for the dashes and gaps
          ctx.globalAlpha = 1;

          let graph_type = "time";
          if (this.logType == "depth") graph_type = "dmea";

          for (let k = 0; k < curve_names.length; k++) {
            try {
              if (curve_names[k] == "activitycode") {
                let startActcCode = 0;
                let endActcCode = 0;
                let startActcTime = 0;
                let endActcTime = 0;
                let totalRecords = data["activitycode"].length - 1;
                let actcColor = "white";
                let logType = this.logType;
                ctx.beginPath();
                for (let i = 0; i < data[curve_names[k]].length; i++) {
                  let d = data[curve_names[k]][i];
                  if (i == 0) {
                    startActcCode = d;
                    startActcTime = y(data[graph_type][i]);
                  } else {
                    endActcCode = d;
                    endActcTime = y(data[graph_type][i]);

                    if (
                      parseInt(startActcCode) != parseInt(endActcCode) ||
                      i == totalRecords
                    ) {
                      let tooltip = "";
                      // switch (startActcCode) {
                      //   case 1:
                      //     actcColor = "#92D050";
                      //     tooltip = "ROT DRLG";
                      //     break;
                      //   case 2:
                      //     actcColor = "#00B050";
                      //     tooltip = "SLD DRLG";
                      //     break;
                      //   case 3:
                      //     actcColor = "#FFC000";
                      //     tooltip = "CIRC WITH ROT";
                      //     break;
                      //   case 4:
                      //     actcColor = "#FFFFFF";
                      //     tooltip = "UNKNOWN";
                      //     break;
                      //   case 10:
                      //     actcColor = "#833C0C";
                      //     tooltip = "CIRCULATION";
                      //     break;
                      //   case 11:
                      //     actcColor = "#00CC99";
                      //     tooltip = "REAMING";
                      //     break;
                      //   case 12:
                      //     actcColor = "#008000";
                      //     tooltip = "BACKREAM";
                      //     break;
                      //   case 13:
                      //     actcColor = "#CC3300";
                      //     tooltip = "CONNECTION";
                      //     break;
                      //   case 14:
                      //     actcColor = "#2F75B5"; // Bright Blue
                      //     tooltip = "TIH-DRLG";
                      //     break;
                      //   case 15:
                      //     actcColor = "#8EA9DB"; //Cadet Blue
                      //     tooltip = "POOH-DRLG";
                      //     break;
                      //   case 16:
                      //     actcColor = "#DBDBDB";
                      //     tooltip = "CIRC WITH ROT";
                      //     break;
                      //   case 17:
                      //     actcColor = "#FFFF00";
                      //     tooltip = "CIRCULATION";
                      //     break;
                      //   case 30:
                      //     actcColor = "#FFE699";
                      //     tooltip = "STATIONARY";
                      //     break;
                      //   case 5:
                      //     actcColor = "#1F4E78";
                      //     tooltip = "TIH";
                      //     break;
                      //   case 6:
                      //     actcColor = "#BDD7EE";
                      //     tooltip = "POOH";
                      //     break;
                      //   case 20:
                      //     actcColor = "#8497B0";
                      //     tooltip = "PUMPOUT";
                      //     break;
                      //   case 21:
                      //     actcColor = "#9900FF";
                      //     tooltip = "REAM-TRIP";
                      //     break;
                      //   case 22:
                      //     actcColor = "#FF3399";
                      //     tooltip = "BACK REAM-TRIP";
                      //     break;
                      //   case 23:
                      //     actcColor = "#CCCC00";
                      //     tooltip = "CONN. TRIP";
                      //     break;
                      //   case 24:
                      //     actcColor = "#CC9900";
                      //     tooltip = "CIRC WITH ROT-TRIP";
                      //     break;
                      //   case 25:
                      //     actcColor = "#FF9933";
                      //     tooltip = "CIRC W/O ROT-TRIP";
                      //     break;
                      //   case 26:
                      //     actcColor = "#833C0C";
                      //     tooltip = "CIRCULATION";
                      //     break;
                      //   case 27:
                      //     actcColor = "#945E0D";

                      //     tooltip = "WASHDOWN";
                      //     break;

                      //   default:
                      //     break;
                      // }
                      if (this.wellActcCodes[startActcCode]) {
                        actcColor = this.wellActcCodes[startActcCode].color;
                        tooltip = this.wellActcCodes[startActcCode].name;
                      }
                      // console.log("🚀 ➽ file: DisTrend.vue:3999  ➽ drawTrend  ➽ actcColor ⏩", actcColor, startActcTime, endActcTime, startActcCode, tooltip)
                      // d3.select(this)
                      //   .append("rect")
                      //   .attr("class", "bar")
                      //   .attr("data-index", i)
                      //   .attr("width", "60px")
                      //   // .attr('x', function(e) { return x(d.Date); })
                      //   .style("fill", actcColor)
                      //   .attr("y", y(startActcTime))
                      //   // .attr("height", "48px");
                      //   .attr(
                      //     "height",
                      //     y(endActcTime) - y(startActcTime)
                      //       ? y(endActcTime) - y(startActcTime) + "px"
                      //       : " 0px"
                      //   )
                      //   .append("text");
                      ctx.lineWidth = this.isDrillingDis ? 40 : width - 40;
                      // ctx.lineWidth = width - 40;
                      ctx.beginPath();
                      ctx.moveTo(plotWidth / 2, startActcTime);
                      ctx.lineTo(plotWidth / 2, endActcTime);
                      ctx.strokeStyle = actcColor;
                      ctx.stroke();
                      this.actcRectDetails.push({
                        name: tooltip,
                        start: plotWidth / 2,
                        end: endActcTime,
                      });
                      if (logType == "time") {
                        startActcCode = d;
                        startActcTime = y(data[graph_type][i]);
                      } else {
                        startActcCode = d;
                        startActcTime = y(data[graph_type][i]);
                      }
                    } else {
                    }
                  }
                }
              } else if (curve_names[k] == "alert") {
                let alertTimes = [];
                let actcColor = "red";
                let lastEndTime = 0;
                let lastOffset = 18;
                function wrap(text, width) {
                  text.each(function (d) {
                    let text = d3.select(this),
                      words = text.text().split(/\s+/).reverse(),
                      word,
                      line = [],
                      lineNumber = 0,
                      lineHeight = 0.8, // ems
                      x = text.attr("x"),
                      y = text.attr("y"),
                      dy = 0,
                      tspan = text
                        .text(null)
                        .append("tspan")
                        .attr("x", x)
                        .attr("y", y)
                        .attr("dy", dy + "em");
                    while ((word = words.pop())) {
                      line.push(word);
                      tspan.text(line.join(" "));

                      if (tspan.node().getComputedTextLength() > width) {
                        line.pop();
                        tspan.text(line.join(" "));
                        line = [word];
                        tspan = text
                          .append("tspan")
                          .attr("x", x)
                          .attr("y", y)
                          .attr("dy", ++lineNumber * lineHeight + dy + "em")
                          .text(word);
                      }
                    }
                  });
                }
                let theme = this.darkDark;
                function drawAlert(istance, alerts, isPair, i) {
                  if (!lastEndTime) {
                    lastEndTime =
                      y(alerts[0].time) +
                      (y(alerts[alerts.length - 1].time) - y(alerts[0].time)) /
                        2;
                  } else {
                    let newPlacement =
                      y(alerts[0].time) +
                      (y(alerts[alerts.length - 1].time) - y(alerts[0].time)) /
                        2;

                    lastEndTime += lastOffset;
                    if (newPlacement > lastEndTime) {
                      lastEndTime = newPlacement;
                    }
                  }

                  let message = alerts[0].msg.split(",");
                  let messageLength = message.length;
                  for (let alertC = 0; alertC < messageLength; alertC++) {
                    let textMessage = message[alertC];
                    if (alertC) lastEndTime += lastOffset;
                    if (isPair) {
                      ctx.lineWidth = 1;
                      ctx.beginPath();
                      ctx.moveTo(0, y(alerts[0].time));
                      ctx.lineTo(18, lastEndTime);
                      ctx.strokeStyle = "red";
                      ctx.stroke();
                      ctx.lineWidth = 1;
                      ctx.beginPath();
                      ctx.moveTo(0, y(alerts[alerts.length - 1].time));
                      ctx.lineTo(18, lastEndTime);
                      ctx.strokeStyle = "red";
                      ctx.stroke();
                    } else {
                      ctx.lineWidth = 1;
                      ctx.beginPath();
                      ctx.moveTo(0, y(alerts[0].time));
                      ctx.lineTo(18, lastEndTime);
                      ctx.strokeStyle = "red";
                      ctx.stroke();
                    }

                    let rectX = 20 + 5;
                    let rectY = lastEndTime;
                    let rectWidth = width - 20;

                    let maxTextWidth = rectWidth - 20; // Leave some padding for the text
                    let textX = rectX + 0; // Start with some padding
                    let textY = rectY + 2.5; // Start with some padding
                    const lineHeight = 8;

                    function wrapText(text, isText) {
                      let rect_height = 0;
                      const words = text.split(" ");
                      let line = "";
                      for (let n = 0; n < words.length; n++) {
                        const testLine = line + words[n] + " ";
                        const testWidth = ctx.measureText(testLine).width;
                        if (testWidth > maxTextWidth && n > 0) {
                          if (isText) ctx.fillText(line, textX, textY);
                          else rect_height++;
                          line = words[n] + " ";
                          textY += lineHeight;
                        } else {
                          line = testLine;
                        }
                      }
                      if (isText) ctx.fillText(line, textX, textY);
                      else rect_height++;

                      return rect_height * lineHeight;
                    }

                    ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
                    ctx.shadowOffsetX = 5;
                    ctx.shadowOffsetY = 5;
                    ctx.shadowBlur = 5;
                    ctx.fillStyle = "white";
                    let height = wrapText(textMessage) + 4;
                    ctx.fillRect(20, lastEndTime - 7, width - 20, height);
                    alertTimes.push({
                      y: lastEndTime - 27,
                      yEnd: lastEndTime - 24 + height,
                      alert: textMessage,
                    });
                    maxTextWidth = rectWidth - 20; // Leave some padding for the text
                    textX = rectX + 0; // Start with some padding
                    textY = rectY + 2.5; // Start with some padding

                    ctx.shadowColor = "transparent"; // Reset shadow for text
                    ctx.fillStyle = "red";
                    wrapText(textMessage, true);
                  }
                }
                let alerts = [];

                for (let i = 0; i < data["alert"].length; i++) {
                  let d = data["alert"][i];
                  let y_axis_mn = "time";
                  if (data["time_alerts"]) y_axis_mn = "time_alerts";
                  alerts.push({ msg: d, index: i, time: data[y_axis_mn][i] });
                  if (alerts[0].msg) {
                    if (alerts[0].msg == data["alert"][i + 1]) {
                    } else {
                      if (alerts.length > 1) {
                        // console.log(
                        //   "more" +
                        //     alerts[0].msg +
                        //     " start index " +
                        //     alerts[0].index +
                        //     " end index : " +
                        //     alerts[alerts.length - 1].index
                        // );
                        drawAlert(this, alerts, true, i);
                        alerts = [];
                      } else {
                        // console.log(
                        //   "else " +
                        //     alerts[0].msg +
                        //     " start index " +
                        //     alerts[0].index
                        // );
                        drawAlert(this, alerts, false, i);
                        alerts.shift();
                      }
                    }
                  } else {
                    alerts = [];
                  }
                }
                this.alertTimes = alertTimes;
                // console.log("this.alertTimes : ", this.alertTimes);
              } else {
                let min = mins[k];
                let max = maxes[k];
                let header_text_line = "";
                if (min != null && max != null) {
                  header_text_line =
                    parseFloat(min).toFixed(1) +
                    " - " +
                    curve_names[k] +
                    "  " +
                    curveUnit +
                    " - " +
                    parseFloat(max).toFixed(1);
                }
                let curveScale = curveScales[k];
                let min_this = "";
                let max_this = "";
                let scaleType = curveScaleType[k];

                if (
                  scaleType == "manual" &&
                  (curveScale != null || curveScale != undefined)
                ) {
                  if (curveScale[0] != null || curveScale[0] != "") {
                    min_this = curveScale[0];
                  } else {
                    // min_this = this.minmax[curve_names[k]].min
                    min_this = d3.min(this.data[curve_names[k]]);
                    curveScale[0] = min_this;
                  }
                  if (curveScale[1] != null || curveScale[1] != "") {
                    max_this = curveScale[1];
                  } else {
                    // max_this = this.minmax[curve_names[k]].max
                    max_this = d3.max(this.data[curve_names[k]]);
                    curveScale[1] = max_this;
                  }
                } else {
                  let currentRange = defaultRanges.ranges.find(
                    (data) => data.name == curve_names[k]
                  );

                  if (
                    (curve_names[k] == "spp" || curve_names[k] == "diff") &&
                    this.unit_conversion
                  ) {
                    currentRange = this.defaultRangesValidation(
                      curve_names[k],
                      template_curves["curve_units"][k],
                      currentRange
                    );
                  }
                  // currentRange = null;
                  if (currentRange) {
                    if (this.unit_conversion) {
                      max_this = Number(currentRange.m_high);
                      min_this = Number(currentRange.m_low);
                    } else {
                      max_this = Number(currentRange.high);
                      min_this = Number(currentRange.low);
                    }
                  } else {
                    min_this = d3.min(this.data[curve_names[k]]);
                    max_this = d3.max(this.data[curve_names[k]]);
                  }
                  if (
                    Object.hasOwn(
                      this.changedConversionFactors,
                      curve_names[k]
                    ) &&
                    this.changedConversionFactors[curve_names[k]] != ""
                  ) {
                  } else {
                    curveScale[0] = min_this;
                    curveScale[1] = max_this;
                  }
                }

                if (min_this == max_this && max_this == 0) {
                  max_this = 1;
                  min_this = 0;
                }

                let x = d3
                  .scaleLinear()
                  .domain([curveScale[0], curveScale[1]])
                  .range([margin.left, width - margin.right]);
                //For unitconversion scale change
                // if (
                //   Object.hasOwn(
                //     this.changedConversionFactors,
                //     curve_names[k]
                //   ) &&
                //   this.changedConversionFactors[curve_names[k]] != ""
                // ) {
                //   if (this.currentChangedMnemonicUnit === curve_names[k]) {
                //     console.log(
                //       "only changed::",
                //       curve_names[k],
                //       this.currentChangedMnemonicUnit === curve_names[k]
                //     );
                //     const factor =
                //       this.changedConversionFactors[curve_names[k]];
                //     // curveScales[k][0] *= factor;
                //     // curveScales[k][1] *= factor;
                //     // console.log('curve scale:',curveScales[k],factor);
                //     x = d3
                //       .scaleLinear()
                //       .domain([min_this * factor, max_this * factor])
                //       .range([margin.left, width - margin.right]);
                //   }
                // }

                //// This creates an object to hold multiple x axis scale functions
                //// that will be used if 'between' style fill is selected.

                //////////////  Header text, two way depending on  =>//////////////

                ctx.lineWidth = template_curves["stroke_width"][k];
                ctx.beginPath();
                ctx.moveTo(x(data[curve_names[k]][0]), 0);
                let stickslip = [];
                let washout = [];
                let stickslip_msg = [];
                let washout_msg = [];
                let lastMsgTime_s = "";
                let lastMsgTime_w = "";
                let skipInterval;
                switch (this.currentTimeRange) {
                  case 1:
                    skipInterval = 3 * 300000;
                    break;
                  case 2:
                    skipInterval = 3 * 300000;
                    break;
                  case 4:
                    skipInterval = 12 * 300000;
                    break;
                  case 8:
                    skipInterval = 6 * 300000;
                    break;
                  case 12:
                    skipInterval = 9 * 300000;
                    break;
                  case 24:
                    skipInterval = 12 * 300000;
                    break;

                  default:
                    skipInterval = 2 * 300000;
                    break;
                }
                let inter = 1;
                if (this.currentTimeRange > 2)
                  inter =
                    Math.floor(parseInt(data[curve_names[k]].length / 2000)) ||
                    1;
                for (let i = 0; i < data[curve_names[k]].length; i += inter) {
                  data[curve_names[k]][i] = Number(data[curve_names[k]][i])
                    ? Number(data[curve_names[k]][i])
                    : 0;
                  ctx.lineTo(
                    x(data[curve_names[k]][i]),
                    y(data[graph_type][i])
                  );
                  if (
                    curve_names[k] == "stickslipindex" &&
                    data["stickslip"][i] &&
                    data["stickslip"][i] != "NONE" &&
                    (lastMsgTime_s == "" ||
                      lastMsgTime_s + skipInterval < data[graph_type][i])
                  ) {
                    lastMsgTime_s = data[graph_type][i];
                    stickslip.push(y(data[graph_type][i]) + 10);
                    stickslip_msg.push(data["stickslip"][i]);
                  }
                  if (
                    curve_names[k] == "washoutindex" &&
                    data["washout"][i] &&
                    data["washout"][i] != "NONE" &&
                    (lastMsgTime_w == "" ||
                      lastMsgTime_w + skipInterval < data[graph_type][i])
                  ) {
                    lastMsgTime_w = data[graph_type][i];
                    washout.push(y(data[graph_type][i]));
                    washout_msg.push(data["washout"][i]);
                  }
                }
                ctx.strokeStyle = curve_colors[k];

                if (curve_names[k] == "depthofcut") {
                  ctx.lineTo(
                    x(0),
                    y(data[graph_type][data[graph_type].length - 1])
                  );
                  ctx.lineTo(x(0), 0);
                  ctx.lineTo(
                    x(data[curve_names[k]][0]),
                    y(data[graph_type][0])
                  );
                  ctx.fillStyle = curve_colors[k];
                  ctx.closePath();
                  // Fill the area under the line graph
                  ctx.fill();
                }

                ctx.stroke();

                let rectX = 10;
                let rectWidth = width - 20;
                let maxTextWidth = rectWidth - 20; // Leave some padding for the text
                let textX = rectX + 0; // Start with some padding
                const lineHeight = 8;
                function wrapText(text, isText, rectY) {
                  let textY = rectY; // Start with some padding
                  let rect_height = 0;
                  const words = text.split(" ");
                  let line = "";
                  for (let n = 0; n < words.length; n++) {
                    const testLine = line + words[n] + " ";
                    const testWidth = ctx.measureText(testLine).width;
                    if (testWidth > maxTextWidth && n > 0) {
                      if (isText) {
                        ctx.fillText(line, textX, textY);
                      } else rect_height++;
                      line = words[n] + " ";
                      textY += lineHeight;
                    } else {
                      line = testLine;
                    }
                  }
                  if (isText) {
                    ctx.fillText(line, textX, textY);
                  } else rect_height++;

                  return rect_height * lineHeight;
                }

                if (curve_names[k] == "stickslipindex")
                  this.alertStickslip = [];
                for (let s = 0; s < stickslip.length; s++) {
                  // ctx.globalAlpha = 0.4;
                  ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
                  ctx.shadowOffsetX = 5;
                  ctx.shadowOffsetY = 5;
                  ctx.shadowBlur = 5;
                  ctx.fillStyle = "yellow";
                  let height = wrapText(stickslip_msg[s]) + 4;
                  ctx.fillRect(0, stickslip[s] - 10, width, height);
                  this.alertStickslip.push({
                    y: stickslip[s] - 30,
                    yEnd: stickslip[s] - 25 + height,
                    alert: stickslip_msg[s],
                  });
                  // ctx.globalAlpha = 1;
                  ctx.fillStyle = "red";
                  // ctx.fillText(stickslip_msg[s], 0, stickslip[s]);
                  wrapText(stickslip_msg[s], true, stickslip[s]);
                }
                if (curve_names[k] == "washoutindex") this.alertWashout = [];
                for (let w = 0; w < washout.length; w++) {
                  // ctx.globalAlpha = 0.4;
                  ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
                  ctx.shadowOffsetX = 5;
                  ctx.shadowOffsetY = 5;
                  ctx.shadowBlur = 5;
                  ctx.fillStyle = "yellow";
                  let height = wrapText(washout_msg[w]) + 4;
                  ctx.fillRect(0, washout[w] - 10, width, height);
                  this.alertWashout.push({
                    y: washout[w] - 30,
                    yEnd: washout[w] - 25 + height,
                    alert: washout_msg[w],
                  });
                  // ctx.globalAlpha = 1;
                  ctx.fillStyle = "red";
                  // ctx.fillText(washout_msg[w], 0, washout[w]);
                  wrapText(washout_msg[w], true, washout[w]);
                }

                stickslip = [];
                washout = [];
                stickslip_msg = [];
                washout_msg = [];
              }
            } catch (error) {
              console.log(
                "DisTrend.vue:4377 curve_names[k] ⏩",
                curve_names[k],
                error
              );
            }
          }
        };

        drawTrend();
      } catch (error) {
        console.log(
          "🚀 ➽ file: DisTrend.vue:3933  ➽ curveBox  ➽ error ⏩",
          template_for_plotting,
          index,
          divId,
          error
        );
      }
      // console.timeEnd("curve box divId " + divId);
      if (this.plots.length - 1 == index) {
        this.isRerendering = false;
      }
    },
    getConfig() {
      let tconfig = {
        height: "500",
        width: "260",
        UWI: "00/01-01-095-19W4/0",
        curveName: "CHALKTALK",
        tracks: this.tracks,
        curveType: "dmea",
        depthName: "dmea",
      };
      this.trackConfig = tconfig;
      return tconfig;
    },
    async configure(iswellChanged = false) {
      let UWI = this.trackConfig.UWI;
      // let curveNames = curveNames;
      // let curveColors=curveColors;
      let curveName = this.trackConfig.curveName;
      let _plots = [];
      let mapping = await helperServices.getIndexDetails(this.wellId);
      if (mapping) mapping = mapping.data.logs[this.logType];

      const tags = mapping.tags;
      const categories = mapping.categories;
      for (let i = 0; i < this.trackConfig.tracks.length; i++) {
        let track = this.trackConfig.tracks[i];
        let curveNames = track.map((curve) => curve.name);
        let curveColors = track.map((curve) => curve.color);

        let fillColor = track.map((curve) => curve.fillC);
        let fillDir = track.map((curve) => curve.fillD);
        let fill = track.map((curve) => curve.fill);
        let strokes = track.map((curve) => curve.stroke || 2);
        let units = track.map((curve, index) => {
          try {
            const mnemonicKey = tags.findIndex((t) => t == curve.name);
            if (iswellChanged) {
              track[index].unit = "";
              track[index].conversion_factor = "";
              track[index].display_unit = "";
            }
            if (Object.hasOwn(track[index], "unit")) {
              if (
                Object.hasOwn(this.changedConversionFactors, curve.name) &&
                this.changedConversionFactors[curve.name] != ""
              ) {
              } else {
                if (
                  Object.hasOwn(track[index], "conversion_factor") &&
                  track[index].conversion_factor != ""
                )
                  this.changedConversionFactors[curve.name] =
                    track[index].conversion_factor;
              }
            } else {
              track[index].unit = "";
              track[index].conversion_factor = "";
              track[index].display_unit = "";
            }

            // if(Object.hasOwn(this.changedConversionFactors, curve.name) && this.changedConversionFactors[curve.name] !=''){

            // }else{
            //   track[index].unit = "" ;
            //   track[index].conversion_factor = "";
            // }

            track[index].full_name = "";
            track[index].unit_category = categories[mnemonicKey];

            if (mapping.tags.indexOf(curve.name) != -1) {
              if (
                Object.hasOwn(track[index], "unit") &&
                track[index].unit === ""
              ) {
                track[index].unit = mapping.units[mnemonicKey];
                track[index].display_unit = mapping.units[mnemonicKey];
              }
              // if(Object.hasOwn(this.changedConversionFactors, curve.name) && this.changedConversionFactors[curve.name] !=''){

              // }else{
              //   track[index].unit = mapping.units[mnemonicKey];
              // }

              // track[index].full_name =
              //   mapping.displayTags[
              //     mapping.tags.indexOf(curve.name)
              //   ].toUpperCase();

              if (curve.name == "diff") track[index].full_name = "DIFF PRESS";
              else if (curve.name == "simplifiedmse")
                track[index].full_name = "MSE";
              else if (curve.name == "bpos")
                track[index].full_name = "BLOCK POS";
              else if (curve.name == "stickslipindex")
                track[index].full_name = "STICK SLIP";
              else if (curve.name == "washoutindex")
                track[index].full_name = "WASH OUT";
              else if (curve.name == "alert") track[index].full_name = "ALERTS";
              else if (curve.name == "depthofcut")
                track[index].full_name = "DEPTH OF CUT";
              else
                track[index].full_name =
                  mapping.displayTags[
                    mapping.tags.indexOf(curve.name)
                  ].toUpperCase();
            }

            return mapping.units[mapping.tags.indexOf(curve.name)];
          } catch (error) {
            return "";
          }
        });
        let scale = track.map((curve) => curve.scale);
        let alert = track.map((curve) => curve.alert);
        let isAlert = track.map((curve) => curve.isAlert);
        let decimals = track.map((curve) => curve.decimals);
        let scaleType = track.map((curve) => curve.scaleType);
        let trackId;
        if (track.length > 0) {
          trackId = track[0].trackId;
        }

        let trackObjs = [];
        for (let j = 0; j < track.length; j++) {
          let configObj = {
            curve_name: "",
            fill: "",
            fill_direction: "",
            cutoffs: [],
            fill_colors: [],
          };
          configObj.curve_name = track[j].name;
          configObj.fill_direction = track[j].fillD;
          configObj.fill = track[j].fill;
          configObj.cutoffs = [0, 4.25];
          configObj.fill_colors = [track[j].fillC];
          trackObjs.push(configObj);
          trackObjs = trackObjs;
        }
        if (this.singleTrackWidth > this.trackWidth) {
          this.singleTrackWidth = this.trackWidth;
        }
        let graphType = "dmea";
        this.logType == "time" ? (graphType = "time") : null;
        let plot = plotConfig(
          this.plotTemplate,
          UWI,
          curveNames,
          curveColors,
          units,
          strokes,
          scale,
          scaleType,
          trackObjs,
          this.div_id,
          this.singleTrackWidth,
          this.singleTrackHeight,
          graphType,
          this.tickColor,
          this.tickThickness,
          "#202124", //leftbarbg = ,
          "white", // tooltipbg = ,
          trackId,
          alert,
          decimals,
          isAlert
        );
        _plots.push(plot);
      }

      this.plots = _plots;
    },
    async getSingleTagData(tagName, isUpdate, from) {
      // let typeOfMnemonic = this.isDrillingDis ? "SOLUTIONS" : await this.getMnemonicType(tagName);
      // console.log('typeOfMnemonic', typeOfMnemonic);
      // this.$store.dispatch("data/startLoading");
      this.isDataLoading = true;
      let result = null;
      let dconfig = {};

      if (isUpdate) dconfig.fields = tagName;
      else dconfig.fields = [tagName];

      dconfig.wellId = this.wellId;
      dconfig.wellboreId = this.wellboreId;
      dconfig.logId = this.logId;
      dconfig.dataPoints = null;
      dconfig.depthRange = null;

      try {
        if (this.logType == "time") {
          let fieldArr =
            tagName == "dmea" ? [tagName, "alert"] : [tagName, "dmea", "alert"];
          if (fieldArr.indexOf("stickslipindex") > -1)
            fieldArr.push("stickslip");
          if (fieldArr.indexOf("washoutindex") > -1) fieldArr.push("washout");
          let to_loop = ["hrs_24_data", "interval_based_hrs_data"];
          for (let text of to_loop) {
            let var_to_use = text;
            if (
              this[var_to_use] != null &&
              Object.keys(this[var_to_use]).length
            ) {
              let payload = {
                topic:
                  this.$store.state.disp?.displays[this.displayId]?.topicName,
                fields: [tagName],
                well_id: this.wellId,
                wellbore_name: this.wellboreId,
                isParse: 1,
                type: this.isDrillingDis ? "SOLUTIONS" : "SOLUTION",
                from: Math.max(...this[var_to_use]["time"]),
                to: Math.min(...this[var_to_use]["time"]),
                well_min: this.scaleMinFixed,
                well_max: this.scaleMaxFixed,
                log_interval: this.logInterval
                  ? this.logInterval.split("_")[0] * 1000
                  : 1000,
              };
              if (var_to_use == "hrs_24_data") {
                payload.log_interval = this.logInterval
                  ? this.logInterval.split("_")[0] * 1000
                  : null;
                payload.twentyfour_hrs = true;
                payload.display_name = this.displayName
                  ? this.displayName.replace(/\s/g, "").toLowerCase()
                  : null;
              }
              console.log("get single tag data called : ", tagName);
              console.log(" nth called 5 st ");
              result = await apiService.WellServices.getTimeLogs(payload);
              if (result && result.data && result.data.data) {
                this[var_to_use] = {
                  ...this[var_to_use],
                  ...result.data.data,
                };
                this.data = { ...this.completeData };
                // display_unit to recently changed conversion factor needed to convert data
                this.convertingDataFromDisptoSelectedUnit();
                this.minmax = { ...this.minmax, ...result.data.minmax };
                // for (const mnemonickey in this.data) {
                //   if (
                //     Object.hasOwn(this.changedConversionFactors, mnemonickey)
                //   ) {
                //     const mappedData = this.data[mnemonickey].map(
                //       (e) => e * this.changedConversionFactors[mnemonickey]
                //     );
                //     this.data[mnemonickey] = mappedData;
                //   }
                // }
              }
            }
          }
          await this.filterData(null, 5093);
        } else {
          // console.log("getSingleTagData called");
          apiService.WellServices.getDepthLogs({
            fields: dconfig.fields,
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            // isPartial: false,
          })
            .then(async (data) => {
              // console.log("---------- whole data came 2 ----------");
              if (data && data.data && data.data.data) {
                // console.log(data.data);
                this.completeData = { ...this.completeData, ...data.data.data };
              }
            })
            .catch((e) => {
              console.error("whole data error : ", e);
            });
          result = await apiService.WellServices.getDepthLogs({
            fields: dconfig.fields,
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            isPartial: true,
            start: this.scaleMin,
            end: this.scaleMax,
          });

          // result = result.data;

          // if (isUpdate) {
          //   for (let i of tagName) {
          //     await this.addData(result, i);
          //   }
          // } else {
          //   result = await this.addData(result, tagName);
          // }
          if (result && result.data && result.data.data) {
            if (
              result.data.data["dmea"] &&
              result.data.data["dmea"].length == 0
            ) {
              this.$toast.error("Data is not available for this well", {
                position: "top",
                duration: 3000,
              });
            }
          }
          this.completeData = { ...this.completeData, ...result.data.data };
          this.data = { ...this.completeData };
          this.minmax = { ...this.minmax, ...result.data.minmax };
        }
      } catch (error) {
        console.error(error);
      }
      setTimeout(() => {
        // this.$store.dispatch("data/stopLoading");
        this.isDataLoading = false;
      }, 700);

      // }
      return result;
    },
    async getMinMaxRange() {
      try {
        let result = null;
        if (this.logType == "time") {
          result = await apiService.WellServices.getMinMaxTime({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
          // console.log(
          //   "time zone difference : ",
          //   result.data.min,
          //   moment(result.data.min).unix() * 1000
          // );
          result.data.min = moment(result.data.min).unix() * 1000;
          result.data.max = moment(result.data.max).unix() * 1000;
        } else {
          result = await apiService.WellServices.getMinMaxDepth({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
          result.data.min = result.data.min;
          result.data.max = result.data.max;
        }

        return result.data;
      } catch (error) {
        console.error(error);
        return { min: 0, max: 1000 };
      }
    },
    async getItFromLocalData(range, isLogChange) {
      if (this.logType == "depth") {
        // console.log("complete data befor ", this.completeData, isLogChange);
        if (isLogChange) {
          let fields = this.getOnScreenMnemonics();
          // await this.getMnemonicType(fields);
          await this.getMultiTagsData(fields, "getItFromLocalData");

          this.rerender("getItFromLocalData depth");
        } else {
          let graphType = "dmea";
          this.logType == "time" ? (graphType = "time") : null;

          if (!this.completeData[graphType]) {
            let fields = this.getOnScreenMnemonics();
            await this.getMnemonicType(fields);
            await this.getMultiTagsData(fields, "getItFromLocalData");
          }
          // console.log("get it from local 1", range)
          this.completeData[graphType].map((row, i) => {
            if (
              row == range[0] ||
              (row < range[0] && !(row > range[0])) ||
              (range[0] == 0 && i == 0)
            ) {
              this.lowerIndex = i;
            }

            if (row == range[1] || row < range[1]) {
              this.upperIndex = i;
            }
          });
          let tempData = { ...this.completeData };
          // console.log("get it from local 2")
          for (let i in tempData) {
            this.data[i] = tempData[i].slice(
              this.lowerIndex,
              this.upperIndex + 1
            );
          }
          // console.log("get it from local 3")
        }
      } else {
        let fields = this.getOnScreenMnemonics();
        // await this.getMnemonicType(fields);
        if (!this.liveDisabled || this.data.length == 0) {
          // this.$store.dispatch("data/startLoading");
          this.isDataLoading = true;
          // for (let i in this.mnemonic_groups) {
          // if(thisgraph is replay)
          console.log(" nth called 6 st ");
          let result = await apiService.WellServices.getTimeLogs({
            fields: fields,
            type: this.isDrillingDis ? "SOLUTIONS" : "SOLUTION",
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            secondsdataavailability: this.secondsdataavailability,
            topic: this.$store.state.disp?.displays[this.displayId]?.topicName,
            isParse: 1,
            // days: 0.2,
            // from: parseInt(range[1]),
            // to: parseInt(range[0]),
            from:
              parseInt(this.scaleMaxFixed) < parseInt(range[1])
                ? parseInt(this.scaleMaxFixed)
                : parseInt(range[1]),
            to:
              parseInt(this.scaleMinFixed) > parseInt(range[0])
                ? parseInt(this.scaleMinFixed)
                : parseInt(range[0]),
            well_min: this.scaleMinFixed,
            well_max: this.scaleMaxFixed,
            log_interval: this.logInterval
              ? this.logInterval.split("_")[0] * 1000
              : 1000,
          });
          if (result && result.data && result.data.data) {
            if (
              result.data.data["time"] &&
              result.data.data["time"].length == 0
            ) {
              // this.$toast.error("Data is not available for this interval", {
              //   position: "top",
              //   duration: 3000,
              // });
            } else {
              this.completeData = {
                ...this.completeData,
                ...result.data.data,
              };
              this.data = { ...this.completeData };
              this.minmax = { ...this.minmax, ...result.data.minmax };
              // display_unit to recently changed conversion factor needed to convert data
              this.convertingDataFromDisptoSelectedUnit();
              // for (const mnemonickey in this.data) {
              //   if (Object.hasOwn(this.changedConversionFactors, mnemonickey)) {
              //     const mappedData = this.data[mnemonickey].map(
              //       (e) => e * this.changedConversionFactors[mnemonickey]
              //     );
              //     this.data[mnemonickey] = mappedData;
              //   }
              // }
            }
          } else {
            // this.$toast.error("Server is taking longer than usual", {
            //   position: "top",
            //   duration: 3000,
            // });
          }
          // }
          setTimeout(() => {
            // this.$store.dispatch("data/stopLoading");
            this.isDataLoading = false;
          }, 700);
        } else {
        }
      }
    },
    async zoomIt(depthMax, depthMin, feet, isLogChange, from) {
      // console.log("graph is rendreing", from);
      if (depthMax == depthMin) {
        return false;
      }
      let totalRows;
      let depthRange;

      if (depthMax > depthMin) {
        totalRows = Math.round(depthMax - depthMin);
        depthRange = [depthMin, depthMax];
      } else {
        totalRows = Math.round(depthMin - depthMax);
        depthRange = [depthMax, depthMin];
      }
      // console.log("depthRange : ", depthRange);
      if (!this.isReplay)
        // console.time('service calling and render time')
        await this.getItFromLocalData(depthRange, isLogChange, from);

      this.rerender();
      // console.timeEnd('service calling and render time')
    },
    async rerender(from) {
      // console.log("delete rerender checkl : ", this.isRerendering);
      if (this.trackConfig.tracks)
        this.setTrackWidth(this.trackConfig.tracks.length);
      else this.setTrackWidth(0);
      this.setTrackHeight();
      if (this.isRerendering) {
        return 0;
      }
      this.isRerendering = true;
      if (this.trackConfig.tracks)
        this.setTrackWidth(this.trackConfig.tracks.length);
      else this.setTrackWidth(0);
      this.setTrackHeight();
      this.trackConfig = await this.getConfig();

      if (this.trackConfig.tracks && this.trackConfig.tracks.length) {
        this.tracksNbr = this.trackConfig.tracks.length;
        if (this.tracksNbr > 0) {
          if (this.trackConfig.tracks[0][0].name != null) {
            this.readyToPlot = true;
            this.emptyPlot = false;
            if (this.logType == "time") {
              this.depthMaxValue = 0;
              this.depthMinValue = 0;
            } else {
              let depth_min_sec = d3.min(this.data["time"]) || null;
              let depth_max_sec = d3.max(this.data["time"]) || null;
              this.depthMaxValue = parseInt(depth_max_sec);
              this.depthMinValue = parseInt(depth_min_sec);
            }

            this.setTrackWidth(this.trackConfig.tracks.length);
            this.configurePlots = await this.configure(from);
            if (this.plots)
              // setTimeout(() => {
              this.verticalplots(this.div_id, this.plots, true);
            // }, 0);

            let sliderId = "slider" + this.compId;
            let sliderEle = document.querySelector("#" + sliderId);
            if (sliderEle != null) {
              sliderEle.style.height = this.singleTrackHeight - 60 + "px";
            }
            // this.setTrackInfo();
          }
        }
      } else {
        this.isRerendering = false;
      }
    },
    async deleteTrack() {
      this.showCurveInfo = false;
      trackDeleteDisabled = true;

      this.trackConfig.tracks.splice(trackNbr, 1);
      this.trackConfig.tracks = this.trackConfig.tracks;
      this.trackConfig = this.trackConfig;
      // this.getTracksRight();
      // this.adjustTracks();
      await this.configure();

      this.verticalplots(this.div_id, this.plots, true);

      // this.setTrackInfo();
    },
    addTrack() {
      let trackId = "t" + Math.random().toString(36).slice(8);

      this.readyToPlot = true;
      this.emptyPlot = false;
      this.isNewTrack = true;
      this.showCurveInfo = false;
      this.curveDeleteDisabled = true;
      this.newCurveDisabled = true;
      if (this.trackConfig == null) {
        // getConfig();
      }
      // getConfig();
      // if (this.trackConfig.tracks.length == 0 ) {
      //
      //     tracks = [];
      //     this.trackConfig.tracks = tracks;
      // }
      // let trackObj = {};
      // trackObj.trackId = trackId;

      this.tracks.push([]);
      this.tracks = this.tracks;
      // this.trackConfig.tracks = tracks;
      if (this.tracks[0].length == 0) {
        this.readyToPlot = false;
      }
      // if (tracks[0].length == 0) {
      //     readyToPlot = false;
      // }

      this.getConfig();
      this.trackConfig.tracks = this.tracks;
      // this.trackConfig.tracks.push([]);
      // this.trackConfig.tracks = this.trackConfig.tracks;
      this.trackConfig = this.trackConfig;
      let currentCurve = {};
      let trackLength = this.trackConfig.tracks.length;
      let trackNbr = trackLength - 1;
      // this.getTracksRight(trackNbr, trackId);
      this.selectedTrackValue = this.totTracks[trackNbr];

      this.newCurveDisabled = false;
      // this.setTrackInfo();
      return trackId;
    },
    changeTooltip() {
      try {
        let tracks = document.querySelectorAll(".for_tooltip_left_position");
        this.tracks_left_position = Object.values(tracks).map(
          (each) => each.getBoundingClientRect().left
        );
        this.isShowTooltip = !this.isShowTooltip;
        if (!this.isShowTooltip) {
          for (let focusAxis of this.focusAxisList) {
            let focus = focusAxis["focus"];
            focus
              .style("display", "none")
              .transition()
              .duration(250)
              .style("opacity", 0);
          }
          this.mouse_y_tooltip = -1;
          this.tooltipUpdateIndex = null;
          this.tooltip_horizontalline_ypos = null;
        }
        // if(this.isZoom = true){
        //   this.isShowTooltip = false
        // }
      } catch (error) {}
    },
    async changeZoom() {
      // if (!this.isZoom) {
      try {
        let new_scale = this.zoom1;
        // console.log("d3.selectAll( canvas ' ", this.zoom1);
        d3.selectAll("#" + this.div_id).each(function (d, i) {
          // console.log(this.id)
          d3.select(this)
            // .transition()
            // .duration(500)
            .call(new_scale.transform, d3.zoomIdentity.scale(1));
        });
      } catch (error) {
        console.error("error in reset : ", error);
      }
      // }
      if (
        this.logType == "time" &&
        !this.$refs["zoomswitch" + this.id].checked &&
        this.zoomed_Y_Axis
      ) {
        this.currentTimeRange = Number(
          (this.scaleMax - this.scaleMin) / (60 * 60 * 1000)
        ).toFixed(2);
        this.scaleMinDate = new Date(this.scaleMin).toLocaleString("en-US", {
          timeZone: this.time_zone,
        });
        await this.filterData(null, 4100);
      } else if (
        this.logType == "depth" &&
        !this.$refs["zoomswitch" + this.id].checked &&
        this.zoomed_Y_Axis
      ) {
        this.currentFeetRange = Number(this.scaleMax - this.scaleMin).toFixed(
          2
        );
      }
      this.isZoom = !this.isZoom;
      this.zoomed_Y_Axis = null;

      console.log(" zoom : ", this.scaleMax, this.scaleMin);
      if (this.isZoom && this.zoomed_Y_Axis) {
        if (this.logType == "depth") {
          // this.scaleMax = this.zoomed_Y_Axis.domain()[0];
          // this.scaleMin = this.zoomed_Y_Axis.domain()[1];
          this.currentFeetRange = Number(this.scaleMax - this.scaleMin).toFixed(
            2
          );
        } else {
          // this.scaleMax = moment(this.zoomed_Y_Axis.domain()[0]).unix() * 1000;
          // this.scaleMin = moment(this.zoomed_Y_Axis.domain()[1]).unix() * 1000;
          this.currentTimeRange = Number(
            (this.scaleMax - this.scaleMin) / (60 * 60 * 1000)
          ).toFixed(2);
        }
      } else {
      }

      this.rerender();
    },
    changeAlert() {
      this.isShowAlerts = !this.isShowAlerts;
      if (!this.isShowAlerts) {
        this.clickedAlert = "";
      }
      this.rerender();
    },
    calculateSectionDimensions() {
      const section = this.$refs.distrenddiv_section;
      var distrenddiv_sectionWidth = section.clientWidth - 100;
      this.distrenddiv_sectionWidth = distrenddiv_sectionWidth;
    },
    async getTags() {
      let display = this.$store.state.disp.selectedDisplay;
      let displayobj = this.$store.state.disp.displays;
      if (displayobj[display]) {
        let displayobj1 = await helperServices.getIndexDetails(
          displayobj[display].wellId
        );
        displayobj1 = displayobj1.data["logs"][displayobj[display].logType];
        this.mneTags = displayobj1.tags;
        this.units = displayobj1.units;
        this.logId = displayobj1.logId;
        this.state = displayobj1.wellboreState;
        this.description = displayobj1.description;
        this.displayTags = displayobj1.displayTags;
        const mnemonics = displayobj1.mnemonics;
        const mnk = Object.keys(displayobj1.mnemonics);
        this.tags = [];
        mnk.forEach((m) => {
          if (m != "MISCELLANEOUS") {
            const currentMn = Object.keys(mnemonics[m]);
            currentMn.forEach((n) => {
              this.tags.push(mnemonics[m][n].display_name);
            });
          }
        });
        console.log("__________gettttTAGSSSSS", this.tags);
        // this.tags = this.displayTags;
        return [];
      } else {
        this.tags = [];
        return [];
      }
    },
    async getMnamonicTags() {
      try {
        // console.log('__________gettttTAGSSSSS_dashboard', this.tags)
        let display = "dashboard_display_id"; //this.$store.state.disp.selectedDisplay;
        let displayobj = this.$store.state.disp.displays;
        if (displayobj[display]) {
          let displayobj1 = await helperServices.getIndexDetails(
            displayobj[display].wellId
          );
          // console.log('__________gettttTAGSSSSS_dashboard', displayobj1)
          displayobj1 = displayobj1.data["logs"][displayobj[display].logType];

          this.mneTags = displayobj1.tags;
          this.units = displayobj1.units;

          this.logId = displayobj1.logId;
          this.state = displayobj1.wellboreState;
          this.description = displayobj1.description;
          this.displayTags = displayobj1.displayTags;
          const mnemonics = displayobj1.mnemonics;
          const mnk = Object.keys(displayobj1.mnemonics);
          this.tags = [];
          mnk.forEach((m) => {
            if (m != "MISCELLANEOUS") {
              const currentMn = Object.keys(mnemonics[m]);
              currentMn.forEach((n) => {
                this.tags.push(mnemonics[m][n]);
                // this.tags.push(mnemonics[m][n].display_name);
                console.log(
                  "__________gettttTAGSSSSS_dashboard",
                  mnemonics[m][n]
                );
              });
            }
          });
          // this.tags = this.displayTags;
          return [];
        } else {
          this.tags = [];
          return [];
        }
        console.log("___this_is_tags__data", this.tags);
      } catch (err) {
        console.error("from catch", err);
      }
    },
    toggleLeftSideDbBar: async function (action) {
      // this.getTags()
      // this.openMnemoncsPOP = true
      this.$store.state.uis.leftSideBarOpen = true;
      console.log(
        "___action____",
        action,
        await this.$store.state.uis.leftSideBarOpen
      );
      await this.onToggleSideBar(action);
      (await this.$store.state.uis.leftSideBarOpen)
        ? (this.clickedLink = action)
        : (this.clickedLink = "");
    },
    opneMnemonics() {
      this.opneMnemonicsModel("Mnemonics");
    },
  },
  watch: {
    width(newValue) {
      this.rerender("width");
    },
    height(newValue) {
      this.rerender("height");
    },
    replayDetails(result) {
      // console.log("Tesnd ", result);

      // this.rerender("zoomIt");
      if (result) {
        let isLiveDepth = false;
        if (
          this.logType == "depth" &&
          this.completeData["dmea"] &&
          this.completeData["dmea"].length &&
          result["dmea"] &&
          result["dmea"].length
        ) {
          if (
            this.completeData["dmea"][this.completeData["dmea"].length - 1] <
            result["dmea"][result["dmea"].length - 1]
          )
            isLiveDepth = true;
        }

        if (this.logType == "time") {
          if (this.data["time"]) {
            if (
              this.data["time"][this.data["time"].length - 1] <
                result["time"][result["time"].length - 1] &&
              this.data["time"][this.data["time"].length - 1] <
                result["time"][0]
            )
              for (let i in this.completeData) {
                if (result[i]) {
                  this.completeData[i] = [
                    ...this.completeData[i],
                    ...result[i],
                  ];
                }
              }
          } else {
            // for (let i in result) {
            //   this.minmax[i] = { min: 0, max: 100 };
            // }
            // console.log("result::else ");
            this.data = result;
            this.completeData = { ...result };
          }
        } else {
          if (isLiveDepth)
            for (let i in this.completeData) {
              if (result[i]) {
                this.completeData[i] = [...this.completeData[i], ...result[i]];
              }
            }
        }
        if (this.logType == "time") {
          this.data = { ...this.completeData };
          if (
            this.data["time"][this.data["time"].length - 1] -
              this.data["time"][0] >
            3600000
          ) {
            this.scaleMin =
              this.data["time"][this.data["time"].length - 1] - 3600000;
            this.scaleMax = this.data["time"][this.data["time"].length - 1];
          } else {
            this.scaleMin = this.data["time"][0];
            this.scaleMax = this.data["time"][0] + 3600000;
          }
        } else {
          this.scaleMin +=
            result["dmea"][result["dmea"].length - 1] - this.scaleMax;
          if (this.scaleMin < 0) {
            this.scaleMin = this.completeData["dmea"][0];
          }
          this.scaleMax = result["dmea"][result["dmea"].length - 1];
          // this.scaleMin = parseInt(this.scaleMin);
          // this.scaleMax = parseInt(this.scaleMax);
        }
        this.rangeInput = this.scaleMax;
        this.scaleMaxFixed = this.scaleMax;
        // console.log(this.data );

        // console.log(
        //   "value of scalemax is smaller than scale Min : ",
        //   new Date(this.scaleMax).toISOString(),
        //   new Date(this.scaleMin).toISOString()
        // );

        if (
          this.scaleMax > this.scaleMin &&
          this.slider &&
          this.slider.noUiSlider
        ) {
          this.slider.noUiSlider.updateOptions({
            behaviour: "drag",
            start: [this.scaleMin, this.scaleMax],
            range: {
              min: this.scaleMinFixed,
              max: this.scaleMaxFixed,
            },
          });
        }
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "update live"
        );
      }
    },
  },
  setup() {
    const { isAuto, replayDetails, setAutoPlay, removeruningIntervals } =
      useReplay();
    return {
      isAuto,
      replayDetails,
      setAutoPlay,
      removeruningIntervals,
    };
  },
  unmounted() {
    this.removeruningIntervals();
  },
  async mounted() {
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    if (this.isDrillingDis)
      window.addEventListener("resize", this.updateScreenSize);
    await this.get_actc_list();
    this.isDis = this.isDisProps;
    this.$store.dispatch("data/getAlertAnnotations");
    // this.$store.dispatch("data/getAlertsList");
    if (this.isDisProps) {
      this.isDis = true;
      this.leftbarWidth = 120;
    }
    this.customer = this.$store.state.data.customer;
    this.compId = this.compId = Math.random().toString(36).slice(8);
    this.compId = this.compId;
    this.div_id = "id" + this.compId;
    this.div_id = this.div_id;
    let dtls = this.$store.state.disp.displays[this.displayId];
    let format = {};
    if (
      dtls != null &&
      dtls.wellId != null &&
      dtls.wellboreId != null &&
      dtls.wellboreState != null
    ) {
      console.log("dtls ********************** ", dtls);
      let rectForSavedName = this.$store.state.disp.rects.find(
        (rect) => rect.displayId == this.displayId
      );
      if (rectForSavedName) {
        this.displayName = rectForSavedName.savedName;
        this.savedId = rectForSavedName.savedId
          ? rectForSavedName.savedId
          : null;
      } else {
        this.displayName = null;
        this.savedId = null;
      }
      this.zoomed_Y_Axis = null;
      this.leftbarWidth = 120;
      this.timer = -1;
      this.takeOffSocket();
      this.data = [];
      this.completeData = [];
      this.mnemonic_groups = {};
      this.unit_conversion = dtls.unit_conversion;
      this.wellId = this.wellId = dtls.wellId;
      this.wellboreId = this.wellboreId = dtls.wellboreId;
      this.logId = this.logId = dtls.logId;
      this.secondsdataavailability = dtls.secondsdataavailability;
      this.wellboreState = this.wellboreState = dtls.wellboreState;
      this.status = dtls.status;
      this.logType = dtls.logType == "trajectory" ? "time" : dtls.logType;
      this.logTypeId = dtls.logTypeId;
      this.topic = dtls.topicName;
      this.time_zone = dtls.time_zone || "CST6CDT";
      this.logInterval = dtls?.well_interval || null;

      // console.log("~~~ time zone alert : mounted ", this.time_zone);
      moment.tz.setDefault(this.time_zone);
      let minMax = await this.getMinMaxRange();

      if (this.logType == "time") {
        if (this.isReplay) {
          this.scaleMin = minMax.min;
          this.timeRanges = [4];
          this.currentTimeRange = 4;

          this.scaleMinFixed = minMax.min;
          this.scaleMax = minMax.min + 3600000 * 4;
        } else {
          this.scaleMin =
            minMax.max - 3600000 > minMax.min
              ? minMax.max - 3600000
              : minMax.min;
          this.scaleMax =
            minMax.max - 3600000 > minMax.min
              ? minMax.max
              : minMax.min + 3600000;
          if (minMax.min > this.scaleMin) this.scaleMin = minMax.min;
          // if(minMax.max < this.scaleMax) this.scaleMax = minMax.max;
          this.scaleMinDate = new Date(this.scaleMin).toLocaleString("en-US", {
            timeZone: this.time_zone,
          });
          this.scaleMaxDate = new Date(this.scaleMax);
          this.scaleMinFixed = minMax.min;
          this.scaleMaxFixed = minMax.max;
        }
      } else {
        let minMax = await this.getMinMaxRange();
        if (minMax.max == minMax.min || minMax.max - 500 < minMax.min)
          minMax.max = minMax.min + 1000;
        this.scaleMin = minMax.max - 500;

        this.scaleMax = minMax.max;

        this.scaleMinFixed = minMax.min;
        this.scaleMaxFixed = minMax.max;
      }
      this.rangeInput = this.scaleMax;
      if (minMax.min > minMax.max) {
        this.data = {};
        this.completeData = {};
        this.rerender();
        this.$toast.error("Start time can not be greater than End time.", {
          position: "top",
          duration: 3000,
        });
        // return false;
      }
      try {
      } catch (error) {}
      this.trackPropsMapping();
      if (!this.isReplay) {
        if (this.status == "UPDATING") {
          let timeout_var_livedata = setTimeout(() => {
            clearTimeout(timeout_var_livedata);
            this.enableStoreStreamingData = true;
            console.log("Get real time data before data ");
            this.getRealtimeData();
          }, 600);
        } else {
        }
      }
      if (this.logType == "time") {
        // console.time('getting 24 hrs data with rendering')
        this.enableStoreStreamingData = true;
        await this.fetch_data_and_render(
          "fromMounted",
          this.savedId ? false : true
        );
        // this.enableStoreStreamingData = false;
        // console.timeEnd('getting 24 hrs data with rendering')
        await this.get_actc_codes();
      }
    } else {
      console.log(" No well details came");
    }

    this.myDisWatcher = this.$store.subscribe(async (mutation, state) => {
      if (
        mutation.type == "disp/setDisplay" &&
        this.displayId == mutation.payload.display &&
        this.id
      ) {
        this.changedConversionFactors = {};
        this.dispToSelectedUnitConversionFactors = {};
        let rectForSavedName = this.$store.state.disp.rects.find(
          (rect) => rect.displayId == this.displayId
        );
        if (rectForSavedName) {
          this.displayName = rectForSavedName.savedName;
          this.savedId = rectForSavedName.savedId
            ? rectForSavedName.savedId
            : null;
        } else {
          this.displayName = null;
          this.savedId = null;
        }
        this.logInterval = mutation?.payload?.well_interval || null;
        this.zoomed_Y_Axis = null;

        if (this.isDisProps) {
          this.isDis = true;
          this.leftbarWidth = 120;
        }
        this.timer = -1;
        this.takeOffSocket();
        this.data = [];
        this.completeData = [];
        this.mnemonic_groups = {};
        this.wellId = mutation.payload.wellId;
        this.wellboreId = mutation.payload.wellboreId;
        this.logId = mutation.payload.logId;
        this.secondsdataavailability = mutation.payload.secondsdataavailability;
        this.logType =
          mutation.payload.logType == "trajectory"
            ? "time"
            : mutation.payload.logType;
        this.logTypeId = mutation.payload.logTypeId;
        this.status = mutation.payload.status;
        this.topic = mutation.payload.topicName;
        this.time_zone = mutation.payload.time_zone || "CST6CDT";
        this.unit_conversion = mutation.payload.unit_conversion;
        moment.tz.setDefault(this.time_zone);

        let behaviour = "drag";
        let format = {};
        this.isRerendering = false;
        if (this.logType == "time") {
          console.log(" Set display here reached 4 ");
          let minMax = await this.getMinMaxRange();
          if (this.isReplay) {
            this.scaleMin = minMax.min;
            this.timeRanges = [4];
            this.currentTimeRange = 4;

            this.scaleMinFixed = minMax.min;
            this.scaleMax = minMax.min + 3600000 * 4;
          } else {
            this.currentTimeRange = 1;
            this.scaleMin =
              minMax.max - 3600000 > minMax.min
                ? minMax.max - 3600000
                : minMax.min;
            this.scaleMax =
              minMax.max - 3600000 > minMax.min
                ? minMax.max
                : minMax.min + 3600000;

            this.scaleMinFixed = minMax.min;
            this.scaleMaxFixed = minMax.max;
            if (this.scaleMinFixed > this.scaleMin)
              this.scaleMin = this.scaleMinFixed;
            // if(this.scaleMaxFixed < this.scaleMax) this.scaleMax = this.scaleMaxFixed;
          }
          this.rangeInput = this.scaleMax;
          this.scaleMinDate = new Date(this.scaleMin).toLocaleString("en-US", {
            timeZone: this.time_zone,
          });
          this.scaleMaxDate = new Date(this.scaleMax);
          if (minMax.min > minMax.max) {
            this.data = {};
            this.completeData = {};
            this.rerender(this.savedId ? false : true);
            this.$toast.error("Start time can not be greater than End time.", {
              position: "top",
              duration: 3000,
            });
            // return false;
          }
        } else {
          this.currentFeetRange = 500;
          let minMax = await this.getMinMaxRange();
          if (minMax.max == minMax.min || minMax.max - 500 < minMax.min)
            minMax.max = minMax.min + 1000;
          this.scaleMin = minMax.max - 500;

          this.scaleMax = minMax.max;
          this.rangeInput = this.scaleMax;
          this.scaleMinFixed = minMax.min;
          this.scaleMaxFixed = minMax.max;
          this.currentFeetRange = this.scaleMax - this.scaleMin;
        }
        console.log(" Set display here reached 1 ");
        this.trackPropsMapping();
        if (!this.isReplay) {
          if (this.status == "UPDATING") {
            let timeout_var_livedata = setTimeout(() => {
              clearTimeout(timeout_var_livedata);
              this.enableStoreStreamingData = true;
              this.getRealtimeData();
            }, 600);
          } else {
          }
        }
        console.log(" Set display here reached 2 ");
        let noDIV = d3
          .select("#" + this.div_id)
          .selectAll("div")
          .remove();
        let noSVG = d3
          .select("#" + this.div_id)
          .selectAll("svg")
          .remove();
        if (this.logType == "time") {
          this.enableStoreStreamingData = true;
          await this.fetch_data_and_render(
            "fromMounted",
            this.savedId ? false : true
          );
          // this.enableStoreStreamingData = false;
          await this.get_actc_codes();
        } else {
          this.zoomIt(
            this.scaleMax,
            this.scaleMin,
            this.feet,
            true,
            "update 140989"
          );
        }
      } else if (
        mutation.type == "disp/MINIMIZE_DISP" &&
        this.displayId == mutation.payload.displayId
      ) {
        console.log("Dis minimize");
        if (this.liveDisabled) {
          this.takeOffSocket();
          this.liveDisabled = false;
        }
        // this.$socket.emit("unsubscribe", {
        //   logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
        //   topic: this.topic,
        //   id: this.id,
        //   clientId: this.$clientId,
        // });
      } else if (
        mutation.type == "disp/MAXIMIZE_DISP" &&
        this.displayId == mutation.payload.displayId
      ) {
        this.makeItLive();
        console.log("Dis maximize");
      }
    });

    if (this.logType == "time") {
    } else {
      console.log(
        "before zoom it 14091 : ",
        this.scaleMax,
        this.scaleMin,
        this.feet,
        dtls
      );
      if (this.feet && dtls)
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "update 14091"
        );
    }

    helperServices.getUnitCategories("Volume");
    // await this.get_actc_list();
  },
};
</script>

<style scoped>
.colorbox {
  width: 5px;
  height: 100%;
}

.zoomin {
  width: 50px;
  background: lightgoldenrodyellow;
  position: absolute;
  bottom: 0;
}

.textBgColor {
  background: yellow;
  background-color: yellow;
}

.area {
  fill: lightsteelblue;
}

/* .scrolldiv {
  } */
.actc_pop {
  position: absolute;
  left: -101px;
}

/* hover tooltip */

.tool_group {
  position: fixed;
  display: inline-grid;
  right: 162px;
  z-index: 99999;
}

.tooltip_actc {
  position: absolute;
  display: block;
  height: 100%;
}

.tooltip_actc .tooltiptext {
  visibility: hidden;
  color: #fff;
  text-align: center;
  border-radius: 12px;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
}

.tooltip_actc .tooltiptext::after {
  content: "";
  position: absolute;
  right: -6px;
  top: 5px;
  content: "\1F782";
  font-size: 14px;
  color: #005e62;
}

.tooltip_actc .tooltiptext {
  visibility: visible;
  background: #0e1016;
  background: #00071a;
  z-index: 999999;
}

.tooltiptext {
  background: #000000 !important;
  padding: 2vmin 2vmin;
  padding-bottom: 1vmin;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  /* grid-gap: 2px; */
  padding: 0;
  justify-content: center;
  float: right;
}

.grid-container > div {
  text-align: center;
  padding: 5px 10px;
  font-size: 30px;
  margin: 0 auto;
  width: 330px;
}

.grid-container > .drill_eff_item_3 {
  width: 850px;
}

.grid-container > .drill_eff_item_1 {
  width: 850px;
}

/* .item1 {
  grid-column: 1 / 2;
} */
.item1 {
  grid-column: 2 / 2;
}

._item_ {
  height: auto;
  max-height: 22em;
  border-radius: 12px;
}

._item_:last-child {
  grid-column: 2;
}

._item_ > img {
  height: 100%;
}

.t_d_col_1 {
  /* width: 20%;
  display: flex;
  justify-content: end; */
}

.t_d_col_2 {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.td_trnk {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc((0.3em + 0.3vmin) + (0.2em + 0.2vmax));
}

.text_gray {
  color: #dee2e6;
}

.text_dark {
  /* color: #000000; */
  font-weight: 600;
}

.t_d_col_3 {
  width: 25%;
}

.t_d_row_1 {
  /* font-size: 8px; */
  /* font-weight: bold; */
  font-size: 9px;
  letter-spacing: 0.5px;
  font-weight: 600 !important;
  padding-top: 4px;
}

.t_d_row_2 {
  /* position: relative;
  bottom: -2px;
  font-size: 7.9px;
  font-weight: bold; */
  position: relative;
  bottom: 2px;
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.tooltip__tip {
  text-align: center;
}

.tooltip__tip::after {
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
  color: white;
}

.tooltip__tip::before {
  content: " ";
  display: none;
  position: absolute;
  width: 7px;
  height: 7px;
  z-index: 999;
}

.tooltip__tip_dark::after {
  background: #1b1a1f !important;
  color: #ffffff;
}

.tooltip__tip_dark::before {
  background: #1b1a1f !important;
}

.tooltip__tip_light::after {
  background: #99fad7 !important;
  color: #000000 !important;
}

.tooltip__tip_light::before {
  background: #99fad7 !important;
  color: #1b1a1f !important;
}

.tooltip__tip:hover::after {
  display: block;
}

.tooltip__tip:hover::before {
  display: block;
}

.tooltip__tip.top::after {
  content: attr(data-tip);
  top: 7px;
  left: 50%;
  width: auto;
  font-size: min(1vw, 9px);
  /* calc(0.2vw + 0.2vh + 0.65vmin); */
  padding: 3px 14px;
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip__tip.top::before {
  top: 5px;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tool_tip_units_r.top::after {
  content: attr(data-tip);
  top: 2px;
  left: 81%;
  padding: 3px 14px;
  font-size: 7px;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  transform: translate(-50%, calc(-100% - 10px));
}

.tool_tip_units_r.top::before {
  top: 0;
  left: 90%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tool_tip_units_l.top::after {
  content: attr(data-tip);
  top: 2px;
  left: 16%;
  padding: 3px 14px;
  font-size: 7px;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  transform: translate(-50%, calc(-100% - 10px));
}

.tool_tip_units_l.top::before {
  top: 0;
  left: 16%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.selectedBtn_tooltip {
  width: 100%;
  text-align: left;
}

.tooltip__tip_live {
  /* border-radius: 10px; */
  /* position: relative; */
  text-align: center;
}

.tooltip__tip_live::after {
  background-color: #000000;
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
  color: white;
}

.tooltip__tip_live::before {
  background-color: #000000;
  content: " ";
  display: none;
  position: absolute;
  height: 7px;
  z-index: 999;
}

.tooltip__tip_live:hover::after {
  display: block;
}

.tooltip__tip_live:hover::before {
  display: block;
}

.tooltip__tip_live.drillingDisTrend:hover::after {
  display: none;
}

.tooltip__tip_live.drillingDisTrend:hover::before {
  display: none;
}

.tooltip__tip_live.top::after {
  content: attr(data-tip);
  bottom: 96px;
  left: 164px;
  width: 100%;
  font-size: 7px;
  padding: 3px 5px;
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip__tip_live_dis.top::after {
  content: attr(data-tip);
  bottom: -2px;
  left: 8px;
  font-size: 7px;
  padding: 1px 5px;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip__tip_live.top::before {
  bottom: 107px;
  left: 164px;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tooltip__tip_live_dis.top::before {
  bottom: 16px;
  left: 12px;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.selectedBtn_tooltip {
  width: 100%;
}

.noUi-target_dup {
  /* background: #202124; */
  background: var(--widgetBg);
  transition: var(--transition);
}

.bg-black_mattie {
  /* background: #1e1e1e; */
  background: var(--widgetBg);
  transition: var(--transition);
}

.light_trend_scale {
  background: var(--widgetBg);
  transition: var(--transition);
  color: var(--textColor);
  fill: var(--textColor);
  stroke: var(--textColor);
}

.light_trend-mode {
  background: var(--widgetBg);
  transition: var(--transition);
  color: var(--textColor);
}

._ann_tooltip_logo {
  width: 100%;
  opacity: 0.3;
}

.ann_head {
  font-size: medium;
  font-family: "Ubuntu", sans-serif;
}

.left_width {
  width: 100%;
  height: 100%;
  max-height: 50em;
  overflow: scroll;
}

.right_width {
  /* width: 10%; */
}

.sub_head_para {
  color: yellow;
}

.grid {
  stroke: 10;
}

.tool_toggle_input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.tool_toggle_label {
  cursor: pointer;
  text-indent: -9999px;
  width: 23px;
  height: 12px;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tool_toggle_label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 10px;
  height: 10px;
  background: var(--sidebarbg);
  border-radius: 90px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.tool_toggle_label {
  /* background: #5aba81; */
  /* background: #4b4c4c; */
  background: var(--toggleSliderBg);
}

.tool_toggle_input:checked + .tool_toggle_label:after {
  -webkit-transform: translateX(9px);
  transform: translateX(9px);
}

.tool_toggle_input:checked + ._tooltip {
  background: #5aba81;
}

.tool_toggle_input:checked + ._alert {
  background: #ff2200;
}

.tool_toggle_input:checked + ._zoom {
  background: #00bcd4;
}

/* ._tooltip{
  background: #5aba81;

} */
/* ._alert {
  background: #ff2200;
} */
/* ._zoom{
  background: #00bcd4;
} */
.muted_bg {
  background: #3c3a3a;
}

.muted_bg:after {
  background: #616161;
}

.tool_section {
  display: flex;
  justify-content: center;
  /* align-self: center; */
}

.live_section_btn {
  display: flex;
  height: 100%;
  align-items: center;
}

.live_section_btn_ {
  display: block;
  height: 100%;
  align-items: center;
}
.drill_eff_left {
  width: 100% !important;
}

.drill_eff_item_1 {
  grid-column: 2;
  grid-row: 1;
}

.drill_eff_item_2 {
  grid-column: 2;
  grid-row: 2;
}

.drill_eff_item_3 {
  grid-column: 2;
  grid-row: 2;
}

.dv {
  fill: var(--textColor);
  font-weight: 700;
  letter-spacing: 0.1px;
}

.ddv {
  fill: var(--textColor);
  font-weight: 700;
  letter-spacing: 0.1px;
}

.dp__input {
  padding: 4px !important;
  color: #ffffff !important;
  background: #0e1016 !important;
  font-size: min(0.9vw, 12px);
}

.dp__main {
  margin: 1px 2px;
  margin-top: 0;
}

mark {
  background: unset;
  /* background: red; */
  color: white;
  font-size: 0.8vw;
  font-weight: 500;
  letter-spacing: 1.5px;
}

[type="checkbox"],
[type="radio"] {
  content: "";
  background-color: #28282f !important;
  background-color: unset !important;
  margin-left: auto;
  border-radius: 0;
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  border: 1px solid #5aba81 !important;
  /* background-image: url(/img/check_ico.406e337a.svg); */
}

.dp__input {
  background-color: #262626 !important;
  color: #ffffff !important;
}

.dis_live_btn {
  position: relative;
  right: -7px;
  top: -8px;
}

.non_dis_sec {
  width: 130px;
  display: flex;
  justify-content: space-around;
  gap: 6px;
}

.actc_tool {
  font-size: 10px;
  background: #000000;
  width: auto;
  position: absolute;
  padding: 2px 5px;
  /* border-radius: 20px; */
}

.disabled {
  /* pointer-events: none; */
  pointer-events: none;
  cursor: default;
  display: inline-block;
  padding: 4px;
  background-color: #b6adb4;
  border-radius: 2px;
  border: 0px;
  text-align: center;
  /* color: #fff !important; */
  text-decoration: none !important;
  opacity: 0.5;
  z-index: -1;
}

.track_config_border {
  /* border-left: 1px solid #3f3f46;
  border-right: 1px solid #3f3f46; */
  /* margin: 2px; */
  /* margin-bottom: 1px; */
  /* border:1px solid red */
}

.track_config_border_tooltip {
  /* background: var(--colorBoxBg); */
  /* color: var(--textColorTooltip); */
  color: black;
}

.td_unit_ {
  font-size: calc((0.2em + 0.2vmin) + (0.2em + 0.2vmax));
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.depth_pick {
  background: unset !important;
  width: 100% !important;
  height: 20px !important;
  font-size: 10px !important;
  /* margin: 0 3px; */
}

.feet_ {
  width: 25%;
}

.feet_boxed {
  width: 75%;
}

.noUi-handle {
  width: 48px !important;
}

.__bg_label_boxes {
  background: var(--colorBoxBg);
  transition: var(--transition);
}
.__bg_label_boxes.drillingDisClass {
  width: 85%;
}

.name_numaric_con {
  width: calc(100% - 5px);
  margin: 0 3px;
  display: block;
}

.dark_trend_mode {
  background: var(--central_bg);
  transition: var(--transition);
}

.margin_top_title {
  margin-top: 0.5rem !important;
}

.colorBox_text_gray {
  color: var(--colorBoxHeadingText);
  font-weight: 600 !important;
  font-size: calc(0.29vw + 0.3vh + 0.35vmin);
  height: 14px;
  display: flex;
  align-items: end;
  justify-content: center;
  /* background: var(--colorBoxHeadBackround); */
  /* font-size: 0.55rem; */
  margin-top: 0.07rem;
}

.colorBox_num {
  color: var(--colorBoxWhiteNum);
  /* font-weight: 600; */
}

.colorBox_textMesure {
  font-size: 9px;
  color: var(--colorBoxHeadingText);
  letter-spacing: 0.7px;
}

.liveBtnDot {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  /* background-color: #12B822; */
  /* background:var(--liveBtnGreen) */
}

.newStylesLiveBtn {
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  padding: 2px 8px;
  /* selectingTimeFeetColorBg */
  background: var(--liveBtnGreen) !important;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 9px !important;
  color: #ffffff !important;
}

.newStWDepth {
  justify-content: start !important;
}

.newStWDeptInput {
  background: var(--colorBoxPopUpInputBg) !important;
  border: none;
  color: var(--textColor) !important;
  margin-left: 0px;
  font-weight: 600;
  text-align: center;
  /* border-radius: 10px; */
}

.normalBg {
  background: var(--colorBoxBg);
  color: var(--selectingTimeFeetColor);
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
  transition: var(--transition);
}

.timeBtn:hover {
  background: var(--activeTextColor);
  color: #ffffff;
  transition: var(--transition);
}

.activeBg {
  background: var(--activeTextColor);
  color: #ffffff;
}

.timeBtn {
  padding: 0px 7px;
  border-radius: 8px;
}

.arrangementClass {
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
  background: var(--widgetBg);
}

.settingPopColor {
  color: var(--textColor);
  /* font-weight: 500; */
  letter-spacing: 1px;
  font-size: 9px;
}

.__toggle__boxes {
  /* height: 20px; */
  /* margin-top: 5px; */
  /* padding-left: 10px; */
  padding-right: 5px;
}

.__label_boxes_container {
  /* column-gap: 5px; */
  margin-top: -3px;
}

.__label_boxes {
  margin-top: auto;
  margin-left: 5px;
}

.__trend_bg_seperation {
  border: solid 0.5px;
  border-color: dimgray;
}

.value_of_Mnemonic_in_DIS {
  color: var(--textColor);
  font-size: 0.65rem;
}

.rages_of_mnemonics_in_DIS {
  padding-bottom: 0.1rem;
  color: var(--textColor);
  /* font-size: 0.55rem; */
}

mark {
  font-weight: 300px;
}

.ann_para ::v-deep ol,
ul {
  list-style-type: decimal;
  word-spacing: 2.5px;
  margin-left: 5%;
}

.annotation_desc ::v-deep h5 > strong {
  letter-spacing: 3px;
}

.annotation_desc ::v-deep p > strong {
  letter-spacing: 3px;
}

::v-deep li.ql-indent-1:not(.ql-direction-rtl) {
  list-style-type: disc;
  margin-left: 5%;
}

::v-deep li.ql-indent-2:not(.ql-direction-rtl) {
  list-style-type: disc;
  margin-left: 10%;
}

.noUi-target_dup {
  /* background: #202124; */
  background: var(--widgetBg);
  /* height: 622px;
  box-shadow: 0px 0px 8px 2px darkgray;
  width: 50px;
  opacity: 1;
  border-radius: 15px;
  top: -15px;
  left: 11px; */
  /* position: relative;
  width: 50px; */
  /* height: 100px; */
  /* height: 1.5em; */

  /* position: relative; */
}

#range1,
label {
  -webkit-tap-highlight-color: transparent;
}

#range1,
.range {
  /* border-radius: 0.75em; */
  overflow: hidden;
  margin-bottom: 1.5em;
}

#range1 {
  background-color: transparent;
  box-shadow: 0.3em 0.3em 0.4em var(--bs2) inset,
    -0.3em -0.3em 0.4em var(--bs1) inset;
  display: block;
  padding: 0 0.1em;
  transform-origin: left;
  position: absolute;
  transform: rotate(90deg);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#range1:focus {
  outline: transparent;
}

/* #range1::-webkit-slider-thumb {
  background-color: #f45525;
  border: 50px;
  box-shadow:
    -0.3em -0.3em 0.5em #0937aa inset,
    0 -0.2em 0.2em 0 #0004,
    0.3em 0.9em 0.8em #0007;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transition: all 0.1s linear;
  z-index: 1;

} */

input#range1[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  z-index: 1;
  height: 3rem;
  width: 1.5rem;
  /* border-radius: 4px; */
  /* border-radius: 50%; */

  box-shadow: -0.3em -0.3em 1.5em var(--activeTextColor) inset,
    0 -1.2em 0.2em 0 #0004, 0.3em 0.9em 0.8em #0007;
  cursor: ew-resize;

  background: transparent;
  cursor: pointer;
  transition: all 0.1s linear;
  z-index: 1;
  /* background-color: #f45525;
  border: 50px;
  box-shadow:
    -0.3em -0.3em 0.5em #0937aa inset,
    0 -0.2em 0.2em 0 #0004,
    0.3em 0.9em 0.8em #0007;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  width: 200%;
  height: 200%;
  transition: all 0.1s linear;
  z-index: 1; */
}

input#range1[type="range"]:hover::-webkit-slider-thumb {
  width: 2rem;
}
/* FIREFOX */
input#range1[type="range"]::-moz-range-thumb {
  appearance: none;
  z-index: 1;
  height: 3rem;
  width: 1.5rem;
  border-radius: 0px;
  border: 0;
  /* border-radius: 50%; */

  box-shadow: -0.3em -0.3em 1.5em var(--activeTextColor) inset,
    0 -1.2em 0.2em 0 #0004, 0.3em 0.9em 0.8em #0007;
  cursor: ew-resize;

  background: transparent;
  cursor: pointer;
  transition: all 0.1s linear;
  z-index: 1;
  /* background-color: #f45525;
  border: 50px;
  box-shadow:
    -0.3em -0.3em 0.5em #0937aa inset,
    0 -0.2em 0.2em 0 #0004,
    0.3em 0.9em 0.8em #0007;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  width: 200%;
  height: 200%;
  transition: all 0.1s linear;
  z-index: 1; */
}

input#range1[type="range"]:hover::-moz-range-thumb {
  width: 2rem;
}

#range1:focus::-webkit-slider-thumb {
  /* background-color: #5583f6;
  box-shadow:
    -0.3em -0.3em 0.5em #0b46da inset,
    0 -0.2em 0.2em 0 #0004,
    0.3em 0.9em 0.8em #0007; */
}

.range {
  position: relative;
  height: 5em;
}

.range__ticks {
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0.75em;
  width: calc(100% - 1.5em);
  height: 100%;
}

.range__tick,
.range__tick-text {
  display: inline-block;
}

.range__tick {
  color: var(--tick);
  font-size: 0.5em;
  text-align: center;
  width: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.range__tick-text {
  transform: translateX(-50%);
}

.track_styles {
  margin-left: 1em;
}

/* Dark mode */
.zoomResetMsz {
  font-size: 10px;
  opacity: 0.5;
  font-style: italic;
  color: var(--textColor);
}

.zoomResetMsz {
  animation: 2s anim-lineUp ease-out infinite;
}

.liveActiveBtn {
  font-size: 5px; /* Slightly increased font size */
  opacity: 0.7; /* Increased opacity for clearer text */
  font-style: italic;
  color: var(--textColor);
  display: flex;
  justify-content: center;
  width: 100%;
}
.liveActiveBtn {
  animation: 2s anim-lineUp ease-out infinite;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.unit_wrap_word {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.loadingtext {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  font-size: 30px;
  z-index: 1;
}
.loadingbgckground {
  position: absolute;
  left: 170px;
  top: 168px;
  background: gray;
  opacity: 0.8;
}
.drillingDisLoader {
  position: absolute;
  left: 135px;
  top: 163px;
  background: gray;
  opacity: 0.8;
  width: calc(100% - 135px) !important;
  height: calc(100% - 163px) !important;
  max-width: none !important;
}
.dropzone_newmnemonics {
  display: flex;
  justify-content: end;
  align-items: center;
  position: absolute;
  right: 0;
  top: 50%;
  width: 52px;
  height: 250px;
  background: var(--colorBoxBg);
  /* border-radius: 50%; */
  opacity: 0.4;
}
#drop_ocon_new {
  margin-right: 5px;
}
</style>
