<template>
  <div class="shadow-lg" :id="widgetId" @contextmenu="this.setContextIdWidget" :style="{
    height: this.widgetHeight + 'px',
    width: this.widgetWidth + 'px',
    paxyAxisngTop: '2px',
    background: 'var(--widgetBg)',
    transition: 'var(--transition)',
    'border-radius': '10px',
  }">
    <div class="block tool_group" :style="{
    top: this.mouse_y - 150 + 'px',
    left: this.mouse_x + 50 + 'px',
  }" :id="'tooltip' + this.widgetId">
      <div v-if="isTvdTooltip" class="actc_tool text-white">
        <p :key="i" v-for="(msg, i) of tvdTooltipValues.split('</br>')">
          {{ msg }}
        </p>
      </div>
    </div>
    <div class="text-white h-Full p-2" style="color: var(--textColor)">
      <div class="flex justify-between flex-wrap">
        <!-- <div></div> -->
        <div class="selections px-2 flex items-center">
          <div v-if="logType == 'time' &&
    (primaryXaxisFullName?.value || secondaryYaxisFullName?.value)
    ">
            <ul class="flex" style="gap: 5px">
              <li class="timeList activeRecord" v-if="!hours.includes(timeDifference)" disabled>
                <span>{{ timeDifference }} </span>
                <span class="pl-1">HRS</span>
              </li>
              <li class="timeList cursor-pointer" @click="selectedDifference(hour)"
                :class="timeDifference == hour && 'activeRecord'" v-for="(hour, index) of hours" :key="index">
                <span>{{ hour }} </span>
                <span class="pl-1">{{ hour == 1 ? "HR" : "HRS" }}</span>
              </li>
            </ul>
          </div>
          <div style="font-size: 9px" class="flex items-center pr-3" v-if="logType == 'depth' &&
    (primaryXaxisFullName?.value || secondaryYaxisFullName?.value)
    ">
            <div class="start flex items-center" style="width: 75%">
              <label class="uppercase">START</label>
              <input type="number" :value="minY" step="0.01" class="newStWDeptInput ml-1"
                @change="startManualDepthValidation($event)" />
            </div>
            <div class="end flex items-center mx-3" style="width: 75%">
              <label class="uppercase">End</label>
              <input type="number" :value="maxY" step="0.01" class="newStWDeptInput ml-1"
                @change="endManualDepthValidation($event)" />
            </div>
            <ul class="flex" style="gap: 5px">
              <li class="timeList activeRecord" v-if="!depths.includes(depthDifference)" disabled>
                <span>{{ depthDifference }}FT </span>
              </li>
              <li class="timeList cursor-pointer" @click="selectedDifference(depth)"
                :class="depthDifference == depth && 'activeRecord'" v-for="(depth, index) of depths" :key="index">
                <span>{{ depth }}FT </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="flex items-center justify-between __toggle__boxes">
          <div class="flex px-2">
            <button class="mx-2 buttonStyles print-button" @click="open_print_options_popup">
              Print
            </button>

          </div>
          <div class="flex px-2">
            <div style="font-size: 9px" class="mt-1 settingPopColor mb-1 cursor-pointer text_xs newStylesLiveBtn"
              @click="resetZoomHandler(), liveClick()" :style="isWellLive && !draggedRangeElement
    ? 'color: #fff; background:var(--liveBtnGreen)'
    : 'background:#b6adb4; color:#fff;'
    ">
              <div class="liveBtnDot" style="background: #fff"></div>
              LIVE
            </div>
          </div>
          <div class="flex px-2">
            <div :style="{ fontSize: 9 + 'px' }" class="mt-1 settingPopColor mb-1 cursor-pointer"
              @click="resetZoomHandler(), liveClick()">
              Reset
            </div>
          </div>

          <div class="flex px-2">
            <div :style="{ fontSize: 9 + 'px' }" class="mt-1 settingPopColor mb-1">
              Zoom
            </div>
            <div class="ml-0.5 tool_section">
              <input type="checkbox" :id="'zoom' + this.widgetId" class="tool_toggle_input" v-model="isZoomEnable" />
              <label class="tool_toggle_label _zoom ml-1 mb-1" :for="'zoom' + this.widgetId">Zoom :</label>
            </div>
          </div>
          <div class="normalBg flex px-2">
            <div :style="{ fontSize: 9 + 'px' }" class="mt-1 settingPopColor mb-1">
              Tooltip
            </div>
            <div class="ml-0.5 tool_section">
              <input v-model="isTooltipEnable" type="checkbox" :id="'tooltip-exponent' + this.widgetId"
                class="tool_toggle_input" />
              <label class="tool_toggle_label _zoom ml-1 mb-1" :for="'tooltip-exponent' + this.widgetId">Tooltip
                :</label>
            </div>
            <div :style="{ fontSize: 9 + 'px' }" class="mt-1 settingPopColor mb-1 ml-3">
              Table
            </div>
            <div class="ml-0.5 tool_section">
              <input v-model="showTable" @click="onChangeValue($event)" type="checkbox"
                :id="'table-exponent' + this.widgetId" class="tool_toggle_input" />
              <label class="tool_toggle_label _zoom ml-1 mb-1" :for="'table-exponent' + this.widgetId">Table :</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row">
      <div class="canvas">
        <CanvasDexponentChatVue :id="'canvasXy_axis'" :totl_height="cHeight - 70" :totl_width="widthOfCanvas"
          @emitScroll="mousescroll" :log-type="logType" :scale="scale" :enableTooltip="isTooltipEnable"
          :enableZoom="isZoomEnable" :primaryAxixX="primaryXaxisFullName" :primaryAxixY="primaryYaxisFullName"
          :secondaryAxixY="secondaryYaxisFullName" :enableSecondaryYaxis="isSecondaryAxis" :min_X="minX" :min_Y="minY"
          :max_X="maxX" :max_Y="maxY" :data_set="primarDataSet" :data_set_secondary="secondaryDataSet"
          :primaryAxisLabelColor="axisLabelColor" :secondaryAxisLabelColor="secondaryAxisLabelColor"
          :gridColor="gridColor" :primaryGrapgColor="graphScaleColor" :secondaryGrapgColor="graphSecondaryScaleColor"
          :xAxisLabelSize="xAxisLabelSize" :yAxisLabelSize="yAxisLabelSize"
          :yAxisLabelSizeSecondary="yAxisSecondaryLabelSize" :primaryYaxisUnit="primaryYaxisUnit"
          :secondryYaxisUnit="secondryYaxisUnit" @xCoordsAfterZoom="xCoordsAfterZoom" ref="canvasXyAxisGraph"
          :key="refreshXyCanvasKey" @onDoubleClickOfDexponent="onDoubleClickOfDexponent" :printButtonClicked="false"
          @dbclick="onDoubleClickOfDexponent" @emitprintButton="emitprintButton" :widgetId="widgetId" />
        <div class="w-full flex justify-center items-center relative mt-2">
          <div v-if="primaryXaxisFullName?.value && cWidth >= 850" class="flex items-center">
            <span style="width: 30px; height: 5px" :style="{
    background: graphScaleColor ? graphScaleColor : '#ff0000',
  }"></span>
            <span class="pl-1" style="color: var(--textColor); font-size: 9px">
              {{ primaryXaxisFullName?.name }}</span>
          </div>
          <div v-if="secondaryYaxisFullName?.value" class="flex items-center pl-5">
            <span style="width: 30px; height: 5px" :style="{
    background: graphSecondaryScaleColor
      ? graphSecondaryScaleColor
      : '#0000ff',
  }"></span>
            <span class="pl-1" style="color: var(--textColor); font-size: 9px">
              {{ secondaryYaxisFullName?.name }}</span>
          </div>
        </div>
      </div>

      <div v-if="NumberOfPrintDivs.length" class="hidden">
        <div :key="index" v-for="(canvas, index) of NumberOfPrintDivs">
          <CanvasDexponentChatVue :id="'canvasXy_axis' + index" :totl_height="1126.89 - 100" :totl_width="715.28 + 400"
            @emitScroll="mousescroll" :log-type="logType" :scale="scale" :enableTooltip="isTooltipEnable" :index="index"
            :totaldivs="NumberOfPrintDivs.length" :enableZoom="isZoomEnable" :primaryAxixX="primaryXaxisFullName"
            :primaryAxixY="primaryYaxisFullName" :secondaryAxixY="secondaryYaxisFullName"
            :enableSecondaryYaxis="isSecondaryAxis" :min_X="minX" :min_Y="canvas.miny" :max_X="maxX"
            :max_Y="canvas.maxy" :data_set="canvas.data" :data_set_secondary="secondaryDataSet"
            :primaryAxisLabelColor="axisLabelColor" :secondaryAxisLabelColor="secondaryAxisLabelColor"
            :gridColor="gridColor" :primaryGrapgColor="graphScaleColor" :secondaryGrapgColor="graphSecondaryScaleColor"
            :xAxisLabelSize="xAxisLabelSize" :yAxisLabelSize="yAxisLabelSize"
            :yAxisLabelSizeSecondary="yAxisSecondaryLabelSize" :primaryYaxisUnit="primaryYaxisUnit"
            :secondryYaxisUnit="secondryYaxisUnit" @xCoordsAfterZoom="xCoordsAfterZoom" ref="canvasXyAxisGraph"
            @onDoubleClickOfDexponent="onDoubleClickOfDexponent" :printButtonClicked="printButtonClicked"
            @dbclick="onDoubleClickOfDexponent" @emitimages="emitimages" @emitprintButton="emitprintButton"
            :widgetId="widgetId" :printIndex="printIndex" />
        </div>
      </div>


      <div v-if="showTable" class="overflow-hidden overflow-y-auto mt-3 mr-3 flex flex-col">
        <div class="file-container mb-3">
          <button class="mx-2" :class="{ 'uploadwhite': isWhiteTheme, 'uploadblack': !isWhiteTheme }"
            @click="onClickOfUploadButton">UPLOAD</button>
          <button class="mx-2" :class="{ 'uploadwhite': isWhiteTheme, 'uploadblack': !isWhiteTheme }"
            @click="ExportExcel(bhaList, 'dexponent')">DOWNLOAD SAMPLE</button>

        </div>

        <div class="table-border">
          <DataTableWidget :id="this.widgetId" :displayId="this.displayId" :widgetId="this.widgetId"
            :wellId="this.wellId" :bitsizeCategory="this.bitsizeCategory" @editdata='editedData'
            @delteemit="deleteButtonClicked" @rowvaluechanged="rowvaluechanged" @add="buttonclicked"
            :headFontSize="this.headFontSize" :rowsValues="this.pagenated_data_points" :column="this.column"
            :widgetHeight="this.widgetHeight - 200" :key="tablerefreshkey" />
          <div v-if="this.pagenated_data_points.length > 0" class="pagination-container" :style="{
    background:
      headBackground ||
      (this.darkDark !== 'white'
        ? 'var(--nav1BgClr)'
        : 'beige'),
  }" :class="{ whitetheme: isWhiteTheme, blacktheme: !isWhiteTheme }">
            <Pagenation v-if='totalPagesCount > 1' :total-pages="totalPagesCount" :total="totalRecords"
              :per-page="perPageCount" :current-page="currentPage" @pagechanged="onPageChange" />
          </div>
        </div>

        <div v-if="updatedRows.length > 0">
          <button class="mx-2" :class="{ 'uploadwhite': isWhiteTheme, 'uploadblack': !isWhiteTheme }"
            @click="onclickOfsave">SAVE</button>
        </div>
      </div>
    </div>

    <div class="w-full flex" v-if="(primaryXaxisFullName?.value || secondaryYaxisFullName?.value) &&
    isSlider
    ">
      <input class="mx-auto" style="width: 95%" type="range" :value="rangeValue"
        @change="maxXSliderRange($event, 'fromRange')" :min="minMsxTime.min" :max="minMsxTime.max" />
    </div>
    <!-- <div :id="'xyAxis' + widgetId"></div> -->
    <div id="d3Tooltip" class="tooltipd3"></div>
    <div :key="printIndex" :id="'dexpo_canvas_parent' + printIndex" class="hidden print" style="display: none"></div>

    <div class="runInfo" v-if="open_printing_options_page">
      <div class="popup" :class="{ whitetheme: isWhiteTheme, blacktheme: !isWhiteTheme }" :style="{
    background:
      headBackground ||
      (this.darkDark !== 'white'
        ? 'var(--nav1BgClr)'
        : 'beige'),
  }">
        <div class="p-5 text-xs flex flex-col items-center">
          <div class="">
            <div class="">
              <div class="flex items-center">
                <label class="pl-2 font-semibold uppercase w-1/2">Start Range</label>
                <input
                  class="fieldHeight w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  type="number" v-model="print_start_depth" style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  " />
              </div>
              <div class="flex items-center mt-2">
                <label class="pl-2 font-semibold uppercase w-1/2">End range</label>
                <input
                  class="fieldHeight w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  type="number" v-model="print_end_depth" @change="changed_print_end_depth" style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  " />
              </div>
              <div class="flex items-center mt-2" v-if="print_start_depth !== null &&
    print_start_depth !== '' &&
    print_end_depth !== null &&
    print_end_depth !== ''
    ">
                <label class="pl-2 font-semibold uppercase w-1/2">Depth per page</label>
                <input
                  class="fieldHeight w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  type="number" v-model="depth_to_print_per_page" @change="changed_depth_to_print_per_page" style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  " />
              </div>
            </div>
            <div class="flex justify-center mt-4">
              <button v-if="print_start_depth !== null &&
    print_start_depth !== '' &&
    print_end_depth !== null &&
    print_end_depth !== '' &&
    depth_to_print_per_page !== null &&
    depth_to_print_per_page !== ''
    " type="button" @click="print_page" class="printBtn text-xxs font-semibold border" style="
                  padding: 3px 8px;
                  outline: none;
                  color: var(--activeTextColor);
                  border-color: currentColor;
                " :disabled="printing_page">
                {{ printing_page ? "Please wait.." : "Print" }}
              </button>
              <button @click="close_print_options_popup" class="text-xxs font-semibold" style="
                  padding: 3px 8px;
                  border: 1px solid #f40303;
                  outline: none;
                  color: #f40303;
                  margin-left: 10px;
                ">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showdeletePopup" class="runInfo">
      <div class="popup" :class="{ 'deletepopup': isWhiteTheme, 'deletepopupwhite': !isWhiteTheme }" :style="{
    background:
      headBackground ||
      (this.darkDark !== 'white'
        ? 'var(--nav1BgClr)'
        : 'beige'),
  }">
        <div>
          <div>
            <div>

              <div class="relative p-6 flex-auto w-full">
                <div class="container px-4 py-4 mx-auto">
                  <div class="flex flex-col justify-center items-center mt-5">

                    <p>Do You Want To Delete The Row ?</p>
                    <div class="flex flex-row mt-2 buttons-container">

                      <button class="text-xxs font-semibold border" style="
                  padding: 3px 8px;
                  outline: none;
                  color: var(--activeTextColor);
                  border-color: currentColor;
                " @click="onClickYesFordelete">
                        Ok
                      </button>
                      <button class="text-xxs font-semibold" style="
                  padding: 3px 8px;
                  border: 1px solid #f40303;
                  outline: none;
                  color: #f40303;
                  margin-left: 10px;
                " @click="onClickNoFordelete">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div v-if="openFilepopup" class="runInfo">
      <div class="popupforupload" :class="{ 'deletepopup': isWhiteTheme, 'deletepopupwhite': !isWhiteTheme }"
        style="font-weight: bold" :style="{
    background:
      headBackground ||
      (this.darkDark !== 'white'
        ? 'var(--nav1BgClr)'
        : 'beige'),
  }">
        <div class="flex items-start justify-center  border-b border-solid border-blueGray-200 rounded-t p-0"
          :style="{ height: inherit }">
          <div class="md:mt-12 md:ml-20 text-center">
            <h6 class="text-center tracking-wide  text">Add / Upload</h6>
          </div>
          <button @click="onclickCloseopenfilePopup"
            class="cross-button p-1 absolute right-5 bg-transparent border-0 text-red-500 opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            style="top: 9px;">
            <span class="bg-transparent text-red-500 opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
              ×
            </span>
          </button>
        </div>
        <div :style="{ height: inherit }">
          <div class="relative flex flex-col w-full outline-none focus:outline-none" :style="{ height: inherit }">
            <form @submit.prevent="preventDefault">
              <label class="addUploadFileLabel block my-2">
                <input type="file"
                  class="block text-xs  file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-xs file:font-semibold file:bg-violet-50 file:primary_text hover:file:bg-red-100 mx-auto"
                  @change="handleFileUpload" />
              </label>
              <div style="max-height: 40vh; overflow-y: auto;">
                <table class="border-collapse border-table w-full">
                  <tbody>
                    <tr v-if="bitSpecs.length > 0" class="border-table text-left">
                      <td class="border-table " v-for="(item, index) in fields" :key="index">
                        <span style="margin-left: 8px !important;">{{ item }}</span>
                      </td>

                    </tr>
                    <tr v-for="(item, index) in bitSpecs" :key="index">
                      <td class="border-table" v-for="(keyofFields, indx) of fields" :key="indx">

                        <input type="number" step="0.01" name="text" style="margin-left: 8px;"
                          class="form focus:outline-none row-text focus:border-sky-0 block w-full sm:text-xs focus:ring-0"
                          placeholder="value" :value="item[indx]" @input="updateList($event, index, indx)" />

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button class="mt-3"
                :class="{ 'uploadwhite': isWhiteTheme, 'uploadblack': !isWhiteTheme, 'disabled': bitSpecs.length == 0 }"
                type="submit" @click="onclickInsert" :disabled="bitSpecs.length == 0">
                Insert
              </button>
            </form>
          </div>

        </div>
      </div>
    </div>

    <!-- <div v-if="open_printing_options_page" class="opacity-1 fixed inset-0 z-40 bg_modal_back"></div> -->
  </div>
</template>
<script>
import { max, min } from "d3";
var svg = "";
let id = null;
import TableLite from "./DataTableLibrary.vue";
import apiService from "../../../../api/services";
import moment from "moment/src/moment";
import CanvasDexponentChatVue from "./CanvasDexponentChat.vue";
import helperServices from "../../../../helper-services";
import DataTableWidget from "./DataTableWidget.vue";
import Pagenation from "../../../pagenation/Pagenation.vue";
import Axios from "axios";
import dexponentService from '../../../../api/services/reports'
const XLSX = require('xlsx');

export default {
  name: "D-Exponent",
  components: {
    CanvasDexponentChatVue,
    DataTableWidget,
    Pagenation,
    TableLite
  },
  props: {
    scale: {
      type: Number,
      default: 1,
    },
    cardBackground: {
      type: String,
      default: "var(navBar2Bg)",
    },
    widgetHeight: {
      type: Number,
      default: 200,
    },
    widgetWidth: {
      type: Number,
      default: 350,
    },
    widgetpopHeight: {
      type: Number,
      default: 480,
    },
    widgetpopWidth: {
      type: Number,
      default: 1080,
    },
    displayId: String,
    id: String,
    gridColor: {
      type: String,
      default: "",
    },
    gridBackground: {
      type: String,
      default: "",
    },
    axisLabelColor: {
      type: String,
      require: false,
      default: "",
    },
    xAxisLabelSize: {
      Number,
    },
    yAxisLabelSize: {
      type: Number,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    widgetId: String,
    isSwitched: {
      type: Boolean,
      default: false,
    },
    widgetUnitL: {
      type: String,
      default: "",
    },
    unitDisplay: {
      type: Boolean,
      default: true,
    },
    openConfig: Function,
    isSecondaryAxis: {
      type: Boolean,
      require: false,
      default: false,
    },
    graphScaleColor: {
      type: String,
      require: false,
      default: "",
    },
    secondaryAxisLabelColor: {
      type: String,
      require: false,
      default: "",
    },
    graphSecondaryScaleColor: {
      type: String,
      require: false,
      default: "",
    },
    yAxisSecondaryLabelSize: {
      type: Number,
      require: false,
      default: 10,
    },
    primaryYaxisFullName: {
      type: Object,
      require: false,
      default: { name: "depth", value: "depth" },
    },
    primaryXaxisUnit: {
      type: String,
      require: false,
      default: "",
    },
    primaryXaxisFullName: {
      type: String,
      require: false,
      default: { name: "D-Exponent", value: "dexponent" },
    },
    primaryYaxisUnit: {
      type: String,
      require: false,
      default: "",
    },
    secondaryYaxisFullName: {
      type: String,
      require: false,
      default: { name: "mnemonic", value: "" },
    },
    secondryYaxisUnit: {
      type: String,
      require: false,
      default: "",
    },
    isSlider: {
      type: Boolean,
      default: false,
    },
    themeColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pageSize: 10,
      isTvdTooltip: false,
      tvdTooltipValues: "",
      mouse_x: 0,
      mouse_y: 0,
      minX: 0,
      maxX: 100,
      minY: 0,
      maxY: 50,
      minY1: 0,
      maxY1: 1000,
      width: this.widgetWidth,
      height: this.widgetHeight,
      widgetUnit: this.widgetUnitL,
      widgetUnits: this.widgetUnitL,
      displayName: "ss",
      displayNames: "yy",
      wellId: null,
      wellboreId: null,
      logId: null,
      logType: null,
      mnemonic_groups: {},
      minMsxTime: {},
      completeData: {},
      isTooltipEnable: false,
      cWidth: this.widgetWidth,
      cHeight: this.widgetHeight,
      primarDataSet: [],
      secondaryDataSet: [],
      isZoomEnable: false,
      refreshXyCanvasKey: 5665,
      topic: null,
      rangeValue: null,
      draggedRangeElement: false,
      timeDifference: 1,
      depthDifference: 50,
      hours: [1, 2, 8, 12, 24],
      depths: [50, 100, 500, 1000],
      isWellLive: false,
      minYFixed: 0,
      maxYFixed: 50,
      mousewheeltimeout: null,
      dexponentObj: {
        name: "D-Exponent",
        value: "dexponent",
      },
      idx: null,
      widthOfCanvas: 600,
      data_points: [],
      perPageCount: 100,
      currentPage: 0,
      pagenated_data_points: [],
      column: [],
      open_printing_options_page: false,
      print_start_depth: null,
      print_end_depth: null,
      depth_to_print_per_page: null,
      printing_page: false,
      printButtonClicked: false,
      isWhiteTheme: false,
      NumberOfPrintDivs: [],
      printData: [],
      refreshkey: 1,
      printIndex: 1,
      unitsColumn: [],
      tablerefreshkey: 534,
      showdeletePopup: false,
      startIndex: 0,
      delteIndex: null,
      updatedRows: [],
      originalDataPoints: [],
      openFilepopup: false,
      bitSpecs: [],
      fields: [],
      completeDataFromSearch: [],
      statusCheck: null,
      realtimeData: null,
      bhaList: [
        {
          dmea: 20,
          rop: 23,
          wob: 45,
          mfi: 14,
          rpm: 23,
          'mud density': 20,
          bitSize: 65,

        },
      ],
      bitsizeCategory: 'Length',
      headFontSize: 13
    };
  },
  beforeMount() {
    let id = "id" + Math.random().toString(16).slice(2);
    this.tempWidgetId = id;
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.idx = i;
      }
    }
    const value = this.$store.state.rect.rects[this.idx].DExponent?.showTable;
    if (value) {
      const originalNumber = this.cWidth;
      const sixtyPercent = Math.floor(originalNumber * 0.5);
      this.widthOfCanvas = sixtyPercent;
      this.refreshXyCanvasKey += 21;
      this.tablerefreshkey += 12
    } else {
      this.widthOfCanvas = this.cWidth - 40;
      this.refreshXyCanvasKey += 12;
      this.tablerefreshkey += 12
    }
  },
  async mounted() {
    console.log('coming to mounted on chnage wellbore')
    this.isWhiteTheme =
      this.$store.state.rect.darkmode == "white" ? true : false;

    console.log(
      this.axisLabelColor,
      this.$store.state.rect.darkmode,
      "is comingfrom trends"
    );
    this.rangeValue = null;
    this.tempWidgetId = this.backgroundId || this.tempWidgetId;
    let dtls = this.$store.state.disp.displays[this.displayId];
    if (
      dtls != null &&
      dtls.wellId != null &&
      dtls.wellboreId != null &&
      dtls.wellboreState != null
    ) {
      this.takeOffSocket();
      this.wellId = dtls.wellId;
      this.wellboreId = dtls.wellboreId;
      this.logId = dtls.logId;
      let rects = this.$store.state.rect.rects;

      let found = this.findMatchingRectIndex(rects);

      // Check the returned value

      if (dtls.logType != 'depth' && this.$store.state.rect.rects[found].selectedReport == 'D-EXPONENT') {
        this.$toast.clear();
        this.$toast.error(`Please select the depth log`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });


      }
      this.logType = "depth";
      this.topic = dtls.topicName;
      this.minMsxTime = await this.getMinMaxRange();
      console.log(this.minMsxTime, "min max");
      // this.maxYFixed = this.minMsxTime.max;
      // this.minYFixed = this.minMsxTime.min;
      this.completeData = null;
      this.draggedRangeElement = false;
      this.timeDifference = 1;
      this.depthDifference = 50;


      // this.logType = this.logType == "trajectory" ? "time" : this.logType;
      if (this.logType) {
        this.primaryYaxisFullName.name = this.logType;
        this.primaryYaxisFullName.value = this.logType;
        this.primaryXaxisFullName.name = this.dexponentObj.name;
        this.primaryXaxisFullName.value = this.dexponentObj.value;
      }


      // if (this.primaryYaxisFullName && this.primaryYaxisFullName.value) {
      //   await this.prepareGraphDetail(this.primaryYaxisFullName.value);
      // }
      // if (this.primaryXaxisFullName && this.primaryXaxisFullName.value) {
      //   await this.prepareGraphDetail(this.primaryXaxisFullName.value);
      //   // this.getRealtimeData();
      // }
      await this.getTableData();
      if (this.maxYFixed >= this.depthDifference) {
        this.maxY = this.maxYFixed
        if (this.maxY - this.depthDifference >= 0) {
          this.minY = this.maxY - this.depthDifference
        }
      }
      if (this.completeDataFromSearch) {
        await this.buildSVG();
        await this.onPageChange(1);
        this.refreshXyCanvasKey += 12
      }


      // setTimeout(() => {

      await this.wellStatusCheck();
      if (this.isWellLive) {
        if (this.statusCheck) {
          clearInterval(this.statusCheck);
        }
        await this.commonWellStatus()
      }


    }
    this.$store.subscribe(async (mutation, state) => {
      if (
        mutation.type == "disp/setDisplay" &&
        this.displayId == mutation.payload.display &&
        this.id
      ) {

        console.log("mutation.payload", mutation.payload);
        this.takeOffSocket();
        this.wellId = mutation.payload.wellId;
        this.wellboreId = mutation.payload.wellboreId;
        this.logId = mutation.payload.logId;
        this.topic = mutation.payload.topicName;
        let rects = this.$store.state.rect.rects;
        let foundIndex = this.findMatchingRectIndex(rects);

        // Check the returned value

        if (mutation.payload.logType != 'depth' && this.$store.state.rect.rects[foundIndex].selectedReport == 'D-EXPONENT') {
          this.$toast.clear();
          this.$toast.error(`Please select the depthlog`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });

        }

        this.logType = "depth";

        if (this.logType) {
          this.primaryYaxisFullName.name = this.logType;
          this.primaryYaxisFullName.value = this.logType;
          this.primaryXaxisFullName.name = this.dexponentObj.name;
          this.primaryXaxisFullName.value = this.dexponentObj.value;
        }
        this.logTypeId = mutation.payload.logTypeId;
        this.minMsxTime = await this.getMinMaxRange();
        // this.completeData = null;
        this.draggedRangeElement = false;
        this.timeDifference = 1;
        this.depthDifference = 50;
        // if (this.primaryYaxisFullName && this.primaryYaxisFullName.value) {
        //   await this.prepareGraphDetail(this.primaryYaxisFullName.value);
        // }
        // if (this.primaryXaxisFullName && this.primaryXaxisFullName.value) {
        //   await this.prepareGraphDetail(this.primaryXaxisFullName.value);
        //   // this.getRealtimeData();
        // }
        // if (this.secondaryYaxisFullName && this.secondaryYaxisFullName.value) {
        //   await this.prepareGraphDetail(this.secondaryYaxisFullName.value);
        //   // this.getRealtimeData();
        // }
        await this.getTableData();
        if (this.maxYFixed >= this.depthDifference) {
          this.maxY = this.maxYFixed
          if (this.maxY - this.depthDifference >= 0) {
            this.minY = this.maxY - this.depthDifference
          }
        }
        await this.buildSVG();
        this.refreshXyCanvasKey += 12


        // setTimeout(() => {
        await this.onPageChange(1);
        await this.wellStatusCheck();
        if (this.isWellLive) {
          if (this.statusCheck) {
            clearInterval(this.statusCheck);
          }
          await this.commonWellStatus()
        }



      } else if (mutation.type == "rect/DARK_MODE") {
        this.refreshXyCanvasKey += 13;
        console.log('coming to else if rect/mode')
      }
    });


    // await this.getTableData();
    // if (this.maxYFixed > this.depthDifference) {
    //   this.maxY = this.maxYFixed
    //   if (this.maxY - this.depthDifference > 0) {
    //     this.minY = this.maxY - this.depthDifference
    //   }
    // }
    // await this.buildSVG();
    // this.refreshXyCanvasKey += 12


    // // setTimeout(() => {
    // await this.onPageChange(1);
    // await this.wellStatusCheck();
    // if (this.isWellLive) {
    //   if (this.statusCheck) {
    //     clearInterval(this.statusCheck);
    //   }
    //   await this.commonWellStatus()
    // }

    // }, 300);
  },
  beforeDestroy() { // Used in Vue 3, equivalent to unmounted in Vue 2
    clearInterval(this.statusCheck);
    console.log('coming to before destroys')
  },
  unmounted() { // destroyed  in Vue 2
    clearInterval(this.statusCheck)
    console.log('coming to unmounted')

  },

  computed: {
    darkDark() {
      console.log("table dark dark : ", this.$store.state.rect.darkmode);
      return this.$store.state.rect.darkmode;
    },
    isWhiteTheme() {
      return this.$store.state.rect.darkmode == "white" ? true : false;
    },
    totalPagesCount() {
      return this.data_points.length
        ? Math.ceil(this.data_points.length / this.perPageCount)
        : 0;
    },
    totalRecords() {
      return this.data_points.length;
    },

    showTable() {
      return (
        this.$store.state.rect.rects[this.idx].DExponent?.showTable || false
      );
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
    axiosParams() {
      const params = new URLSearchParams();
      params.append("well_id", this.wellId);
      params.append("well_bore_id", this.wellboreId);
      params.append("customer", this.customer);
      return params;
    },
  },

  sockets: {
    connect: function () {
      console.log("socket connected in trend");
    },
    disconnect() {
      try {
        let topic = this.topic;
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error("disconnect unsubscribe error ", error);
      }
    },

    reconnect() {
      this.$socket.emit("subscribe", {
        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
        topic: this.topic,
        fields: this.fields,
        id: this.widgetId,
        clientId: this.$clientId,
      });
      console.log("----------------------------------------");
    },
  },
  emits: ["dexponentEmits"],
  methods: {
    findMatchingRectIndex(rects) {
      if (typeof rects != "undefined" && rects.length > 0) {
        for (let i = 0; i < rects.length; i++) {
          if (
            rects[i].widgetId == this.widgetId &&
            rects[i].displayId == this.displayId
          ) {
            return i;
          }
        }
      }

      // If no matching rectangle is found, return a default value like -1
      return -1;
    },
    ExportExcel(data, name) {
      var excl = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, excl, "excl");
      XLSX.writeFile(wb, name + ".xlsx");
    },
    async commonWellStatus() {
      this.statusCheck = setInterval(async () => {
        await this.wellStatusCheck()
        if (this.isWellLive) {
          console.log('well is in live')
          await this.getRealtimeDataForTable()
        }
      }, 5000)

    },

    async getRealtimeDataForTable() {

      let depthValue = 0
      if (this.data_points.length > 0) {
        const endrow = this.data_points[this.data_points.length - 1]
        depthValue = endrow.dmea ? endrow.dmea : 0
        this.maxYFixed = depthValue
      }
      let payload1 = {
        "wellId": this.wellId,
        "wellBoreName": this.wellboreId,
        'depth': depthValue,
        "fromDepthOperator": "GT"

      };

      try {
        let response = await dexponentService.dexponentSearch(payload1)
        const data = JSON.parse(JSON.stringify(response))
        this.completeDataFromSearch = [...this.completeDataFromSearch, ...data.data]
        const outputList = [];

        if (data.data) {
          data.data.forEach(inputData => {
            const { depth, values } = inputData;
            const outputData = {
              dmea: depth,
              ...values,
            };
            outputList.push(outputData);
          });

          console.log(outputList, 'output list')


          this.data_points = [...JSON.parse(JSON.stringify(this.data_points)), ...outputList]


          this.data_points = this.data_points.sort((a, b) => a.dmea - b.dmea);

          console.log(this.bitSpecs, 'after')

          console.log(this.data_points, 'data points')
          this.calculatePagedata(this.currentPage);

          this.maxY = this.data_points[this.data_points.length - 1].dmea
          if (this.maxY - this.depthDifference >= 0) {
            this.minY = this.maxY - this.depthDifference
          } else {
            this.minY = 0
          }
          await this.graphData()


          this.refreshXyCanvasKey += 18
          console.log(this.totalPagesCount, this.currentPage, 'is equal')
          if (this.totalPagesCount == this.currentPage) {
            this.tablerefreshkey += 34
          }
        }
      } catch (error) {
        console.log(error, 'error')
      }


    },

    async graphData() {
      // this.primarDataSet = [];
      if (this.completeDataFromSearch.length) {

        const xy = this.completeDataFromSearch.map((eachone) => {
          const object = {
            name: 'dexponent',
            x: parseFloat(eachone.values.dexponent),
            y: eachone.depth
          };
          return object;
        });
        const filteredXY = xy.filter(obj => obj.y >= this.minY && obj.y <= this.maxY);
        // console.log(xy, 'xy from graphdata')
        let det = {
          width: 2,
          color: "red",
          data: filteredXY,
        };
        this.primarDataSet = [det]
        // console.log(this.primarDataSet, 'graphprime')
      }



    },
    async onclickInsert() {
      const requiredFields = ['dmea', 'rop', 'wob', 'mfi', 'rpm', 'bitSize', 'mud density'];

      // Filter the required fields to find the ones that are missing in this.fields
      const missingFields = requiredFields.filter(field => !this.fields.includes(field));
      console.log(this.bitSpecs, 'bit specs12')

      if (missingFields.length === 0) {

        this.$store.dispatch("data/startLoading");
        const isValidBitSpecs = this.validateBitSpecs(this.bitSpecs);
        if (isValidBitSpecs) {
          setTimeout(async () => {
            this.openFilepopup = false
            const result = this.bitSpecs.map(row =>
              row.reduce((obj, value, index) => {
                obj[this.fields[index]] = value;
                return obj;
              }, { isnew: true }) // Adding isNew property with value true
            );
            this.updatedRows = [...this.updatedRows, ...result]

            this.data_points = [...this.data_points, ...result]
            this.data_points = this.data_points.sort((a, b) => a.dmea - b.dmea);
            console.log(this.data_points, 'data points')
            this.calculatePagedata(this.currentPage);
            this.tablerefreshkey += 12
            this.$store.dispatch("data/stopLoading");
            this.bitSpecs = []
          }, 0)
        }
        else {
          this.$toast.clear();
          this.$toast.error(`Values Are Missing Or Incorrect`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
          this.$store.dispatch("data/stopLoading");

        }

      } else {
        this.$toast.clear();
        this.$toast.error(`"${missingFields}" Fields Are Missing`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
        console.log("Missing fields:", missingFields);
      }



      // this.bitSpecs = []


    },
    validateBitSpecs(bitSpecs) {
      // Check if all rows in bitSpecs are valid (non-null, non-empty values)
      for (let i = 0; i < bitSpecs.length; i++) {
        const row = bitSpecs[i];
        if (row.length < 7) {
          return false
        }
        // if(row.length !=)

        for (let j = 0; j < row.length; j++) {
          const value = row[j];
          if (value == null || value == undefined || value == '' || isNaN(value)) {
            return false; // Found invalid value, return false
          }
        }
      }

      return true; // All rows are valid
    },
    updateList(event, index, column) {
      this.bitSpecs[index][column] = parseFloat(event.target.value);
      console.log(this.bitSpecs, 'edited value')

    },
    onclickCloseopenfilePopup() {
      this.openFilepopup = false
      this.bitSpecs = []
      this.fields = []
    },
    onClickOfUploadButton() {
      this.openFilepopup = true
    },
    handleFileUpload(event) {


      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = e => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        let objects = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        this.bitSpecs = objects

        // Assuming the first row contains the fields
        const fields = objects.shift();
        this.fields = fields
        objects = null


      };

      reader.readAsArrayBuffer(file);

    },
    async onclickOfsave() {
      this.$store.dispatch("data/startLoading");
      const formatedObjects = this.convertFormat(this.updatedRows)
      // this.completeDataFromSearch = [...this.completeDataFromSearch, ...formatedObjects]
      console.log(formatedObjects, this.completeDataFromSearch, 'formated objects')
      let payload1 = {
        list: formatedObjects
      };

      try {
        let response = await dexponentService.dexponentListSave(payload1)

        if (response.status == 200) {
          await this.getserachData()
          this.minYFixed = this.data_points[0].dmea
          const endrow = this.data_points[this.data_points.length - 1]
          const depthValue = endrow.dmea >= 50 ? endrow.dmea : 50
          this.maxYFixed = depthValue
          console.log(this.maxYFixed,'maxyfixed after searchdata')
          this.$toast.clear();
          this.$toast.success(`saved successfully`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        }
        this.$store.dispatch("data/stopLoading");

      } catch (error) {
        console.log(error, 'error for save/update')
        this.$store.dispatch("data/stopLoading");
      }



    },
    convertFormat(inputList) {
      const outputList = [];
      inputList.forEach(inputData => {
        const { dmea, rop, rpm, wob, mfi, bitSize, totalrpm, mudmotorrpm, dexponent, isnew } = inputData;
        const outputData = {
          wellId: this.wellId,
          wellBoreName: this.wellboreId,
          depth: dmea,
          values: {
            rop: rop,
            rpm: rpm,
            wob: wob,
            mfi: mfi,
            bitSize: bitSize,
            'mud density': inputData['mud density'],
            totalrpm: totalrpm,
            mudmotorrpm: mudmotorrpm,
            dexponent: dexponent,
            isnew: false
          },

        };
        outputList.push(outputData);
      });
      return outputList;
    },
    editedData(obj) {
      this.updatedRows.push(obj)
      console.log(obj, 'object value')
    },
    onClickNoFordelete() {
      this.showdeletePopup = false
    },
    async onClickYesFordelete() {
      this.$store.dispatch("data/startLoading");
      setTimeout(async () => {

        this.showdeletePopup = false;
        console.log('coming to delete', this.data_points[this.startIndex + this.delteIndex]);

        //if its not saved db ,removed locally

        if (this.data_points[this.startIndex + this.delteIndex].isnew) {
          this.updatedRows = this.updatedRows.filter((eachone) => {
            return eachone.dmea !== this.data_points[this.startIndex + this.delteIndex].dmea;
          });
          console.log(this.updatedRows);
          this.data_points.splice(this.startIndex + this.delteIndex, 1);
          this.calculatePagedata(this.currentPage);
          this.tablerefreshkey += 124;
          this.$store.dispatch("data/stopLoading");
        } else {

          // if it saved in db,deleting through api
          console.log(parseFloat(this.data_points[this.startIndex + this.delteIndex].dmea), 'dmea value')
          let payload1 = {
            data: {
              "wellId": this.wellId,
              "wellBoreName": this.wellboreId,
              "depth": parseFloat(this.data_points[this.startIndex + this.delteIndex].dmea)
            },
            headers: {
              'Content-Type': 'application/json'
            }
          };

          try {
            let response = await dexponentService.dexponentDelete(payload1)
            this.completeDataFromSearch = this.completeDataFromSearch.filter((eachone) => {
              return eachone.depth !== parseFloat(this.data_points[this.startIndex + this.delteIndex].dmea);
            });
            this.graphData()
            this.refreshXyCanvasKey += 13
            console.log(this.updatedRows);
            this.data_points.splice(this.startIndex + this.delteIndex, 1);
            this.calculatePagedata(this.currentPage);
            this.tablerefreshkey += 124;
            this.$store.dispatch("data/stopLoading");

          } catch (error) {
            console.log(error, 'error')
            this.$store.dispatch("data/stopLoading");
          }
        }


      }, 0)



    },
    deleteButtonClicked(index) {
      this.showdeletePopup = true
      this.delteIndex = index
      console.log('delete button clicked', index)

    },

    async getserachData() {
      let payload1 = {
        "wellId": this.wellId,
        "wellBoreName": this.wellboreId
      };
      try {
        let response = await dexponentService.dexponentSearch(payload1)

        console.log(response, 'frpm resp')

        this.completeDataFromSearch = response.data


        const outputList = [];
        response.data.forEach(inputData => {
          const { depth, values } = inputData;

          const outputData = {
            dmea: depth,
            ...values,

          };
          outputList.push(outputData);
        });

        console.log(outputList, 'output list')
        this.data_points = outputList;
        this.updatedRows = []
        this.data_points = this.data_points.sort((a, b) => a.dmea - b.dmea);
        console.log(this.data_points, 'data points')
        this.calculatePagedata(this.currentPage);
        this.tablerefreshkey += 34
        this.graphData()
        this.refreshXyCanvasKey += 1

      } catch (error) {
        console.log(error, 'error')
      }
    },

    //adding a new row in table 
    buttonclicked(obj) {
      console.log('add button clicked cam', obj)
      this.data_points.push(obj)
      this.updatedRows.push(obj)
      this.data_points = this.data_points.sort((a, b) => a.dmea - b.dmea);
      console.log(this.data_points, 'data points')
      this.calculatePagedata(this.currentPage);
      this.tablerefreshkey += 12

    },

    //units of mnemonics which are shown in table

    async getDisplayUnit(mnemonic) {

      let mnemonic_groups = {};
      let logDetails = await helperServices.getIndexDetails(this.wellId);
      if (logDetails.data.logs[this.logType]) {

        let all_mnemonics = logDetails.data.logs[this.logType].mnemonics;

        let mnemonic_type = "UNPREFERRED";
        let displayUnitObject = { 'mnemonic': mnemonic, 'value': { display_unit: 'undefined' } }
        for (let i in all_mnemonics) {
          if (all_mnemonics[i][mnemonic]) {
            mnemonic_type = i;
            mnemonic_groups[i] = [];
            break;
          }
        }
        if (!this.mnemonic_groups[mnemonic_type])
          this.mnemonic_groups[mnemonic_type] = [];
        if (this.mnemonic_groups[mnemonic_type].indexOf(mnemonic) == -1)
          this.mnemonic_groups[mnemonic_type] = [
            ...this.mnemonic_groups[mnemonic_type],
            mnemonic,
          ];
        console.log("get mnemonic type", mnemonic_type)
        if (mnemonic_type != 'UNPREFERRED') {
          displayUnitObject = {
            'mnemonic': mnemonic,
            'value': all_mnemonics[mnemonic_type][mnemonic]
          }
        }



        return displayUnitObject;
      } else {
        return {
          'mnemonic': '',
          'value': ''

        }
      }
    },
    changed_depth_to_print_per_page() {
      if (
        this.depth_to_print_per_page >
        this.print_end_depth - this.print_start_depth
      ) {
        this.depth_to_print_per_page = null;
        this.$toast.clear();
        this.$toast.error(
          `Depth per page should not be greater than ${this.print_end_depth - this.print_start_depth
          }`,
          {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          }
        );
      }
    },
    changed_print_end_depth() {
      if (this.print_end_depth <= this.print_start_depth) {
        this.print_end_depth = null;
        this.$toast.clear();
        this.$toast.error(`End range should be greater than the start range`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    async emitimages(applyHeight, applyWidth, leftPos, topPos) {
      console.log(
        "emit function triggered",
        applyHeight,
        applyWidth,
        leftPos,
        topPos
      );
      let printWindow = window.open(
        "testing",
        "testing",
        `height=${applyHeight}, width=${applyWidth + 200
        }, left=${leftPos},top=${topPos}, title=""`
      );

      printWindow.document.write("<html>");
      printWindow.document.write(
        `<head><style>
          .header {
        display: none;
      }
                </style></head><body>`
      );
      printWindow.document.write(
        document.querySelector(`#dexpo_canvas_parent${this.printIndex}`)
          .innerHTML
      );
      printWindow.document.write("</body></html>");
      printWindow.addEventListener("afterprint", async (event) => {
        printWindow.close();
      });

      printWindow.document.close();
      await this.printInWindow(printWindow);
      this.refreshkey += 2;
      this.emitprintButton();
    },
    async printInWindow(printWindow) {
      return new Promise((resolve) => {
        printWindow.onload = async () => {
          await printWindow.print();
          resolve();
        };
      });
    },
    emitprintButton() {
      console.log("coming to emitss");
      this.NumberOfPrintDivs = [];
      this.printButtonClicked = false;
      this.printing_page = false;
      this.open_printing_options_page = false;
      this.print_start_depth = null;
      this.print_end_depth = null;
      this.depth_to_print_per_page = null;
    },
    async print_page() {
      if (this.completeDataFromSearch) {

        this.printIndex += 1;
        this.printButtonClicked = true;
        this.printing_page = true;
        this.NumberOfPrintDivs = [];

        setTimeout(async () => {
          let startDepth = this.print_start_depth;
          let endDepth = this.print_end_depth;
          let perPage = this.depth_to_print_per_page;

          this.NumberOfPrintDivs = [];
          for (
            let page_starting_depth = startDepth;
            page_starting_depth < endDepth;
            page_starting_depth += perPage
          ) {
            let primarDataSet = [];
            const xy = this.completeDataFromSearch.map((eachone) => {
              const object = {
                name: 'dexponent',
                x: parseFloat(eachone.values.dexponent),
                y: eachone.depth
              };
              return object;
            });
            const filteredXY = xy.filter(obj => obj.y >= page_starting_depth && obj.y <= page_starting_depth + perPage);
            console.log(xy, 'xy from graphdata')
            let det = {
              width: 2,
              color: "red",
              data: filteredXY,
            };
            primarDataSet = [det]
            console.log(primarDataSet, 'graphprime')



            this.NumberOfPrintDivs.push({
              miny: page_starting_depth,
              maxy: page_starting_depth + perPage,
              data: primarDataSet,
            });
          }
          console.log(this.NumberOfPrintDivs, "number of divs");
          for (
            let page_starting_depth = startDepth;
            page_starting_depth < endDepth;
            page_starting_depth += perPage
          ) {
            let endDepthPerPage = page_starting_depth + perPage;
            console.log(this.NumberOfPrintDivs, "page start depth");
            console.log(endDepthPerPage, "end depth per page");
          }
        }, 0);
      } else {
        this.$toast.clear();
        this.$toast.error(`no data found`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    close_print_options_popup() {
      this.printButtonClicked = false;
      this.printing_page = false;
      this.open_printing_options_page = false;
      this.print_start_depth = null;
      this.print_end_depth = null;
      this.depth_to_print_per_page = null;
    },
    open_print_options_popup() {
      console.log("print bbutton clicked");
      this.open_printing_options_page = true;
    },

    async getTableData() {
      this.$store.dispatch("data/startLoading");

      let payload = {
        fields: [],
        well_id: this.wellId,
        wellbore_name: this.wellboreId,
      };
      console.log(this.wellboreId, 'after changing')
      payload.fields.push("rop", "wob", "mfi", "rpm", "dexponent", "mud density", "bitsize", "mudmotorrpm", "totalrpm");
      const dmea = await this.getDisplayUnit('dmea')
      const rop = await this.getDisplayUnit('rop');
      const wob = await this.getDisplayUnit('wob');
      const mfi = await this.getDisplayUnit('mfi');
      const bitsize = await this.getDisplayUnit('bitsize')
      // this.bitsizeCategory = bitsize.value.category

      const rpm = await this.getDisplayUnit('rpm');
      const totalrpm = await this.getDisplayUnit('totalrpm')
      const mudmotorrpm = await this.getDisplayUnit('mudmotorrpm')
      const muddensity = await this.getDisplayUnit('mud density')

      console.log(this.wellboreId, 'after after changing')
      let payload1 = {
        "wellId": this.wellId,
        "wellBoreName": this.wellboreId
      };


      try {
        let response = await dexponentService.dexponentSearch(payload1)
        this.completeDataFromSearch = response.data
        console.log(response, 'frpm resp')
        let outputList = [];
        if (response.data) {
          response.data.forEach(inputData => {
            const { depth, values } = inputData;

            const outputData = {
              dmea: depth,
              ...values,

            };
            outputList.push(outputData);
          });

          console.log(outputList, 'output list')
        } else {
          this.data_points = []
          this.maxYFixed = 50
          this.maxY = 50
          this.depthDifference = 50
          this.refreshXyCanvasKey += 12

        }


        this.data_points = outputList;
        let depthValue = 0
        if (this.data_points.length > 0) {
          this.minYFixed = this.data_points[0].dmea
          const endrow = this.data_points[this.data_points.length - 1]
          depthValue = endrow.dmea > 50 ? endrow.dmea : 50
          this.maxYFixed = depthValue
          const minValue = this.data_points.reduce((min, obj) => obj.dexponent < min ? obj.dexponent : min, this.data_points[0].dexponent);
          if (minValue != null) {
            this.minX = parseFloat(minValue)
          }
        }



        let sequence1 = [
          {
            field: "dmea",
            label: "Depth" + " " + dmea.value.display_unit,
          },
          {
            field: "rop",
            label: 'rop' + " " + rop.value.display_unit,
          },
          {
            field: "wob",
            label: 'wob' + " " + wob.value.display_unit,
          },
          {
            field: "mfi",
            label: "mfi" + " " + mfi.value.display_unit,
          },

          {
            field: "rpm",
            label: 'rpm' + " " + rpm.value.display_unit,
          },
          {
            field: "mud density",
            label: "M.WT" + " " + muddensity.value.display_unit,
          },
          {
            field: "bitSize",
            label: 'holedia' + " " + bitsize.value.display_unit,
          },
          {
            field: "mudmotorrpm",
            label: "MM.RPM" + " " + mudmotorrpm.value.display_unit,
          },
          {
            field: "totalrpm",
            label: "T.RPM" + " " + totalrpm.value.display_unit,
          },
          {
            field: "dexponent",
            label: "D-exp" + " " + 'undefined',
          },

        ];

        this.column = sequence1.map((eachone) => ({
          field: eachone.field, // Assuming "md" stands for "Measurement Depth" and you want lowercase field names
          label: eachone.label, // Adding "ft" to the label
        }));


        this.$store.dispatch("data/stopLoading");
      } catch (error) {
        console.log('error', error)
        this.$store.dispatch("data/stopLoading");
      }

    },
    async calculatePagedata(current) {
      console.log("____pagination__data___current_1", current);

      // Increment current by 1 if it's initially 0
      if (current === 0) {
        current = 1;
      }

      let startInd = (current - 1) * this.perPageCount;
      this.startIndex = startInd
      let lastInd = startInd + this.perPageCount;

      if (lastInd > this.data_points.length) {
        lastInd = this.data_points.length;
      } else if (startInd < 0) {
        startInd = 0;
      }

      this.pagenated_data_points = this.data_points.slice(startInd, lastInd);
      console.log(this.pagenated_data_points, "pagenation working");
      console.log(
        "____pagination__data___current",
        current,
        this.perPageCount,
        "_",
        lastInd
      );
      console.log("____pagination__data___before", startInd, "_", lastInd);
    },
    async onPageChange(page) {

      this.$store.dispatch("data/startLoading");



      setTimeout(async () => {
        console.log("page changed here", page);
        this.currentPage = page;
        this.tablerefreshkey += 23

        await this.calculatePagedata(page);
        this.$store.dispatch("data/stopLoading");
      }, 0)

    },

    async onChangeValue(e) {
      const object = this.$store.state.rect.rects[this.idx].DExponent;
      await this.commonDispatcher("DExponent", {
        ...object,
        showTable: e.target.checked,
      });

      if (e.target.checked) {
        const originalNumber = this.cWidth;
        const sixtyPercent = Math.floor(originalNumber * 0.5);
        this.widthOfCanvas = sixtyPercent;
        this.refreshXyCanvasKey += 21;
        // await this.getTableData();
        this.calculatePagedata(this.currentPage);
      } else {
        this.widthOfCanvas = this.cWidth - 40;
      }
      // this.filterData();
      this.graphData()
      this.refreshXyCanvasKey += 3;
    },
    async commonDispatcher(keyToDispatch, val) {
      setTimeout(() => {
        this.$store.dispatch("rect/swabSurgeRealTimedata", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: val,
          field: keyToDispatch,
        });
      }, 300);
    },
    filterData() {
      let xy = [];
      let xy1 = [];
      this.primarDataSet = [];
      this.secondaryDataSet = [];

      if (
        this.primaryXaxisFullName &&
        this.primaryXaxisFullName.value &&
        this.completeData[this.primaryXaxisFullName.value]
      ) {
        for (let i = 0; i < this.completeData["dmea"].length; i++) {
          if (this.logType === "time") {
            xy.push({
              x: this.completeData["time"][i],
              y: this.completeData[this.primaryXaxisFullName.value][i],
              name: this.primaryXaxisFullName?.value,
            });
            xy1.push({ x: this.completeData["time"][i], y: null, name: "" });
          } else {
            if (
              this.minY <= this.completeData["dmea"][i] &&
              this.maxY >= this.completeData["dmea"][i]
            ) {
              xy.push({
                y: this.completeData["dmea"][i],
                x: this.completeData[this.primaryXaxisFullName.value][i],
                name: this.primaryXaxisFullName?.value,
              });
            }
          }
        }
        let det = {
          width: 2,
          color: "red",
          data: xy,
        };
        // this.primarDataSet.push(det);
      }
      console.log(this.primarDataSet, "primary dataset");
    },

    mousescroll(event) {
      // console.log(event.deltaY, "from scroll");

      if (!this.isZoomEnable) {
        if (this.mousewheeltimeout) {
          clearTimeout(this.mousewheeltimeout);
        }
        this.mousewheeltimeout = setTimeout(() => {
          let scroll_amount =
            (this.maxY - this.minY) / 10 > 0
              ? parseInt((this.maxY - this.minY) / 10)
              : 10;

          if (event.deltaY > 0) {
            // Scrolling down
            if (
              this.minY + scroll_amount < this.maxYFixed &&
              this.maxY + scroll_amount <= this.maxYFixed
            ) {
              this.minY += scroll_amount;
              this.maxY += scroll_amount;
              // this.filterData();
              this.graphData()
              this.refreshXyCanvasKey += 12;
              // console.log("coming to scrolldown event", this.minY, this.maxY);
            }
          } else if (event.deltaY < 0) {
            console.log('coming to upscroll')
            if (this.statusCheck) {
              console.log('coming to upscroll12')
              clearInterval(this.statusCheck)
              this.isWellLive = false
            }
            // Scrolling up
            if (this.minY - scroll_amount > this.minYFixed) {
              this.minY -= scroll_amount;
              this.maxY -= scroll_amount;
              // this.filterData();
              this.graphData()
              this.refreshXyCanvasKey += 13;
              // console.log("coming to up event", this.minY, this.maxY);
            }
          }

          clearTimeout(this.mousewheeltimeout);
        }, 100);
      }

      // console.log(this.minY,this.maxY,'from scroll')
    },

    onDoubleClickOfDexponent() {
      if (!this.open_printing_options_page) {
        this.$emit("dexponentEmits");
        console.log("function calling");
      }
    },
    async xCoordsAfterZoom(minX, maxX) {
      this.draggedRangeElement = true;
      if (this.logType == "time") {
        let Min_forTimediffTime = moment(minX).unix() * 1000;
        let Max_forTimediffTime = moment(maxX).unix() * 1000;
        this.timeDifference = Number(
          (Max_forTimediffTime - Min_forTimediffTime) / (60 * 60 * 1000)
        ).toFixed(2);
      } else {
        this.minX = parseFloat(Number(minX).toFixed(2));
        this.maxX = parseFloat(Number(maxX).toFixed(2));
        console.log('coming to dexponent',)
        // this.depthDifference = Number(maxX - minX).toFixed(2);
      }
    },
    async startManualDepthValidation(event) {
      this.$store.dispatch("data/startLoading");

      setTimeout(() => {
        clearInterval(this.statusCheck)
        console.log(this.maxY, "maxy");
        const inputValue = +event.target.value;
        if (inputValue >= this.maxY) {
          event.target.value = this.minY;
          this.$toast.clear();
          this.$toast.error(`Start depth should not be greater than end depth`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        } else if (inputValue < this.minYFixed) {
          event.target.value = this.minY;
          this.$toast.clear();
          this.$toast.error(`Start depth should not be less than ${this.minYFixed}`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });

        } else {

          this.minY = +event.target.value;
          // this.filterData();
          this.graphData()
          this.refreshXyCanvasKey += 5;
        }

        this.depthDifference = this.maxY - this.minY;
        this.rangeValue = this.maxY;
        this.$store.dispatch("data/stopLoading");
      }, 0)
      // this.draggedRangeElement = true;
      // await this.selectedDifference(this.maxY - this.minY);
    },
    async endManualDepthValidation(event) {
      this.$store.dispatch("data/startLoading");

      setTimeout(() => {
        clearInterval(this.statusCheck)
        const inputValue = +event.target.value;
        if (inputValue <= this.minY) {
          event.target.value = this.maxY;
          this.$toast.clear();
          this.$toast.error(`End depth should be greater than start depth`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        } else if (this.maxYFixed < inputValue) {
          event.target.value = this.maxY;
          this.$toast.clear();
          this.$toast.error(
            `End depth should not be greater than ${this.maxYFixed}`,
            { duration: "2000", position: "top", pauseOnHover: true }
          );
        } else {
          this.maxY = inputValue;
          // this.filterData();
          this.graphData()
          this.refreshXyCanvasKey += 10;
        }
        this.depthDifference = this.maxY - this.minY;
        this.rangeValue = this.maxY;
        this.$store.dispatch("data/stopLoading");
      }, 0)
      // this.draggedRangeElement = true;
      // await this.selectedDifference(this.maxY - this.min);
    },
    async wellStatusCheck() {
      try {
        let wellDetails = await apiService.WellServices.getWellDetails({
          well_id: this.wellId,
          well_borename: this.wellboreId,
          customer: this.$store.state.data.customer,
          log: this.logType,
        });
        if (
          (wellDetails?.data?.well_status == 4 ||
            wellDetails?.data?.well_status == 104)
        ) {
          this.isWellLive = true;
        } else {
          console.log('is not live')
          this.isWellLive = false;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async selectedDifference(val) {
      this.$store.dispatch("data/startLoading");

      // if(this.maxY)

      setTimeout(async () => {
        if (this.maxY > val) {
          this.minY = this.maxY - val;
          // this.filterData();
          await this.graphData()
          this.refreshXyCanvasKey += 13;
          this.depthDifference = val;
        } else {
          this.maxY = val + this.minY;
          // this.filterData();
          await this.graphData()
          this.refreshXyCanvasKey += 14;
          this.depthDifference = val;
        }
        this.$store.dispatch("data/stopLoading");
      }, 0)

      // this.$store.dispatch("data/startLoading");
      // if (this.logType == "time") {
      //   this.timeDifference = val;
      // } else {
      //   this.depthDifference = val;
      // }
      // if (
      //   this.logType == "time" &&
      //   !this.draggedRangeElement &&
      //   this.isWellLive
      // ) {
      //   this.draggedRangeElement = true;
      //   this.timeDifference = val;
      //   if (this.primaryXaxisFullName && this.primaryXaxisFullName.value) {
      //     await this.settingDataAfterTimeDifference(
      //       parseFloat(this.rangeValue),
      //       this.primaryXaxisFullName.value
      //     );
      //   }
      //   if (this.secondaryYaxisFullName && this.secondaryYaxisFullName.value) {
      //     await this.settingDataAfterTimeDifference(
      //       parseFloat(this.rangeValue),
      //       this.secondaryYaxisFullName.value
      //     );
      //   }
      //   this.draggedRangeElement = false;
      // } else {
      //   if (this.primaryXaxisFullName && this.primaryXaxisFullName.value) {
      //     await this.settingDataAfterTimeDifference(
      //       parseFloat(this.rangeValue),
      //       this.primaryXaxisFullName.value
      //     );
      //   }
      //   if (this.secondaryYaxisFullName && this.secondaryYaxisFullName.value) {
      //     await this.settingDataAfterTimeDifference(
      //       parseFloat(this.rangeValue),
      //       this.secondaryYaxisFullName.value
      //     );
      //   }
      // }
      // await this.buildSVG();
      // this.$store.dispatch("data/stopLoading");
    },
    async settingDataAfterTimeDifference(value, axisMnemonic) {
      try {
        let nemonicsType = await this.getMnemonicType(axisMnemonic);
        let min = 0;
        let max = value;
        if (this.logType == "time") {
          min = max - this.timeDifference * 1000 * 60 * 60;
        } else {
          min = this.minMsxTime.min;
          return;
        }
        let range = [max, min];

        await this.getdateFromDb(range, nemonicsType, axisMnemonic);
      } catch (err) {
        console.error(err);
      }
    },

    async liveClick() {
      this.timeDifference = 1;
      this.depthDifference = 50;
      if (this.maxYFixed >= this.depthDifference) {
        this.maxY = this.maxYFixed
        if (this.maxY - this.depthDifference >= 0) {
          this.minY = this.maxY - this.depthDifference
        }
      }
      await this.buildSVG();

      // this.draggedRangeElement = false;
      // let max = this.minMsxTime.max;
      // this.rangeValue = max;
      // let obj = { target: { value: "" } };
      // obj.target.value = max;


      await this.wellStatusCheck();
      if (this.isWellLive) {
        if (this.statusCheck) {
          clearInterval(this.statusCheck);
        }
        await this.commonWellStatus()
      }
      // if (
      //   this.logType == "time" &&
      //   !this.draggedRangeElement &&
      //   this.isWellLive
      // ) {
      //   this.draggedRangeElement = true;
      //   await this.maxXSliderRange(obj);
      //   this.draggedRangeElement = false;
      // } else {
      //   await this.maxXSliderRange(obj, "fromLive");
      // }
      // await this.getRealtimeData();
    },
    async maxXSliderRange(event, from) {
      this.$store.dispatch("data/startLoading");
      if (this.primaryXaxisFullName && this.primaryXaxisFullName.value) {
        await this.settingDataAfterSliderDrag(
          event.target.value,
          this.primaryXaxisFullName.value,
          from
        );
      }
      if (this.secondaryYaxisFullName && this.secondaryYaxisFullName.value) {
        await this.settingDataAfterSliderDrag(
          event.target.value,
          this.secondaryYaxisFullName.value,
          from
        );
      }
      await this.buildSVG();
      this.$store.dispatch("data/stopLoading");
    },
    async settingDataAfterSliderDrag(value, axisMnemonic, from) {
      try {
        let nemonicsType = await this.getMnemonicType(axisMnemonic);
        let min = 0;
        let max = value;
        this.rangeValue = value;
        if (from && from == "fromLive") {
          this.draggedRangeElement = false;
        } else {
          this.draggedRangeElement = true;
        }
        if (this.logType == "time") {
          min = max - this.timeDifference * 1000 * 60 * 60;
        } else {
          min = this.minMsxTime.min;
          return;
        }
        let range = [max, min];
        await this.getdateFromDb(range, nemonicsType, axisMnemonic);
      } catch (err) {
        console.error(err);
      }
    },
    memomenucsChangesHandler(menomenics) {
      //unsuscribe & suscribe websocket
      this.takeOffSocket();
    },
    resetZoomHandler() {
      console.log("resetZoom");
      this.$refs.canvasXyAxisGraph.resetZoom();
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
    },
    processResult(sResult) {
      console.log("processResult ", sResult);
      console.log("processResult widgetId", this.widgetId);
    },

    windowToggle(name) {
      this.classes = name;
    },
    dark() {
      document.querySelector("nav").classList.add("dark-mode");
      document.querySelector("div").classList.add("dark-mode");
      document.querySelector("button").classList.add("dark-mode");
      document.querySelector("a").classList.add("text-red-600");
      this.darkMode = true;
      this.$emit("dark");
      console.log("Dark Mode");
    },
    light() {
      document.querySelector("nav").classList.remove("dark-mode");
      document.querySelector("div").classList.remove("dark-mode");
      document.querySelector("button").classList.remove("dark-mode");
      document.querySelector("a").classList.remove("text-red-600");
      this.darkMode = false;
      this.$emit("light");
    },
    async buildSVG() {
      await this.graphData()

      // if (
      //   this.primaryXaxisFullName &&
      //   this.primaryXaxisFullName.value &&
      //   this.completeData[this.primaryXaxisFullName.value]
      // ) {
      //   this.maxX = parseFloat(
      //     max(this.completeData[this.primaryXaxisFullName.value], (d) => d)
      //   );
      //   this.minX = parseFloat(
      //     min(this.completeData[this.primaryXaxisFullName.value], (d) => d)
      //   );

      //   if (this.logType === "time") {
      //     this.minX = min(this.completeData["time"], (d) => d);
      //     this.maxX = max(this.completeData["time"], (d) => d);
      //     if (!this.draggedRangeElement) {
      //       this.rangeValue = this.maxX;
      //     }
      //   } else {
      //     if (!this.draggedRangeElement) {
      //       this.minY = parseFloat(min(this.completeData["dmea"], (d) => d));

      //       this.maxY = parseFloat(max(this.completeData["dmea"], (d) => d));
      //       this.minY = this.maxY - this.depthDifference;
      //       this.rangeValue = this.maxY;
      //     } else {
      //       this.maxY = this.rangeValue;
      //       this.minY = this.maxY - this.depthDifference;
      //     }
      //   }
      // }
      // if (
      //   this.secondaryYaxisFullName &&
      //   this.secondaryYaxisFullName.value &&
      //   this.completeData[this.secondaryYaxisFullName.value]
      // ) {
      //   this.minY1 = min(
      //     this.completeData[this.secondaryYaxisFullName.value],
      //     (d) => d
      //   );
      //   this.maxY1 = max(
      //     this.completeData[this.secondaryYaxisFullName.value],
      //     (d) => d
      //   );
      // }

      // let xy = [];
      // let xy1 = [];
      // this.primarDataSet = [];
      // this.secondaryDataSet = [];

      // if (
      //   this.primaryXaxisFullName &&
      //   this.primaryXaxisFullName.value &&
      //   this.completeData[this.primaryXaxisFullName.value]
      // ) {
      //   for (let i = 0; i < this.completeData["dmea"].length; i++) {
      //     // xy[i].y = this.completeData[this.primaryXaxisFullName.value][i];
      //     // xy[i].x = this.completeData['dmea'][i];

      //     if (this.logType === "time") {
      //       xy.push({
      //         x: this.completeData["time"][i],
      //         y: this.completeData[this.primaryXaxisFullName.value][i],
      //         name: this.primaryXaxisFullName?.value,
      //       });
      //       xy1.push({ x: this.completeData["time"][i], y: null, name: "" });
      //     } else {
      //       if (
      //         this.minY <= this.completeData["dmea"][i] &&
      //         this.maxY >= this.completeData["dmea"][i]
      //       ) {
      //         xy.push({
      //           y: this.completeData["dmea"][i],
      //           x: this.completeData[this.primaryXaxisFullName.value][i],
      //           name: this.primaryXaxisFullName?.value,
      //         });
      //         // xy1.push({ y: this.completeData["dmea"][i], x: null, name: "" });
      //       }
      //     }
      //   }
      //   let det = {
      //     width: 2,
      //     color: "red",
      //     data: xy,
      //   };
      //   this.primarDataSet.push(det);
      // }
      // console.log(this.primarDataSet, "primary dataset");

      // // if (this.isSecondaryAxis) {
      // //   if (
      // //     this.secondaryYaxisFullName &&
      // //     this.secondaryYaxisFullName.value &&
      // //     this.completeData[this.secondaryYaxisFullName.value]
      // //   ) {
      // //     if (this.logType == "time") {
      // //       for (
      // //         let i = 0;
      // //         i < this.completeData[this.secondaryYaxisFullName.value].length;
      // //         i++
      // //       ) {
      // //         xy1[i].y =
      // //           this.completeData[this.secondaryYaxisFullName.value][i];
      // //         xy1[i].name = this.secondaryYaxisFullName?.value;
      // //       }
      // //     } else {
      // //       for (let i = 0; i < xy1.length; i++) {
      // //         let index = this.completeData["dmea"].findIndex(
      // //           (each) => each == xy1[i].x
      // //         );
      // //         if (index > 0) {
      // //           xy1[i].y =
      // //             this.completeData[this.secondaryYaxisFullName.value][index];
      // //           xy1[i].name = this.secondaryYaxisFullName?.value;
      // //         }
      // //       }
      // //     }
      // //     let det = {
      // //       width: 2,
      // //       color: "blue",
      // //       data: xy1,
      // //     };
      // //     this.secondaryDataSet.push(det);
      // //   }
      // // }
    },
    // async getRealtimeData() {
    //   let topic = this.topic;
    //   let wells = this.$store.state.data.wells;
    //   let well = wells[this.wellId];
    //   // await this.wellStatusCheck();
    //   let fields = [];
    //   if (this.primaryYaxisFullName && this.primaryYaxisFullName.value) {
    //     fields.push(this.primaryYaxisFullName.value);
    //   }
    //   if (this.primaryXaxisFullName && this.primaryXaxisFullName.value) {
    //     fields.push(this.primaryXaxisFullName.value);
    //   }
    //   if (this.secondaryYaxisFullName && this.secondaryYaxisFullName.value) {
    //     fields.push(this.secondaryYaxisFullName.value);
    //   }
    //   let topicExists = this.$store.state.live.isSubscribed[topic];
    //   if (well) {
    //     if (this.wellId && this.wellboreId && this.logType) {
    //       this.$socket.emit("subscribe", {
    //         logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
    //         topic: this.topic,
    //         fields: fields,
    //         id: this.id,
    //         clientId: this.$clientId,
    //         logType: this.logType,
    //       });
    //       if (!topicExists) {
    //         this.$store.dispatch("live/setSubscriptionFlag", {
    //           topic,
    //           flag: true,
    //         });
    //       }

    //       this.sockets.subscribe(this.$clientId, (sResult) => {
    //         let result = sResult[this.id];
    //         if (result) {
    //           if (!this.isWellLive) {
    //             this.isWellLive = true;
    //           }
    //           //get minmax
    //           if (
    //             this.primaryXaxisFullName &&
    //             this.primaryXaxisFullName.value
    //           ) {
    //             // console.log('live result', result)
    //             if (this.primarDataSet.length > 0) {
    //               let countX = result[this.logType].length;
    //               let newDataSet = this.primarDataSet[0].data.slice(
    //                 countX,
    //                 this.primarDataSet[0].data.length
    //               );
    //               result[this.logType].forEach((e, i) => {
    //                 newDataSet.push({
    //                   x: e,
    //                   y: result[this.primaryXaxisFullName.value][i],
    //                   name: this.primaryXaxisFullName.value,
    //                 });
    //               });
    //               if (!this.draggedRangeElement) {
    //                 if (this.logType == "time") {
    //                   this.maxX = max(newDataSet, (d) => d.x);
    //                   this.minX = min(newDataSet, (d) => d.x);
    //                   // this.minX = this.maxX - this.timeDifference * 1000 * 60 * 60;
    //                   // newDataSet = newDataSet.filter((each)=>{
    //                   //   return this.minX <= each.x && this.maxX > each.x
    //                   // })
    //                   this.minMsxTime.max = this.maxX;
    //                   this.rangeValue = this.maxX;
    //                   let Min_forTimediffTime = moment(this.minX).unix() * 1000;
    //                   let Max_forTimediffTime = moment(this.maxX).unix() * 1000;
    //                   this.timeDifference = Number(
    //                     (Max_forTimediffTime - Min_forTimediffTime) /
    //                     (60 * 60 * 1000)
    //                   ).toFixed(2);
    //                   this.minY = parseFloat(min(newDataSet, (d) => d.y));
    //                   this.maxY = parseFloat(max(newDataSet, (d) => d.y));
    //                   newDataSet.sort((a, b) => a.x - b.x);
    //                   this.primarDataSet[0].data = newDataSet;
    //                 } else {
    //                   let newDepthData = [];
    //                   this.maxX = parseFloat(
    //                     max(result[this.logType], (d) => d)
    //                   );
    //                   this.minX = parseFloat(this.maxX - this.depthDifference);
    //                   if (this.completeData["dmea"].length) {
    //                     let minValue = min(result[this.logType], (d) => d);
    //                     for (
    //                       let depthIndex = 0;
    //                       depthIndex < this.completeData["dmea"].length;
    //                       depthIndex++
    //                     ) {
    //                       if (
    //                         this.completeData["dmea"][depthIndex] >= minValue
    //                       ) {
    //                         break;
    //                       }
    //                       if (
    //                         this.minX <= this.completeData["dmea"][depthIndex]
    //                       ) {
    //                         newDepthData.push({
    //                           y: this.completeData["dmea"][depthIndex],
    //                           x: this.completeData[
    //                             this.primaryXaxisFullName.value
    //                           ][depthIndex],
    //                           name: this.primaryXaxisFullName.value,
    //                         });
    //                       }
    //                     }
    //                   }
    //                   result[this.logType].forEach((e, i) => {
    //                     newDepthData.push({
    //                       x: e,
    //                       y: result[this.primaryXaxisFullName.value][i],
    //                       name: this.primaryXaxisFullName.value,
    //                     });
    //                   });
    //                   this.minMsxTime.max = this.maxY;
    //                   this.rangeValue = this.maxY;
    //                   this.minY = parseFloat(min(newDepthData, (d) => d.y));
    //                   this.maxY = parseFloat(max(newDepthData, (d) => d.y));
    //                   this.primarDataSet[0].data = newDepthData;
    //                 }

    //                 // this.minMsxTime.max = this.maxX;
    //                 // this.rangeValue = this.maxX
    //                 // this.minY = min(newDataSet, (d) => d.y);
    //                 // this.maxY = max(newDataSet, (d) => d.y);
    //                 // this.primarDataSet[0].data = newDataSet;
    //               }
    //             }
    //           }
    //           if (
    //             this.secondaryYaxisFullName &&
    //             this.secondaryYaxisFullName.value &&
    //             this.isSecondaryAxis
    //           ) {
    //             if (this.secondaryDataSet.length > 0) {
    //               let countX = result[this.logType].length;
    //               let newDataSet = this.secondaryDataSet[0].data.slice(
    //                 countX,
    //                 this.secondaryDataSet[0].data.length
    //               );
    //               result[this.logType].forEach((e, i) => {
    //                 newDataSet.push({
    //                   x: e,
    //                   y: result[this.secondaryYaxisFullName.value][i],
    //                   name: this.secondaryYaxisFullName.value,
    //                 });
    //               });
    //               if (!this.draggedRangeElement) {
    //                 if (this.logType == "time") {
    //                   this.maxX = parseFloat(max(newDataSet, (d) => d.x));
    //                   this.minX = parseFloat(min(newDataSet, (d) => d.x));
    //                   this.minY1 = min(newDataSet, (d) => d.y);
    //                   this.maxY1 = max(newDataSet, (d) => d.y);
    //                   newDataSet.sort((a, b) => a.x - b.x);
    //                   this.secondaryDataSet[0].data = newDataSet;
    //                 } else {
    //                   let newDepthData = [];
    //                   this.maxX = parseFloat(
    //                     max(result[this.logType], (d) => d)
    //                   );
    //                   this.minX = parseFloat(this.maxX - this.depthDifference);
    //                   if (this.completeData["dmea"].length) {
    //                     let minValue = min(result[this.logType], (d) => d);
    //                     for (
    //                       let depthIndex = 0;
    //                       depthIndex < this.completeData["dmea"].length;
    //                       depthIndex++
    //                     ) {
    //                       if (
    //                         this.completeData["dmea"][depthIndex] >= minValue
    //                       ) {
    //                         break;
    //                       }
    //                       if (
    //                         this.minX <= this.completeData["dmea"][depthIndex]
    //                       ) {
    //                         newDepthData.push({
    //                           y: this.completeData["dmea"][depthIndex],
    //                           x: this.completeData[
    //                             this.secondaryYaxisFullName.value
    //                           ][depthIndex],
    //                           name: this.secondaryYaxisFullName.value,
    //                         });
    //                       }
    //                     }
    //                   }
    //                   result[this.logType].forEach((e, i) => {
    //                     newDepthData.push({
    //                       x: e,
    //                       y: result[this.secondaryYaxisFullName.value][i],
    //                       name: this.secondaryYaxisFullName.value,
    //                     });
    //                   });

    //                   this.minY1 = min(newDepthData, (d) => d.y);
    //                   this.maxY1 = max(newDepthData, (d) => d.y);
    //                   this.secondaryDataSet[0].data = newDepthData;
    //                 }
    //                 // this.minY1 = min(newDataSet,(d) => d.y);
    //                 // this.maxY1 = max(newDataSet,(d) => d.y);
    //                 // this.secondaryDataSet[0].data = newDataSet;
    //               }
    //             }
    //           }
    //         }
    //       });
    //     }
    //     topicExists = this.$store.state.live.isSubscribed[topic];
    //   }
    // },
    takeOffSocket() {
      if (this.$socket) {
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.id,
          clientId: this.$clientId,
        });
        this.sockets.unsubscribe(this.$clientId);
      }
      this.stopUpdation = false;
    },
    async getTagValue(displayDtls, title) {
      if (this.table) {
        let valueAt = "end";
        let dconfig = {};
        let wells = this.$store.state.data.wells;
        let well = wells[this.wellId];
        if (well) {
          let wellbore = well.wellbores[this.wellboreId];
          let log = wellbore["logs"][this.logType];
          this.logId = log.id;
          dconfig.field = title || this.title;
          dconfig.logId = this.logId;
          dconfig.table = this.table;
          if (this.logType === "depth") {
            dconfig.logName = "depthlog";
          }
          if (this.logType === "time") {
            dconfig.logName = "timelog";
          }
          let url = await getLatestValueUrl(dconfig);
          let result = await executeQuery(url);
          if (result != null) {
            this.numericValue = result.data[0]
              ? result.data[0][dconfig.field]
              : "";
            if (this.logType === "depth") {
              this.tooltipValue = result.data[0]["dept"];
            }
            if (this.logType === "time") {
              this.tooltipValue = new Date(
                result.data[0]["time"]
              ).toLocaleString("en-US", {
                timeZone: this.time_zone,
              });
            }
          }
        } else {
          this.numericValue = "";
        }
        return this.numericValue;
      }
    },
    getNumericValue(displayDtls) {
      let value;
      let valueAt = "end";
      let titleValues = this.$store.state.data.tagValues[this.title];
      if (titleValues != null) {
        for (let i = 0; i < titleValues.length; i++) {
          if (
            titleValues[i].wellId == displayDtls.wellId &&
            titleValues[i].wellboreId == displayDtls.wellboreId
          ) {
            let logs = titleValues[i].logs;
            for (let j = 0; j < logs.length; j++) {
              if ((logs[j].logId = displayDtls.logId)) {
                let logValues = logs[j].logValues;
                value = logValues[valueAt];
                break;
              }
            }
          }
        }
      }
      return value;
    },
    async getMinMaxRange() {
      try {
        let result = null;
        if (this.logType == "time") {
          result = await apiService.WellServices.getMinMaxTime({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
          result.data.min = moment(result.data.min).unix() * 1000;
          result.data.max = moment(result.data.max).unix() * 1000;
        } else {
          result = await apiService.WellServices.getMinMaxDepth({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
          result.data.min = result.data.min;
          result.data.max = result.data.max;
        }

        return result.data;
      } catch (error) {
        console.error(error);
        return { min: 0, max: 1000 };
      }
    },
    async getdateFromDb(range, mnemonicType, mnemonicValue) {
      console.log(range);
      // this.$store.dispatch("data/startLoading");
      let payload = {
        fields: [mnemonicValue],
        well_id: this.wellId,
        wellbore_name: this.wellboreId,
        type: mnemonicType,
        topic:this.$store.state.disp?.displays[this.displayId]?.topicName
      };
      let result = null;
      if (this.logType === "time") {
        payload["from"] = parseFloat(range[0]);
        payload["to"] = parseFloat(range[1]);
        console.log("coming to logtype time");
        result = await apiService.WellServices.getTimeLogs(payload);
      } else {
        payload["end"] = parseFloat(range[0]);
        payload["start"] = parseFloat(range[1]);
        // result = await apiService.WellServices.getDepthLogs(payload);
        let payload1 = {
          "wellId": this.wellId,
          "wellBoreName": this.wellboreId
        };
        try {
          result = await dexponentService.dexponentSearch(payload1)
          this.completeDataFromSearch = result.data
          console.log(this.completeDataFromSearch, "completedata");

        } catch (error) {
          console.log(error, 'error')
        }

      }

    },
    async getMnemonicType(mnemonic) {
      // console.time("get mnemonic type", mnemonic)
      let mnemonic_groups = {};
      // let all_mnemonics =
      //   this.$store.state.disp.displays[this.displayId].mnemonics;
      let logDetails = await helperServices.getIndexDetails(this.wellId);
      let all_mnemonics = logDetails.data.logs[this.logType].mnemonics;
      console.log('this.logType', logDetails.data.logs[this.logType]);
      let mnemonic_type = "UNPREFERRED";
      for (let i in all_mnemonics) {
        if (all_mnemonics[i][mnemonic]) {
          mnemonic_type = i;
          mnemonic_groups[i] = [];
          break;
        }
      }
      if (!this.mnemonic_groups[mnemonic_type])
        this.mnemonic_groups[mnemonic_type] = [];
      if (this.mnemonic_groups[mnemonic_type].indexOf(mnemonic) == -1)
        this.mnemonic_groups[mnemonic_type] = [
          ...this.mnemonic_groups[mnemonic_type],
          mnemonic,
        ];

      return mnemonic_type;
    },
    async prepareGraphDetail(mnemonic) {
      this.$store.dispatch("data/startLoading");
      let nemonicsType = await this.getMnemonicType(mnemonic);
      console.log(mnemonic, "mnemonic from graph details");
      let min = 0;
      let max = this.minMsxTime.max;
      if (this.logType == "time") {
        min = this.minMsxTime.max - this.timeDifference * 1000 * 60 * 60;
      } else {
        min = this.minMsxTime.min;
      }
      let range = [max, min];
      try {
        await this.getdateFromDb(range, nemonicsType, mnemonic);
      } catch (err) {
        console.error(err);
      }

      //suscribe websocket

      this.buildSVG();
      this.$store.dispatch("data/stopLoading");
    },
  },
  watch: {
    bitSpecs() {
      console.log(this.bitSpecs, 'in watch bit specs')
    },

    // isWellLive(val) {
    //   if (val) {
    //     // this.realtimeData = setInterval(async () => {
    //     //   console.log('getting realtime data')
    //     //   // await this.getRealtimeDataForTable()
    //     // }, 5000)

    //   }


    // },
    cWidth(value) {
      if (this.showTable) {
        const originalNumber = value;
        const sixtyPercent = Math.floor(originalNumber * 0.5);
        this.widthOfCanvas = sixtyPercent;
        if (value / 2 < 650) {
          this.headFontSize = 11
        } else {
          this.headFontSize = 13
        }
        console.log(value / 2, 'width of canvas')
        this.refreshXyCanvasKey += 21;
      } else {
        this.widthOfCanvas = value - 40;
        this.refreshXyCanvasKey += 12;
      }
      console.log(value, "width from watch");
    },
    minY() {
      console.log(this.minY, "from watcheer");
    },
    isZoomEnable(val) {
      if (!val) {
        this.draggedRangeElement = false;
        this.$refs.canvasXyAxisGraph.resetZoom();
      }
    },
    themeColor() {
      if (
        this.gridColor &&
        this.axisLabelColor &&
        this.secondaryAxisLabelColor
      ) {
        this.refreshXyCanvasKey += 22;
      }
    },
    isSecondaryAxis() {
      this.buildSVG();
    },
    gridColor(val) {
      // this.buildSVG();
      // selectAll('.yaxis-grid').style("color", val);
      this.refreshXyCanvasKey += 13;
    },
    gridBackground() {
      this.buildSVG();
    },
    axisLabelColor() {
      // this.buildSVG();
      // selectAll(".primary_xlabel").style("fill",val);
      this.refreshXyCanvasKey += 9;
    },
    scale() {
      this.refreshXyCanvasKey += 13;
    },
    graphScaleColor() {
      // this.buildSVG();
      // select(".primary_line").attr("stroke",val);
      this.refreshXyCanvasKey += 14;
    },
    xAxisLabelSize() {
      // this.buildSVG();
      this.refreshXyCanvasKey += 18;
    },
    yAxisLabelSize() {
      this.refreshXyCanvasKey += 19;
    },
    secondaryAxisLabelColor(val) {
      // this.buildSVG();
      // select(".secondary_ylabel").style("fill",val);
      this.refreshXyCanvasKey += 11;
    },
    graphSecondaryScaleColor(val) {
      // this.buildSVG();
      // select(".secondary_line").attr("stroke",val);
      this.refreshXyCanvasKey += 15;
    },
    yAxisSecondaryLabelSize() {
      // this.buildSVG();
      this.refreshXyCanvasKey += 21;
    },
    async primaryXaxisFullName(val, old) {
      // this.buildSVG();
      if (val?.value) {
        if (
          this.logType == "time" &&
          !this.draggedRangeElement &&
          this.isWellLive
        ) {
          this.draggedRangeElement = true;
          if (this.primaryXaxisFullName && this.primaryXaxisFullName.value) {
            // await this.prepareGraphDetail(this.primaryXaxisFullName.value);
          }
          if (
            this.secondaryYaxisFullName &&
            this.secondaryYaxisFullName.value
          ) {
            // await this.prepareGraphDetail(this.secondaryYaxisFullName.value);
          }
          this.draggedRangeElement = false;
        } else {
          // await this.prepareGraphDetail(val.value);
        }
        // this.prepareGraphDetail(val.value);
        // await this.getRealtimeData();
      }
    },
    primaryYaxisUnit() {
      this.buildSVG();
    },
    primaryYaxisFullName(val) {
      // this.buildSVG();
      if (val) {
        // this.prepareGraphDetail(val.value);
      }
    },
    primaryXaxisUnit() {
      this.buildSVG();
    },
    async secondaryYaxisFullName(val, old) {
      // this.buildSVG();
      // if (val?.value) {
      //   if (
      //     this.logType == "time" &&
      //     !this.draggedRangeElement &&
      //     this.isWellLive
      //   ) {
      //     this.draggedRangeElement = true;
      //     if (this.primaryXaxisFullName && this.primaryXaxisFullName.value) {
      //       await this.prepareGraphDetail(this.primaryXaxisFullName.value);
      //     }
      //     if (
      //       this.secondaryYaxisFullName &&
      //       this.secondaryYaxisFullName.value
      //     ) {
      //       await this.prepareGraphDetail(val.value);
      //     }
      //     this.draggedRangeElement = false;
      //   } else {
      //     await this.prepareGraphDetail(val.value);
      //   }
      //   await this.getRealtimeData();
      // }
    },
    secondryYaxisUnit() {
      this.buildSVG();
    },
    widgetHeight(val) {
      this.height = val;
      this.cHeight = val;
      this.refreshXyCanvasKey += 6;
      // this.buildSVG();
    },
    widgetWidth(val) {
      this.width = val;
      // this.buildSVG();
      this.cWidth = val;
      this.refreshXyCanvasKey += 7;
    },
    selectedMenomenics(newVal, oldVal) {
      console.log("selectedMenomenics---", newVal, oldVal);
    },
  },
  beforeUnmount() {
    this.completeData = null;
    this.takeOffSocket();
  },
};
</script>
<style scoped>
h1 {
  font-size: 20px;
}

.axis-grids .tick {
  display: none !important;
}

.text-xxs {
  font-size: 9px;
}

.tool_section {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 4px;
}

.tool_toggle_label {
  cursor: pointer;
  text-indent: -9999px;
  width: 23px;
  height: 12px;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tool_toggle_input:checked+._tooltip {
  /* background: #5aba81; */
  background: var(--activeTextColor);
}

.tool_toggle_input:checked+._alert {
  /* background: #ff2200; */
  background: var(--activeTextColor);
}

.tool_toggle_input:checked+._zoom {
  /* background: #00bcd4; */
  background: var(--activeTextColor);
}

.tool_toggle_input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.tool_toggle_label {
  cursor: pointer;
  text-indent: -9999px;
  width: 23px;
  height: 12px;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tool_toggle_label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 10px;
  height: 10px;
  background: var(--sidebarbg);
  border-radius: 90px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.tool_toggle_label {
  /* background: #5aba81; */
  background: var(--toggleSliderBg);
}

.tool_toggle_input:checked+.tool_toggle_label:after {
  /* left: calc(100% - 2px); */
  -webkit-transform: translateX(9px);
  transform: translateX(9px);
}

.tooltipd3 {
  position: absolute;
  padding: 10px;
  background-color: steelblue;
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  display: none;
  opacity: 0.75;
}

.tool_group {
  position: fixed;
  display: inline-grid;
  /* left: 221px; */
  right: 162px;
  z-index: 99999;
}

.actc_tool {
  font-size: 10px;
  background: #000000;
  width: auto;
  position: absolute;
  padding: 2px 5px;
  /* border-radius: 20px; */
}

.timeList {
  background: var(--colorBoxBg);
  color: var(--selectingTimeFeetColor);
  /* box-shadow: 0 4px 10px 4px rgba(0,0,0,.15); */
  transition: var(--transition);
  padding: 0 7px;
  border-radius: 8px;
  font-size: 10px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeList:hover {
  background: var(--activeTextColor);
  color: #fff;
}

.activeRecord {
  background: var(--activeTextColor);
  color: #fff;
}

.newStylesLiveBtn {
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  padding: 2px 8px;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 9px;
  color: #fff;
}

.scrollable-container {
  /* Add styles for your scrollable container if needed */
  overflow-y: auto;
  /* Enable vertical scroll */
}

.liveBtnDot {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.newStWDeptInput {
  background: var(--colorBoxPopUpInputBg) !important;
  border: none;
  color: var(--textColor) !important;
  font-weight: 600;
  text-align: center;
  height: 20px;
  width: 60px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.activeRecord[disabled] {
  /* opacity: 0.9; */
  pointer-events: none;
}

.pagination-container {
  /* background-color: #000000; */
  font-size: 12px;
  padding: 10px;
}

.pagination-container {
  font-size: 15px;
  /* background-color: rgb(39, 38, 38); */
  padding: 0;
  overflow: hidden;
}

.print-button {
  font-size: 10px;
}

.runInfo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  /* right: 40%; */
}

.fieldHeight {
  height: 25px;
}



.popupforupload {
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  max-height: 60vh;

}

.popup {
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
}

.blacktheme {
  background-color: black;
}

.whitetheme {
  background-color: beige;
}

.print {
  width: 1800px;
}

.deletepopupwhite {
  background-color: rgb(60, 63, 63);
  color: white;
  font-size: 15px !important;
}

.deletepopup {
  background-color: beige;
  color: rgb(49, 48, 48);
  font-size: 15px !important
}

.button-conform {
  background-color: rgb(66, 64, 64);
  color: white;
  border-radius: 8px;
  font-size: 12px !important;
  font-weight: 900;
}

.save-button {
  font-size: 15px;
  background-color: var(--colorBoxBg);
  color: var(--textColor);
  padding: 3px 10px
}

.file-container {
  color: var(--textColor);
  /* background-color: var(--colorBoxBg); */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

}

.input-file {

  font-size: 12px;
  height: 50px
}

.form {
  /* background-color: var(--colorBoxBg);
  color: var(--textColor) */
  background-color: inherit;
}

.uploadwhite {
  padding: 5px;
  background-color: var(--activeTextColor);
  border-radius: 8px;
  font: bold;
  color: white;
  font-size: 10px;
  padding: 5px 10px
}

.uploadwhite:hover {
  background-color: black;

  color: beige;
  transition: all 2s;

}

.uploadblack {
  padding: 5px;
  background-color: var(--activeTextColor);
  border-radius: 8px;
  font: bold;
  color: white;
  font-size: 10px;
  padding: 5px 10px
}

.uploadblack:hover {
  background-color: white;
  color: black;
  transition: all 2s;

}

.cross-button {
  right: 15px !important;
  top: 9px !important;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  width: 60%
}

.disabled {
  opacity: 20px;
  background-color: var(--activeTextColor);
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled:hover {

  background-color: var(--activeTextColor) !important;
  color: white !important;
  opacity: 0.5;
  cursor: not-allowed;

}

.text {
  font-size: 14px !important
}

.row-text {
  font-size: 13px !important
}

.border-table {
  border: 1px solid gray
}

.table-border {
  border: 1px solid var(--nav1BgClr) !important
}
</style>
